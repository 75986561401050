import { IconTypes } from "@components/atoms";

export type GameType = {
	type: string;
	name: string;
	imageUrl: string;
	secondaryName: string;
	labelText?: string;
	labelIcon?: IconTypes;
	url?: string;
	comingSoon?: boolean;
};

export const gamesConfig: GameType[] = [
	{
		type: "pvh",
		name: "Roulette",
		secondaryName: "Game of chance",
		imageUrl: "/game-cards/roulette-card.png",
		// ...(import.meta.env.GAMBLINO_IS_PROD === "true" && {
		// 	comingSoon: true,
		// 	labelText: "SOON",
		// }),
		comingSoon: false,
		labelText: "PvH",
		url: "/games/roulette",
		labelIcon: "USERS2",
	},
	{
		type: "pvh",
		name: "Flip Master",
		secondaryName: "Heads or tails",
		imageUrl: "/game-cards/flip-master-card.png",
		// comingSoon: true,
		// labelText: "SOON",
		// ...(import.meta.env.GAMBLINO_IS_PROD === "true" && {
		// 	comingSoon: true,
		// 	labelText: "SOON",
		// }),
		// ...(import.meta.env.GAMBLINO_IS_PROD === "false" && {
		comingSoon: false,
		labelText: "PvH",
		url: "/games/flip-master",
		labelIcon: "USERS2",
		// }),
	},
	{
		type: "pvh",
		name: "Dice",
		secondaryName: "Roll to win",
		imageUrl: "/game-cards/dice-card.png",
		// comingSoon: true,
		// labelText: "SOON",
		// ...(import.meta.env.GAMBLINO_IS_PROD === "true" && {
		// 	comingSoon: true,
		// 	labelText: "SOON",
		// }),
		// ...(import.meta.env.GAMBLINO_IS_PROD === "false" && {
		comingSoon: false,
		labelText: "PvH",
		url: "/games/dice",
		labelIcon: "USERS2",
		// }),
	},
	{
		type: "pvh",
		name: "Mines",
		secondaryName: "mines",
		imageUrl: "/game-cards/mines-card.png",
		comingSoon: false,
		labelText: "PvH",
		url: "/games/mines",
		labelIcon: "USERS2",
		// ...(import.meta.env.GAMBLINO_IS_PROD === "true" && {
		// 	comingSoon: true,
		// 	labelText: "SOON",
		// }),
		// ...(import.meta.env.GAMBLINO_IS_PROD === "false" && {
		// 	comingSoon: true,
		// 	labelText: "SOON",
		// }),
	},
	{
		type: "pvp",
		name: "Market Minds",
		secondaryName: "Predict to win",
		comingSoon: true,
		imageUrl: "/game-cards/market-minds-card.png",
		labelText: "SOON",
		// ...(import.meta.env.GAMBLINO_IS_PROD === "true" && {
		// 	comingSoon: true,
		// 	labelText: "SOON",
		// }),
		// ...(import.meta.env.GAMBLINO_IS_PROD === "false" && {
		// 	comingSoon: false,
		// 	labelText: "PvP",
		// 	url: "/games/market-minds",
		// 	labelIcon: "USERS2",
		// }),
	},

	{
		type: "pvp",
		name: "Adrenalin Wheel",
		secondaryName: "Prize pool",
		imageUrl: "/game-cards/adrenaline-wheel-card.png",
		comingSoon: true,
		labelText: "SOON",
		// ...(import.meta.env.GAMBLINO_IS_PROD === "true" && {
		// 	comingSoon: true,
		// 	labelText: "SOON",
		// }),
		// ...(import.meta.env.GAMBLINO_IS_PROD === "false" && {
		// 	comingSoon: false,
		// 	labelText: "PvP",
		// 	url: "/games/adrenaline-wheel",
		// 	labelIcon: "USERS2",
		// }),
	},

	{
		type: "soon",
		name: "Gamblino Lottery",
		secondaryName: "Buy the glory",
		imageUrl: "/game-cards/lottery-card.png",
		comingSoon: true,
		labelText: "SOON",
		// ...(import.meta.env.GAMBLINO_IS_PROD === "true" && {
		// 	comingSoon: true,
		// 	labelText: "SOON",
		// }),
		// ...(import.meta.env.GAMBLINO_IS_PROD === "false" && {
		// 	comingSoon: true,
		// 	labelText: "SOON",
		// }),
	},
	{
		type: "soon",
		name: "Plinko",
		secondaryName: "plinko",
		imageUrl: "/game-cards/plinko-card.png",
		comingSoon: true,
		labelText: "SOON",
		// ...(import.meta.env.GAMBLINO_IS_PROD === "true" && {
		// 	comingSoon: true,
		// 	labelText: "SOON",
		// }),
		// ...(import.meta.env.GAMBLINO_IS_PROD === "false" && {
		// 	comingSoon: true,
		// 	labelText: "SOON",
		// }),
	},

	{
		type: "soon",
		name: "Crash",
		secondaryName: "crash",
		imageUrl: "/game-cards/crash-card.png",
		comingSoon: true,
		labelText: "SOON",
		// ...(import.meta.env.GAMBLINO_IS_PROD === "true" && {
		// 	comingSoon: true,
		// 	labelText: "SOON",
		// }),
		// ...(import.meta.env.GAMBLINO_IS_PROD === "false" && {
		// 	comingSoon: true,
		// 	labelText: "SOON",
		// }),
	},
];
