import { Address } from 'viem';
import { wagmiConfig } from 'wallet-connection';
import { readContract } from "wagmi/actions";

import { abi } from "../../config";

const getLastUserBets = async ({
	userAddress,
	dataLength,
	contractAddress,
}: {
	userAddress: Address;
	dataLength: number;
	contractAddress: Address;
	}) => {
	
	const result = await readContract(wagmiConfig, {
		abi,
		address: contractAddress,
		functionName: "getLastUserBets",
		args: [userAddress, BigInt(dataLength)],
	});

	return { result };
};

export { getLastUserBets };
