import React from "react";
import { cx } from "src/utils";

const BallSvg: React.FC<{ className?: string }> = ({ className }) => {
	return (
		<svg
			viewBox="0 0 308 308"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={cx("rotate-[4.7deg] overflow-visible", className)}
		>
			<g
			//  clipPath="url(#clip0_2715_8543)"
			>
				<path
					data-element-type="ball"
					d="M162 278.5C162 282.09 159.09 285 155.5 285C151.91 285 149 282.09 149 278.5C149 274.91 151.91 272 155.5 272C159.09 272 162 274.91 162 278.5Z"
					fill="url(#paint37_radial_2715_8543)"
				/>
			</g>
			<defs>
				<clipPath id="clip0_2715_8543">
					<rect width="308" height="308" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export { BallSvg };
