import { Address, parseUnits } from "viem";
import { Button } from "@components/molecules";
import { dice } from "business-logic-gamblino";
import React from "react";
import { cx } from "src/utils";
import { useWindowSize } from "usehooks-ts";
import { useAccount } from "wagmi";
import { AnimatePresence, motion } from "framer-motion";
import * as confetti from "canvas-confetti";
import ReactCanvasConfetti from "react-canvas-confetti";
import wait from "wait";
import { useAudioManager } from "src/redux";
import { toast } from "sonner";
import {
	CONTRACT_ADDRESS_GAMBLINO_BANK,
	MIN_BET_AMOUNT_DICE,
} from "src/config";
import { coerce, z } from "zod";
import { Tooltip } from "@components/atoms";
import { formatPrice } from "formatting-service";

const animationConfig: React.ComponentProps<(typeof motion)["div"]> = {
	initial: { opacity: 0, scale: 0.66 },
	animate: { opacity: 1, scale: 1 },
	exit: { opacity: 0, scale: 0.66 },
	transition: { duration: 0.5, type: "spring" },
};

const statuses = ["INITIAL", "WON", "LOST", "LOADING"] as const;
type Status = (typeof statuses)[number];

const DiceGameRollButton: React.FC<{
	contractAddress: Address;
	amount: {
		valueWei: string;
		value: string;
	};
	cap: number;
	className?: string;
}> = ({ contractAddress, amount, cap, className }) => {
	const { playSoundOnce } = useAudioManager();
	const refAnimationInstance = React.useRef(null);

	const getInstance = React.useCallback((instance) => {
		refAnimationInstance.current = instance;
	}, []);

	const { data: maxBetData, isSuccess: isSuccessMaxBetAmount } =
		dice.useCalculateMaxBetAmountQuery(
			{
				contractAddress,
				cap,
				bankContractAddress: CONTRACT_ADDRESS_GAMBLINO_BANK,
			},
			{
				pollingInterval: 5000,
			},
		);

	const makeShot = React.useCallback((particleRatio, opts) => {
		refAnimationInstance.current &&
			refAnimationInstance.current({
				...opts,
				origin: { y: 0.7 },
				particleCount: Math.floor(200 * particleRatio),
			});
	}, []);

	const fire = React.useCallback(() => {
		makeShot(0.25, {
			spread: 26,
			startVelocity: 55,
		});

		makeShot(0.2, {
			spread: 60,
		});

		makeShot(0.35, {
			spread: 100,
			decay: 0.91,
			scalar: 0.8,
		});

		makeShot(0.1, {
			spread: 120,
			startVelocity: 25,
			decay: 0.92,
			scalar: 1.2,
		});

		makeShot(0.1, {
			spread: 120,
			startVelocity: 45,
		});
	}, [makeShot]);
	const { isConnected: isUserConnected } = useAccount();
	const { width } = useWindowSize();

	const [wager, { data, isLoading, isSuccess, isError, error }] =
		dice.useWagerMutation({ fixedCacheKey: `useWagerMutation` });

	const [status, setStatus] = React.useState<Status>("INITIAL");

	React.useEffect(() => {
		if (status !== "INITIAL") {
			return;
		}

		if (isLoading) {
			setStatus("LOADING");
		}
	}, [isLoading]);

	React.useEffect(() => {
		if (status !== "LOADING") {
			return;
		}

		void (async () => {
			// await wait();
			if (isSuccess && data) {
				setStatus(data.isWin ? "WON" : "LOST");
				playSoundOnce(
					data.isWin
						? "/10046325_magic-coin_by_gamechestaudio_preview.mp3"
						: "/15344564_wrong-answer_by_gamechestaudio_preview.mp3",
				);
				if (data.isWin) {
					await wait(600);
					fire();
				}

				await wait(5000);
			}

			setStatus("INITIAL");
		})();
	}, [isSuccess, data?.isWin, isLoading]);

	const schema = z
		.bigint()
		.max(BigInt(maxBetData?.maxBetAmountWei || 0n))
		.refine((value) => {
			return value >= parseUnits(`${MIN_BET_AMOUNT_DICE}`, 18);
		});

	const renderButton = () => {
		if (status === "INITIAL") {
			if (schema.safeParse(BigInt(amount.valueWei)).success) {
				return (
					<motion.div
						className="w-full"
						key="INITIAL"
						// {...animationConfig}
					>
						<Button
							className={cx("h-[44px] w-full", className)}
							type="gradient"
							onClick={() => {
								const promise = wager({
									contractAddress,
									amountWei: amount.valueWei,
									cap,
								}).unwrap();

								toast.promise(promise, {
									error: "Failed to roll",
								});
							}}
							disabled={
								parseFloat(amount.value) <= 0 ||
								amount.value === "" ||
								isLoading ||
								!isUserConnected
							}
						>
							<Button.Text>Roll</Button.Text>
						</Button>
					</motion.div>
				);
			}

			const message = (() => {
				if (!isUserConnected) {
					return "Wallet Not Connected";
				}

				if (!isSuccessMaxBetAmount) {
					return "Loading";
				}

				if (
					BigInt(maxBetData.maxBetAmountWei) <
					parseUnits(`${MIN_BET_AMOUNT_DICE}`, 18)
				) {
					return "Not enough balance in the bank for current multiplier";
				}

				if (
					BigInt(amount.valueWei) > BigInt(maxBetData.maxBetAmountWei)
				) {
					return `Max bet amount is currently ${formatPrice(+maxBetData?.maxBetAmount, { decimals: 4 })}`;
				}

				return `Min bet amount is currently ${MIN_BET_AMOUNT_DICE}`;
			})();

			return (
				<Tooltip className="w-full" content={<p>{message}</p>}>
					<motion.div>
						<Button
							className={cx("h-[44px] w-full", className)}
							type="gradient"
							onClick={() => {
								const promise = wager({
									contractAddress,
									amountWei: amount.valueWei,
									cap,
								}).unwrap();

								toast.promise(promise, {
									error: "Failed to roll",
								});
							}}
							disabled={true}
						>
							<Button.Text>Roll</Button.Text>
						</Button>
					</motion.div>
				</Tooltip>
			);
		} else if (status === "LOADING") {
			return (
				<motion.div
					key="LOADING"
					className="w-full"
					{...animationConfig}
				>
					<Button
						onClick={() => {}}
						type="gradient"
						disabled
						className={cx("h-[44px] w-full", className)}
					>
						<Button.Icon
							type="LOADER"
							className={cx("animate-spin")}
						/>
						<Button.Text>
							{width >= 768 ? "Generating randomness..." : ""}
						</Button.Text>
					</Button>
				</motion.div>
			);
		} else if (status === "WON") {
			return (
				<motion.div
					key="WON"
					{...animationConfig}
					className="relative w-full"
				>
					<ReactCanvasConfetti
						refConfetti={getInstance}
						className="pointer-events-none absolute left-1/2 top-0 z-0 h-[1000px] w-[700px] -translate-x-1/2 -translate-y-2/3"
					/>
					<Button
						onClick={() => {}}
						className={cx(
							"h-[44px] w-full animate-bounce",
							className,
						)}
					>
						<Button.Text>You Won</Button.Text>
					</Button>
				</motion.div>
			);
		} else if (status === "LOST") {
			return (
				<motion.div className="w-full" key="LOST" {...animationConfig}>
					<Button
						onClick={() => {}}
						type="secondary"
						className={cx(
							"animate-shake animate-twice animate-ease-in-out h-[44px] w-full",
							className,
						)}
					>
						<Button.Text>You Lost</Button.Text>
					</Button>
				</motion.div>
			);
		}
	};
	return <AnimatePresence mode="wait">{renderButton()}</AnimatePresence>;
};

export default DiceGameRollButton;
