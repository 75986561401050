import { TooltipNumberWrapper } from "@components/molecules";
import React from "react";
import { AssetIconDice } from "../..";

const BetAmountCell: React.FC<{
	amount: number;
}> = ({ amount }) => {
	return (
		<div className="flex items-center gap-1">
			<AssetIconDice className="h-5 w-5" />
			<TooltipNumberWrapper number={amount} copy />
		</div>
	);
};

export { BetAmountCell };
