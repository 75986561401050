import mockedData from "./mockedData";
import { schema } from "./schema";
import { createRequestQueryFunction } from "query-function-creators";

const requestTestEth = createRequestQueryFunction({
	getAxiosRequestConfig: ({
		email,
		userAddress,
	}: {
		email: string;
		userAddress: string;
	}) => ({
		url: `${import.meta.env.BACKEND_SERVICE_GAMBLINO_BASE_URL}/faucet`,
		method: "POST",
		data: {
			email,
			address: userAddress,
		},
	}),
	schema,
	getMockedData: () => mockedData.response1,
	isMockingEnabled: false,
});

export { requestTestEth };
