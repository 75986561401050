import { FullScreenLoader } from "@components/atoms";
import { SimpleDashboardGameLayout } from "../SimpleDashboardGameLayout";
import React from "react";
import { cx } from "src/utils";
import { useWindowSize } from "usehooks-ts";
import { AnimatePresence, motion } from "framer-motion";

const transition = {
	delay: 2.5,
	duration: 2.6,
	type: "spring",
	bounce: 0.04,
};

const SimpleDashboardGameWithLoadingLayout: React.FC<
	React.ComponentPropsWithoutRef<typeof SimpleDashboardGameLayout> & {
		isLoading: boolean;
		loadingImageUrl: string;
	}
> = ({
	isLoading,
	loadingImageUrl,
	bgImageUrl,
	gameComponent,
	children,
	className,
	imgClassName,
}) => {
	const { height } = useWindowSize();
	return (
		<AnimatePresence mode="popLayout">
			{isLoading && (
				<motion.div
					key="loading"
					initial={{ opacity: 1 }}
					animate={{ opacity: 1, y: 0, scale: 1 }}
					exit={{ opacity: 0, y: -height, scale: 0.6 }}
					transition={transition}
					className={cx(
						"z-2 content-badge-alert h-full max-h-screen w-full",
						className,
					)}
				>
					<FullScreenLoader imageUrl={loadingImageUrl} />
				</motion.div>
			)}
			{!isLoading && (
				<motion.div
					key="page"
					initial={{ opacity: 0, y: height, scale: 0.86 }}
					animate={{ opacity: 1, y: 0, scale: 1 }}
					exit={{ opacity: 0 }}
					transition={transition}
					className={cx("", className)}
				>
					<SimpleDashboardGameLayout
						{...{
							bgImageUrl,
							gameComponent,
							children,
							imgClassName,
						}}
					/>
				</motion.div>
			)}
		</AnimatePresence>
	);
};

export { SimpleDashboardGameWithLoadingLayout };
