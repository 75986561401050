import React from "react";
import { cx } from "src/utils";
import { ActiveBets } from "../ActiveBets";
import { SettledBets } from "../SettledBets";

const ActiveSettledBetTabs: React.FC<{ className?: string }> = ({
	className,
}) => {
	const [active, setActive] = React.useState(true);
	return (
		<div>
			<div className={cx("mt-1 flex justify-center gap-2", className)}>
				<div
					className={cx("cursor-pointer text-xs", {
						"opacity-50": !active,
					})}
					onClick={() => {
						setActive(true);
					}}
				>
					Active bets
				</div>
				<p className="text-xs opacity-50">|</p>
				<div
					className={cx("cursor-pointer text-xs", {
						"opacity-50": active,
					})}
					onClick={() => {
						setActive(false);
					}}
				>
					Settled bets
				</div>
			</div>

			{active ? (
				<ActiveBets className="mt-1" />
			) : (
				<SettledBets className="mt-1" />
			)}
		</div>
	);
};

export { ActiveSettledBetTabs };
