import React from "react";
import { useHover } from "usehooks-ts";

import { cx } from "src/utils";
import { AssetIcon } from "@components/atoms";
import { Button } from "@components/molecules";

const RouletteButton: React.FC<{
	value: string | number;
	placed?: boolean;
	onClick: () => void;
	onHover?: (isHover: boolean) => void;
	className?: string;
	type?: "GREEN" | "RED" | "BLACK" | "DEFAULT";
}> = ({ className, placed, value, type = "DEFAULT", onClick, onHover }) => {
	const hoverRef = React.useRef(null);
	const isHover = useHover(hoverRef);

	React.useEffect(() => {
		if (!onHover) {
			return;
		}
		onHover(isHover);
	}, [isHover]);

	return (
		<div className={cx("min-w-[32px] flex-1", className)} ref={hoverRef}>
			<Button
				className={cx(
					"border-border-primary h-full w-full rounded border px-[1px] py-2",
					{
						"bg-content-badge-error": type === "RED",
						"bg-[#22C55E]": type === "GREEN",
						"bg-background-secondary": type === "DEFAULT",
						"bg-background-tertiary": type === "BLACK",
					},
				)}
				onClick={onClick}
			>
				{placed ? (
					<AssetIcon
						className="relative w-4 shrink-0"
						type="GAMBLINO_COIN"
					/>
				) : (
					<p>{value}</p>
				)}
			</Button>
		</div>
	);
};

export { RouletteButton };
