import wait from "wait";
import React from "react";

import { cx } from "src/utils";
import { GameSection } from "./GameSection";
import { CONTRACT_ADDRESS_MINES } from "src/config";
import { PastPredictionsSection } from "./PastPredictionsSection";
import { SimpleDashboardGameWithLoadingLayout } from "@components/layouts";

const MinesPage: React.FC<{ className?: string }> = ({ className }) => {
	const contractAddress = CONTRACT_ADDRESS_MINES;

	const [isSuccess, setIsSuccess] = React.useState<boolean>(false);

	React.useEffect(() => {
		void (async () => {
			await wait(1500);
			setIsSuccess(true);
		})();
	}, []);

	return (
		<SimpleDashboardGameWithLoadingLayout
			bgImageUrl="/games-bg.png"
			className={cx("", className)}
			gameComponent={<GameSection contractAddress={contractAddress} />}
			isLoading={!isSuccess}
			loadingImageUrl="/game-cards/mines-card.png"
		>
			<PastPredictionsSection contractAddress={contractAddress} />
		</SimpleDashboardGameWithLoadingLayout>
	);
};

export { MinesPage };
