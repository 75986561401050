import { createSlice } from "@reduxjs/toolkit";
import { type PayloadAction } from "@reduxjs/toolkit";

const initialState: {
	asideStatus: boolean;
} = {
	asideStatus: true,
};

const asideStatusSlice = createSlice({
	name: "asideStatus",
	initialState,
	reducers: {
		setAsideStatus: (state, action: PayloadAction<boolean>) => {
			state.asideStatus = action.payload;
		},
	},
});

export { asideStatusSlice };
