import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

const maxSelection = [
	24, 21, 17, 14, 12, 10, 9, 8, 7, 6, 5, 5, 4, 4, 3, 3, 3, 2, 2, 2, 2, 1, 1,
	1,
];

const initialState: {
	fields: boolean[];
	mines: number;
	maxSelection: number[];
} = {
	fields: Array(25).fill(false),
	mines: 0,
	maxSelection,
};

const minesSlice = createSlice({
	name: "minesSlice",
	initialState,
	reducers: {
		resetMines: (state) => {
			state.fields = Array(25).fill(false);
		},
		setMines: (state, { payload }: PayloadAction<number>) => {
			if (payload < 1 || payload > 24) {
				return;
			}

			state.mines = payload;
		},
		toggleSelection: (
			state,
			{
				payload,
			}: PayloadAction<{
				index: number;
			}>,
		) => {
			if (state.fields[payload.index]) {
				state.fields[payload.index] = false;
				return;
			}

			state.fields[payload.index] = true;
		},
	},
});

export { minesSlice };
