import { abi } from "../../config";
import { readContract } from "wagmi/actions";
import { wagmiConfig } from "wallet-connection";
import { Address } from "viem";

const getLastUserBets = async ({
	user,
	contractAddress,
	length,
}: {
	user: Address;
	contractAddress: Address;
	length: number;
}) => {
	const result = await readContract(wagmiConfig, {
		abi,
		address: contractAddress,
		functionName: "getLastUserBets",
		args: [user, BigInt(length)],
	});

	return result;
};

export { getLastUserBets };
