import React from "react";
import { zeroAddress } from "viem";
import { useAccount, useConfig } from "wagmi";
import { useLocation } from "react-router-dom";
import { gamblinoBusinessLogicApi } from "business-logic-gamblino";

import { useModalManager } from "./ModalsWrapper";

const AddressNotParallelizedWrapper = () => {
	const { modalManager } = useModalManager();

	const location = useLocation();
	const { chainId, isConnected, address: userAddress } = useAccount();

	const wagmiConfig = useConfig();

	const [isFound, setIsFound] = React.useState<boolean>(false);

	const isCorrectChain = chainId && chainId === wagmiConfig.chains[0].id;

	const { data, isLoading, isUninitialized, fulfilledTimeStamp } =
		gamblinoBusinessLogicApi.useGetSeiParallelizedAddressQuery(
			{
				userAddress: userAddress || zeroAddress,
			},
			{ skip: !isConnected || isFound, pollingInterval: 3_000 },
		);

	const isOnSei =
		import.meta.env.WALLLET_CONNECTION_VARIANT === "SEI_MAINNET" ||
		import.meta.env.WALLLET_CONNECTION_VARIANT === "SEI_TESTNET";

	const shouldOpenModal =
		isCorrectChain &&
		location?.pathname !== "/" &&
		!isLoading &&
		!isUninitialized &&
		!data?.isParallelized &&
		isOnSei;

	React.useEffect(() => {
		if (shouldOpenModal) {
			modalManager.open("AddressNotParallelizedModal", {});
		} else {
			modalManager.close("AddressNotParallelizedModal");
		}
	}, [shouldOpenModal, location?.pathname]);

	React.useEffect(() => {
		setIsFound(false);
	}, [userAddress]);

	React.useEffect(() => {
		if (data?.isParallelized) {
			setIsFound(true);
		}
	}, [fulfilledTimeStamp, data?.isParallelized]);

	return <></>;
};

export { AddressNotParallelizedWrapper };
