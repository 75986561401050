import React from "react";
import { Address, parseUnits } from "viem";
import { dice } from "business-logic-gamblino";

import { cx } from "src/utils";
import { AssetIconTypeDice } from "../..";
import { AssetIcon, Tooltip } from "@components/atoms";
import { Input } from "@components/molecules/Input/Input";
import { TooltipNumberWrapper } from "@components/molecules";
import DiceGameRollButton from "../RollButton/DiceGameRollButton";
import { CONTRACT_ADDRESS_GAMBLINO_BANK } from "src/config";
import { z } from "zod";
import { formatPrice } from "formatting-service";

const DiceGameSectionForm: React.FC<{
	contractAddress: Address;
	className?: string;
	rollOverValue: number[];
	onChangeRollOver: (e: React.SetStateAction<string>) => void;
}> = ({ contractAddress, className, rollOverValue, onChangeRollOver }) => {
	const [totalAmountValue, setTotalAmountValue] = React.useState<{
		value: string;
		valueWei: string;
	}>({
		value: "",
		valueWei: "",
	});

	const { data: maxBetData, isSuccess } = dice.useCalculateMaxBetAmountQuery(
		{
			contractAddress,
			cap: +rollOverValue[0],
			bankContractAddress: CONTRACT_ADDRESS_GAMBLINO_BANK,
		},
		{
			pollingInterval: 5000,
		},
	);

	const [, { isLoading: isGamePlaying }] = dice.useWagerMutation({
		fixedCacheKey: `useWagerMutation`,
	});

	const multiplier = +(100 / (100 - +rollOverValue[0])) * 0.95;

	const winChance = 100 - +rollOverValue;
	const profitOnWin = (+totalAmountValue.value * +multiplier).toFixed(4);

	const handleSetTotalAmount = (value: string) => {
		const regex = /^-?\d*(\.\d*)?$/;

		if (value === "" || regex.test(value.toString())) {
			setTotalAmountValue({
				value,
				valueWei: parseUnits(value, 18).toString(),
			});
		}
	};

	const schema = z
		.bigint()
		.max(BigInt(maxBetData?.maxBetAmountWei || 0n))
		.refine((value) => {
			return value >= parseUnits("2", 18);
		});

	return (
		<div className={cx("flex flex-col gap-4", className)}>
			<div className="bg-background-tertiary flex flex-col items-center justify-center gap-1 rounded-lg p-4">
				<div className="flex items-center gap-2 text-3xl">
					<TooltipNumberWrapper
						number={profitOnWin}
						formatPriceConfig={{
							decimals: 2,
						}}
					/>
					<AssetIcon type={"SEI"} />
				</div>
				<p className="text-content-secondary text-sm">Target Payout</p>
			</div>

			<div className="flex w-full items-center gap-4">
				<div className="bg-background-tertiary flex flex-1 flex-col items-center justify-center gap-1 rounded-lg p-4">
					<div className="flex items-center gap-2 text-3xl">
						<TooltipNumberWrapper
							number={multiplier}
							formatPriceConfig={{
								labelPosition: "right",
								currencyLabel: "x",
							}}
						/>
					</div>
					<p className="text-content-secondary text-sm">Multiplier</p>
				</div>
				<div className="bg-background-tertiary flex flex-1 flex-col items-center justify-center gap-1 rounded-lg p-4">
					<div className="flex items-center gap-2 text-3xl">
						<TooltipNumberWrapper
							number={winChance}
							formatPriceConfig={{
								labelPosition: "right",
								currencyLabel: "%",
							}}
						/>
					</div>
					<p className="text-content-secondary text-sm">Win Chance</p>
				</div>
			</div>

			<div>
				<div className="text-content-secondary flex w-full items-center justify-between px-1  text-sm">
					<p>Enter Amount</p>

					<span className="text-content-primary">
						<TooltipNumberWrapper
							number={0}
							formatPriceConfig={{
								decimals: 2,
								labelPosition: "left",
								currencyLabel: "$",
							}}
						/>
					</span>
				</div>
				<Input
					type="text"
					value={totalAmountValue.value}
					onChange={(e) =>
						handleSetTotalAmount(e.currentTarget.value)
					}
					placeholder="0"
					name="TotalAmount"
					className={cx("h-[44px]", {
						"border-background-error": !schema.safeParse(
							BigInt(totalAmountValue.valueWei),
						).success,
					})}
					wrapperClassName="w-full"
				>
					<Input.AssetIcon type={AssetIconTypeDice} />
					<Input.Button
						type="tertiary"
						size="sm"
						disabled={!isSuccess || isGamePlaying}
						className="h-9 self-center"
						onClick={() => {
							if (!isSuccess) {
								return;
							}
							setTotalAmountValue({
								value: maxBetData.maxBetAmount,
								valueWei: maxBetData.maxBetAmountWei,
							});
						}}
					>
						<Input.Button.Text>MAX</Input.Button.Text>
					</Input.Button>
				</Input>
			</div>

			<div className="w-full">
				<DiceGameRollButton
					contractAddress={contractAddress}
					amount={totalAmountValue}
					cap={+rollOverValue[0]}
					className=""
				/>
			</div>
		</div>
	);
};

export default DiceGameSectionForm;
