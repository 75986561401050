import React from "react";
import { useAccount } from "wagmi";
import { leaderboard } from "business-logic-gamblino";

import { cx } from "src/utils/cx";
import UserStatCard from "./UserStatCard/UserStatCard";
import { Avatar, TooltipNumberWrapper } from "@components/molecules";
import { truncateEthAddress } from "formatting-service";
import { Icon } from "@components/atoms";

const UserStats = () => {
	const { address, isConnected: isUserConnected } = useAccount();
	const { data, isSuccess } = leaderboard.useLeaderboardPointsQuery({
		userAddress: address?.toLowerCase(),
	});

	if (!isUserConnected) {
		return null;
	}

	if (!isSuccess) {
		return null;
	}

	if (data.userLeaderboard === null) {
		return null;
	}

	return (
		<div className="@container/user-stats border-border-primary bg-background-secondary mt-6 flex flex-col items-center gap-4 rounded-lg border">
			<div className="bg-background-secondary flex flex-row items-center gap-1 rounded-full px-4 pt-4">
				<Avatar userAddress={address!} className=" h-6 w-6 " />
				<span>{truncateEthAddress(address!)}</span>
			</div>
			<div className="bg-border-primary h-px w-full"></div>
			<div
				className={cx(
					"@xl:grid-cols-3 grid w-full grid-cols-1 items-center gap-2 px-4 pb-4",
				)}
			>
				<UserStatCard
					label="Rank"
					descriptionTooltip="Your rank on the leaderboard"
					value={
						<TooltipNumberWrapper
							number={data.userLeaderboard.rank}
							copy
							formatPriceConfig={{
								currencyLabel: "#",
								labelPosition: "left",
								decimals: 0,
							}}
						/>
					}
					image="/leaderboard/rank.png"
				/>
				<UserStatCard
					label="Multiplier"
					value={<TooltipNumberWrapper number={"-"} copy />}
					image="/leaderboard/flame.png"
				/>

				<UserStatCard
					label="Points"
					value={
						<TooltipNumberWrapper
							number={data.userLeaderboard.totalPoints}
							copy
							formatPriceConfig={{
								currencyLabel: " pts",
							}}
						/>
					}
					image="/leaderboard/medal.png"
				/>
			</div>
		</div>
	);
};

export default UserStats;
