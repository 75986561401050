import { AssetIcon } from "@components/atoms";
import React from "react";
import { getNativeChainIconType } from "src/utils/getNativeChainIconType";

const AssetIconTypeFlipMaster: React.ComponentProps<typeof AssetIcon>["type"] =
	getNativeChainIconType();
const AssetIconFlipMaster: React.FC<{ className?: string }> = ({
	className,
}) => {
	return <AssetIcon className={className} type={AssetIconTypeFlipMaster} />;
};

export { AssetIconFlipMaster, AssetIconTypeFlipMaster };
