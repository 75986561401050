import React from "react";
import { cx } from "src/utils";
import { motion } from "framer-motion";
import { useLoadImageQuery } from "business-logic-gamblino";

const ImageWithLoading = ({
	className,
	url,
}: {
	className?: string;
	url: string;
}) => {
	const imageLoader = useLoadImageQuery({
		src: url,
	});

	const shouldRenderImageSkeleton =
		imageLoader.isLoading ||
		imageLoader.isFetching ||
		imageLoader.isUninitialized;

	return (
		<div className={cx(" ", className)}>
			<div className="relative  h-full overflow-hidden">
				<motion.div
					className="absolute inset-0 h-full w-full "
					initial={{ opacity: 1 }}
					animate={{ opacity: shouldRenderImageSkeleton ? 1 : 0 }}
					transition={{ ease: "easeInOut", delay: 0.5 }}
				>
					<div className="bg-content-primary h-full w-full animate-pulse select-none"></div>
				</motion.div>
				<img src={url} className="h-full w-full select-none" alt="" />
			</div>
		</div>
	);
};

export { ImageWithLoading };
