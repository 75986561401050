import React from "react";
import { useAudioManager } from "src/redux";

const AudioManagerWrapper: React.FC<React.PropsWithChildren> = ({
	children,
}) => {
	const { isMuted, activeAudioResource } = useAudioManager();

	return (
		<>
			{children}
			{activeAudioResource && !isMuted && (
				<audio
					key={activeAudioResource}
					controls
					autoPlay
					loop
					className="hidden"
				>
					<source src={activeAudioResource} type="audio/mp3" />
					Your browser does not support the audio element.
				</audio>
			)}
		</>
	);
};

export { AudioManagerWrapper };
