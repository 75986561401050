import { z } from "zod";

const schema = z
	.object({
		status: z.string(),
		games: z.array(
			z.object({
				betId: z.string(),
				amount: z.number(),
				betTimestamp: z.number(),
				betTxHash: z.string(),
				cap: z.number(),
				rolled: z.number(),
				isWin: z.boolean(),
				payout: z.number(),
			}),
		),
	})
	.strict();

export { schema };
