import React, { SetStateAction } from "react";

import { cx } from "src/utils";
import { Icon, IconTypes } from "@components/atoms";

const gameTypes = ["allGames", "pvp", "pvh"] as const;

type GameType = (typeof gameTypes)[number];

const GameTypeTabs: React.FC<{
	selected: GameType;
	onSelectChange: React.Dispatch<SetStateAction<GameType>>;
}> = ({ onSelectChange, selected }) => {
	return (
		<div className="mx-auto mt-5 flex max-w-[450px] flex-row justify-between">
			<GameTypeTab
				text="All games"
				type="allGames"
				icon="DICES"
				isSelected={selected === "allGames"}
				onSelectedChange={(type) => {
					onSelectChange(type);
				}}
			/>
			<GameTypeTab
				text="PvP"
				type="pvp"
				icon="USERS2"
				isSelected={selected === "pvp"}
				onSelectedChange={(type) => {
					onSelectChange(type);
				}}
			/>
			<GameTypeTab
				text="PvH"
				type="pvh"
				icon="CASTLE"
				isSelected={selected === "pvh"}
				onSelectedChange={(type) => {
					onSelectChange(type);
				}}
			/>
		</div>
	);
};

const GameTypeTab: React.FC<{
	text: string;
	type: GameType;
	icon: IconTypes;
	isSelected: boolean;
	onSelectedChange: (type: GameType) => void;
}> = ({ text, type, icon, isSelected, onSelectedChange }) => {
	return (
		<div
			className="flex flex-col items-center"
			onClick={() => {
				onSelectedChange(type);
			}}
		>
			<div
				className={cx(
					"bg-background-secondary h-12 w-12 cursor-pointer rounded-lg p-3",
					{
						"bg-content-primary": isSelected,
					},
				)}
			>
				<Icon
					type={icon}
					className={cx("stroke-content-primary h-6 w-6", {
						"stroke-background-secondary": isSelected,
					})}
				/>
			</div>
			<p className="text-content-primary mt-2">{text}</p>
		</div>
	);
};

export { GameTypeTabs };
