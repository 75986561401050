import { z } from "zod";

const schema = z
	.object({
		data: z
			.object({
				top: z.array(
					z
						.object({
							address: z.string(),
							totalScore: z.number(),
							rewardMultiplier: z.any(),
							bonusScore: z.any(),
							rank: z.number(),
						})
						.strict(),
				),
				totalScore: z.string(),
				percentOfTotalScore: z.string(),
			})
			.strict(),
	})
	.strict();

export { schema };
