import React from "react";
import { TooltipNumberWrapper } from "@components/molecules";

const MultiplierCell: React.FC<{
	multiplier: number;
}> = ({ multiplier }) => {
	return (
		<span>
			<TooltipNumberWrapper number={multiplier * 100} />x
		</span>
	);
};

export { MultiplierCell };
