import { Icon, Tooltip } from "@components/atoms";
import { cx } from "class-variance-authority";
import React from "react";

const UserStatCard: React.FC<{
	label: string;
	value: React.JSX.Element;
	image: string;
	className?: string;
	descriptionTooltip?: string;
}> = ({ label, value, image, className, descriptionTooltip }) => {
	return (
		<div
			className={cx(
				"bg-background-tertiary flex w-full flex-row items-center justify-between rounded-lg px-2 py-3",
				className,
			)}
		>
			<div className="flex flex-col items-start gap-1">
				<div className="flex flex-row items-center gap-1">
					<span className="text-content-primary whitespace-nowrap text-sm">
						{label}
					</span>
					{descriptionTooltip && (
						<Tooltip content={descriptionTooltip}>
							<Icon
								type="INFO"
								className="stroke-content-primary h-4 w-4"
							/>
						</Tooltip>
					)}
				</div>
				{value}
			</div>
			<img src={image} className="h-10 w-10" />
		</div>
	);
};

export default UserStatCard;
