import { DashboardPageSection, PageSection } from "@components/atoms";
import { Button } from "@components/molecules";
import React from "react";
import { cx } from "src/utils";

const FirstSection: React.FC<{ className?: string }> = ({ className }) => {
	return (
		<div className={cx("static rounded-3xl px-10 py-20", className)}>
			<img
				src="/page-bg.png"
				alt="bg-image"
				className="absolute left-0 right-0 top-0 w-full "
			/>
			<DashboardPageSection>
				<div
					className={cx(
						"@[800px]/dashboard:flex-row-reverse @[800px]/dashboard:items-end @[800px]/dashboard:gap-0 flex flex-col-reverse gap-6",
						className,
					)}
				>
					<div className="@[800px]/dashboard:block @[800px]/dashboard:basis-1/2 @[800px]/dashboard:pb-6 relative hidden">
						<img
							className="@[800px]/dashboard:bottom-1/2 @[800px]/dashboard:w-[210px] @[1100px]/dashboard:right-[18%] @[1100px]/dashboard:w-[260px] 2@[1100px]/dashboard:w-[300px] absolute right-0"
							src="/megaphone.png"
							alt=""
						/>
						<img src="/ribbon.svg" alt="" />
					</div>
					<div className="bg-background-secondary @[800px]/dashboard:basis-1/2 @[800px]/dashboard:py-10 @[800px]/dashboard:px-14 rounded-3xl p-5">
						<h3 className="text-content-primary @[800px]/dashboard:text-5xl text-4xl font-semibold">
							Introducing Gamblino&apos;s exclusive Referral
							Program
						</h3>
						<p className="@[800px]/dashboard:mt-6 text-content-secondary @[800px]/dashboard:text-xl mt-4 text-base">
							Designed to make your gaming experience even more
							rewarding! Share in the thrill with your friends by
							using your unique referral code, and watch as your
							rewards multiply.
						</p>
						<div className="relative w-max">
							<p className="bg-purple-light text-content-primary absolute left-1/2 top-0 z-[2] -translate-x-1/2 -translate-y-1/2 whitespace-nowrap rounded-3xl px-2 py-1 text-xs">
								COMING SOON
							</p>

							<Button
								onClick={() => {
									console.log("generate code");
								}}
								type="gradient"
								className="@[800px]/dashboard:mt-8 mt-6"
								disabled
							>
								<Button.Text>Generate Code</Button.Text>
							</Button>
						</div>
					</div>
				</div>
				<div className="@[800px]/dashboard:hidden relative mt-28">
					<img
						className="absolute bottom-auto right-0 top-0 w-[130px] -translate-y-1/2 sm:w-[180px]"
						src="/megaphone.png"
						alt=""
					/>
					<img src="/ribbon.svg" alt="" />
				</div>
			</DashboardPageSection>
		</div>
	);
};

export { FirstSection };
