import { Icon, PageSection } from "@components/atoms";
import { Button } from "@components/molecules";
import React from "react";
import { cx } from "src/utils";

const GetInTouchSection: React.FC<{ className?: string }> = ({ className }) => {
	return (
		<PageSection className={cx(" relative py-16 lg:py-28", className)}>
			<div className="bg-background-secondary border-border-primary flex h-[440px] flex-col rounded-3xl border from-30% p-5 md:h-auto lg:p-12">
				<img
					className="absolute bottom-10 left-0 z-0 w-full max-w-xs md:bottom-auto md:left-auto md:right-16 md:top-1/2 md:-translate-y-1/2 md:translate-x-0 lg:h-[331px] lg:w-[400px] lg:max-w-none xl:right-32 "
					src="/airplane-illustration.png"
					alt="airplane"
				/>
				<h3 className="text-content-primary text-4xl font-semibold lg:text-5xl">
					Get in touch
				</h3>
				<p className="mt-4 text-xl lg:mt-6 lg:text-2xl">
					Connect with the Gamblino team on
				</p>
				<div className="relative z-[1] mt-4 flex items-center gap-6">
					<Button
						onClick={() => {
							window.open(
								"https://twitter.com/gamblino_app",
								"_blank",
							);
						}}
						type="ghost"
						size="wrapper"
						className="transition-opacity ease-in hover:opacity-70"
					>
						<Button.Icon
							type="TWITTER"
							className="fill-content-primary-accent h-10 w-10 stroke-none"
						/>
					</Button>
					<Button
						onClick={() => {
							window.open(
								"https://t.me/+E_1CMg0i54s1N2Ix",
								"_blank",
							);
						}}
						type="ghost"
						size="wrapper"
						className="transition-opacity ease-in hover:opacity-70"
					>
						<Button.Icon
							type="TELEGRAM"
							className="fill-content-primary-accent h-10 w-10 stroke-none"
						/>
					</Button>
					<Button
						onClick={() => {
							window.open(
								"https://discord.gg/v63xHNz4BJ",
								"_blank",
							);
						}}
						type="ghost"
						size="wrapper"
						className="transition-opacity ease-in hover:opacity-70"
					>
						<Button.Icon
							type="DISCORD"
							className="stroke-background-secondary fill-content-primary-accent h-10 w-10 stroke-1 "
						/>
					</Button>
					<Button
						onClick={() => {
							window.open(
								"https://medium.com/@gamblino_app",
								"_blank",
							);
						}}
						type="ghost"
						size="wrapper"
						className="transition-opacity ease-in hover:opacity-70"
					>
						<Button.Icon
							type="MEDIUM"
							className=" fill-content-primary-accent h-10 w-10 stroke-1 "
						/>
					</Button>
				</div>
			</div>
		</PageSection>
	);
};

export { GetInTouchSection };
