import React from "react";
import { SimpleTable, Pagination } from "@components/organisms";
import { usePaginatedUserHistory } from "./usePaginatedUserQuery";
import { cx } from "src/utils";
import { DashboardPageSection } from "@components/atoms";

const UserHistoryTable = ({ className }: { className?: string }) => {
	const {
		table,
		isLoading,
		isUninitialized,
		isSuccess,
		isError,
		getCanNextPage,
		getCanPreviousPage,
		goToNextPage,
		goToPreviousPage,
		itemsCount,
		pageCount,
		pageIndex,
		pageSize,
		setPageIndex,
	} = usePaginatedUserHistory();

	return (
		<div className="w-full">
			<SimpleTable
				isLoading={isLoading || isUninitialized}
				isError={isError}
				isSuccess={isSuccess}
				itemsCount={itemsCount}
				pageSize={pageSize}
				table={table}
				className=""
			/>
			<Pagination
				getCanNextPage={getCanNextPage}
				getCanPreviousPage={getCanPreviousPage}
				goToNextPage={goToNextPage}
				goToPreviousPage={goToPreviousPage}
				itemsCount={itemsCount}
				pageCount={pageCount}
				pageIndex={pageIndex + 1}
				pageSize={pageSize}
				setPageIndex={setPageIndex}
				className="mt-4"
			/>
		</div>
	);
};

export { UserHistoryTable };
