import React from "react";
import { cx } from "src/utils";

const StatusPill: React.FC<{
	className?: string;
	type: "COMING_SOON" | "BETA" | "GAME_COUNT" | "GREEN" | "RED";
	text?: string;
}> = ({ className, type, text }) => {
	return (
		<p
			className={cx(
				"w-min whitespace-nowrap rounded-3xl px-4 py-1 text-xs font-semibold capitalize",
				{
					"bg-badge-background-neutral text-content-neutral":
						type === "COMING_SOON" || type === "GAME_COUNT",
					"bg-content-badge-alert/20 text-content-badge-alert":
						type === "BETA",
					"bg-badge-background-success text-badge-content-success":
						type === "GREEN",
					"bg-background-badge-error/50 text-content-badge-error":
						type === "RED",
				},
				className,
			)}
		>
			{text}
		</p>
	);
};

export { StatusPill };
