import Jazzicon from "@raugfer/jazzicon";

const getAvatarUrl = (address: string) => {
	try {
		return `data:image/svg+xml;base64,${btoa(Jazzicon(address))}`;
	} catch (e) {
		console.error(e);
		return `data:image/svg+xml;base64,${btoa(
			Jazzicon("0x0000000000000000000000000000000000000000"),
		)}`;
	}
};

export { getAvatarUrl };
