import { Icon } from "@components/atoms";
import React from "react";
import { useAudioManager } from "src/redux";
import { cx } from "src/utils";

const MuteUnmuteButton: React.FC<{ className?: string }> = ({ className }) => {
	const { isMuted, mute, unmute } = useAudioManager();
	return (
		<div
			className={cx(
				"bg-background-secondary border-border-primary flex h-10 w-10 cursor-not-allowed items-center justify-center rounded-full border",
				className,
			)}
			onClick={() => {
				if (isMuted) {
					unmute();
					return;
				}
				mute();
			}}
		>
			<Icon
				type={isMuted ? "VOLUME_DISABLED" : "VOLUME_ENABLED"}
				className="stroke-content-primary h-6 w-6"
			/>
		</div>
	);
};

export { MuteUnmuteButton };
