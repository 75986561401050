import { abi } from "../../config";
import { readContract } from "wagmi/actions";
import { wagmiConfig } from "wallet-connection";
import { Address } from "viem";

const getFee = async ({ contractAddress }: { contractAddress: Address }) => {
	const fee = await readContract(wagmiConfig, {
		abi,
		address: contractAddress,
		functionName: "getFee",
	});

	return { fee };
};

export { getFee };
