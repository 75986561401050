import React from "react";
import { cx } from "src/utils";

const ScoreCell: React.FC<{
	isWin: boolean;
}> = ({ isWin }) => {
	return (
		<span
			className={cx("text-badge-content-success", {
				"text-content-badge-error": !isWin,
			})}
		>
			{isWin ? "Win" : "Lose"}
		</span>
	);
};

export { ScoreCell };
