import mockedData from "./mockedData";
import { schema } from "./schema";
import { createRequestQueryFunction } from "query-function-creators";

const getUserStats = createRequestQueryFunction({
	getAxiosRequestConfig: ({
		userAddress = "0x0000000000000000000000000000000000000000",
	}: {
		userAddress?: string;
	}) => ({
		url: `${
			import.meta.env.BACKEND_SERVICE_GAMBLINO_BASE_URL
		}/leaderboard/${userAddress}`,
	}),
	schema,
	getMockedData: () => mockedData.response1,
	isMockingEnabled: false,
	transformData: (data) => {
		let sportsbookStats = data.leaderboard.find(
			(item) => item.game === "sportsbook",
		);

		if (!sportsbookStats) {
			sportsbookStats = {
				game: "sportsbook",
				totalVolume: 0,
				netProfit: 0,
				roundsPlayed: 0,
				wins: 0,
				losses: 0,
				streak: 0,
				topVictory: null,
				fortunateVictory: null,
			};
		}

		const winRate =
			sportsbookStats.roundsPlayed !== 0
				? (sportsbookStats.wins / sportsbookStats.roundsPlayed) * 100
				: 0;
		const streakDayString = sportsbookStats?.streak === 1 ? "day" : "days";

		return {
			...data,
			sportsbookStats,
			winRate,
			streakDayString,
		};
	},
});

export { getUserStats };
