import React from "react";
import { cx } from "src/utils";

type ASSET_TYPES =
	| "BITCOIN"
	| "BNB"
	| "ETHER"
	| "TETHER"
	| "GAMBLINO_COIN"
	| "METAMASK"
	| "COINBASEWALLET"
	| "MATIC"
	| "AVAX"
	| "AZURO"
	| "SEI";

const getLogoFile = ({ type }: { type: ASSET_TYPES }) => {
	if (type === "BITCOIN") {
		return "/bitcoin-logo.svg";
	}

	if (type === "ETHER") {
		return "/ether-logo.svg";
	}

	if (type === "BNB") {
		return "/bnb-logo.svg";
	}

	if (type === "GAMBLINO_COIN") {
		return "/roulette/gamblino-coin.svg";
	}

	if (type === "TETHER") {
		return "/tether.svg";
	}

	if (type === "AZURO") {
		return "/sportsbook/azuro-small.png";
	}

	if (type === "METAMASK") {
		return "/metamask.png";
	}

	if (type === "COINBASEWALLET") {
		return "/coinbase.png";
	}

	if (type === "MATIC") {
		return "/matic-logo.png";
	}

	if (type === "AVAX") {
		return "/avalanche-logo.png";
	}
	if (type === "SEI") {
		return "/sei-logo.svg";
	}
	return "";
};

const AssetIcon: React.FC<{
	type: ASSET_TYPES;
	className?: string;
}> = ({ type, className }) => {
	return (
		<div className={cx("h-6 w-6", className)}>
			<img
				className="h-full w-full"
				src={getLogoFile({ type })}
				alt="asset logo"
			/>
		</div>
	);
};

export { AssetIcon };
