import React from "react";
import { Icon } from "@components/atoms";
import { Button } from "@components/molecules";
import { cx } from "src/utils";
import usePagination from "@mui/material/usePagination";

const Pagination: React.FC<{
	className?: string;
	goToPreviousPage: () => void;
	getCanPreviousPage: () => boolean;
	goToNextPage: () => void;
	getCanNextPage: () => boolean;
	setPageIndex: (index: number) => boolean;
	pageCount: number;
	pageIndex: number;
	pageSize: number;
	itemsCount: number;
}> = ({
	className,
	goToPreviousPage,
	getCanPreviousPage,
	goToNextPage,
	getCanNextPage,
	setPageIndex,
	pageCount,
	pageIndex,
	pageSize,
	itemsCount,
}) => {
	const { items } = usePagination({
		count: pageCount,
		page: pageIndex,
		onChange(event, page) {
			setPageIndex(page - 1);
		},
	});

	return (
		<div
			className={cx(
				"flex items-center justify-between px-0 lg:px-6",
				className,
			)}
		>
			<div className="w-full flex-row-reverse lg:flex lg:flex-1 lg:items-center lg:justify-between">
				<div className="flex flex-row-reverse">
					<nav className="flex gap-4">
						{items.map(({ type, onClick, disabled }, index) => {
							if (
								type === "start-ellipsis" ||
								type === "end-ellipsis" ||
								type === "page"
							) {
								return null;
							}
							return (
								<Button
									key={index}
									disabled={disabled}
									onClick={onClick}
									type="outline"
									size="sm"
								>
									<Button.Text>
										{index === 0 ? "Previous" : "Next"}
									</Button.Text>
									{/* <Button.Icon
											type={
												index === 0
													? "CHEVRON_LEFT"
													: "CHEVRON_RIGHT"
											}
											className="stroke-content-primary h-5 w-5"
										/> */}
								</Button>
							);
						})}
					</nav>
				</div>
				<div className="hidden lg:block">
					<p className="text-content-primary text-sm">
						<span className="text-content-secondary">Showing </span>
						<span className="font-medium">
							{itemsCount === 0
								? 0
								: (pageIndex - 1) * pageSize + 1}
						</span>{" "}
						<span className="text-content-secondary">to </span>
						<span className="font-medium">
							{itemsCount === 0
								? 0
								: pageIndex === pageCount
									? (pageIndex - 1) * pageSize +
										(itemsCount % pageSize)
									: pageIndex * pageSize}
						</span>{" "}
						<span className="text-content-secondary">of </span>{" "}
						<span className="font-medium">{itemsCount}</span>{" "}
						<span className="text-content-secondary">
							result
							{itemsCount > 1 ? "s" : ""}
						</span>
					</p>
				</div>
			</div>
		</div>
	);
};

export { Pagination };
