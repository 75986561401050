import { Address } from "viem";
import { getMaxBetAmount as getMaxBetAmountContract } from "contract-service-gamblino-bank";

const getMaxBetAmount = async ({
	bankContractAddress,
}: {
	bankContractAddress: Address;
}) => {
	const { maxBetAmount, maxBetAmountWei } = await getMaxBetAmountContract({
		bankContractAddress,
		multiplier: 20_000,
	});

	return { maxBetAmount, maxBetAmountWei };
};

export { getMaxBetAmount };
