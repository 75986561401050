import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

const initialState: {
	isMuted: boolean;
	activeAudioResource: string | null;
} = {
	isMuted: true,
	activeAudioResource: null,
};

const audioManagerSlice = createSlice({
	name: "audioManager",
	initialState,
	reducers: {
		mute: (state) => {
			state.isMuted = true;
		},
		unmute: (state) => {
			state.isMuted = true;
		},
		setActiveAudioResource: (state, action: PayloadAction<string>) => {
			state.activeAudioResource = action.payload;
		},
	},
});

export { audioManagerSlice };
