import React from "react";
import { PageSection } from "@components/atoms";
import { cx } from "src/utils";
import { ImageWithLoading } from "./ImageWithLoading";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";

const config: {
	text: string;
	imageUrl: string;
	hasBlurEffect: boolean;
}[] = [
	{
		text: "Arbitrum",
		imageUrl: "/arbitrum-logo.png",
		hasBlurEffect: true,
	},
	{
		text: "Sei",
		imageUrl: "/sei-logo.png",
		hasBlurEffect: false,
	},
	{
		text: "Polygon",
		imageUrl: "/polygon-logo.png",
		hasBlurEffect: false,
	},
];

const renderConfig = [...config, ...config, ...config];

const EcosystemSection: React.FC<{ className?: string }> = ({ className }) => {
	return (
		<PageSection
			className={cx(" text-center text-2xl font-medium", className)}
		>
			<h3 className="">Gamblino Ecosystem on</h3>
			<div className="relative mx-auto mt-10 max-w-[768px]">
				<div className="from-background-primary z-1 absolute bottom-0 left-0 top-0 w-32 bg-gradient-to-r sm:w-52"></div>
				<div className="from-background-primary z-1 absolute bottom-0 right-0 top-0 w-32 bg-gradient-to-l sm:w-52"></div>
				<Swiper
					wrapperClass="!ease-linear"
					modules={[Autoplay]}
					loop={true}
					slidesPerView={1}
					spaceBetween={20}
					speed={3000}
					breakpoints={{
						960: {
							slidesPerView: 4,
						},
						700: {
							slidesPerView: 3,
						},
						400: {
							slidesPerView: 2,
						},
					}}
					preventInteractionOnTransition={true}
					autoplay={{
						delay: 1,
						disableOnInteraction: false,
					}}
					className="relative z-0"
				>
					{renderConfig.map(
						({ text, imageUrl, hasBlurEffect }, index) => (
							<SwiperSlide key={index}>
								<div
									key={text}
									className="border-border-primary h-32 w-32 rounded-full border p-[1px]"
								>
									<div className="bg-background-primary relative flex h-full w-full flex-col items-center justify-center gap-2 rounded-full">
										<ImageWithLoading
											className={cx("h-20 w-20", {
												"blur-sm": hasBlurEffect,
											})}
											url={imageUrl}
										/>
										{/* <p
							className={cx(
								"whitespace-nowrap text-center text-base font-medium lg:text-left",
								{
									"blur-sm": hasBlurEffect,
								},
							)}
						>
							{text}
						</p> */}
										{hasBlurEffect && (
											<p className="text-purple-dark absolute  left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 whitespace-nowrap rounded-3xl bg-[#fff] px-2 py-1 text-xs">
												COMING SOON
											</p>
										)}
									</div>
								</div>
							</SwiperSlide>
						),
					)}
				</Swiper>
			</div>
		</PageSection>
	);
};

export { EcosystemSection };
