import React, { useEffect } from "react";
import { useAccount, useConfig } from "wagmi";
import { useLocation } from "react-router-dom";
import { useModalManager } from "./ModalsWrapper";

const WrongChain = () => {
	const location = useLocation();

	const { modalManager } = useModalManager();

	const wagmiConfig = useConfig();

	const { chainId, isConnected } = useAccount();

	const isCorrectChain = chainId && chainId === wagmiConfig.chains[0].id;

	const shouldOpenModal =
		!isCorrectChain && isConnected && location.pathname !== "/";

	useEffect(() => {
		if (shouldOpenModal) {
			modalManager.open("WrongChainModal", {});
		}
	}, [shouldOpenModal, location.pathname]);

	useEffect(() => {
		if (isConnected && isCorrectChain) {
			modalManager.close("WrongChainModal");
		}
	}, [chainId]);

	return <></>;
};

export { WrongChain };
