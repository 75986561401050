import React from "react";

import { PlaceBet } from "./PlaceBet";
import { BetSlipList } from "./BetSlipList";

import { BetSlipHeading } from "./BetSlipHeading";
import { SlippageSelection } from "./SlippageSection";

const BetSlipTab = React.forwardRef(() => {
	const [slippage, setSlippage] = React.useState<number>(5);

	return (
		<div className="@lg/dashboard:h-[550px] flex h-[450px] flex-col justify-between">
			<SlippageSelection setSlippage={setSlippage} slippage={slippage} />
			<BetSlipHeading className="" />
			<BetSlipList className="" />
			<PlaceBet className="py-2" slippage={slippage} />
		</div>
	);
});

BetSlipTab.displayName = "BetSlipTab";

export { BetSlipTab };
