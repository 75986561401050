import React from "react";
import { type IModal } from "@components/organisms";
import { Modal, ModalButtons } from "@components/organisms";
import { cx } from "src/utils";

const HowToPlayCoinFlipModal: IModal<"HowToPlayCoinFlipModal"> &
	React.FC = () => (
	<Modal className="bg-background-secondary border-violet-light/10 relative w-full max-w-[524px] overflow-hidden rounded-xl border p-6">
		<Modal.Step>
			<div className="flex flex-col gap-6">
				<p className="text-lg font-semibold">
					How to play Flip Master?
				</p>
				<ModalButtons />
				<img
					className="w-full rounded-lg"
					src="/how-to-play-modals/coin-flip/coin-flip-1.png"
					alt=""
				/>

				<p>
					🪙 A coin flip is a simple randomness procedure used to make
					a quick decision between two equally likely outcomes.
					Initially, enter the amount you’d like to bet and choose
					your side, heads or tails!
				</p>
			</div>
		</Modal.Step>
		<Modal.Step>
			<div className="flex flex-col gap-6">
				<p className="text-lg font-semibold">
					How to play Flip Master?
				</p>
				<ModalButtons />
				<img
					className="w-full rounded-lg"
					src="/how-to-play-modals/coin-flip/coin-flip-2.png"
					alt=""
				/>

				<p>
					Once your bet is placed, the transaction will be processed
					on the blockchain, utilizing Chainlink VRF to generate a
					random result. Should you be successful, the payout will be
					included within the same transaction. Additionally, your
					betting history is consistently available for review in the
					table below.
				</p>
			</div>
		</Modal.Step>
	</Modal>
);

HowToPlayCoinFlipModal.modalName = "HowToPlayCoinFlipModal";

export { HowToPlayCoinFlipModal };
