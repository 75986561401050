import React from "react";
import { Address } from "viem";

import { DashboardPageSection } from "@components/atoms";
import { MuteUnmuteButton, OpenContractButton } from "@components/organisms";
import { MinesBetSection } from "./MinesBetSection";
import { MinesSection } from "./MinesSection";
import { OpenGameStats } from "@components/organisms/OpenGameStats/OpenGameStats";

const GameSection: React.FC<{
	contractAddress: Address;
	className?: string;
}> = ({ contractAddress, className }) => {
	return (
		<>
			<DashboardPageSection className="z-1 relative flex items-center justify-between">
				<h1 className="text-3xl">Mines</h1>

				<div className="flex items-center gap-2">
					<MuteUnmuteButton />
					<OpenContractButton contractAddress={contractAddress} />
					<OpenGameStats />
					{/* <OpenHowToModalButton modalName="HowToPlayCoinFlipModal" /> */}
				</div>
			</DashboardPageSection>
			<DashboardPageSection className="mt-4 flex h-full flex-col items-center gap-4 md:flex-row">
				<div className="bg-background-secondary border-border-primary flex w-full flex-col items-center justify-center rounded-2xl border bg-opacity-90 p-4 md:h-[480px] md:flex-auto md:basis-4/6">
					<MinesSection />
				</div>

				<div className="bg-background-secondary border-border-primary flex h-[480px] w-full flex-col gap-4 rounded-2xl border p-4 md:w-auto md:basis-3/6">
					<MinesBetSection />
				</div>
			</DashboardPageSection>
		</>
	);
};

export { GameSection };
