/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
	Address,
	bytesToHex,
	decodeEventLog,
	parseEventLogs,
	zeroAddress,
} from "viem";
import { abi } from "../../config";
import {
	simulateContract,
	waitForTransactionReceipt,
	writeContract,
} from "wagmi/actions";
import { wagmiConfig } from "wallet-connection";

export const formatRouletteNumbers = (numbers: string[]) => {
	const selectedNumbers = new Array<number>(37).fill(0);
	for (const num of numbers) {
		const num1 = parseInt(num);
		if (num1 > 36) {
			continue;
		}
		selectedNumbers[num1] = 1;
	}
	const selectedNumber = parseInt(selectedNumbers.reverse().join(""), 2);
	return selectedNumber;
};
const wager = async ({
	numbers,
	amountWei,
	contractAddress,
	feeWei,
}: {
	numbers: string[];
	amountWei: string;
	contractAddress: Address;
	feeWei: string;
}) => {
	const selectedNumbers = formatRouletteNumbers(numbers);

	const tokenAmount = BigInt(amountWei);

	const payableAmountWei = BigInt(amountWei) + BigInt(feeWei);

	const randomBytes = crypto.getRandomValues(new Uint8Array(32));
	const randomNumber = bytesToHex(randomBytes);

	const { request } = await simulateContract(wagmiConfig, {
		address: contractAddress,
		abi: abi,
		functionName: "wager",
		args: [selectedNumbers, randomNumber, zeroAddress, tokenAmount],
		value: payableAmountWei,
	});

	const hash = await writeContract(wagmiConfig, request);

	const receipt = await waitForTransactionReceipt(wagmiConfig, {
		hash,
		confirmations: 3,
	});

	const logs = parseEventLogs({
		abi,
		eventName: "PlaceBet",
		logs: receipt.logs,
	});

	const placeBetEventLogs = logs.map(({ data, topics }) =>
		decodeEventLog({ abi, data, topics, eventName: "PlaceBet" }),
	);

	if (placeBetEventLogs.length === 0) {
		throw new Error("PlaceBet event is not found in tx receipt.");
	}

	if (placeBetEventLogs.length > 1) {
		console.error({
			placeBetEventLogs,
			logs,
		});
		throw new Error("More than 1 PlaceBet event is scanned.");
	}

	const betId = `${placeBetEventLogs[0]!.args.id}`;

	return {
		txHash: hash,
		betId,
	};
};

export { wager };
