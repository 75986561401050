import React from "react";

const RankCell: React.FC<{ rank: number }> = ({ rank }) => {
	switch (rank) {
		case 1:
			return (
				<img src="/leaderboard/rank-one.png" className="h-10 w-10" />
			);
		case 2:
			return (
				<img src="/leaderboard/rank-two.png" className="h-10 w-10" />
			);
		case 3:
			return (
				<img src="/leaderboard/rank-three.png" className="h-10 w-10" />
			);
		default:
			return (
				<span className="grid h-10 w-10 place-content-center">
					{rank}
				</span>
			);
	}
};

export { RankCell };
