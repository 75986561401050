import { LeaguesQueryVariables } from "../gql/graphql";
import { client } from "../apollo";
import { graphql } from "../gql";

const query = graphql(`
	query Leagues(
		$first: Int = 100
		$orderBy: League_orderBy
		$orderDirection: OrderDirection
		$skip: Int = 0
		$where: League_filter
	) {
		leagues(
			first: $first
			orderBy: $orderBy
			orderDirection: $orderDirection
			skip: $skip
			subgraphError: allow
			where: $where
		) {
			id
			name
			slug
			country {
				id
			}
			games {
				id
				title
				slug
				league {
					id
				}
				provider
				turnover
			}
			hasActiveGames
			activeGamesEntityIds
			turnover
		}
	}
`);

const getLeagues = async (variables: LeaguesQueryVariables) => {
	if (!client) {
		throw new Error("Apollo client not initialized");
	}
	const {
		data: { leagues },
	} = await client.query({
		query,
		variables,
	});

	return leagues;
};

export { getLeagues };
