import { TooltipNumberWrapper } from "@components/molecules";
import React from "react";

const PointsCell: React.FC<{ points: number }> = ({ points }) => {
	return (
		<TooltipNumberWrapper
			number={points}
			formatPriceConfig={{
				currencyLabel: " pts",
			}}
		/>
	);
};

export { PointsCell };
