import React from "react";
import { useDisconnect, useSwitchChain } from "wagmi";

import { Button } from "@components/molecules";
import { wagmiConfig } from "wallet-connection";
import { Modal } from "@components/organisms/Modal";
import type { IModal } from "@components/organisms/Modal";
import { useModalManager } from "@components/ethereals/ModalsWrapper";

const WrongChainModal: IModal<"WrongChainModal"> & React.FC = () => {
	const { modalManager } = useModalManager();

	const { disconnect } = useDisconnect();
	const { switchChain } = useSwitchChain();

	return (
		<Modal className="bg-background-tertiary border-border-primary text-content-primary relative max-h-[564px] min-w-[300px] overflow-hidden rounded-2xl border sm:min-w-[416px] lg:w-[500px]">
			<Modal.Step>
				<div className="flex flex-col items-center gap-5 p-5">
					<img src="/wrong-network.png" className="h-20 w-20" />

					<div className="relative flex w-full items-center justify-center">
						<h3 className="text-danger self-center text-lg font-semibold text-black">
							Wrong Network
						</h3>
					</div>

					<p className="text-content-secondary px-10 text-center text-sm">
						Doing transactions on the wrong network can result in
						permanent loss of funds.
					</p>

					<p className="text-content-primary px-10 text-center text-sm">
						Please change the network to continue
					</p>
					<Button
						type="gradient"
						onClick={() => {
							switchChain({ chainId: wagmiConfig.chains[0].id });
						}}
						className="w-full"
					>
						<Button.Text>Switch Network</Button.Text>
					</Button>

					<p
						onClick={() => {
							disconnect();
							modalManager.close("WrongChainModal");
						}}
						className="text-content-secondary cursor-pointer text-sm hover:opacity-90"
					>
						Disconnect
					</p>
				</div>
			</Modal.Step>
		</Modal>
	);
};

WrongChainModal.modalName = "WrongChainModal";

export { WrongChainModal };
