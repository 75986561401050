import { z } from "zod";

const schema = z
	.object({
		status: z.string(),
		games: z.array(
			z
				.object({
					name: z.string(),
					secondaryName: z.string(),
					imageUrl: z.string(),
				})
				.strict(),
		),
	})
	.strict();

export { schema };
