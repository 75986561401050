import { Avatar } from "@components/molecules";
import { truncateEthAddress } from "formatting-service";
import React from "react";
import { useBlockExplorer } from "src/hooks";

const PlayerCell: React.FC<{ user: string }> = ({ user }) => {
	const getBlockExplorer = useBlockExplorer();
	return (
		<a
			href={getBlockExplorer(user)}
			target="_blank"
			className="flex cursor-pointer items-center gap-2"
			rel="noreferrer"
		>
			<Avatar className="h-6 w-6" userAddress={user} />
			<span className="text-content-primary">
				{truncateEthAddress(user, 3)}
			</span>
			{/* <Icon type="EXTERNAL_LINK" className=" stroke-content-primary w-5" /> */}
		</a>
	);
};

export { PlayerCell };
