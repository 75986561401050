import React from "react";
import { UserHistoryTable } from "./UserHistoryTable/UserHistoryTable";
import DiceGameSection from "./DiceGameSection/DiceGameSection";
import { SimpleDashboardGameLayout } from "@components/layouts";
import { CONTRACT_ADDRESS_DICE_GAME } from "src/config";
import { diceBusinessLogicApi } from "business-logic-gamblino";
import { useAppDispatch } from "src/redux/store";

const DicePage = () => {
	const contractAddress = CONTRACT_ADDRESS_DICE_GAME;

	const dispatch = useAppDispatch();

	React.useEffect(() => {
		return () => {
			dispatch(diceBusinessLogicApi.util.resetApiState());
		};
	}, []);

	return (
		<SimpleDashboardGameLayout
			bgImageUrl="/games-bg.png"
			gameComponent={
				<DiceGameSection contractAddress={contractAddress} />
			}
		>
			<UserHistoryTable className="mt-0" />
		</SimpleDashboardGameLayout>
	);
};

export { DicePage };
