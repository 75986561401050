import React from "react";
import { ViewAllBets } from "./ViewAllBets/ViewAllBets";

import { ActiveSettledBetTabs } from "./ActiveSettledBetTabs";

const MyPlacedBetsTab = React.forwardRef(() => {
	return (
		<div className="@lg/dashboard:h-[550px] flex  h-[450px] flex-col justify-between">
			<ActiveSettledBetTabs className="" />

			<ViewAllBets className="pb-2" isLoading={false} />
		</div>
	);
});

MyPlacedBetsTab.displayName = "MyPlacedBetsTab";

export { MyPlacedBetsTab };
