import { Address } from "viem";
import { getPublicClient } from "wagmi/actions";
import { wagmiConfig } from "wallet-connection";

const getSeiParallelizedAddress = async ({
	userAddress,
}: {
	userAddress: Address;
}) => {
	try {
		const client = getPublicClient(wagmiConfig);

		const wasmAddress = await client.request({
			method: "sei_getSeiAddress",
			params: [userAddress],
		});

		return { wasmAddress, isParallelized: true };
	} catch (error) {
		return { isParallelized: false };
	}
};

export { getSeiParallelizedAddress };
