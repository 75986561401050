import React from "react";

import { AppRoutes } from "./components/ethereals/AppRoutes";
import { validateEnvironment } from "./validateEnvironment";
import { useModalManager } from "@components/ethereals/ModalsWrapper";
import { useLocalStorage } from "usehooks-ts";
import { setUser } from "@sentry/react";

import { loadErrorMessages, loadDevMessages } from "@apollo/client/dev";
import { useAccount } from "wagmi";
import { sentryInit } from "./lib/sentry";

// validateEnvironment();

sentryInit();

console.log({ "import.meta.env.PROD": import.meta.env.PROD });

if (import.meta.env.DEV) {
	// Adds apollo error/dev messages only in a dev environment
	loadDevMessages();
	loadErrorMessages();
}

const App: React.FC = () => {
	const { address } = useAccount();
	const { modalManager } = useModalManager();
	const isProd = import.meta.env.GAMBLINO_IS_PROD === "true";
	const [isWelcomeModalShown, setIsWelcomeModalShown] =
		useLocalStorage<boolean>(
			isProd ? "welcome-modal-prod" : "welcome-modal-beta",
			false,
		);

	React.useEffect(() => {
		if (!isWelcomeModalShown) {
			setIsWelcomeModalShown(true);
			modalManager.open(
				isProd ? "WelcomeModalProd" : "WelcomeModalBeta",
				{},
			);
		}
	}, []);

	// Adds user's wallet address to sentry
	React.useEffect(() => {
		if (address) {
			setUser({ address });
		}
	}, [address]);

	React.useLayoutEffect(() => {
		const userAgent = window.navigator.userAgent;
		if (userAgent.indexOf("Mac") === -1) {
			document.body.dataset.isMac = "false";
		}
	}, []);

	return <AppRoutes />;
};

export { App };
