import React from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "@components/molecules";
import { cx } from "src/utils";

const ViewAllBets: React.FC<{ className?: string; isLoading: boolean }> = ({
	className,
}) => {
	const navigate = useNavigate();
	return (
		<div className={cx("px-4 lg:px-2", className)}>
			<Button
				className=" bg-content-primary w-full bg-opacity-10"
				onClick={() => navigate("/sportsbook/my-bets")}
			>
				<Button.Text>View All</Button.Text>
			</Button>
		</div>
	);
};

export { ViewAllBets };
