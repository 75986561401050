import { TooltipNumberWrapper } from "@components/molecules";
import React from "react";

const PayoutCell: React.FC<{
	payout: number;
}> = ({ payout }) => {
	return <TooltipNumberWrapper number={payout} />;
};

export { PayoutCell };
