import React from "react";
import { Address } from "viem";

import { cx } from "src/utils";
import { DashboardPageSection } from "@components/atoms";
import { BoardButtons } from "./BoardButtons";
import { ControlButtons } from "./ControlButtons";
import { AmountInput } from "./AmountInput";
import { Wheel } from "./Wheel";
import { Stats } from "./Stats";
import { MuteUnmuteButton, OpenContractButton } from "@components/organisms";
import { BoardButtonsMobile } from "./BoardButtons/BoardButtonsMobile";
import { OpenGameStats } from "@components/organisms/OpenGameStats/OpenGameStats";

const GameSection: React.FC<{
	contractAddress: Address;
	className?: string;
}> = ({ contractAddress, className }) => {
	const [amount, setAmount] = React.useState<{
		valueWei: string;
		value: string;
	}>({ valueWei: "", value: "" });

	return (
		<>
			<DashboardPageSection className=" z-modal relative flex items-center justify-between">
				<h1 className="text-3xl">Roulette</h1>
				<div className=" flex items-center gap-2">
					<MuteUnmuteButton />
					<OpenContractButton contractAddress={contractAddress} />
					<OpenGameStats />
				</div>
			</DashboardPageSection>
			<DashboardPageSection
				className={cx(
					"bg-background-secondary border-violet-light/10 @5xl/dashboard:flex-row-reverse @5xl/dashboard:items-stretch @5xl/dashboard:gap-8 @2xl/dashboard:pb-6 z-0 mt-6  flex flex-col-reverse items-center gap-4 rounded-2xl border bg-opacity-90 p-6 pb-24",
					className,
				)}
			>
				<div className="@5xl/dashboard:grow @5xl/dashboard:w-auto @5xl/dashboard:h-auto flex w-full flex-col justify-between gap-4">
					<div className="flex flex-col gap-4">
						<BoardButtons className="@xl/dashboard:block hidden h-[216px]" />
						<BoardButtonsMobile className="@xl/dashboard:hidden flex" />
						<ControlButtons className="@xl/dashboard:h-[40px] h-auto" />
					</div>
					<Stats className="" amount={amount} />
					<AmountInput
						contractAddress={contractAddress}
						className=""
						setAmount={setAmount}
						amount={amount}
					/>
				</div>

				<Wheel className="@5xl/dashboard:gap-6 @5xl/dashboard:max-w-[384px] aspect-square w-full max-w-[432px]" />
			</DashboardPageSection>
		</>
	);
};

export { GameSection };
