import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { ConditionStatus, Participant } from "azuro-service/src/gql/graphql";

export interface AzuroState {
	betOdds: number;
	conditionName: string;
	participants: Participant["name"][];
	conditionId: string;
	outcomeId: string;
	startsAt: string;
	coreAddress: string;
}

const getBetsFromLocalStorage = () => {
	if (!window.localStorage) {
		return [];
	}

	const bets = JSON.parse(
		window.localStorage.getItem("azuro-bets") || "[]",
	) as AzuroState[];

	return bets;
};

const initialState = getBetsFromLocalStorage();

const azuroSlice = createSlice({
	name: "azuro",
	initialState,
	reducers: {
		addBet(
			state,
			{
				payload,
			}: PayloadAction<{
				conditionName: string;
				conditionId: string;
				startsAt: string;
				outcomeId: string;
				betOdds: number;
				participants: AzuroState["participants"];
				coreAddress: string;
			}>,
		) {
			state.push({
				...payload,
			});

			if (!window.localStorage) {
				return;
			}

			const bets = JSON.stringify(state);

			window.localStorage.setItem("azuro-bets", bets);
		},
		deleteBet(
			state,
			{
				payload,
			}: PayloadAction<{
				conditionId: string;
				outcomeId: string;
			}>,
		) {
			const index = state.findIndex(
				(bet) =>
					bet.conditionId === payload.conditionId &&
					bet.outcomeId === payload.outcomeId,
			);

			if (index === -1) {
				return;
			}

			state.splice(index, 1);

			if (!window.localStorage) {
				return;
			}

			const bets = JSON.stringify(state);

			window.localStorage.setItem("azuro-bets", bets);
		},
		deleteAllBets(state) {
			if (!window.localStorage) {
				return;
			}

			window.localStorage.setItem("azuro-bets", JSON.stringify([]));

			return [];
		},
	},
});

export const { addBet } = azuroSlice.actions;
export { azuroSlice };
export default azuroSlice.reducer;
