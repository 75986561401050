import React from "react";
import { cx } from "src/utils";
import { PromotionsSection } from "./PromotionsSection";
import { GamesSection } from "./GamesSection";
import { useAudioManager } from "src/redux";

import { Menu } from "@components/organisms";

const Games: React.FC<{ className?: string }> = ({ className }) => {
	const { setActiveAudioResource, playSoundOnce } = useAudioManager();
	React.useEffect(() => {
		setActiveAudioResource(
			"/48021784_asia-cinematic-game-background_by_blackalexstudio_preview.mp3",
		);

		return () => {
			setActiveAudioResource(null);
		};
	}, []);

	return (
		<div className={cx("py-10", className)}>
			{/* <Button
				onClick={() => {
					playSoundOnce(
						"/10046325_magic-coin_by_gamechestaudio_preview.mp3",
					);
				}}
			>
				<Button.Text>Play Sound</Button.Text>
			</Button> */}
			<Menu className="z-sidebar relative" />
			<PromotionsSection className="relative z-0 mt-10" />
			<GamesSection className="relative mt-6" />
		</div>
	);
};

export { Games };
