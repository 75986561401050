import { CountryQueryVariables, SportQueryVariables } from "../gql/graphql";
import { client } from "../apollo";
import { graphql } from "../gql";

const query = graphql(`
	query Sport($id: ID!) {
		sport(id: $id, subgraphError: allow) {
			id
			sportId
			name
			sporthub {
				id
				name
				slug
			}
			countries {
				id
				name
				slug
			}
			games {
				id
				title
				slug
				league {
					id
					name
					slug
				}
				provider
				turnover
			}
		}
	}
`);

const getSport = async (variables: SportQueryVariables) => {
	if (!client) {
		throw new Error("Apollo client not initialized");
	}
	const {
		data: { sport },
	} = await client.query({
		query,
		variables,
	});

	return sport;
};

export { getSport };
