import { Chain } from "wagmi/chains";
import { createConfig, fallback, http } from "wagmi";
import * as Viem from "viem";
import * as wagmiActions from "wagmi/actions";
import { rpcSchema } from "viem";
import { validateEnvironment } from "./validateEnvironment";
import { connectorsForWallets } from "@rainbow-me/rainbowkit";
import {
	compassWallet,
	metaMaskWallet,
	coinbaseWallet,
	walletConnectWallet,
} from "@rainbow-me/rainbowkit/wallets";
import { seifWallet } from "./seifWallet";

// validateEnvironment();

const polygon = {
	id: 137,
	name: "Polygon",
	rpcUrls: {
		default: {
			http: ["https://polygon-rpc.com"],
		},
	},
	nativeCurrency: {
		decimals: 18,
		name: "Matic",
		symbol: "MATIC",
	},
	blockExplorers: {
		default: {
			name: "PolygonScan",
			url: "https://polygonscan.com/",
		},
	},
} as const satisfies Chain;

const seiTestnet = {
	id: 713715,
	name: "Sei Testnet Network",
	// network: "sei",
	contracts: {
		multicall3: {
			address: "0xDdaa5FbffD813Ed02D140946EB66bD6487F3C778",
		},
	},
	nativeCurrency: {
		decimals: 18,
		name: "Sei",
		symbol: "SEI",
	},
	rpcUrls: {
		default: {
			http: ["https://evm-rpc-arctic-1.sei-apis.com"],
		},
		// alternative: {
		// 	http: ["https://evm-rpc.arctic-1.seinetwork.io"],
		// },
	},
	blockExplorers: {
		default: {
			name: "Seitrace",
			url: "https://seitrace.com/",
		},
	},
} as const satisfies Chain;

const seiMainnet = {
	id: 1329,
	name: "Sei Mainnet",
	network: "sei",
	contracts: {
		multicall3: {
			address: "0xcA11bde05977b3631167028862bE2a173976CA11",
		},
	},
	nativeCurrency: {
		decimals: 18,
		name: "Sei",
		symbol: "SEI",
	},
	rpcUrls: {
		default: {
			http: ["https://evm-rpc.sei-apis.com"],
		},
		// alternative: {
		// 	http: ["https://evm-rpc.arctic-1.seinetwork.io"],
		// },
	},
	blockExplorers: {
		default: {
			name: "Seitrace",
			url: "https://seitrace.com/",
		},
	},
} as const satisfies Chain;

const network =
	import.meta.env.WALLET_CONNECTION_VARIANT === "POLYGON"
		? polygon
		: import.meta.env.WALLET_CONNECTION_VARIANT === "SEI_MAINNET"
			? seiMainnet
			: seiTestnet;

type CustomRpcSchema = [
	{
		Method: "eth_wagmi";
		Parameters: [string];
		ReturnType: string;
	},
];

const wagmiConfig = createConfig({
	chains: [network],
	batch: {
		multicall: {
			wait: 333,
		},
	},

	rpcSchema: {
		[network.id]: rpcSchema<CustomRpcSchema>(),
	},
	transports: {
		[network.id]: fallback(
			Object.values(network.rpcUrls).map((el) =>
				http(el.http[0], {
					batch: { wait: 333 },

					onFetchResponse: (response) => {
						if (!response.ok && window.modalManager) {
							// window.modalManager.open("RPCErrorModal", {});
						}
					},
				}),
			),
			{},
		),
	},
	multiInjectedProviderDiscovery: true,
	connectors: connectorsForWallets(
		[
			{
				groupName: "Sei Native",
				wallets: [compassWallet, seifWallet],
			},
			{
				groupName: "Popular",
				wallets: [metaMaskWallet, coinbaseWallet, walletConnectWallet],
			},
		],
		{
			appName: "Gamblino",
			projectId: "2bb4c38f62ddeed2531fb5a6f2deaef4",
		},
	),
});

window.wc = wagmiConfig;
window.viem = Viem;
window.wa = wagmiActions;

export { wagmiConfig };
export * from "./avatar";
