import mockedData from "./mockedData";
import { schema } from "./schema";
import { createRequestQueryFunction } from "query-function-creators";

const getCoinUserHistory = createRequestQueryFunction({
	getAxiosRequestConfig: ({ userAddress }: { userAddress: string }) => ({
		url: `${
			import.meta.env.BACKEND_SERVICE_GAMBLINO_BASE_URL
		}/games/coin-flip/${userAddress}`,
	}),
	schema,
	getMockedData: () => mockedData.response1,
	isMockingEnabled: false,
});

export { getCoinUserHistory };
