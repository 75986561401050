import { abi } from "../../config";
import { readContract } from "wagmi/actions";
import { wagmiConfig } from "wallet-connection";
import { Address, formatEther } from "viem";

const getLastUserBets = async ({
	userAddress,
	dataLength,
	contractAddress,
}: {
	userAddress: Address;
	dataLength: number;
	contractAddress: Address;
}) => {

	const result = await readContract(wagmiConfig, {
		abi,
		address: contractAddress,
		functionName: "getLastUserBets",
		args: [userAddress, BigInt(dataLength)],
	});

	const bets = result.map((bet) => {
		return {
			amount: +formatEther(bet.bet.amount),
			id: bet.bet.id.toString(),
			payout: +formatEther(bet.bet.payout),
			userAddress: bet.bet.user,
			resolved: bet.bet.resolved,
			cap: bet.diceBet.cap,
			rolled: bet.diceBet.rolled,
		};
	});

	return {
		bets
	};
	
};

export { getLastUserBets };
