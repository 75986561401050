import { WalletDetailsParams } from "@rainbow-me/rainbowkit";
import {
	CreateConnector,
	Wallet,
} from "@rainbow-me/rainbowkit/dist/wallets/Wallet";
import { createConnector } from "wagmi";
import { injected } from "wagmi/connectors";

const getExplicitInjectedProvider = () => {
	if (typeof window === "undefined") return;
	if (window.ethereum && window.ethereum["__seif"]) {
		return window.ethereum;
	}

	if ((window as any)["__seif"]) {
		return (window as any)["__seif"];
	}

	return undefined;
};

const createInjectedConnector = (provider?: any): CreateConnector => {
	return (walletDetails: WalletDetailsParams) => {
		// Create the injected configuration object conditionally based on the provider.
		const injectedConfig = provider
			? {
					target: () => ({
						id: walletDetails.rkDetails.id,
						name: walletDetails.rkDetails.name,
						provider,
					}),
				}
			: {};

		return createConnector((config) => ({
			// Spread the injectedConfig object, which may be empty or contain the target function
			...injected(injectedConfig)(config),
			...walletDetails,
		}));
	};
};

const seifWallet = (): Wallet => {
	const injectedProvider = getExplicitInjectedProvider();
	return {
		id: "seif",
		name: "Seif",
		installed: !!injectedProvider,
		iconUrl: "https://wallet-edge.vercel.app/static/SeifIcon.svg",
		iconBackground: "#fff",
		downloadUrls: {
			chrome: "https://chromewebstore.google.com/detail/seif/albakdmmdafeafbehmcpoejenbeojejl",
		},
		createConnector: createInjectedConnector(injectedProvider),
		rdns: "com.passkeywallet.seif",
	};
};

export { seifWallet };
