import { DashboardPageSection } from "@components/atoms";
import { Menu } from "@components/organisms";
import React from "react";
import { cx } from "src/utils";

const SimpleDashboardGameLayout: React.FC<
	React.PropsWithChildren<{
		gameComponent: React.JSX.Element | null;
		bgImageUrl: string;
		className?: string;
		imgClassName?: string;
	}>
> = ({ bgImageUrl, gameComponent, children, className, imgClassName }) => {
	return (
		<div className={cx("pb-16", className)}>
			<DashboardPageSection
				fullWidth
				className="relative overflow-hidden rounded-b-3xl pb-[200px] pt-6"
			>
				<div
					className={cx(
						"-z-1 absolute inset-0 h-full overflow-hidden",
						imgClassName,
					)}
				>
					<img
						src={bgImageUrl}
						alt="bg"
						className="h-[555px] w-full object-cover"
					/>
				</div>
				<Menu className="relative z-[999]" />
				<div className="mt-4">{gameComponent}</div>
			</DashboardPageSection>
			<div className="relative z-0 -mt-[140px]">{children}</div>
		</div>
	);
};

export { SimpleDashboardGameLayout };
