import React from "react";
import { Modal } from "@components/organisms/Modal";
import { type IModal } from "@components/organisms/Modal";
import { useRequestTestEthMutation } from "business-logic-gamblino";
import {
	useTime,
	useTransform,
	motion,
	useMotionTemplate,
} from "framer-motion";
import { cx } from "src/utils";
import { Button, Input } from "@components/molecules";
import { isValid, z } from "zod";
import { useModalManager } from "@components/ethereals/ModalsWrapper";
import { AnimatedIllustration } from "@components/organisms/AnimatedIllustration";
import { isAddress } from "viem";

const RequestTestEthModal: IModal<"RequestTestEthModal"> & React.FC = () => {
	const { modalManager } = useModalManager();
	const time = useTime();
	const rotate = useTransform(time, [0, 10000], [0, 360], { clamp: false });

	const backgroundImageSuccess = useMotionTemplate`linear-gradient(${rotate}deg, #4F67CF, #3E2F7A)`;

	const [email, setEmail] = React.useState<string>("");
	const [userAddress, setUserAddress] = React.useState<string>("");

	const zodSchemaEmail = z.string().email();
	const zodSchemaUserAddress = z.custom<string>(isAddress, "Invalid Address");

	const valEmail = zodSchemaEmail.safeParse(email);
	const valUserAddress = zodSchemaUserAddress.safeParse(userAddress);

	const [requestTestEth, { isLoading, isError, isSuccess, error }] =
		useRequestTestEthMutation();

	React.useEffect(() => {
		if (isError) {
			modalManager.close("RequestTestEthModal");
			modalManager.open("SimpleErrorModal", {
				heading: "Something went wrong",
				description:
					"Please try again. If problem persists, please contact our support.",
			});
		}
	}, [isError]);

	React.useEffect(() => {
		if (isSuccess) {
			modalManager.close("RequestTestEthModal");
			modalManager.open("SuccessSimpleModal", {
				heading: "Success",
				description: `You requested test eth. Please check you email to claim test tokens.`,
			});
		}
	}, [isSuccess]);

	return (
		<Modal className="bg-background-secondary relative w-full  max-w-[440px] overflow-hidden rounded-xl">
			<Modal.Step>
				<div className="relative h-[200px] w-full overflow-hidden rounded-xl">
					<motion.div
						className="absolute left-0 right-0 top-0 h-full w-full "
						style={{
							backgroundSize: "100% 100%",
							backgroundImage: backgroundImageSuccess,
						}}
						transition={{ ease: "easeInOut", duration: 3 }}
					/>
					<AnimatedIllustration
						animationDataPath="/email.json"
						className="absolute left-[50%] top-[50%] h-48 w-48 -translate-x-[50%] -translate-y-[50%]"
					/>
				</div>
				<div
					className={cx("min-h-24 w-full p-5", {
						"pointer-events-none cursor-not-allowed opacity-50":
							isLoading,
					})}
				>
					<p className="text-center text-lg font-medium">
						Request Test Tokens
					</p>
					<p className="mt-2">
						You will receive 0.005 ETH. Please note that you can
						obtain testnet ETH only once per Wallet/Email.
					</p>
					<Input
						type="email"
						value={email}
						setValue={setEmail}
						placeholder="satoshi@mail.com"
						name={"Email"}
						wrapperClassName="w-full mt-4"
						className="text-content-primary h-12"
					>
						<Input.Label className="" name="Email"></Input.Label>
						{/* <Input.Icon type="CUBOID" className="h-4 w-4" /> */}
					</Input>
					<Input
						type="email"
						value={userAddress}
						setValue={setUserAddress}
						placeholder="0x.."
						name={"Wallet address"}
						wrapperClassName="w-full mt-4"
						className="text-content-primary h-12"
					>
						<Input.Label
							className=""
							name="Wallet address"
						></Input.Label>
					</Input>
					<Button
						onClick={() => {
							if (valEmail.success && valUserAddress.success) {
								requestTestEth({
									email,
									userAddress,
								});
							}
						}}
						type="outline"
						className={cx("mt-4 w-full")}
						disabled={!valEmail.success || !valUserAddress.success}
					>
						<Button.Text>Request Test ETH</Button.Text>
					</Button>
				</div>
			</Modal.Step>
		</Modal>
	);
};

RequestTestEthModal.modalName = "RequestTestEthModal";

export { RequestTestEthModal };
