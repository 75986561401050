import React from "react";
import { useAccount } from "wagmi";

import { cx } from "src/utils";
import { Button } from "@components/molecules";
import { useAsideStatus } from "src/redux/useAsideStatus";
import { DashboardPageSection, Icon } from "@components/atoms";
import WalletConnection from "./WalletConnection/WalletConnection";

const Menu: React.FC<{ className?: string }> = ({ className }) => {
	const { asideStatus, setAsideStatus } = useAsideStatus();

	return (
		<DashboardPageSection
			className={cx(
				"flex w-full flex-row-reverse justify-between",
				className,
			)}
			fullWidth={className?.includes("px-0")}
		>
			<div className="flex items-center gap-3 ">
				{/* <AppTabToggle /> */}
				{/* <MuteUnmuteButton /> */}

				<div className="shrink-0">
					{/* <RainbowConnectButton /> */}
					<WalletConnection />
				</div>
			</div>
			<Button
				onClick={() => {
					if (asideStatus) {
						setAsideStatus(false);
					} else {
						setAsideStatus(true);
					}
				}}
				type="ghost"
				size="wrapper"
				className="hover:bg-content-primary h-12 w-12 transition-colors ease-linear hover:bg-opacity-10 md:hidden"
			>
				<Button.Icon
					type={"HAMBURGER"}
					className="stroke-content-primary h-6 w-6"
				/>
			</Button>
		</DashboardPageSection>
	);
};

export { Menu };
