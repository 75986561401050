import React from "react";

import { cx } from "src/utils";
import { PageSection } from "@components/atoms";
import { Button } from "@components/molecules";
import { useNavigate } from "react-router-dom";
import { useLoadImageQuery } from "business-logic-gamblino";
import { motion } from "framer-motion";
import { Menu } from "@components/organisms";

const imageUrl = "/404.png";

const Image: React.FC<{ className?: string }> = ({ className }) => {
	const { isSuccess } = useLoadImageQuery({
		src: imageUrl,
	});

	return (
		<motion.div
			className={cx(
				"@xl/dashboard:max-w-[500px] flex w-full items-center justify-center",
				className,
			)}
		>
			<motion.img
				src={imageUrl}
				initial={{ opacity: 0 }}
				animate={{ opacity: isSuccess ? 1 : 0 }}
				className="aspect-[609/378] w-full max-w-[550px]"
			/>
		</motion.div>
	);
};
const Page404: React.FC<{ className?: string }> = ({ className }) => {
	const navigate = useNavigate();
	return (
		<div className={cx("py-5", className)}>
			<Menu />
			<PageSection className="flex h-screen flex-col-reverse items-center justify-evenly xl/dashboard:flex-row">
				<div className="">
					<p className="text-3xl font-normal">404 ERROR</p>
					<h3 className="mt-4 whitespace-nowrap text-5xl font-normal">
						Page not found.
					</h3>
					<p className="mt-3 text-base">
						Sorry, we couldn’t find the page you’re looking for.
					</p>

					<Button
						onClick={() => {
							navigate("/");
						}}
						type="gradient"
						className="mt-6"
					>
						<p>Back to home</p>
					</Button>
				</div>
				<Image className="shrink basis-1/2" />
			</PageSection>
		</div>
	);
};

export { Page404 };
