import { AssetIcon } from "@components/atoms";
import { wagmiConfig } from "wallet-connection";

const getNativeChainIconType = (): React.ComponentProps<
	typeof AssetIcon
>["type"] => {
	const symbol = wagmiConfig.chains[0].nativeCurrency.symbol;
	switch (symbol) {
		// case "AVAX":
		// 	return "AVAX";
		// case "ETH":
		// 	return "ETHER";
		// case "MATIC":
		// 	return "MATIC";
		case "SEI":
			return "SEI";
	}
	return "ETHER";
};
export { getNativeChainIconType };
