import dayjs from "dayjs";

import React, { useMemo, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";

import { AssetIcon, Icon, Tooltip } from "@components/atoms";
import { MyBetSubItem } from "../MyBetSubItem/MyBetSubItem";
import { AnimateChangeInHeight } from "@components/organisms";
import { TooltipNumberWrapper } from "@components/molecules";
import { Bet } from "azuro-service/src/queries/getBets";
import { truncateEthAddress } from "formatting-service";
import { cx } from "src/utils";

const MyBetItem: React.FC<{ bet: Bet; id?: number }> = ({ bet, id }) => {
	const [showSlip, setShowSlip] = useState(false);

	const showMoreIcon = showSlip ? "CIRCLE_MINUS" : "CIRCLE_PLUS";
	const showMoreText = showSlip ? "Hide betslip" : "Show betslip";

	const renderStatus = useMemo(() => {
		if (bet.isActive)
			return (
				<>
					<Icon
						type="CHECKMARK"
						className="stroke-gamblino-light h-4 w-4"
					/>
					<p className="text-gamblino-light text-xs">Active</p>
				</>
			);

		if (bet.isCanceled) {
			return (
				<>
					<Icon
						type="FORBIDDEN"
						className="stroke-content-badge-alert h-4 w-4"
					/>
					<p className="text-content-badge-alert text-xs">Canceled</p>
				</>
			);
		}

		if (bet.isWin) {
			return (
				<>
					<Icon
						type="BOOKMARK"
						className="stroke-text-badge-content-success h-5 w-5"
					/>
					<p className="text-text-badge-content-success text-xs">
						Won
					</p>
				</>
			);
		}

		if (bet.isLose) {
			return (
				<>
					<Icon
						type="BOOKMARK_X"
						className="stroke-content-badge-error h-5 w-5"
					/>
					<p className="text-content-badge-error text-xs">Lost</p>
				</>
			);
		}

		return <></>;
	}, [bet.isActive, bet.isCanceled, bet.isLose, bet.isWin]);

	return (
		<div
			className={cx(
				"bg-background-tertiary flex min-h-[56px] w-full shrink-0 flex-col gap-2 rounded p-2",
			)}
		>
			<div className="flex justify-between">
				<div className="text-content-primary flex items-center gap-2 text-xs">
					<Tooltip content={<p>{bet.id}</p>}>
						<p>ID {truncateEthAddress(bet.id.split("_")[0], 3)}</p>
					</Tooltip>

					<div className="flex items-center gap-1">
						<Icon
							type="CALENDAR"
							className="stroke-content-primary h-3 w-3"
						/>
						<p>{dayjs.unix(bet?.createdAt).format("D MMM YYYY")}</p>
					</div>
					<div className="flex items-center gap-1">
						<Icon
							type="CLOCK"
							className="stroke-content-primary h-3 w-3"
						/>
						<p>{dayjs.unix(bet?.createdAt).format("HH:mm")}</p>
					</div>
				</div>

				<div className="flex">{renderStatus}</div>
			</div>

			{!showSlip && (
				<AnimatePresence>
					<motion.div
						initial={{ opacity: 0, y: 10 }}
						animate={{ opacity: 1, y: 0 }}
						exit={{ opacity: 0, y: 10 }}
						className="flex justify-between"
					>
						<div className="flex gap-2 ">
							<p className="text-xs">
								{bet?.outcomes?.length || 0} events
							</p>
						</div>

						<div className="flex gap-1">
							<AssetIcon type="TETHER" className=" h-4 w-4" />
							<p className="text-xs">
								<TooltipNumberWrapper
									number={bet?.possibleWin || 0}
								/>
							</p>
						</div>
					</motion.div>
				</AnimatePresence>
			)}

			<AnimateChangeInHeight>
				<AnimatePresence>
					{showSlip && (
						<>
							<motion.div
								initial={{ opacity: 0, y: 10 }}
								animate={{ opacity: 1, y: 0 }}
								exit={{ opacity: 0, y: 10 }}
								transition={{
									type: "just",
									bounce: 0.2,
									duration: 0.4,
								}}
								className="flex flex-col gap-2"
							>
								{bet.outcomes.map((outcome) => (
									<MyBetSubItem
										key={outcome?.game?.id}
										betOutcome={outcome}
									/>
								))}

								<div className="flex items-center justify-between">
									<div className="flex gap-2 ">
										<p className="text-xs opacity-50">
											Total odds
										</p>
									</div>

									<div className="flex gap-1">
										<p className="text-xs">
											<TooltipNumberWrapper
												number={bet?.totalOdds}
											/>
										</p>
									</div>
								</div>

								<div className="flex items-center justify-between">
									<div className="flex gap-2 ">
										<p className="text-xs opacity-50">
											Bet amount
										</p>
									</div>

									<div className="flex gap-1">
										<AssetIcon
											type="TETHER"
											className=" h-4 w-4"
										/>
										<p className="text-xs">
											<TooltipNumberWrapper
												number={bet?.amount}
											/>
										</p>
									</div>
								</div>

								<div className="flex items-center justify-between">
									<div className="flex gap-2 ">
										<p className="text-xs opacity-50">
											Total payout
										</p>
									</div>

									<div className="flex gap-1">
										<AssetIcon
											type="TETHER"
											className=" h-4 w-4"
										/>
										<p className="text-xs">
											<TooltipNumberWrapper
												number={bet?.possibleWin || 0}
											/>
										</p>
									</div>
								</div>
							</motion.div>
						</>
					)}
				</AnimatePresence>
			</AnimateChangeInHeight>

			<div className="flex justify-center">
				<div
					className="flex cursor-pointer gap-2"
					onClick={() => {
						setShowSlip(!showSlip);
					}}
				>
					<p className="text-xs">{showMoreText}</p>
					<Icon
						type={showMoreIcon}
						className="stroke-content-primary h-4 w-4"
					/>
				</div>
			</div>
		</div>
	);
};

export { MyBetItem };
