/* eslint-disable max-lines-per-function */
import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react";
import { getEndpointCreators } from "get-endpoint-creators";
import {
	loadMultipleImages,
	loadImage,
	addTokenToWallet,
} from "../service/general";
import {
	getGames,
	getPromotions,
	subscribeToEmailList,
	getUserByToken as getAuthenticatedUser,
	requestTestEth,
	verifyTestEthRequest,
	getGamesList,
	getTokensPrice,
	getGamesStats,
} from "backend-service-gamblino";
import { calculateGasEstimate } from "../service/calculateGasEstimate";
import { getSeiParallelizedAddress } from "../service/getSeiParallelizedAddress";

const gamblinoBusinessLogicApi = createApi({
	reducerPath: "gamblinoBusinessLogicApi",
	baseQuery: fakeBaseQuery(),
	tagTypes: ["GET_AUTHENTICATED_USER"],
	endpoints: (builder) => {
		const { createQuery, createMutation } = getEndpointCreators(builder);

		return {
			loadMultipleImages: createQuery(loadMultipleImages),
			loadImage: createQuery(loadImage),
			tokensPrice: createQuery(getTokensPrice),
			subscribeToEmailList: createMutation(subscribeToEmailList),
			getGames: createQuery(getGames),
			getPromotions: createQuery(getPromotions),
			getAuthenticatedUser: createQuery(getAuthenticatedUser, {
				providesTags: ["GET_AUTHENTICATED_USER"],
			}),
			getSeiParallelizedAddress: createQuery(
				getSeiParallelizedAddress,
				{},
			),
			rerender: createQuery(() => Promise.resolve(null)),
			requestTestEth: createMutation(requestTestEth),
			verifyTestEthRequest: createMutation(verifyTestEthRequest),
			addTokenToWallet: createMutation(addTokenToWallet),
			gamesList: createQuery(getGamesList),
			calculateGasEstimate: createQuery(calculateGasEstimate),
			getGamesStats: createQuery(getGamesStats),
		};
	},
});

const useRerenderInterval = ({
	pollingInterval,
}: {
	pollingInterval?: number;
}) => {
	return gamblinoBusinessLogicApi.useRerenderQuery(undefined, {
		pollingInterval,
	});
};

export { useRerenderInterval };

export const {
	useLoadImageQuery,
	useLoadMultipleImagesQuery,
	useSubscribeToEmailListMutation,
	useCalculateGasEstimateQuery,
	useGetGamesQuery,
	useTokensPriceQuery,
	useGetPromotionsQuery,
	useGetAuthenticatedUserQuery,
	useLazyGetAuthenticatedUserQuery,
	useGetGamesStatsQuery,
	useRequestTestEthMutation,
	useVerifyTestEthRequestMutation,
	useAddTokenToWalletMutation,
	useGamesListQuery,
} = gamblinoBusinessLogicApi;

export { gamblinoBusinessLogicApi };
