const ROUND_LENGTH_IN_SECONDS = 5 * 60;

const CONTRACT_ADDRESS_PREDICTION_ON_BTC_PRICE = import.meta.env
	.CONTRACT_ADDRESS_PREDICTION_ON_BTC_PRICE;

const CONTRACT_ADDRESS_ADRENALIN_WHEEL = import.meta.env
	.CONTRACT_ADDRESS_ADRENALIN_WHEEL;

const CONTRACT_ADDRESS_DICE_GAME = import.meta.env.CONTRACT_ADDRESS_DICE_GAME;

const CONTRACT_ADDRESS_COIN_FLIP = import.meta.env.CONTRACT_ADDRESS_COIN_FLIP;

const CONTRACT_ADDRESS_GAMBLINO_BANK = import.meta.env
	.CONTRACT_ADDRESS_GAMBLINO_BANK;

const CONTRACT_ADDRESS_ROULETTE = import.meta.env.CONTRACT_ADDRESS_ROULETTE;

const CONTRACT_ADDRESS_MINES = import.meta.env.CONTRACT_ADDRESS_MINES;

const AZURO_AFFILIATE_ADDRESS = import.meta.env.AZURO_AFFILIATE_ADDRESS;

const MIN_BET_AMOUNT_DEV_DEFAULT = 0.02;
const MIN_BET_AMOUNT_PORD_DEFAULT = 8;

const MIN_BET_AMOUNT_ROULETTE = import.meta.env.DEV
	? MIN_BET_AMOUNT_DEV_DEFAULT
	: 1;
const MIN_BET_AMOUNT_DICE = import.meta.env.DEV
	? MIN_BET_AMOUNT_DEV_DEFAULT
	: MIN_BET_AMOUNT_PORD_DEFAULT;
const MIN_BET_AMOUNT_FLIP_MASTER = import.meta.env.DEV
	? MIN_BET_AMOUNT_DEV_DEFAULT
	: MIN_BET_AMOUNT_PORD_DEFAULT;
const MIN_BET_AMOUNT_MINES = import.meta.env.DEV
	? MIN_BET_AMOUNT_DEV_DEFAULT
	: MIN_BET_AMOUNT_PORD_DEFAULT;

export {
	CONTRACT_ADDRESS_ADRENALIN_WHEEL,
	CONTRACT_ADDRESS_PREDICTION_ON_BTC_PRICE,
	CONTRACT_ADDRESS_DICE_GAME,
	CONTRACT_ADDRESS_COIN_FLIP,
	CONTRACT_ADDRESS_GAMBLINO_BANK,
	ROUND_LENGTH_IN_SECONDS,
	CONTRACT_ADDRESS_ROULETTE,
	CONTRACT_ADDRESS_MINES,
	AZURO_AFFILIATE_ADDRESS,
	MIN_BET_AMOUNT_ROULETTE,
	MIN_BET_AMOUNT_DICE,
	MIN_BET_AMOUNT_FLIP_MASTER,
	MIN_BET_AMOUNT_MINES,
};
