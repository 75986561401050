import React from "react";
import { AnimatePresence, motion } from "framer-motion";

import { cx } from "src/utils";
import { AssetIcon, Icon } from "@components/atoms";

const MyBetItemSkeleton: React.FC<{ className?: string }> = ({ className }) => {
	const showMoreIcon = "CIRCLE_PLUS";
	const showMoreText = "Show betslip";

	return (
		<div
			className={cx(
				"bg-background-tertiary flex min-h-[56px] w-full shrink-0 flex-col gap-2 rounded p-2",
				className,
			)}
		>
			<div className="flex justify-between">
				<div className=" bg-content-primary  flex h-6 w-[55%] animate-pulse items-center gap-1 rounded-lg text-xs opacity-20 "></div>

				<div className="bg-content-primary w-[10%] animate-pulse rounded-lg opacity-20"></div>
			</div>

			<AnimatePresence>
				<motion.div
					initial={{ opacity: 0, y: 10 }}
					animate={{ opacity: 1, y: 0 }}
					exit={{ opacity: 0, y: 10 }}
					className="flex justify-between"
				>
					<div className="flex gap-2 ">
						<p className="bg-content-primary h-4 w-20 animate-pulse rounded-md opacity-20"></p>
					</div>

					<div className="flex gap-1">
						<AssetIcon type="TETHER" className=" h-4 w-4" />

						<p className="bg-content-primary h-4 w-5 animate-pulse rounded-md text-xs opacity-20"></p>
					</div>
				</motion.div>
			</AnimatePresence>

			<div className="flex justify-center">
				<div className="flex cursor-pointer gap-2">
					<p className="text-xs">{showMoreText}</p>
					<Icon
						type={showMoreIcon}
						className="stroke-content-primary h-4 w-4"
					/>
				</div>
			</div>
		</div>
	);
};

export { MyBetItemSkeleton };
