import React from "react";
import { cx } from "src/utils";
import { Outlet, useLocation } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { useOnClickOutside, useWindowSize } from "usehooks-ts";
import { SidebarV2 } from "../SidebarV2";
import { useAsideStatus } from "src/redux/useAsideStatus";

const BREAK_POINT = 1024; // lg:

const calculateSidebarWidth = (windowWidth: number) => {
	if (windowWidth < BREAK_POINT) {
		return 260;
	}

	return 320;
};

const PageTemplateWithSidebar: React.FC<{ className?: string }> = ({
	className,
}) => {
	const [isSidebarOpen, setIsSidebarOpen] = React.useState<boolean>(false);

	const { width } = useWindowSize();

	const { pathname } = useLocation();
	React.useEffect(() => {
		setIsSidebarOpen(false);
	}, [pathname]);

	const ref = React.useRef<HTMLDivElement>(null);
	useOnClickOutside(ref, () => {
		if (width < BREAK_POINT) {
			setIsSidebarOpen(false);
		}
	});

	const sideBarWidth = React.useMemo(
		() => calculateSidebarWidth(width),
		[width],
	);

	React.useLayoutEffect(() => {
		if (width < BREAK_POINT) {
			setIsSidebarOpen(false);
			return;
		}
		setIsSidebarOpen(true);
	}, [width]);

	const { asideStatus } = useAsideStatus();
	return (
		<div
			className={cx(
				"bg-background-primary relative flex min-h-screen",
				className,
			)}
		>
			{/* Dark overlay when sidebar is open */}
			<AnimatePresence>
				{isSidebarOpen && width < BREAK_POINT && (
					<motion.div
						className="bg-background-tertiary z-sidebar-overlay absolute inset-0 h-full w-full"
						initial={{ opacity: 0 }}
						animate={{ opacity: 0.8 }}
						exit={{ opacity: 0 }}
					></motion.div>
				)}
			</AnimatePresence>
			<SidebarV2 />
			<section
				className={cx(
					"flex flex-auto flex-col",
					"transition-all duration-300 ease-in-out",
					className,
					{
						"peer-[]:md:pl-[21rem]": asideStatus,
						// Mobile Design
						"peer-[]:md:pl-[6.225em]": !asideStatus,
					},
				)}
			>
				<div className="relative h-full min-h-screen">
					<Outlet />
				</div>
			</section>
		</div>
	);
};

export { PageTemplateWithSidebar };
