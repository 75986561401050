import { getSubgraphUrlFromEnv } from "../../helpers/getSubgraphUrlFromEnv";
import mockedData from "./mockedData";
import { schema } from "./schema";
import { createRequestQueryFunction } from "query-function-creators";

const getGamesList = createRequestQueryFunction({
	getAxiosRequestConfig: ({
		sportSlug,
		sportsId,
		countrySlug,
		leagueSlug,
	}: {
		sportSlug: string;
		sportsId: string;
		countrySlug: string;
		leagueSlug: string;
	}) => ({
		url: `${import.meta.env.BACKEND_SERVICE_GAMBLINO_BASE_URL}/graphql`,
		method: "POST",
		data: {
			url: getSubgraphUrlFromEnv(),
			query: `{sportHubs(where: {id: "${sportsId}"}) { id name sports(where: {slug: "${sportSlug}"}) { id name slug countries(where: {hasActiveLeagues: true, slug_contains_nocase: "${countrySlug}"} orderBy: turnover orderDirection: desc) { id name slug turnover leagues(where: {hasActiveGames: true, games_: {status_in: [Created, Paused]}, slug_contains_nocase: "${leagueSlug}"} orderBy: turnover orderDirection: desc) { id name turnover slug country { name } games(first: 1000 where: {status_in: [Created, Paused]} orderBy: startsAt orderDirection: desc) { id turnover status startsAt title slug participants { name image } conditions { conditionId turnover isExpressForbidden outcomes { id outcomeId currentOdds } core { address } } liquidityPool { address } sport { id name slug sporthub { id } } league { name country { name } } } } } } }}`,
		},
	}),
	schema,
	getMockedData: () => mockedData.response1,
	isMockingEnabled: false,
});

export { getGamesList };
