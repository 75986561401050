import {
	GetHotSportHubsQuery,
	GetHotSportHubsQueryVariables,
} from "../gql/graphql";
import { client } from "../apollo";
import { graphql } from "../gql";

const query = graphql(`
	query getHotSportHubs($gamesLimit: Int = 10, $sportsSlugs: [String!]) {
		sportHubs {
			id
			name
			sports(where: { slug_in: $sportsSlugs }) {
				id
				name
				slug
				countries(
					where: { hasActiveLeagues: true }
					orderBy: turnover
					orderDirection: desc
					first: 1
				) {
					id
					name
					slug
					turnover
					leagues(
						where: {
							hasActiveGames: true
							games_: { status_in: [Created, Paused] }
						}
						orderBy: turnover
						orderDirection: desc
						first: 1
					) {
						id
						name
						turnover
						slug
						country {
							name
							__typename
						}
						games(
							first: $gamesLimit
							where: { status_in: [Created, Paused] }
							orderBy: turnover
							orderDirection: desc
						) {
							id
							turnover
							status
							startsAt
							title
							slug
							participants {
								name
								image
								__typename
							}
							conditions {
								conditionId
								turnover
								isExpressForbidden
								outcomes {
									id
									outcomeId
									currentOdds
									__typename
								}
								core {
									address
									__typename
								}
								__typename
							}
							liquidityPool {
								address
								__typename
							}
							sport {
								id
								name
								slug
								sporthub {
									id
									__typename
								}
								__typename
							}
							league {
								name
								country {
									name
									__typename
								}
								__typename
							}
							__typename
						}
						__typename
					}
					__typename
				}
				__typename
			}
			__typename
		}
	}
`);

const getHotSports = async (variables: GetHotSportHubsQueryVariables) => {
	if (!client) {
		throw new Error("Apollo client not initialized");
	}

	const {
		data: { sportHubs },
	} = await client.query<GetHotSportHubsQuery, GetHotSportHubsQueryVariables>(
		{
			query,
			variables,
		},
	);

	return sportHubs;
};

export { getHotSports };
