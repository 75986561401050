import React from "react";

import { motion, useAnimation } from "framer-motion";
import { cx } from "src/utils";
import { useAudioManager } from "src/redux";

const getRandomDelay = () => -(Math.random() * 0.7 + 0.05);

const randomDuration = () => Math.random() * 0.07 + 0.23;

const variants = {
	start: (i) => ({
		rotate: i % 2 === 0 ? [-2, 2.3, 0] : [2, -2.4, 0],
		transition: {
			delay: getRandomDelay(),
			repeat: Infinity,
			duration: randomDuration(),
		},
	}),
	reset: {
		rotate: 0,
	},
};

const SOUND_PATH =
	"/sounds/neutral/20595124_button_by_sound_effect_preview.mp3";

const MinesCard: React.FC<{
	isDisabled?: boolean;
	className?: string;
	isSelected: boolean;
	isMine?: boolean;
	isMineSelected?: boolean;
	isLoading?: boolean;
	onSelect: () => void;
}> = ({
	className,
	isSelected,
	onSelect,
	isDisabled,
	isMineSelected,
	isMine,
	isLoading,
}) => {
	const controls = useAnimation();

	const { playSoundOnce } = useAudioManager();

	React.useEffect(() => {
		if (isLoading) {
			controls.start("start");
			return;
		}

		controls.stop();
		controls.set("reset");
	}, [isLoading]);

	const getImageUrl = () => {
		if (isMineSelected) {
			return "/mines/card-explosion.svg";
		}
		if (isMine) {
			return "/mines/card-mine.svg";
		}

		if (isSelected) {
			return "/gamblino-logo-without-text.svg";
		}
	};

	const handleClick = () => {
		if (isDisabled && !isSelected) {
			return;
		}
		if (isMine) {
			return;
		}
		if (isMineSelected) {
			return;
		}

		onSelect();
		playSoundOnce(SOUND_PATH);
	};
	return (
		<motion.div
			variants={variants}
			animate={controls}
			onClick={handleClick}
			className={cx(
				"bg-background-tertiary flex aspect-square cursor-pointer items-center justify-center rounded shadow-[0px_2px_0px_0px_#020617]",

				className,
				{
					"bg-background-badge-error": isMine,
					"cursor-default": isMine || isMineSelected,
				},
			)}
		>
			<img
				src={getImageUrl()}
				className={cx("w-full max-w-[30px] md:max-w-[55px]")}
			/>
		</motion.div>
	);
};

export { MinesCard };
