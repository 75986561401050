import React from "react";
import { cx } from "src/utils";

const InputLabel: React.FC<{
	name: string;
	className?: string;
}> = ({ name, className }) => {
	return (
		<label className={cx("hover:cursor-pointer", className)} htmlFor={name}>
			{name}
		</label>
	);
};

export { InputLabel };
