import React from "react";
import { DashboardPageSection } from "@components/atoms";
import { cx } from "src/utils";
import { Menu } from "@components/organisms";
import { Button } from "@components/molecules";
import { useModalManager } from "@components/ethereals/ModalsWrapper";
import {
	useLocation,
	useNavigate,
	useSearchParams,
	useParams,
} from "react-router-dom";
import { useVerifyTestEthRequestMutation } from "business-logic-gamblino";

const config: { title: string; list: string[] }[] = [
	{
		title: "Claim Your Testnet ETH",
		list: [
			"Click the “Get Test ETH” button to receive your allotted Testnet ETH.",
			"Use this virtual currency exclusively for testing purposes within the Gamblino platform.",
			"Please note that you can obtain testnet ETH only once per Wallet/Email",
		],
	},
	{
		title: "Test Your Strategies",
		list: [
			"Try out various strategies and approaches without any actual financial investment, currently the only game in BETA is Market Minds.",
		],
	},
	{
		title: "Experience the Gamblino Difference:",
		list: [
			"Immerse yourself in a seamless and secure #gambleFI experience.",
			"Explore the innovative features and cutting-edge technology that sets Gamblino apart.",
		],
	},
	{
		title: " Provide Feedback",
		list: [
			" We value your insights! Share your experiences, suggestions, and any issues you encounter with our support team at hello@gamblino.app",
		],
	},
];

const Step: React.FC<{
	title: string;
	list: string[];
	stepNumber: number;
	backgroundImg?: string;
	className?: string;
}> = ({ title, list, stepNumber, backgroundImg, className }) => {
	return (
		<div
			className={cx(
				"bg-content-primary relative h-full cursor-pointer rounded-2xl bg-opacity-10 p-4 backdrop-blur-sm",
				className,
			)}
		>
			<div className="bg-content-primary w-max rounded-full px-2 py-1 ">
				<p className="text-gradient-1">Step #{stepNumber}</p>
			</div>
			<h3 className="text-gradient-1 mt-4 text-xl font-medium">
				{title}
			</h3>
			<ul className="mt-3 flex list-inside list-disc flex-col gap-2">
				{list.map((text) => (
					<li key={text} className="text-content-primary">
						{text}
					</li>
				))}
			</ul>
		</div>
	);
};

const FaucetPage: React.FC<{ className?: string }> = ({ className }) => {
	const { modalManager } = useModalManager();
	const [searchParams, setSearchParams] = useSearchParams();
	const [verifyTestEthRequest, { isLoading, isError, isSuccess, data }] =
		useVerifyTestEthRequestMutation();

	React.useEffect(() => {
		void (async () => {
			const token = searchParams.get("token");

			if (token) {
				await verifyTestEthRequest({ token });
			}
		})();
	}, []);

	React.useEffect(() => {
		setSearchParams({});

		if (isError) {
			modalManager.open("SimpleErrorModal", {
				heading: "Something went wrong",
				description:
					"Please try again. If problem persists, please contact our support.",
			});
		}
	}, [isError]);

	React.useEffect(() => {
		setSearchParams({});

		if (isSuccess) {
			modalManager.open("SuccessSimpleModal", {
				heading: "Success",
				description: `You request is approved. You will receive test tokens soon.`,
			});
		}
	}, [isSuccess]);

	return (
		<DashboardPageSection
			fullWidth
			// className="bg-[url(/faucet-background.jpg)] bg-cover pb-32 pt-6 bg-blend-darken "
			className="h-full overflow-hidden pb-16 pt-10"
		>
			<div className="absolute inset-0 h-full overflow-hidden brightness-50">
				<img
					src="/faucet-background.jpg"
					alt="bg"
					className=" h-full w-full object-cover"
				/>
			</div>
			<Menu className="" />
			<DashboardPageSection>
				<h2 className=" text-content-primary relative mt-10 pb-6 text-5xl font-bold">
					Testnet Faucet
				</h2>
			</DashboardPageSection>
			<DashboardPageSection className="flex flex-col-reverse gap-6 lg:flex-row">
				<div className="flex basis-1/2 flex-col gap-4">
					{config.map((step, index) => (
						<Step
							key={step.title}
							{...step}
							stepNumber={index + 1}
						/>
					))}
				</div>
				<div className="  basis-1/2">
					<div className="bg-gradient-1 w-full rounded-2xl p-6">
						<img
							className="mx-auto max-w-[320px]"
							src="/game-controller.png"
							alt=""
						/>
						<p className="text-content-badge-alert text-2xl font-bold">
							Happy gaming!
						</p>
						<p className="mt-2">
							Enjoy the journey of discovery, strategy, and
							entertainment at Gamblino. We’re thrilled to have
							you on board!
						</p>

						<Button
							onClick={() => {
								modalManager.open(
									"RequestTestEthModal",
									undefined,
								);
							}}
							className="mt-6"
						>
							<Button.Text>Get Test ETH</Button.Text>
						</Button>
					</div>
					<div className="bg-gradient-sidebar border-violet-light mt-3 rounded-2xl border border-opacity-10 bg-opacity-5 p-6">
						<h3 className="text-content-primary text-xl font-bold">
							Please Remember
						</h3>
						<ul className="mt-2 flex list-disc flex-col	gap-2 pl-4">
							<li className="">
								Testnet ETH holds no real-world value and is
								exclusively for testing within the Gamblino
								platform.
							</li>
							<li>
								It cannot be withdrawn, transferred, or
								converted to actual Ethereum.
							</li>
						</ul>
					</div>
				</div>
			</DashboardPageSection>
		</DashboardPageSection>
	);
};

export { FaucetPage };
