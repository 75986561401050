import { TooltipNumberWrapper } from "@components/molecules";
import React from "react";

const MultiplierCell: React.FC<{
	payout: number;
	amount: number;
}> = ({ payout, amount }) => {
	return (
		<TooltipNumberWrapper
			number={payout === 0 ? 0 : payout / amount}
			copy
			formatPriceConfig={{
				removeTrailingZeros: false,
			}}
		/>
	);
};

export { MultiplierCell };
