import { type diceBusinessLogicApi } from "business-logic-gamblino";
import { dice } from "business-logic-gamblino";
import {
	getCoreRowModel,
	getPaginationRowModel,
	useReactTable,
} from "@tanstack/react-table";

import { PAGE_SIZE, columns } from "../config";
import React from "react";
import { useAccount } from "wagmi";

const POLLING_INTERVAL = 5000;

const fallbackArray: (typeof diceBusinessLogicApi)["endpoints"]["diceUserHistory"]["Types"]["ResultType"]["games"] =
	[];

const usePaginatedUserHistory = () => {
	const { isConnected: isUserConnected, address } = useAccount();
	const queryResult = dice.useUserHistory(
		{ userAddress: address },
		{
			pollingInterval: POLLING_INTERVAL,
			skip: !isUserConnected,
		},
	);
	const table = useReactTable({
		data: isUserConnected
			? queryResult.data?.games ?? fallbackArray
			: fallbackArray,
		columns,
		getCoreRowModel: getCoreRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		debugTable: false,
	});

	React.useEffect(() => {
		table.setPageSize(PAGE_SIZE);
	}, []);

	return {
		...queryResult,
		data: table.getRowModel().rows.map((row) => row.original),
		originalData: queryResult.data,
		itemsCount: table.getPrePaginationRowModel().rows.length,
		pageSize: PAGE_SIZE,
		pageIndex: table.getState().pagination.pageIndex,
		pageCount: table.getPageCount(),
		goToNextPage: table.nextPage,
		goToPreviousPage: table.previousPage,
		getCanNextPage: table.getCanNextPage,
		getCanPreviousPage: table.getCanPreviousPage,
		setPageIndex: table.setPageIndex,
		table,
	};
};

export { usePaginatedUserHistory };
