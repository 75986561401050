import React from "react";
import { type ColumnDef } from "@tanstack/react-table";
import { type rouletteBusinessLogicApi } from "business-logic-gamblino";
import {
	BetAmountCell,
	PayoutCell,
	TimeCell,
	RolledCell,
	ProfitCell,
} from "./Cells";
import { PlayedSelectionCell } from "./Cells/PlayedSelectionCell";

const PAGE_SIZE = 5;

const columns: ColumnDef<
	(typeof rouletteBusinessLogicApi)["endpoints"]["getRouletteUserHistory"]["Types"]["ResultType"]["games"][number]
>[] = [
	{
		accessorFn: (row) => row.rolled,
		id: "rolled",
		header: () => <p className="text-content-secondary text-sm">Rolled</p>,
		cell: (info) => <RolledCell rolled={info.row.original.rolled} />,
		footer: (props) => props.column.id,
	},
	{
		accessorFn: (row) => row.entry,
		id: "Bet amount",
		header: () => (
			<p className="text-content-secondary text-sm">Bet Amount</p>
		),
		cell: (info) => <BetAmountCell amount={info.row.original.entry} />,
		footer: (props) => props.column.id,
	},
	{
		accessorFn: (row) => row.payout,
		id: "Payout",
		header: () => <p className="text-content-secondary text-sm">Payout</p>,
		cell: (info) => <PayoutCell payout={info.row.original.payout} />,
		footer: (props) => props.column.id,
	},
	{
		accessorFn: (row) => row.payout,
		id: "Profit",
		header: () => <p className="text-content-secondary text-sm">Profit</p>,
		cell: (info) => (
			<ProfitCell
				profit={info.row.original.payout - info.row.original.entry}
			/>
		),
		footer: (props) => props.column.id,
	},
	{
		accessorFn: (row) => row.timestamp,
		id: "Time played",
		header: () => (
			<p className="text-content-secondary text-sm">Time played</p>
		),
		cell: (info) => <TimeCell time={info.row.original.timestamp} />,
		footer: (props) => props.column.id,
	},
	{
		accessorFn: (row) => row.payout,
		id: "Details",
		header: () => <p className="text-content-secondary text-sm">Details</p>,
		cell: (info) => (
			<PlayedSelectionCell
				playedSelection={info.row.original.number}
				rolled={info.row.original.rolled}
			/>
		),
		footer: (props) => props.column.id,
	},
];

export { PAGE_SIZE, columns };
