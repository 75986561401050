import { z } from "zod";

const schema = z
	.object({
		success: z.boolean(),
		games: z.array(
			z.object({
				user: z.string(),
				amount: z.number(),
				payout: z.number(),
				numberOfMines: z.number(),
				minesTiles: z.array(z.any()),
				revealedTiles: z.array(z.any()),
				multiplier: z.number(),
				blockNumber: z.number(),
				blockTimestamp: z.number(),
				txHash: z.string(),
			}),
		),
	})
	.strict();

export { schema };
