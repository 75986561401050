import wait from "wait";
import React from "react";
import { coerce, z } from "zod";
import { toast } from "sonner";
import { useAccount } from "wagmi";
import { Address, parseUnits } from "viem";
import { useWindowSize } from "usehooks-ts";
import * as confetti from "canvas-confetti";
import { Tooltip } from "@components/atoms";
import { Button } from "@components/molecules";
import { roulette } from "business-logic-gamblino";
import { AnimatePresence, motion } from "framer-motion";
import ReactCanvasConfetti from "react-canvas-confetti";

import { cx } from "src/utils";
import { useAudioManager } from "src/redux";
import { BLACK_NUMBERS } from "../../config";
import { useAppSelector } from "src/redux/store";
import { animationDurationsInSeconds } from "../config";
import {
	CONTRACT_ADDRESS_GAMBLINO_BANK,
	MIN_BET_AMOUNT_ROULETTE,
} from "src/config";
import { formatPrice } from "formatting-service";

const animationConfig: React.ComponentProps<(typeof motion)["div"]> = {
	initial: { opacity: 0, scale: 0.66 },
	animate: { opacity: 1, scale: 1 },
	exit: { opacity: 0, scale: 0.66 },
	transition: { duration: 1, type: "spring" },
};

const statuses = ["INITIAL", "WON", "LOST", "LOADING"] as const;
type Status = (typeof statuses)[number];

const SpinButton: React.FC<{
	contractAddress: Address;
	className?: string;
	amount: {
		valueWei: string;
		value: string;
	};
}> = ({ amount, contractAddress, className }) => {
	const { playSoundOnce } = useAudioManager();
	const refAnimationInstance = React.useRef(null);

	const { selectedNumbers } = useAppSelector((state) => state.roulette);

	const [wager, { isError, isLoading, isSuccess, data }] =
		roulette.useWagerMutation({
			fixedCacheKey: "rouletteBusinessLogicApi.useWagerMutation",
		});

	const getInstance = React.useCallback((instance) => {
		refAnimationInstance.current = instance;
	}, []);

	const { data: maxBetData, isSuccess: isSuccessMaxBetAmount } =
		roulette.useCalculateMaxBetAmountQuery(
			{
				contractAddress,
				bankContractAddress: CONTRACT_ADDRESS_GAMBLINO_BANK,
				numbers: selectedNumbers,
			},
			{ pollingInterval: 5000, refetchOnMountOrArgChange: true },
		);

	const makeShot = React.useCallback((particleRatio, opts) => {
		refAnimationInstance.current &&
			refAnimationInstance.current({
				...opts,
				origin: { y: 0.7 },
				particleCount: Math.floor(200 * particleRatio),
			});
	}, []);

	const fire = React.useCallback(() => {
		makeShot(0.25, {
			spread: 26,
			startVelocity: 55,
		});

		makeShot(0.2, {
			spread: 60,
		});

		makeShot(0.35, {
			spread: 100,
			decay: 0.91,
			scalar: 0.8,
		});

		makeShot(0.1, {
			spread: 120,
			startVelocity: 25,
			decay: 0.92,
			scalar: 1.2,
		});

		makeShot(0.1, {
			spread: 120,
			startVelocity: 45,
		});
	}, [makeShot]);

	const { isConnected: isUserConnected } = useAccount();
	const { width } = useWindowSize();

	const schema = z
		.bigint()
		.max(BigInt(maxBetData?.maxBetAmountWei || 0))
		.refine(
			(value) => {
				return value >= parseUnits(`${MIN_BET_AMOUNT_ROULETTE}`, 18);
			},
			{
				message: `Min bet amount is ${MIN_BET_AMOUNT_ROULETTE} SEI`,
			},
		);

	const [status, setStatus] = React.useState<Status>("INITIAL");

	React.useEffect(() => {
		if (status !== "INITIAL") {
			return;
		}

		if (isLoading) {
			setStatus("LOADING");
		}
	}, [isLoading]);

	React.useEffect(() => {
		if (status !== "LOADING") {
			return;
		}

		void (async () => {
			playSoundOnce(
				// "/sounds/roulette/8718804_roulette_by_boomopera_sfx_preview.mp3",
				"/sounds/roulette/24192841_lucky-spin_by_musicwins_preview.mp3",
			);
			await wait(1000 * animationDurationsInSeconds.ROTATION_PART_1);
			if (isSuccess && data) {
				setStatus(data.isWin ? "WON" : "LOST");
				// playSoundOnce(
				// 	data.isWin
				// 		? "/10046325_magic-coin_by_gamechestaudio_preview.mp3"
				// 		: "/15344564_wrong-answer_by_gamechestaudio_preview.mp3",
				// );

				playSoundOnce(
					data.isWin
						? "/sounds/positive/9474090_well-done_by_gamechestaudio_preview.mp3"
						: "/sounds/negative/7738258_disappointing-prize_by_gamechestaudio_preview.mp3",
				);
				if (data.isWin) {
					await wait(600);
					fire();
				}

				await wait(
					1000 *
						(animationDurationsInSeconds.ROTATION_PART_2 +
							animationDurationsInSeconds.WAIT_BEFORE_RESET),
				);
			}

			setStatus("INITIAL");
		})();
	}, [isSuccess, data?.isWin, isLoading]);

	const isDisabled =
		isLoading ||
		!schema.safeParse(BigInt(amount.valueWei)).success ||
		!isUserConnected ||
		!selectedNumbers.length;

	const isRolledBlack = BLACK_NUMBERS.some(
		(number) => parseInt(number.value) === data?.rolled,
	);

	const renderButton = () => {
		if (schema.safeParse(BigInt(amount.valueWei)).success) {
			return (
				<Button
					disabled={isDisabled}
					type="gradient"
					className={cx(
						"@xl/dashboard:w-[200px] h-[44px] w-full",
						className,
					)}
					onClick={() => {
						playSoundOnce(
							// "/sounds/neutral/9998791_ready-button_by_gamechestaudio_preview.mp3",
							"/sounds/neutral/8145614_selection_by_gamechestaudio_preview.mp3",
						);
						const promise = wager({
							contractAddress,
							amountWei: amount.valueWei,
							numbers: selectedNumbers,
						}).unwrap();

						toast.promise(promise, {
							error: "Failed to spin",
						});
					}}
				>
					<Button.Text>Spin</Button.Text>
				</Button>
			);
		}

		const message = (() => {
			if (!isUserConnected) {
				return "Wallet Not Connected";
			}

			if (selectedNumbers.length === 0) {
				return "No numbers selected";
			}

			if (!isSuccessMaxBetAmount) {
				return "Loading";
			}

			if (
				BigInt(maxBetData.maxBetAmountWei) <
				parseUnits(`${MIN_BET_AMOUNT_ROULETTE}`, 18)
			) {
				return "Not enough balance in the bank for current multiplier";
			}

			if (BigInt(amount.valueWei) > BigInt(maxBetData.maxBetAmountWei)) {
				return `Max bet amount is currently ${formatPrice(+maxBetData?.maxBetAmount, { decimals: 4 })}`;
			}

			return `Min bet amount is currently ${MIN_BET_AMOUNT_ROULETTE}`;
		})();

		return (
			<Tooltip className="w-full" content={<p>{message}</p>}>
				<Button
					disabled
					type="gradient"
					className={cx(
						"@xl/dashboard:w-[200px] h-[44px] w-full",
						className,
					)}
					onClick={() => {
						wager({
							contractAddress,
							amountWei: amount.valueWei,
							numbers: selectedNumbers,
						});
					}}
				>
					<Button.Text>Spin</Button.Text>
				</Button>
			</Tooltip>
		);
	};
	return (
		<AnimatePresence mode="popLayout">
			{status === "INITIAL" && (
				<motion.div
					className="w-full"
					key="INITIAL"
					{...animationConfig}
				>
					{renderButton()}
				</motion.div>
			)}
			{status === "LOADING" && (
				<motion.div
					key="LOADING"
					className="w-full"
					{...animationConfig}
				>
					<Button
						onClick={() => {}}
						type="gradient"
						disabled
						className={cx(
							"@xl/dashboard:w-[200px] h-[44px] w-full",
							className,
						)}
					>
						<Button.Icon
							type="LOADER"
							className={cx("animate-spin")}
						/>
						<Button.Text>
							{width >= 768 ? "Spining..." : ""}
						</Button.Text>
					</Button>
				</motion.div>
			)}
			{status === "WON" && (
				<motion.div
					key="WON"
					className="relative w-full"
					{...animationConfig}
				>
					<ReactCanvasConfetti
						refConfetti={getInstance}
						className="pointer-events-none absolute left-1/2 top-0 z-0 h-[1000px] w-[700px] -translate-x-1/2 -translate-y-2/3"
					/>
					<Button
						size="sm"
						onClick={() => {}}
						className={cx(
							"@xl/dashboard:w-[200px] w-full animate-bounce",
							className,
						)}
					>
						<Button.Text>You Won</Button.Text>
					</Button>
				</motion.div>
			)}
			{status === "LOST" && (
				<motion.div key="LOST" className="w-full" {...animationConfig}>
					<Button
						size="sm"
						onClick={() => {}}
						type="secondary"
						className={cx(
							"animate-shake animate-twice animate-ease-in-out @xl/dashboard:w-[200px] h-[44px] w-full",

							className,
							{
								"bg-purple-black":
									data?.rolled && isRolledBlack,
								"border-content-secondary border":
									data?.rolled && isRolledBlack,
								"bg-content-badge-error":
									data?.rolled && !isRolledBlack,
							},
						)}
					>
						<Button.Text>Rolled {data?.rolled}</Button.Text>
					</Button>
				</motion.div>
			)}
		</AnimatePresence>
	);
};

export { SpinButton };
