import React from "react";
import { Aside, AsideBody, AsideHead } from "./Aside";
import { Nav, NavCollapse, NavItem, NavTitle } from "./Nav";
import LogoAndAsideTogglePart from "./LogoAndAsideToggle";
import { Icon, StatusPill } from "@components/atoms";
import { SportsbookItems } from "./SportsbookItems";

const SidebarV2: React.FC<{ className?: string }> = ({ className }) => {
	return (
		<Aside>
			<AsideHead>
				<LogoAndAsideTogglePart />
			</AsideHead>
			<AsideBody>
				<Nav>
					<NavTitle>Games</NavTitle>

					{import.meta.env.WALLET_CONNECTION_VARIANT !== "POLYGON" ? (
						<NavCollapse
							id="casino"
							to="/games"
							text="Casino"
							iconType="DICES"
						>
							<NavItem
								id="all-games"
								to="/games"
								text="All Casino Games"
								iconType="DICES"
							/>

							<NavCollapse
								id="pvh"
								to="/games"
								text="PvH"
								iconType="CASTLE"
							>
								<NavItem
									id="roulette"
									to="/games/roulette"
									text="Roulette"
									iconType="ROULETTE"
									iconClassName="stroke-[0.2]"
								>
									<StatusPill type="BETA" text="Beta" />
								</NavItem>
								<NavItem
									id="flip-master"
									to="/games/flip-master"
									text="Flip Master"
									iconType="FLIP_MASTER"
									isActive={
										import.meta.env.GAMBLINO_IS_PROD !==
										"true"
									}
								>
									{import.meta.env.GAMBLINO_IS_PROD ===
									"true" ? (
										<StatusPill
											type="COMING_SOON"
											text="Soon"
										/>
									) : (
										<StatusPill type="BETA" text="Beta" />
									)}
								</NavItem>

								<NavItem
									id="dice"
									to="/games/dice"
									text="Dice"
									iconType="DICE6"
									isActive={
										import.meta.env.GAMBLINO_IS_PROD !==
										"true"
									}
								>
									{import.meta.env.GAMBLINO_IS_PROD ===
									"true" ? (
										<StatusPill
											type="COMING_SOON"
											text="Soon"
										/>
									) : (
										<StatusPill type="BETA" text="Beta" />
									)}
								</NavItem>

								<NavItem
									id="mines"
									to="/games/mines"
									text="Mines"
									iconType="MINES"
									iconClassName="stroke-[0.2]"
								>
									<StatusPill type="BETA" text="Beta" />
								</NavItem>
							</NavCollapse>
						</NavCollapse>
					) : (
						<SportsbookItems />
					)}

					{import.meta.env.WALLET_CONNECTION_VARIANT !== "POLYGON" ? (
						<a href="https://sportsbook.gamblino.app/sportsbook/hot">
							<div className="flex cursor-pointer items-center justify-between hover:opacity-50">
								<NavItem
									text={"Sportsbook"}
									id="sportsbook"
									iconType="BOOK_OPEN_TEXT"
									className="hover:stroke-content-primary"
								/>
							</div>
						</a>
					) : (
						<a href="https://gamblino.app/games">
							<div className="flex cursor-pointer items-center justify-between hover:opacity-50">
								<NavItem
									id="casino"
									text="Casino"
									iconType="DICES"
								/>
							</div>
						</a>
					)}

					<NavItem
						id="points"
						to="/points"
						text="Points"
						iconType="TROPHY"
					/>
					{/* <NavSeparator /> */}
					<NavTitle>Other</NavTitle>
					<NavItem
						id="referral"
						to="/referral"
						text="Referral"
						iconType="SHARE"
					/>
					{/* {import.meta.env.GAMBLINO_IS_PROD !== "true" && (
						<NavItem
							id="faucet"
							to="/faucet"
							text="Faucet"
							iconType="DIAMOND"
						/>
					)} */}
					<NavItem
						id="docs"
						onClick={() => {
							window.open(`https://docs.gamblino.app/`);
						}}
						text="Docs"
						iconType="DOCS"
					/>
				</Nav>
			</AsideBody>
		</Aside>
	);
};

export { SidebarV2 };
