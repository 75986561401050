import { CountryQueryVariables } from "../gql/graphql";
import { client } from "../apollo";
import { graphql } from "../gql";

const query = graphql(`
	query Country($id: ID!) {
		country(id: $id, subgraphError: allow) {
			id
			name
			slug
			leagues {
				name
			}
			hasActiveLeagues
			activeLeaguesEntityIds
			turnover
		}
	}
`);

const getCountry = async (variables: CountryQueryVariables) => {
	if (!client) {
		throw new Error("Apollo client not initialized");
	}
	const {
		data: { country },
	} = await client.query({
		query,
		variables,
	});

	return country;
};

export { getCountry };
