import {
	Address,
	bytesToHex,
	zeroAddress,
	formatUnits,
	encodeFunctionData,
} from "viem";
import { wagmiConfig } from "wallet-connection";
import { getAccount, getBalance } from "wagmi/actions";
import { abi as mineAbi } from "contract-service-mines/src/config";
import { getFee, wager as wagerContract } from "contract-service-mines";
import { getMaxBetAmount as getMaxBetAmountContract } from "contract-service-gamblino-bank";

import { calculateGasEstimate } from "../../calculateGasEstimate";

const calculateMaxBetAmount = async ({
	tiles,
	numMines,
	multiplier,
	bankContractAddress,
	contractAddress,
}: Omit<Parameters<typeof wagerContract>[0], "feeWei" | "amountWei"> & {
	bankContractAddress: Address;
	multiplier: number;
}) => {
	const { fee } = await getFee({ contractAddress });

	const randomBytes = crypto.getRandomValues(new Uint8Array(32));
	const randomNumber = bytesToHex(randomBytes);

	const { address } = getAccount(wagmiConfig);

	const balance = await getBalance(wagmiConfig, {
		address: address || zeroAddress,
	});

	const { maxBetAmountWei: bankMaxBetWei } = await getMaxBetAmountContract({
		bankContractAddress,
		multiplier: multiplier * 10 ** 4,
	});

	const maxWithoutGas = balance.value - BigInt(fee.toString());

	const max =
		BigInt(maxWithoutGas) > BigInt(bankMaxBetWei)
			? BigInt(bankMaxBetWei)
			: maxWithoutGas;

	const payableAmountWei = max + BigInt(fee.toString());
	const gasEstimate = await calculateGasEstimate({
		functionData: encodeFunctionData({
			abi: mineAbi,
			functionName: "wager",
			args: [tiles, numMines, randomNumber, zeroAddress, max],
		}),
		to: contractAddress,
		value: payableAmountWei,
	});

	const maxBetAmountWei = (max - BigInt(gasEstimate.valueWei)).toString();
	const maxBetAmount = formatUnits(BigInt(maxBetAmountWei), 18);

	return {
		maxBetAmount,
		maxBetAmountWei,
	};
};

export { calculateMaxBetAmount };
