import { type FC, type HTMLAttributes, type ReactNode } from "react";
import React from "react";
import { useAsideStatus } from "src/redux/useAsideStatus";
import { cx } from "src/utils";
import { useWindowSize } from "usehooks-ts";

interface IAsideHeadProps extends HTMLAttributes<HTMLElement> {
	children: ReactNode;
	className?: string;
}

export const AsideHead: FC<IAsideHeadProps> = ({
	children,
	className,
	...rest
}) => {
	return (
		<div
			className={cx(
				"z-sidebar flex items-center justify-between px-6 pb-6 max-md:flex-row-reverse",
				className,
			)}
			{...rest}
		>
			{children}
		</div>
	);
};

interface IAsideBodyProps extends HTMLAttributes<HTMLElement> {
	children: ReactNode;
	className?: string;
}

export const AsideBody: FC<IAsideBodyProps> = ({
	children,
	className,
	...rest
}) => {
	return (
		<div
			className={cx(
				"h-full overflow-x-auto px-6",
				"no-scrollbar",
				className,
			)}
			{...rest}
		>
			{children}
		</div>
	);
};

interface IAsideFooterProps extends HTMLAttributes<HTMLElement> {
	children: ReactNode;
	className?: string;
}

export const AsideFooter: FC<IAsideFooterProps> = ({
	children,
	className,
	...rest
}) => {
	return (
		<div className={cx("px-6", className)} {...rest}>
			{children}
		</div>
	);
};

interface IAsideProps extends HTMLAttributes<HTMLElement> {
	children: ReactNode;
	className?: string;
}

const Aside: FC<IAsideProps> = ({ children, className, ...rest }) => {
	const { width } = useWindowSize();
	const { asideStatus, setAsideStatus } = useAsideStatus();
	React.useEffect(() => {
		if (width !== 0 && width < 768) {
			setAsideStatus(false);
		}
	}, [width]);
	return (
		<aside
			className={cx(
				"peer",
				"z-sidebar fixed bottom-0 top-0",
				"flex flex-col",
				"py-6",
				"border-r",
				"text-content-primary bg-background-tertiary border-border-primary",
				"transition-all duration-300 ease-in-out",
				className,
				// Mobile Design
				"max-md:-left-[21rem] max-md:w-[21rem] max-md:shadow-2xl",
				{
					"md:w-[21rem]": asideStatus,
					"md:w-[6.225em]": !asideStatus,
					"max-md:-left-[21rem] ": !asideStatus,
					"max-md:left-0": asideStatus,
				},
			)}
			{...rest}
		>
			{children}
		</aside>
	);
};

export { Aside };
