import { Address } from "viem";
import { Icon, Tooltip } from "@components/atoms";
import React from "react";
import { useBlockExplorer } from "src/hooks";
import { cx } from "src/utils";

const OpenContractButton: React.FC<{
	className?: string;
	contractAddress: Address;
}> = ({ className, contractAddress }) => {
	const getBlockExplorerUrl = useBlockExplorer();
	return (
		<Tooltip content="Open Contract">
			<div
				className={cx(
					"bg-background-secondary border-border-primary flex h-10 w-10 cursor-pointer items-center justify-center rounded-full border",
					className,
				)}
				onClick={() => {
					window.open(getBlockExplorerUrl(contractAddress), "_blank");
				}}
			>
				<Icon
					type="SCROLL_TEXT"
					className="stroke-content-primary h-6 w-6"
				/>
			</div>
		</Tooltip>
	);
};

export { OpenContractButton };
