import {
	cubicBezier,
	motion,
	useAnimate,
	useMotionValue,
	useMotionValueEvent,
	useTime,
	useTransform,
} from "framer-motion";
import React from "react";

import { coinFlip } from "business-logic-gamblino";
import { cx } from "src/utils";
import wait from "wait";
import { animationDurationsInSeconds } from "../config";

const bounceVariants: React.ComponentProps<(typeof motion)["div"]>["variants"] =
	{
		initial: {
			y: 0,
		},
		animate: {
			y: -20,
			transition: {
				duration: 0.4,
				repeatType: "mirror",
				repeat: Infinity,
				ease: "easeOut",
			},
		},
	};

const CoinSection: React.FC<{ className?: string }> = ({ className }) => {
	const [scope, animate] = useAnimate();
	const rotateY = useMotionValue(0);
	const scale = useMotionValue(0.5);
	const opacity = useMotionValue(0);

	const [, { data, isSuccess, isLoading }] = coinFlip.useWagerMutation({
		fixedCacheKey: "coinFlipBet",
	});

	React.useEffect(() => {
		void (async () => {
			await wait(1000);

			animate(rotateY, 360 * 3, {
				// ease: "easeInOut",
				type: "spring",
				duration: animationDurationsInSeconds.MAIN_COIN_ROTATION,
			});

			animate(scale, 1, {
				type: "spring",
				bounce: 0.5,
				duration: animationDurationsInSeconds.MAIN_COIN_ROTATION,
			});

			animate(opacity, 1, {
				type: "spring",
				duration: 0.66,
			});
		})();
	}, []);

	React.useEffect(() => {
		if (!isSuccess || !data) {
			return;
		}

		void (async () => {
			// setVariant(data.rolled ? "head" : "tail");
			const currentRotation = rotateY.get();
			const isTail = !data.rolled;

			await Promise.all([
				animate(
					rotateY,
					currentRotation + 360 * 4 + (isTail ? 180 : 0),
					{
						type: "spring",
						duration:
							animationDurationsInSeconds.MAIN_COIN_ROTATION,
						bounce: 0.5,
					},
				),
				void (async () => {
					await wait(200);
					await animate(scale, 1.2, {
						type: "spring",
						duration:
							animationDurationsInSeconds.MAIN_COIN_ROTATION / 3,
						bounce: 0.5,
					});
					animate(scale, 1, {
						type: "spring",
						duration:
							animationDurationsInSeconds.MAIN_COIN_ROTATION / 3,
						bounce: 0.22,
					});
				})(),
			]);

			await wait(1000 * animationDurationsInSeconds.WAIT_BEFORE_RESET);

			if (isTail) {
				const newRotation = rotateY.get();

				animate(rotateY, newRotation + 180, {
					duration: animationDurationsInSeconds.COIN_RESET,
				});
			}
		})();
	}, [isSuccess]);

	return (
		<motion.div
			initial="initial"
			animate={isLoading ? "animate" : "initial"} // Apply bounce animation only when isLoading is true
			variants={bounceVariants}
			className={cx("", className)}
		>
			<motion.div
				style={{
					rotateY,
					scale,
					opacity,

					perspective: "1200px",
					transformStyle: "preserve-3d",
					width: "176px",
					height: "176px",
				}}
			>
				<div
					style={{
						width: "100%",
						height: "100%",
						zIndex: 1,
						backfaceVisibility: "hidden",
						position: "absolute",
					}}
				>
					<img
						src="/flip-master/coin-head.svg"
						style={{
							width: "100%",
							height: "100%",
						}}
						alt="bet coin head"
					/>
				</div>

				<img
					style={{
						width: "100%",
						height: "100%",
					}}
					src="/flip-master/coin-tail.svg"
					alt="bet coin tail"
				/>
			</motion.div>
		</motion.div>
	);
};

export default CoinSection;
