import type { schema } from "./schema";
import type { z } from "zod";

type ModelType = z.infer<typeof schema>;

const response1: ModelType = {
	status: "ok",
	games: [
		{
			entry: 0.004,
			payout: 0.0039877777777778,
			txHash: "0x3ae5e4e12271956b7a54f14480bf41c5a8bb20d40a3220d666ce9e7b333c018d",
			rolled: 22,
			number: "45991767380",
			timestamp: 1701450870,
			status: "win",
		},
		{
			entry: 0.004,
			payout: 0.0039877777777778,
			txHash: "0xea6c128b1efc9c5abfa8827a09b780ba97236ec7763a0758edbef5735f597ede",
			rolled: 13,
			number: "45991767380",
			timestamp: 1701450837,
			status: "win",
		},
		{
			entry: 0.005,
			payout: 0.0049847222222222,
			txHash: "0x6346fda385a9aa0ee96ba2eabdb4415db7b8c44a6bc0cfc243be8df43ecb4f31",
			rolled: 32,
			number: "45991767380",
			timestamp: 1701450674,
			status: "win",
		},
		{
			entry: 0.005,
			payout: 0,
			txHash: "0x432681ee099411fcf2f6c261d68ebc73515869072bffbd9c776d46a30040f7d8",
			rolled: 34,
			number: "45991767380",
			timestamp: 1701450590,
			status: "lost",
		},
		{
			entry: 0.11,
			payout: 0.10966388888889,
			txHash: "0xd2e4a887a624640e601d6c831830047ae9a4994e415979688e2654d7582862f9",
			rolled: 8,
			number: "45991767380",
			timestamp: 1701450533,
			status: "win",
		},
	],
};

export default { response1 };
