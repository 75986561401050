/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from "react";
import { truncateEthAddress } from "formatting-service";
import { Popover } from "@headlessui/react";
import { Avatar, Button, TooltipNumberWrapper } from "@components/molecules";
import { AssetIcon, Icon } from "@components/atoms";
import { cx } from "src/utils";
import { useAccount, useBalance, useDisconnect } from "wagmi";
import { useCopyToClipboard } from "usehooks-ts";
import { Link } from "react-router-dom";
import { useBlockExplorer } from "src/hooks";
import { azuro } from "business-logic-gamblino";

const WalletConnectedPopover = () => {
	const { address } = useAccount();
	const { disconnect } = useDisconnect();
	const [copyValueText, setCopyValueText] = React.useState("Copy");

	const { data: azuroScore } = azuro.useScoreQuery({ address: address! });

	const handleCopy = () => {
		copy(address!);
		// set a timeout to reset the copy value text
		setCopyValueText("Copied!");
		setTimeout(() => {
			setCopyValueText("Copy");
		}, 3000);
	};

	const handleDisconnect = () => {
		disconnect();
	};

	const { data: usdtBalance } = useBalance({
		address,
		token: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
	});

	const { data: nativeBalance } = useBalance({
		address,
	});

	const [copiedValue, copy] = useCopyToClipboard();

	const getBlockExplorer = useBlockExplorer();

	return (
		<Popover className="z-modal relative">
			{({ open, close }) => (
				<>
					<Popover.Button className="bg-background-secondary flex flex-row items-center gap-1 rounded-full px-3 py-2">
						<Avatar userAddress={address!} className=" h-6 w-6 " />
						<span>{truncateEthAddress(address!)}</span>
						<Icon
							type="CHEVRON_DOWN"
							className={cx(
								"stroke-content-primary ml-2 h-4 w-4 transition-all duration-75",
								{ "rotate-180": open },
							)}
						/>
					</Popover.Button>

					<Popover.Panel className="bg-background-secondary border-border-primary absolute right-0 z-[9999] mt-2 grid min-w-[322px] grid-cols-2 gap-x-1 gap-y-2 rounded-lg border p-3">
						<div
							className="bg-background-tertiary
                        col-span-2 grid grid-cols-2 gap-x-1 gap-y-3 rounded-[4px] p-2"
						>
							<div className="col-span-2 flex flex-row items-center gap-1 place-self-center">
								<Avatar
									userAddress={address!}
									className="border-border-primary h-6 w-6 border"
								/>
								{truncateEthAddress(address!)}
							</div>
							<button
								onClick={handleCopy}
								className="flex flex-col items-center justify-center gap-1"
							>
								<Icon
									type="COPY"
									className="stroke-content-primary h-4 w-4"
								/>
								<span className="text-xs">{copyValueText}</span>
							</button>
							<a
								href={getBlockExplorer(address!)}
								target="_blank"
								className="flex flex-col items-center justify-center gap-1"
								rel="noreferrer"
							>
								<Icon
									type="EXTERNAL_LINK"
									className="stroke-content-primary h-4 w-4"
								/>
								<span className="text-xs">
									View on explorer
								</span>
							</a>
						</div>
						<div
							className="bg-background-tertiary
                    flex flex-col gap-1 rounded-[4px] p-2"
						>
							<AssetIcon
								type={
									nativeBalance?.symbol as React.ComponentProps<
										typeof AssetIcon
									>["type"]
								}
								className="h-4 w-4"
							/>
							<span className="text-sm">
								<TooltipNumberWrapper
									number={nativeBalance?.formatted}
									formatPriceConfig={{
										decimals: 5,
									}}
								/>
							</span>
							<span className="text-content-secondary text-xs">
								{nativeBalance?.symbol}
							</span>
						</div>
						<div
							className="bg-background-tertiary
                         flex flex-col gap-1 rounded-[4px] p-2"
						>
							<AssetIcon type="TETHER" className="h-4 w-4" />
							<span className="text-sm">
								<TooltipNumberWrapper
									number={usdtBalance?.formatted || 0}
									formatPriceConfig={{
										decimals: 5,
									}}
								/>
							</span>
							<span className="text-content-secondary text-xs">
								{usdtBalance?.symbol || "USDT"}
							</span>
						</div>

						{import.meta.env.WALLET_CONNECTION_VARIANT !==
						"POLYGON" ? (
							<></>
						) : (
							<Link
								to="/sportsbook/my-bets"
								className="
                        hover:bg-background-tertiary/50 col-span-2 flex w-full flex-row items-center justify-start gap-2 rounded-[4px] px-2 py-4"
							>
								<Button.Icon
									type="PAPER"
									className="stroke-content-secondary h-6 w-6 stroke-[1px]"
								/>
								<Button.Text className="text-content-secondary text-sm font-normal">
									My Bets
								</Button.Text>
							</Link>
						)}

						{azuroScore && (
							<div className="col-span-2 flex w-full flex-row items-center justify-start gap-2 rounded-[4px] p-2">
								<AssetIcon type="AZURO" className="h-6 w-6" />
								<div className="text-content-secondary space-x-1 text-sm font-normal">
									<span>Azuro score</span>
									<span className="text-content-primary">
										{+azuroScore}
									</span>
								</div>
							</div>
						)}
						<Button
							type="ghost"
							onClick={() => {
								handleDisconnect();
								close();
							}}
							className="hover:bg-background-tertiary/50 col-span-2 w-full justify-start rounded-[4px] p-2"
						>
							<Button.Icon
								type="LEAVE"
								className="stroke-content-secondary h-6 w-6 stroke-[1px]"
							/>
							<Button.Text className="text-content-secondary text-sm font-normal">
								Disconnect
							</Button.Text>
						</Button>
					</Popover.Panel>
				</>
			)}
		</Popover>
	);
};

export { WalletConnectedPopover };
