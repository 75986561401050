import React from "react";
import { footerConfig } from "./footerConfig";
import { cx } from "src/utils";
import { PageSection } from "@components/atoms";
import { Link } from "react-router-dom";
import { Input } from "@components/molecules";
import { z } from "zod";
import { useModalManager } from "@components/ethereals/ModalsWrapper";

const Footer: React.FC<{ className?: string }> = ({ className }) => {
	const { modalManager } = useModalManager();
	const [email, setEmail] = React.useState<string>("");

	const zodSchema = z.string().email();

	const val = zodSchema.safeParse(email);

	return (
		<PageSection
			fullWidth
			className={cx("bg-background-tertiary pb-6 pt-10", className)}
		>
			<PageSection className="flex flex-col items-center">
				<img
					className=""
					src="/gamblino-logo-with-text.svg"
					alt="gablino logo"
				/>
				<h2 className="text-content-primary mt-6 text-center text-xl font-bold md:text-2xl">
					Never miss our updates!
				</h2>
				<p className="text-content-primary mt-3 text-center text-base font-normal lg:mt-4">
					Subscribe now to receive exclusive updates and stay at the
					forefront of all things Gamblino
				</p>
				<Input
					type="text"
					value={email}
					setValue={setEmail}
					placeholder="Enter an email"
					name={"Enter an email hero"}
					wrapperClassName="mt-4 max-w-[400px] w-full md:mt-5"
				>
					<Input.Button
						onClick={() => {
							if (val.success) {
								modalManager.open("SubscribeToEmailListModal", {
									email: val.data,
								});
								setEmail("");
							}
						}}
						type="outline"
						className=" h-[52px] self-center rounded-full"
						disabled={email !== "" && !val.success}
					>
						<Input.Button.Text>Subscribe</Input.Button.Text>
						{/* <Input.Button.Icon
							type="ARROW_RIGHT"
							className="h-6 w-6 stroke-white"
						/> */}
					</Input.Button>
				</Input>
				<div className="mt-6 flex flex-col items-center gap-4 md:flex-row">
					<p className="text-content-secondary text-base">
						Privacy policy
					</p>
					<p className="text-content-secondary text-base">
						Terms of services
					</p>
					<a
						href="https://dappradar.com/dapp/gamblino/"
						target="_blank"
						rel="noreferrer"
					>
						<p className="text-content-secondary text-base">
							DappRadar
						</p>
					</a>
				</div>
				<p className="text-content-primary-accent mt-4 text-center">
					© {new Date().getFullYear()} Gamblino. App All rights
					reserved.
				</p>
			</PageSection>
		</PageSection>
	);
};

export { Footer };
