import type { schema } from "./schema";
import type { z } from "zod";

type ModelType = z.infer<typeof schema>;

const response1: ModelType = {
	status: "ok",
	games: [
		{
			betId: "94168519352046756480393007165734484354327972300508726958872257332205027836723",
			amount: 0.084199999999999997069011214989586733281612396240234375,
			betTimestamp: 1682923399,
			betTxHash:
				"0x2c7db2e176a43bd12e254cb8257a42eb99cb20c3d943f4c32553eff2227c83de",
			cap: 60,
			rolled: 96,
			isWin: false,
			payout: 0.429863158000000022962439061302575282752513885498046875,
		},
		{
			betId: "94168519352046756480393007165734484354327972300508726958872257332205027836724",
			amount: 0.075299999999999997069011214989586733281612396240234375,
			betTimestamp: 1682923400,
			betTxHash:
				"0x3d8e2a4e276b52d08e254cb8257a42eb99cb20c3d943f4c32553eff2227c83df",
			cap: 70,
			rolled: 42,
			isWin: true,
			payout: 0.592731580000000022962439061302575282752513885498046875,
		},
		{
			betId: "94168519352046756480393007165734484354327972300508726958872257332205027836725",
			amount: 0.062399999999999997069011214989586733281612396240234375,
			betTimestamp: 1682923401,
			betTxHash:
				"0x4e5f2b5f376c61b06e254cb8257a42eb99cb20c3d943f4c32553eff2227c83e0",
			cap: 80,
			rolled: 19,
			isWin: false,
			payout: 0.313158600000000022962439061302575282752513885498046875,
		},
		{
			betId: "94168519352046756480393007165734484354327972300508726958872257332205027836726",
			amount: 0.098499999999999997069011214989586733281612396240234375,
			betTimestamp: 1682923402,
			betTxHash:
				"0x5f4c3a6d486d70c16e254cb8257a42eb99cb20c3d943f4c32553eff2227c83e1",
			cap: 90,
			rolled: 61,
			isWin: true,
			payout: 0.692733840000000022962439061302575282752513885498046875,
		},
		{
			betId: "94168519352046756480393007165734484354327972300508726958872257332205027836727",
			amount: 0.071599999999999997069011214989586733281612396240234375,
			betTimestamp: 1682923403,
			betTxHash:
				"0x6a3b4c7e596e7db26e254cb8257a42eb99cb20c3d943f4c32553eff2227c83e2",
			cap: 100,
			rolled: 74,
			isWin: true,
			payout: 0.856722730000000022962439061302575282752513885498046875,
		},
		{
			betId: "94168519352046756480393007165734484354327972300508726958872257332205027836728",
			amount: 0.053699999999999997069011214989586733281612396240234375,
			betTimestamp: 1682923404,
			betTxHash:
				"0x7b2a5d8f6a7a83e36e254cb8257a42eb99cb20c3d943f4c32553eff2227c83e3",
			cap: 120,
			rolled: 31,
			isWin: false,
			payout: 0.271538460000000022962439061302575282752513885498046875,
		},
		{
			betId: "94168519352046756480393007165734484354327972300508726958872257332205027836729",
			amount: 0.084199999999999997069011214989586733281612396240234375,
			betTimestamp: 1682923405,
			betTxHash:
				"0x8c197e9a7c8d94f46e254cb8257a42eb99cb20c3d943f4c32553eff2227c83e4",
			cap: 140,
			rolled: 55,
			isWin: true,
			payout: 0.748298540000000022962439061302575282752513885498046875,
		},
		{
			betId: "94168519352046756480393007165734484354327972300508726958872257332205027836730",
			amount: 0.098499999999999997069011214989586733281612396240234375,
			betTimestamp: 1682923406,
			betTxHash:
				"0x9d167fbb8d9ea5d56e254cb8257a42eb99cb20c3d943f4c32553eff2227c83e5",
			cap: 160,
			rolled: 83,
			isWin: true,
			payout: 0.910283680000000022962439061302575282752513885498046875,
		},
		{
			betId: "94168519352046756480393007165734484354327972300508726958872257332205027836731",
			amount: 0.071599999999999997069011214989586733281612396240234375,
			betTimestamp: 1682923407,
			betTxHash:
				"0xad056fcd8d9e8cc66e254cb8257a42eb99cb20c3d943f4c32553eff2227c83e6",
			cap: 180,
			rolled: 27,
			isWin: true,
			payout: 0.856722730000000022962439061302575282752513885498046875,
		},
		{
			betId: "94168519352046756480393007165734484354327972300508726958872257332205027836732",
			amount: 0.062399999999999997069011214989586733281612396240234375,
			betTimestamp: 1682923408,
			betTxHash:
				"0xbe04d0ee9e9e9bb76e254cb8257a42eb99cb20c3d943f4c32553eff2227c83e7",
			cap: 200,
			rolled: 47,
			isWin: false,
			payout: 0.313158600000000022962439061302575282752513885498046875,
		},
		{
			betId: "94168519352046756480393007165734484354327972300508726958872257332205027836733",
			amount: 0.084199999999999997069011214989586733281612396240234375,
			betTimestamp: 1682923409,
			betTxHash:
				"0xcf01e2fe9e9f9aa86e254cb8257a42eb99cb20c3d943f4c32553eff2227c83e8",
			cap: 220,
			rolled: 68,
			isWin: false,
			payout: 0.429863158000000022962439061302575282752513885498046875,
		},
		{
			betId: "94168519352046756480393007165734484354327972300508726958872257332205027836734",
			amount: 0.098499999999999997069011214989586733281612396240234375,
			betTimestamp: 1682923410,
			betTxHash:
				"0xdfedf5cfa6eabdd96e254cb8257a42eb99cb20c3d943f4c32553eff2227c83e9",
			cap: 240,
			rolled: 91,
			isWin: true,
			payout: 0.910283680000000022962439061302575282752513885498046875,
		},
		{
			betId: "94168519352046756480393007165734484354327972300508726958872257332205027836735",
			amount: 0.071599999999999997069011214989586733281612396240234375,
			betTimestamp: 1682923411,
			betTxHash:
				"0xeecdf4eeb6eaccc16e254cb8257a42eb99cb20c3d943f4c32553eff2227c83ea",
			cap: 260,
			rolled: 12,
			isWin: false,
			payout: 0.356711460000000022962439061302575282752513885498046875,
		},
		{
			betId: "94168519352046756480393007165734484354327972300508726958872257332205027836736",
			amount: 0.053699999999999997069011214989586733281612396240234375,
			betTimestamp: 1682923412,
			betTxHash:
				"0xfed3f3dfc6f1d7e26e254cb8257a42eb99cb20c3d943f4c32553eff2227c83eb",
			cap: 280,
			rolled: 56,
			isWin: true,
			payout: 0.592731580000000022962439061302575282752513885498046875,
		},
		{
			betId: "94168519352046756480393007165734484354327972300508726958872257332205027836737",
			amount: 0.084199999999999997069011214989586733281612396240234375,
			betTimestamp: 1682923413,
			betTxHash:
				"0x1a5c2d9e7c1d8ee36e254cb8257a42eb99cb20c3d943f4c32553eff2227c83ec",
			cap: 300,
			rolled: 78,
			isWin: true,
			payout: 0.748298540000000022962439061302575282752513885498046875,
		},
		{
			betId: "94168519352046756480393007165734484354327972300508726958872257332205027836738",
			amount: 0.098499999999999997069011214989586733281612396240234375,
			betTimestamp: 1682923414,
			betTxHash:
				"0x2b4b1e8d8d2e8dd46e254cb8257a42eb99cb20c3d943f4c32553eff2227c83ed",
			cap: 320,
			rolled: 34,
			isWin: false,
			payout: 0.429863158000000022962439061302575282752513885498046875,
		},
		{
			betId: "94168519352046756480393007165734484354327972300508726958872257332205027836739",
			amount: 0.071599999999999997069011214989586733281612396240234375,
			betTimestamp: 1682923415,
			betTxHash:
				"0x3c5a1dab8d3e8cc56e254cb8257a42eb99cb20c3d943f4c32553eff2227c83ee",
			cap: 340,
			rolled: 50,
			isWin: true,
			payout: 0.592731580000000022962439061302575282752513885498046875,
		},
	],
};

export default { response1 };
