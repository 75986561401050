import React from "react";
import { PageSection } from "@components/atoms";
import { cx } from "src/utils";
import { FirstSection } from "./FirstSection";
import { SecondSection } from "./SecondSection";
import { ThirdSection } from "./ThirdSection";
import { FourthSection } from "./FourthSection";
import { useAudioManager } from "src/redux";
import { Menu } from "@components/organisms";

const ReferralPage: React.FC<{ className?: string }> = ({ className }) => {
	const { setActiveAudioResource } = useAudioManager();
	React.useEffect(() => {
		setActiveAudioResource(
			"40425412_casino-music_by_googleeman_preview.mp3",
		);
	}, []);

	return (
		<div className={cx("relative pt-10", className)}>
			<Menu />
			<FirstSection className="mt-10" />
			<SecondSection className="mt-10 lg:mt-24" />
			<ThirdSection className="mt-20 lg:mt-32" />
			<FourthSection className="" />
		</div>
	);
};

export { ReferralPage };
