import React from "react";
import { useAccount } from "wagmi";
import { wagmiConfig } from "wallet-connection";
import { useConnectModal, useChainModal } from "@rainbow-me/rainbowkit";

import { Button } from "@components/molecules";
import { cx } from "src/utils";

const WalletConnection = ({
	children,
	className,
}: React.PropsWithChildren<{ className?: string }>) => {
	const { openConnectModal } = useConnectModal();
	const { openChainModal } = useChainModal();

	const { chainId, isConnected } = useAccount();
	const isCorrectChain = chainId && chainId === wagmiConfig.chains[0].id;

	if (isConnected && !isCorrectChain) {
		return (
			<Button
				type="ghost"
				className={cx(
					"bg-red text-content-primary bg-background-error w-full",
					className,
				)}
				onClick={openChainModal}
			>
				<Button.Text className="text-content-primary ">
					Wrong network
				</Button.Text>
			</Button>
		);
	}

	if (!isConnected) {
		return (
			<Button
				onClick={openConnectModal}
				type="ghost"
				className={cx(
					"bg-gradient-button text-content-primary w-full",
					className,
				)}
			>
				<Button.Text className="text-content-primary">
					Connect wallet
				</Button.Text>
			</Button>
		);
	}

	return children;
};

export default WalletConnection;
