import React from "react";
import {
	motion,
	useScroll,
	useTransform,
	AnimatePresence,
	useMotionTemplate,
	useMotionValueEvent,
} from "framer-motion";

import { cx } from "src/utils";
import { Icon, PageSection, StatusPill } from "@components/atoms";
import { AnimateChangeInHeight } from "@components/organisms";

const variants = {
	initial: (direction) => {
		return {
			translateY: direction > 0 ? "40%" : "-120%",
			translateX: "-50%",
			opacity: 0,
			transition: {
				translateY: { duration: 0.15 },
				opacity: { duration: 0.15 },
			},
		};
	},
	animate: {
		translateY: "-50%",
		translateX: "-50%",
		opacity: 1,
		transition: {
			translateY: { duration: 0.15 },
			opacity: { duration: 0.15 },
		},
	},
	exit: (direction) => {
		return {
			translateY: direction > 0 ? "-120%" : "40%",
			translateX: "-50%",
			opacity: 0,
			transition: {
				translateY: { duration: 0.15 },
				opacity: { duration: 0.15 },
			},
		};
	},
};

const config: {
	name: string;
	timeline: string;
	description: string;
	features: {
		name: string;
		bullets: string[];
		isCompleted: boolean;
		isBeta?: boolean;
	}[];
	imageUrl: string;
}[] = [
	{
		name: "one",
		timeline: "Q4 2023",
		description:
			"In Phase One, our primary objectives for the Gamblino app are to implement an In-App Faucet feature, introduce 5-10 games in beta testing, and seamlessly integrate Azuro's Sportsbook for a comprehensive betting experience. These milestones are strategically chosen to foster user engagement, refine gaming experiences, and establish a robust sports betting platform.",
		imageUrl: "/roadmap-image-1.png",
		features: [
			{
				name: "Website, Docs, Whitepaper",
				isCompleted: true,
				bullets: [],
			},
			{ name: "In-App Discord Chat", isCompleted: true, bullets: [] },
			{
				name: "Arbitrum In-App Faucet ",
				isCompleted: true,
				bullets: [
					"Gamblino team released arbitrum faucet so all the beta testers can get testnet ETH seamlessly and enjoy the full experience. Just for the record - currently it's very hard to find Goerly testnet ETH. ",
				],
			},
			{
				name: "Market Minds",
				isCompleted: true,
				isBeta: true,
				bullets: [
					"PvP Game",
					"Market Minds is a prediction game where players predict whether the price will be up or down over a 5-minute window, similar to binary options but at a much faster pace. This is a Parimutuel betting game where the final odds or payout of winning predictions will vary round by round. The more people playing, the better the potential multiples.",
				],
			},
			{
				name: "Adrenaline Wheel",
				isCompleted: true,
				isBeta: true,
				bullets: [
					"PvP Game",
					"The Adrenaline Wheel brings together high-frequency gameplay with a touch of strategy. There is a five-minute countdown window where players can deposit as much ETH as they like. When the round is closed, the player with the most ETH in the pool will have a statistical advantage as they own more of the pool. Snipers can enter last minute to tip the odds in their favor.",
				],
			},
			{
				name: "CoinFlip",
				isCompleted: true,
				isBeta: true,
				bullets: [
					"PvH Game",
					"Coin Flip is a simple and thrilling casino game where players bet on the outcome of a flipped coin, aiming to predict whether it will land on heads or tails for a chance to win. In a fair Coin Flip game, where the coin is unbiased and the flip is not manipulated, the chances of winning are 50%, as there are only two possible outcomes: heads or tails.",
				],
			},
			{
				name: "Dice",
				isCompleted: true,
				isBeta: true,
				bullets: [
					"PvH Game",
					"The dice casino game involves players rolling one or more dice and placing bets on the outcome, offering a diverse range of betting options and an element of chance that adds excitement to the gameplay.",
				],
			},
			{
				name: "SportsBook",
				isCompleted: true,
				bullets: [
					"Gamblino will be extending it's features to include Sportsbook, a move of immense significance as it opens up exciting new opportunities and experiences for our users in the realm of sports betting. Liquidity and Odds provided by Azuro.",
				],
			},
			{
				name: "On Chain Roulette",
				isCompleted: true,
				isBeta: true,
				bullets: [
					"PvH Game",
					"Roulette is a popular casino game where players bet on the outcome of a spinning wheel, choosing from a variety of numbers, colors, or combinations, with the potential for substantial winnings based on their selections.",
				],
			},
			{
				name: "Leaderboard",
				isCompleted: true,
				bullets: [
					"Introducing Leaderboard, a dynamic feature that adds an exciting layer to your Gamblino experience. By participating, you're not just playing, but competing for a range of enticing rewards, including jackpots, airdrops, and more. Leaderboards encourage friendly competition, fostering a sense of community and achievement among users. So, gear up, aim high, and get ready to claim your spot on the leaderboard!",
				],
			},
		],
	},
	{
		name: "two",
		timeline: "Q1 2024",
		description:
			"In Phase Two of the Gamblino app, we are set to introduce several transformative features. These include Synthetic Betting Pools, Custom Prediction Pools, a Jackpot feature, a Roulette game, a Token Generation Event, and the launch of our Staking program. Additionally, we will be implementing a Referral Program to drive user acquisition and engagement. These advancements will not only broaden our platform's offerings but also provide enhanced user experiences while promoting active community involvement.",
		imageUrl: "/roadmap-image-2.png",
		features: [
			{
				name: "Lottery",
				isBeta: true,
				isCompleted: false,
				bullets: [
					"PvP Game",
					"The Gamlino Lottery system is a decentralized application that enables users to engage in a transparent on-chain lottery. The selection of winners is carried out through a provably fair and trustless mechanism, which is constructed using Chainlink's VRF (Verifiable Random Function).",
				],
			},
			{
				name: "Mines",
				isBeta: true,
				isCompleted: true,
				bullets: ["PvH Game", "COMING SOON"],
			},
			{
				name: "Custom Prediction Pools / Synthetic Betting pools",
				isCompleted: false,
				bullets: [
					"PvP Game",
					"Bet on the outcome of another bet",
					"Bet on the ETF PnL ",
				],
			},
			{
				name: "Jackpots",
				isCompleted: false,
				isBeta: true,
				bullets: [
					"Portion of the house's revenue is allocated to a pot, and once it reaches a specific threshold, winners are randomly selected from the pool of participants.",
				],
			},
			{
				name: "On-Chain PvP Poker",
				isCompleted: false,
				bullets: [
					"PvP Game",
					"Tournaments",
					"On-chain poker is a decentralized version of the traditional game, operating on a blockchain using smart contracts. These contracts automate game rules, guaranteeing fair play and transparent outcomes. This approach boosts trust and security, as players' funds are managed by the blockchain, reducing the risk of manipulation by a central authority.",
				],
			},
			{
				name: "TGE",
				isCompleted: false,
				bullets: ["Launch of $GLO token", "DEX / CEX Listing"],
			},
			{
				name: "$GLO Staking & Farming",
				isCompleted: false,
				bullets: [
					"Launch of Staking platform, so $GLO is utilized from the inception stage. ",
					"LP Farming, to incentivize liquidity provision. ",
				],
			},
			{
				name: "Crypto Futures",
				isCompleted: false,
				bullets: [
					"PvH Game",
					"Introducing Crypto Futures: A high-stakes game where users can employ up to 1000x leverage, allowing them to amplify their gains or losses on both long and short positions. This game empowers players to navigate the volatile world of cryptocurrency trading with unprecedented precision and strategy",
				],
			},
		],
	},
	{
		name: "three",
		timeline: "Q2 2024",
		description:
			"Our innovative vision encompasses the integration of cutting-edge Traditional Market Pools and immersive Casino Classics. Leveraging the invaluable insights gleaned from the successes of Phases One and Two, we are poised to revolutionize the gaming landscape with an unparalleled fusion of experience-driven excellence",
		imageUrl: "/roadmap-image-3.png",
		features: [
			{ name: "Referral Program", isCompleted: false, bullets: [] },
			{
				name: "Traditional Market Pools",
				isCompleted: false,
				bullets: [],
			},
			{ name: "Casino Classics", isCompleted: false, bullets: [] },
			{ name: "Baccarat", isCompleted: false, bullets: [] },
			{ name: "Slots", isCompleted: false, bullets: [] },
			{ name: "On-Chain Blackjack", isCompleted: false, bullets: [] },
			{ name: "Live Events", isCompleted: false, bullets: [] },
		],
	},
];

const PhaseDescription: React.FC<{
	index: number;
	name: string;
	timeline: string;
	description?: string;
	className?: string;
}> = ({ index, name, description, timeline, className }) => {
	return (
		<div className={cx("flex flex-col items-center", className)}>
			<div className="relative flex h-10 w-12 items-center justify-center xl:h-14 xl:w-16">
				<img
					className="absolute inset-0 z-0 object-cover"
					src="/roadmap-number-background.svg"
					alt=""
				/>
				<p className="text-content-primary z-1 text-2xl font-bold xl:text-3xl">
					{index}
				</p>
			</div>

			<h4 className="text-content-primary mt-2 text-center text-3xl xl:text-4xl">
				Phase {name}
			</h4>
			<p className="text-content-secondary text-lg">{timeline}</p>
			{description ? (
				<p className="text-content-tertiary mt-2 max-w-xl text-center text-sm">
					{description}
				</p>
			) : null}
		</div>
	);
};

const ExpandableItem: React.FC<{
	feature: (typeof config)[number]["features"][number];
	isActive: boolean;
	onClick: () => void;
	onClickActive: () => void;
}> = ({ feature, isActive, onClick, onClickActive }) => (
	<AnimatePresence>
		<motion.div
			onClick={() => {
				if (isActive) {
					onClickActive();
				} else {
					onClick();
				}
			}}
			className={cx(
				"border-border-primary relative line-clamp-1 flex flex-col rounded-lg border bg-transparent px-6 py-4",
				{
					"cursor-pointer": feature.bullets.length > 0,
				},
			)}
		>
			<AnimateChangeInHeight duration={0.6} className="z-[1]">
				<div className="z-[1] flex items-center justify-between">
					<div className="flex items-center gap-3">
						<Icon
							type="CHECK_CIRCLE"
							className={cx("stroke-content-primary h-6 w-6", {
								"stroke-badge-content-success":
									feature.isCompleted,
							})}
						/>
						<h5 className="text-content-primary text-base font-semibold">
							{feature.name}
						</h5>

						{feature.isBeta && (
							<StatusPill text="BETA" type="BETA" />
						)}
					</div>
					{feature.bullets.length > 0 && (
						<Icon
							type="CHEVRON_DOWN"
							className=" stroke-content-primary h-5 w-5 transition-all duration-[600ms] "
							style={{
								rotate: !isActive ? "0deg" : "180deg",
							}}
						/>
					)}
				</div>
				{feature.bullets.length > 0 && isActive ? (
					<div className={cx("mt-4 flex flex-col items-start gap-4")}>
						{feature.bullets.map((bullet) => (
							<div
								key={bullet}
								className="flex items-start gap-1"
							>
								<Icon
									type="CHEVRON_RIGHT"
									className="stroke-violet-light mt-[3px] h-4 w-4 shrink-0"
								/>
								<p className="text-content-secondary text-base">
									{bullet}
								</p>
							</div>
						))}
					</div>
				) : null}
			</AnimateChangeInHeight>
		</motion.div>
	</AnimatePresence>
);

const PhaseList: React.FC<{
	features: (typeof config)[number]["features"];
	className?: string;
}> = ({ features, className }) => {
	const [activeId, setActiveId] = React.useState<number>(-1);
	return (
		<div className={cx("flex flex-col gap-3", className)}>
			{features.map((feature, index) => (
				<ExpandableItem
					key={feature.name}
					feature={feature}
					onClick={() => {
						if (feature.bullets.length > 0) {
							setActiveId(index);
						}
					}}
					onClickActive={() => setActiveId(-1)}
					isActive={activeId === index}
				/>
			))}
		</div>
	);
};

const Desktop: React.FC<{ className?: string }> = ({ className }) => {
	const [direction, setDirection] = React.useState<number>(1);
	const [isHeaderVisible, setIsHeaderVisible] = React.useState<boolean>(true);
	const [phase, setPhase] = React.useState<number>(0);
	const ref = React.useRef<HTMLDivElement>(null);
	const { scrollYProgress } = useScroll({
		target: ref,
		offset: ["start start", "end start"],
	});

	useMotionValueEvent(scrollYProgress, "change", (y) => {
		if (y < 0.25) {
			setPhase(0);
		} else if (y < 0.5) {
			setPhase(1);
		} else {
			setPhase(2);
		}

		if (y < 0.578) {
			setIsHeaderVisible(true);
		} else {
			setIsHeaderVisible(false);
		}
	});

	const textScale = useTransform(scrollYProgress, [0, 0.5], [0.95, 1.15]);
	const progress = useTransform(scrollYProgress, [0, 0.5], [`0%`, `100%`]);

	const textColor = useMotionTemplate`linear-gradient(89deg, #FFB800 0%,#FFB800 ${progress}, #F8FAFC ${progress}, #F8FAFC 100%)`;

	return (
		<PageSection fullWidth className={cx("", className)} ref={ref}>
			<motion.div
				className="bg-background-primary z-1 sticky py-5"
				// layout="position"
				initial={{ top: 84 }}
				animate={{
					top: isHeaderVisible ? 84 : 0,
				}}
			>
				<motion.h3
					className=" mx-auto w-min self-start whitespace-nowrap bg-clip-text text-center text-4xl font-semibold text-transparent"
					style={{ backgroundImage: textColor, scale: textScale }}
				>
					What you can expect from us
				</motion.h3>
			</motion.div>
			<div className="relative mt-5 flex w-full">
				<div className="bg-background-tertiary sticky top-0 flex h-screen w-full basis-1/2 flex-col items-center justify-center px-5 pt-28 ">
					<div
						className={cx(
							"flex max-w-[520px] flex-col 2xl:max-w-[650px]",
						)}
					>
						<AnimatePresence mode="popLayout">
							<motion.img
								key={`${phase}-${config[phase].imageUrl}`}
								src={config[phase].imageUrl}
								className="h-[180px] w-full max-w-md overflow-visible object-cover 2xl:h-[300px]"
								initial={{
									opacity: 0,
									scale: 0.88,
									x: 50,
								}}
								animate={{
									opacity: 1,
									scale: 1,
									x: 0,
								}}
								exit={{
									opacity: 0,
									scale: 0.88,
									x: -50,
								}}
								alt=""
							/>
						</AnimatePresence>

						<div className="relative mt-16 flex h-10 w-12 items-center justify-center xl:h-14 xl:w-16">
							<img
								className="absolute inset-0 z-0 object-cover"
								src="/roadmap-number-background.svg"
								alt=""
							/>
							<p className="text-content-primary z-1 relative m-auto h-[30px] w-full -translate-y-[3px] overflow-hidden text-center text-2xl font-bold xl:text-3xl">
								<AnimatePresence
									initial={false}
									custom={direction}
								>
									<motion.span
										key={phase}
										className="absolute inset-0 left-1/2 top-1/2 h-full w-full -translate-x-1/2 -translate-y-1/2"
										variants={variants}
										animate="animate"
										initial="initial"
										exit="exit"
										custom={direction}
									>
										{phase + 1}
									</motion.span>
								</AnimatePresence>
							</p>
						</div>
						<h4 className="text-content-primary mt-3 text-start text-3xl xl:text-4xl">
							<span className="pr-2">Phase</span>
							<span className="relative m-auto h-[36px] w-full">
								<AnimatePresence
									initial={false}
									custom={direction}
								>
									<motion.span
										key={`${phase}-${config[phase].name}}`}
										className="absolute inset-0 left-1/2 top-1/2 h-full w-full -translate-x-1/2 -translate-y-1/2"
										variants={variants}
										animate="animate"
										initial="initial"
										exit="exit"
										custom={direction}
									>
										{config[phase].name}
									</motion.span>
								</AnimatePresence>
							</span>
						</h4>
						<p className="text-content-secondary text-lg">
							{config[phase].timeline}
						</p>
						<AnimatePresence mode="popLayout">
							{config[phase].description ? (
								<motion.p
									key={`${phase}-${config[phase].name}-${config[phase].description}`}
									className="text-content-tertiary mt-2 h-12 text-sm"
									initial={{
										opacity: 0,
									}}
									animate={{
										opacity: config[phase].description
											? 1
											: 0,
									}}
									exit={{
										opacity: 0,
									}}
								>
									{config[phase].description}
								</motion.p>
							) : null}
						</AnimatePresence>
					</div>
				</div>
				<div className="w-full basis-1/2">
					{config.map(({ features, name }, index) => (
						<div
							key={name}
							className={cx(
								"bg-gradient-2 flex min-h-screen items-start justify-center py-20",
								{
									"bg-gradient-1": index === 1,
									"h-[140vh]": index === 2,
								},
							)}
						>
							<motion.div
								className="sticky w-full max-w-[700px]"
								animate={{
									top: isHeaderVisible ? 128 + 84 : 128,
								}}
							>
								<PhaseList
									features={config[index].features}
									className={cx(" w-full ")}
								/>
							</motion.div>
						</div>
					))}
				</div>
			</div>
		</PageSection>
	);
};

const Mobile: React.FC<{ className?: string }> = ({ className }) => {
	return (
		<PageSection className={cx("flex flex-col gap-10", className)}>
			<h3 className=" text-content-primary text-center text-4xl font-semibold">
				What you can expect from us
			</h3>
			{config.map(
				(
					{ name, description, features, imageUrl, timeline },
					index,
				) => (
					<div
						className="flex w-full flex-col items-start gap-4"
						key={name}
					>
						<PhaseDescription
							index={index + 1}
							name={name}
							description={description}
							timeline={timeline}
							className="w-full"
						/>
						<img
							className="w-full max-w-[380px] self-center"
							src={imageUrl}
							alt=""
						/>
						<PhaseList className="w-full" features={features} />
					</div>
				),
			)}
		</PageSection>
	);
};

const RoadmapSection: React.FC<{ className?: string }> = ({ className }) => {
	return (
		<React.Fragment>
			<Desktop className={cx("hidden lg:block", className)} />
			<Mobile className={cx("flex lg:hidden", className)} />
		</React.Fragment>
	);
};

export { RoadmapSection };
