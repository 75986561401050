import React from "react";

import { SimpleTable, Pagination } from "@components/organisms";
import { usePaginatedUserHistory } from "./usePaginatedUserQuery";

import { cx } from "src/utils";
import { DashboardPageSection } from "@components/atoms";

const UserHistoryTable = ({ className }: { className?: string }) => {
	const {
		table,
		isLoading,
		isUninitialized,
		isSuccess,
		isError,
		getCanNextPage,
		getCanPreviousPage,
		goToNextPage,
		goToPreviousPage,
		itemsCount,
		pageCount,
		pageIndex,
		pageSize,
		setPageIndex,
	} = usePaginatedUserHistory();

	return (
		<DashboardPageSection className={cx("", className)}>
			<div className="bg-background-primary">
				<div className="">
					<SimpleTable
						isLoading={isLoading || isUninitialized}
						isError={isError}
						isSuccess={isSuccess}
						itemsCount={itemsCount}
						pageSize={pageSize}
						table={table}
						className="bg-background-primary "
					/>
					<Pagination
						getCanNextPage={getCanNextPage}
						getCanPreviousPage={getCanPreviousPage}
						goToNextPage={goToNextPage}
						goToPreviousPage={goToPreviousPage}
						itemsCount={itemsCount}
						pageCount={pageCount}
						pageIndex={pageIndex + 1}
						pageSize={pageSize}
						setPageIndex={setPageIndex}
						className="mt-4"
					/>
				</div>
			</div>
		</DashboardPageSection>
	);
};

export { UserHistoryTable };
