import { type IModal, Modal } from "@components/organisms";
import React from "react";
import { Button } from "@components/molecules";
import { useModalManager } from "@components/ethereals/ModalsWrapper";

const WelcomeModalProd: IModal<"WelcomeModalProd"> &
	React.FC<{
		heading: string;
		description: string;
	}> = ({ heading, description }) => {
	const { modalManager } = useModalManager();

	return (
		<Modal className="bg-background-secondary relative w-full max-w-[600px] overflow-hidden rounded-xl">
			<Modal.Step>
				<div className="p-6">
					<h2 className="text-content-primary  text-xl font-semibold">
						Welcome to Gamblino!
					</h2>
					<h3 className="text-content-primary  mt-5 text-base font-semibold">
						Great to See You Here! 🌟
					</h3>
					<p className="text-content-primary mt-1 w-full  text-sm">
						You&apos;re now experiencing the best of Gamblino - our
						fully-functional <strong>sportsbook</strong>. Dive into
						an immersive betting experience with a wide range of
						sports and live events! 🏆
					</p>
					<h3 className="text-content-primary mt-5 text-base font-semibold">
						What&apos;s in store?
					</h3>
					<p className="text-content-primary mt-1 w-full text-sm">
						Enjoy a seamless and dynamic Sportsbook experience,
						completely decentralized and permisionless.
					</p>
					<h3 className="text-content-primary mt-5 text-base font-semibold">
						Exciting news!
					</h3>
					<p className="text-content-primary mt-1 w-full text-sm">
						We&apos;re always looking to improve your experience.
						While you&apos;re enjoying our Sportsbook, know that
						we&apos;re working hard on bringing more features to
						you. Check out our other offerings, currently in beta,
						at{" "}
						<a href="https://test.gamblino.app">
							test.gamblino.app
						</a>
						. We can&apos;t wait to show you what&apos;s next! 🚀
					</p>
					{/* <h3 className="text-content-primary  text-base font-semibold">
						Your feedback matters!
					</h3>
					<p className="text-content-primary w-full  text-sm">
						We’re committed to perfection. If you have any
						suggestions or encounter any issues, we’re here to
						listen. Your feedback helps us grow.
					</p>
					<div className="flex w-full gap-4">
						<Button
							type="outline"
							size="sm"
							onClick={() => {
								modalManager.close("WelcomeModalProd");
							}}
							className={"w-full"}
						>
							<Button.Text>Feedback</Button.Text>
						</Button>
					</div> */}
					<p className="text-content-primary mt-5 w-full text-sm">
						Enjoy your time at Gamblino, where every moment is a
						thrill!
					</p>
					<Button
						type="ghost"
						size="sm"
						onClick={() => {
							modalManager.close("WelcomeModalProd");
						}}
						className={"bg-button-secondary-background mt-5 w-full"}
					>
						<Button.Text>Close</Button.Text>
					</Button>
				</div>
			</Modal.Step>
		</Modal>
	);
};

WelcomeModalProd.modalName = "WelcomeModalProd";

export { WelcomeModalProd };
