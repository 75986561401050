import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";

interface Location {
	pathname: string;
	state: {
		noScrollToTop?: boolean;
	};
}
const ScrollToTop = () => {
	const location = useLocation();
	const { state, pathname } = location as Location;

	useEffect(() => {
		if (state?.noScrollToTop) {
			return;
		}
		document.documentElement.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
	}, [pathname, state?.noScrollToTop]);

	return <Outlet />;
};

export { ScrollToTop };
