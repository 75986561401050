import mockedData from "./mockedData";
import { schema } from "./schema";
import { createRequestQueryFunction } from "query-function-creators";

const getGames = createRequestQueryFunction({
	getAxiosRequestConfig: () => ({
		url: `${import.meta.env.BACKEND_SERVICE_GAMBLINO_BASE_URL}/games`,
	}),
	schema,
	getMockedData: () => mockedData.response1,
	isMockingEnabled: true,
});

export { getGames };
