import React from "react";

import { cx } from "src/utils";

import { TooltipNumberWrapper } from "@components/molecules";
import { BetOutcome } from "azuro-service/src/queries/getBets";

const MyBetSubItem: React.FC<{
	betOutcome: BetOutcome;

	className?: string;
}> = ({ className, betOutcome }) => {
	const participants = betOutcome?.game?.participants;
	const participantsText = `${participants?.[0]?.name || ""} - ${
		participants?.[1]?.name || ""
	}`;

	return (
		<div
			className={cx(
				"bg-background-secondary flex min-h-[56px] w-full shrink-0 flex-col gap-2 rounded p-2",
				{
					"shadow-[inset_0px_-8px_16px_-12px_#4ADE80]":
						betOutcome.isWin,
				},
				{
					"shadow-[inset_0px_-8px_16px_-12px_#F43F5E]":
						betOutcome.isLose,
				},
				className,
			)}
		>
			<div className="flex justify-between">
				<div>
					{/* icon */}
					<p className={"text-content-primary text-xs"}>
						{participantsText}
					</p>
				</div>
			</div>
			<div className="flex justify-between">
				<div className="flex gap-2 ">
					<p className="text-xs">Full time results:</p>

					<p className="text-content-primary text-xs">
						{betOutcome.selectionName}
					</p>
				</div>

				<p className="text-content-primary text-xs">
					<TooltipNumberWrapper number={betOutcome.odds} />
				</p>
			</div>
		</div>
	);
};

export { MyBetSubItem };
