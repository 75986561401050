export const abi = [
	{
		inputs: [
			{
				internalType: "address",
				name: "treasuryAddress",
				type: "address",
			},
			{
				internalType: "address",
				name: "teamWalletAddress",
				type: "address",
			},
		],
		stateMutability: "nonpayable",
		type: "constructor",
	},
	{ inputs: [], name: "AccessDenied", type: "error" },
	{ inputs: [], name: "TokenExists", type: "error" },
	{ inputs: [], name: "TokenHasPendingBets", type: "error" },
	{ inputs: [], name: "TokenNotPaused", type: "error" },
	{ inputs: [], name: "WrongAddress", type: "error" },
	{
		inputs: [{ internalType: "uint16", name: "splitSum", type: "uint16" }],
		name: "WrongHouseEdgeSplit",
		type: "error",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: "address",
				name: "token",
				type: "address",
			},
		],
		name: "AddToken",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: "address",
				name: "token",
				type: "address",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "bank",
				type: "uint256",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "dividend",
				type: "uint256",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "partner",
				type: "uint256",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "treasury",
				type: "uint256",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "team",
				type: "uint256",
			},
		],
		name: "AllocateHouseEdgeAmount",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: "address",
				name: "token",
				type: "address",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "newBalance",
				type: "uint256",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "amount",
				type: "uint256",
			},
		],
		name: "CashIn",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: "address",
				name: "token",
				type: "address",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "amount",
				type: "uint256",
			},
		],
		name: "Deposit",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: "address",
				name: "token",
				type: "address",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "amount",
				type: "uint256",
			},
		],
		name: "HarvestDividend",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: "address",
				name: "token",
				type: "address",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "treasuryAmount",
				type: "uint256",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "teamAmount",
				type: "uint256",
			},
		],
		name: "HouseEdgeDistribution",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: "address",
				name: "token",
				type: "address",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "partnerAmount",
				type: "uint256",
			},
		],
		name: "HouseEdgePartnerDistribution",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: "address",
				name: "token",
				type: "address",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "newBalance",
				type: "uint256",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "profit",
				type: "uint256",
			},
		],
		name: "Payout",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: "bytes32",
				name: "role",
				type: "bytes32",
			},
			{
				indexed: true,
				internalType: "bytes32",
				name: "previousAdminRole",
				type: "bytes32",
			},
			{
				indexed: true,
				internalType: "bytes32",
				name: "newAdminRole",
				type: "bytes32",
			},
		],
		name: "RoleAdminChanged",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: "bytes32",
				name: "role",
				type: "bytes32",
			},
			{
				indexed: true,
				internalType: "address",
				name: "account",
				type: "address",
			},
			{
				indexed: true,
				internalType: "address",
				name: "sender",
				type: "address",
			},
		],
		name: "RoleGranted",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: "bytes32",
				name: "role",
				type: "bytes32",
			},
			{
				indexed: true,
				internalType: "address",
				name: "account",
				type: "address",
			},
			{
				indexed: true,
				internalType: "address",
				name: "sender",
				type: "address",
			},
		],
		name: "RoleRevoked",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: "address",
				name: "token",
				type: "address",
			},
			{
				indexed: false,
				internalType: "bool",
				name: "allowed",
				type: "bool",
			},
		],
		name: "SetAllowedToken",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: "address",
				name: "token",
				type: "address",
			},
			{
				indexed: false,
				internalType: "uint16",
				name: "balanceRisk",
				type: "uint16",
			},
		],
		name: "SetBalanceRisk",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: "address",
				name: "token",
				type: "address",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "minHouseEdgeWithdrawAmount",
				type: "uint256",
			},
		],
		name: "SetMinHouseEdgeWithdrawAmount",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: "address",
				name: "token",
				type: "address",
			},
			{
				indexed: false,
				internalType: "bool",
				name: "paused",
				type: "bool",
			},
		],
		name: "SetPausedToken",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: "address",
				name: "teamWallet",
				type: "address",
			},
		],
		name: "SetTeamWallet",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: "address",
				name: "token",
				type: "address",
			},
			{
				indexed: false,
				internalType: "uint16",
				name: "bank",
				type: "uint16",
			},
			{
				indexed: false,
				internalType: "uint16",
				name: "dividend",
				type: "uint16",
			},
			{
				indexed: false,
				internalType: "uint16",
				name: "partner",
				type: "uint16",
			},
			{
				indexed: false,
				internalType: "uint16",
				name: "treasury",
				type: "uint16",
			},
			{
				indexed: false,
				internalType: "uint16",
				name: "team",
				type: "uint16",
			},
		],
		name: "SetTokenHouseEdgeSplit",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: "address",
				name: "token",
				type: "address",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "minBetAmount",
				type: "uint256",
			},
		],
		name: "SetTokenMinBetAmount",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: "address",
				name: "token",
				type: "address",
			},
			{
				indexed: false,
				internalType: "address",
				name: "partner",
				type: "address",
			},
		],
		name: "SetTokenPartner",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: "address",
				name: "token",
				type: "address",
			},
			{
				indexed: false,
				internalType: "uint64",
				name: "subId",
				type: "uint64",
			},
		],
		name: "SetTokenVRFSubId",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: "address",
				name: "token",
				type: "address",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "amount",
				type: "uint256",
			},
		],
		name: "Withdraw",
		type: "event",
	},
	{
		inputs: [],
		name: "DEFAULT_ADMIN_ROLE",
		outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "GAME_ROLE",
		outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "HARVESTER_ROLE",
		outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [{ internalType: "address", name: "token", type: "address" }],
		name: "addToken",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{ internalType: "address", name: "tokenAddress", type: "address" },
			{ internalType: "uint256", name: "amount", type: "uint256" },
			{ internalType: "uint256", name: "fees", type: "uint256" },
		],
		name: "cashIn",
		outputs: [],
		stateMutability: "payable",
		type: "function",
	},
	{
		inputs: [{ internalType: "bytes", name: "checkData", type: "bytes" }],
		name: "checkUpkeep",
		outputs: [
			{ internalType: "bool", name: "upkeepNeeded", type: "bool" },
			{ internalType: "bytes", name: "performData", type: "bytes" },
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{ internalType: "address", name: "token", type: "address" },
			{ internalType: "uint256", name: "amount", type: "uint256" },
		],
		name: "deposit",
		outputs: [],
		stateMutability: "payable",
		type: "function",
	},
	{
		inputs: [{ internalType: "address", name: "token", type: "address" }],
		name: "getBalance",
		outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{ internalType: "address", name: "token", type: "address" },
			{ internalType: "uint256", name: "multiplier", type: "uint256" },
		],
		name: "getMaxBetAmount",
		outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [{ internalType: "address", name: "token", type: "address" }],
		name: "getMinBetAmount",
		outputs: [
			{ internalType: "uint256", name: "minBetAmount", type: "uint256" },
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [{ internalType: "bytes32", name: "role", type: "bytes32" }],
		name: "getRoleAdmin",
		outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{ internalType: "bytes32", name: "role", type: "bytes32" },
			{ internalType: "uint256", name: "index", type: "uint256" },
		],
		name: "getRoleMember",
		outputs: [{ internalType: "address", name: "", type: "address" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [{ internalType: "bytes32", name: "role", type: "bytes32" }],
		name: "getRoleMemberCount",
		outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [{ internalType: "address", name: "token", type: "address" }],
		name: "getTokenOwner",
		outputs: [{ internalType: "address", name: "", type: "address" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "getTokens",
		outputs: [
			{
				components: [
					{ internalType: "uint8", name: "decimals", type: "uint8" },
					{
						internalType: "address",
						name: "tokenAddress",
						type: "address",
					},
					{ internalType: "string", name: "name", type: "string" },
					{ internalType: "string", name: "symbol", type: "string" },
					{
						components: [
							{
								internalType: "bool",
								name: "allowed",
								type: "bool",
							},
							{
								internalType: "bool",
								name: "paused",
								type: "bool",
							},
							{
								internalType: "uint16",
								name: "balanceRisk",
								type: "uint16",
							},
							{
								internalType: "uint64",
								name: "VRFSubId",
								type: "uint64",
							},
							{
								internalType: "address",
								name: "partner",
								type: "address",
							},
							{
								internalType: "uint256",
								name: "minBetAmount",
								type: "uint256",
							},
							{
								internalType: "uint256",
								name: "minHouseEdgeWithdrawAmount",
								type: "uint256",
							},
							{
								components: [
									{
										internalType: "uint16",
										name: "bank",
										type: "uint16",
									},
									{
										internalType: "uint16",
										name: "dividend",
										type: "uint16",
									},
									{
										internalType: "uint16",
										name: "partner",
										type: "uint16",
									},
									{
										internalType: "uint16",
										name: "treasury",
										type: "uint16",
									},
									{
										internalType: "uint16",
										name: "team",
										type: "uint16",
									},
									{
										internalType: "uint256",
										name: "dividendAmount",
										type: "uint256",
									},
									{
										internalType: "uint256",
										name: "partnerAmount",
										type: "uint256",
									},
									{
										internalType: "uint256",
										name: "treasuryAmount",
										type: "uint256",
									},
									{
										internalType: "uint256",
										name: "teamAmount",
										type: "uint256",
									},
								],
								internalType: "struct Bank.HouseEdgeSplit",
								name: "houseEdgeSplit",
								type: "tuple",
							},
						],
						internalType: "struct Bank.Token",
						name: "token",
						type: "tuple",
					},
				],
				internalType: "struct Bank.TokenMetadata[]",
				name: "",
				type: "tuple[]",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [{ internalType: "address", name: "token", type: "address" }],
		name: "getVRFSubId",
		outputs: [{ internalType: "uint64", name: "", type: "uint64" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{ internalType: "bytes32", name: "role", type: "bytes32" },
			{ internalType: "address", name: "account", type: "address" },
		],
		name: "grantRole",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [],
		name: "harvestDividends",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{ internalType: "bytes32", name: "role", type: "bytes32" },
			{ internalType: "address", name: "account", type: "address" },
		],
		name: "hasRole",
		outputs: [{ internalType: "bool", name: "", type: "bool" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{ internalType: "address", name: "tokenAddress", type: "address" },
		],
		name: "isAllowedToken",
		outputs: [{ internalType: "bool", name: "", type: "bool" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [{ internalType: "bytes[]", name: "data", type: "bytes[]" }],
		name: "multicall",
		outputs: [
			{ internalType: "bytes[]", name: "results", type: "bytes[]" },
		],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{ internalType: "address", name: "user", type: "address" },
			{ internalType: "address", name: "token", type: "address" },
			{ internalType: "uint256", name: "profit", type: "uint256" },
			{ internalType: "uint256", name: "fees", type: "uint256" },
		],
		name: "payout",
		outputs: [],
		stateMutability: "payable",
		type: "function",
	},
	{
		inputs: [{ internalType: "bytes", name: "performData", type: "bytes" }],
		name: "performUpkeep",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{ internalType: "bytes32", name: "role", type: "bytes32" },
			{ internalType: "address", name: "account", type: "address" },
		],
		name: "renounceRole",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{ internalType: "bytes32", name: "role", type: "bytes32" },
			{ internalType: "address", name: "account", type: "address" },
		],
		name: "revokeRole",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{ internalType: "address", name: "token", type: "address" },
			{ internalType: "bool", name: "allowed", type: "bool" },
		],
		name: "setAllowedToken",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{ internalType: "address", name: "token", type: "address" },
			{ internalType: "uint16", name: "balanceRisk", type: "uint16" },
		],
		name: "setBalanceRisk",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{ internalType: "address", name: "token", type: "address" },
			{ internalType: "uint16", name: "bank", type: "uint16" },
			{ internalType: "uint16", name: "dividend", type: "uint16" },
			{ internalType: "uint16", name: "partner", type: "uint16" },
			{ internalType: "uint16", name: "_treasury", type: "uint16" },
			{ internalType: "uint16", name: "team", type: "uint16" },
		],
		name: "setHouseEdgeSplit",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{ internalType: "address", name: "token", type: "address" },
			{
				internalType: "uint256",
				name: "minHouseEdgeWithdrawAmount",
				type: "uint256",
			},
		],
		name: "setMinHouseEdgeWithdrawAmount",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{ internalType: "address", name: "token", type: "address" },
			{ internalType: "bool", name: "paused", type: "bool" },
		],
		name: "setPausedToken",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{ internalType: "address", name: "_teamWallet", type: "address" },
		],
		name: "setTeamWallet",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{ internalType: "address", name: "token", type: "address" },
			{
				internalType: "uint256",
				name: "tokenMinBetAmount",
				type: "uint256",
			},
		],
		name: "setTokenMinBetAmount",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{ internalType: "address", name: "token", type: "address" },
			{ internalType: "address", name: "partner", type: "address" },
		],
		name: "setTokenPartner",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{ internalType: "address", name: "token", type: "address" },
			{ internalType: "uint64", name: "subId", type: "uint64" },
		],
		name: "setTokenVRFSubId",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{ internalType: "bytes4", name: "interfaceId", type: "bytes4" },
		],
		name: "supportsInterface",
		outputs: [{ internalType: "bool", name: "", type: "bool" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "teamWallet",
		outputs: [{ internalType: "address", name: "", type: "address" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [{ internalType: "address", name: "", type: "address" }],
		name: "tokens",
		outputs: [
			{ internalType: "bool", name: "allowed", type: "bool" },
			{ internalType: "bool", name: "paused", type: "bool" },
			{ internalType: "uint16", name: "balanceRisk", type: "uint16" },
			{ internalType: "uint64", name: "VRFSubId", type: "uint64" },
			{ internalType: "address", name: "partner", type: "address" },
			{ internalType: "uint256", name: "minBetAmount", type: "uint256" },
			{
				internalType: "uint256",
				name: "minHouseEdgeWithdrawAmount",
				type: "uint256",
			},
			{
				components: [
					{ internalType: "uint16", name: "bank", type: "uint16" },
					{
						internalType: "uint16",
						name: "dividend",
						type: "uint16",
					},
					{ internalType: "uint16", name: "partner", type: "uint16" },
					{
						internalType: "uint16",
						name: "treasury",
						type: "uint16",
					},
					{ internalType: "uint16", name: "team", type: "uint16" },
					{
						internalType: "uint256",
						name: "dividendAmount",
						type: "uint256",
					},
					{
						internalType: "uint256",
						name: "partnerAmount",
						type: "uint256",
					},
					{
						internalType: "uint256",
						name: "treasuryAmount",
						type: "uint256",
					},
					{
						internalType: "uint256",
						name: "teamAmount",
						type: "uint256",
					},
				],
				internalType: "struct Bank.HouseEdgeSplit",
				name: "houseEdgeSplit",
				type: "tuple",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "treasury",
		outputs: [{ internalType: "address", name: "", type: "address" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{ internalType: "address", name: "token", type: "address" },
			{ internalType: "uint256", name: "amount", type: "uint256" },
		],
		name: "withdraw",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{ internalType: "address", name: "tokenAddress", type: "address" },
		],
		name: "withdrawHouseEdgeAmount",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{ internalType: "address", name: "tokenAddress", type: "address" },
		],
		name: "withdrawPartnerAmount",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
] as const;
