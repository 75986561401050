import { client } from "../apollo";
import { graphql } from "../gql/gql";
import {
	Exact,
	GetHotSportHubsQuery,
	GetHotSportHubsQueryVariables,
	GetSportHubsQuery,
	GetSportHubsQueryVariables,
	InputMaybe,
} from "../gql/graphql";

import { QueryOptions } from "@apollo/client";

const sportHubsQuery = graphql(`
	query getSportHubs(
		$sportsSlug: String!
		$countrySlug: String = ""
		$leagueSlug: String = ""
	) {
		sportHubs(where: { id: "sports" }) {
			id
			name
			sports(where: { slug: $sportsSlug }) {
				id
				name
				slug
				countries(
					where: {
						hasActiveLeagues: true
						slug_contains_nocase: $countrySlug
					}
					orderBy: turnover
					orderDirection: desc
				) {
					id
					name
					slug
					turnover
					leagues(
						where: {
							hasActiveGames: true
							slug_contains_nocase: $leagueSlug
						}
						orderBy: turnover
						orderDirection: desc
					) {
						id
						name
						turnover
						slug
						country {
							name
							__typename
						}
						games(
							first: 1000
							where: { status_in: [Created, Paused] }
							orderBy: startsAt
							orderDirection: asc
						) {
							id
							turnover
							status
							startsAt
							title
							slug
							participants {
								name
								image
								__typename
							}
							conditions {
								conditionId
								turnover
								isExpressForbidden
								outcomes {
									id
									outcomeId
									currentOdds
									__typename
								}
								core {
									address
									__typename
								}
								__typename
							}
							liquidityPool {
								address
								__typename
							}
							sport {
								id
								name
								slug
								sporthub {
									id
									__typename
								}
								__typename
							}
							league {
								name
								country {
									name
									__typename
								}
								__typename
							}
							__typename
						}
						__typename
					}
					__typename
				}
				__typename
			}
			__typename
		}
	}
`);

const hotSportsQuery = graphql(`
	query getHotSportHubs($gamesLimit: Int = 10, $sportsSlugs: [String!]) {
		sportHubs {
			id
			name
			sports(where: { slug_in: $sportsSlugs }) {
				id
				name
				slug
				countries(
					where: { hasActiveLeagues: true }
					orderBy: turnover
					orderDirection: desc
					first: 1
				) {
					id
					name
					slug
					turnover
					leagues(
						where: {
							hasActiveGames: true
							games_: { status_in: [Created, Paused] }
						}
						orderBy: turnover
						orderDirection: desc
						first: 1
					) {
						id
						name
						turnover
						slug
						country {
							name
							__typename
						}
						games(
							first: $gamesLimit
							where: { status_in: [Created, Paused] }
							orderBy: turnover
							orderDirection: desc
						) {
							id
							turnover
							status
							startsAt
							title
							slug
							participants {
								name
								image
								__typename
							}
							conditions {
								conditionId
								turnover
								isExpressForbidden
								outcomes {
									id
									outcomeId
									currentOdds
									__typename
								}
								core {
									address
									__typename
								}
								__typename
							}
							liquidityPool {
								address
								__typename
							}
							sport {
								id
								name
								slug
								sporthub {
									id
									__typename
								}
								__typename
							}
							league {
								name
								country {
									name
									__typename
								}
								__typename
							}
							__typename
						}
						__typename
					}
					__typename
				}
				__typename
			}
			__typename
		}
	}
`);

const getGamesList = async <T extends boolean>({
	hotSports,
	variables,
}: {
	hotSports: T;
	variables: T extends true
		? GetHotSportHubsQueryVariables
		: GetSportHubsQueryVariables;
}) => {
	if (!client) {
		throw new Error("Apollo client not initialized");
	}

	if (hotSports) {
		const {
			data: { sportHubs },
		} = await client.query<GetHotSportHubsQuery>({
			query: hotSportsQuery,
			variables: variables as GetHotSportHubsQueryVariables,
		});

		return sportHubs;
	}

	const {
		data: { sportHubs },
	}: { data: GetSportHubsQuery } = await client.query<GetSportHubsQuery>({
		query: sportHubsQuery,
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		variables: variables as GetSportHubsQueryVariables,
	});

	return sportHubs;
};

export { getGamesList };
