import React from "react";

import { SimpleDashboardGameLayout } from "@components/layouts";
import { GameSection } from "./GameSection";
import { UserHistoryTable } from "./UserHistoryTable";

import { CONTRACT_ADDRESS_COIN_FLIP } from "src/config";

const FlipMasterPage: React.FC<{ className?: string }> = () => {
	const contractAddress = CONTRACT_ADDRESS_COIN_FLIP;
	// React.useEffect(() => {
	// 	if (!isHowToModalShown) {
	// 		setIsHowToModalShown(true);
	// 		setTimeout(() => {
	// 			modalManager.open("HowToPlayDiceModal", {});
	// 		}, 5000);
	// 	}
	// }, [isHowToModalShown]);

	//
	return (
		<SimpleDashboardGameLayout
			bgImageUrl="/games-bg.png"
			gameComponent={<GameSection contractAddress={contractAddress} />}
		>
			<UserHistoryTable />
		</SimpleDashboardGameLayout>
	);
};

export { FlipMasterPage };
