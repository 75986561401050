import { motion } from "framer-motion";
import React from "react";

const EmptyTab: React.FC<{
	title: string;
	imgSrc: string;
	subtitle: string;
}> = ({ title, subtitle, imgSrc }) => {
	return (
		<motion.div
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			className="flex flex-col items-center gap-2 pb-2 text-center"
		>
			<img src={imgSrc} alt="bet img" />

			<p className="text-content-primary text-lg">{title}</p>

			<p className="text-content-secondary text-xs">{subtitle}</p>
		</motion.div>
	);
};

export { EmptyTab };
