import React from "react";
import { useAccount } from "wagmi";

import {
	BetStatus,
	Bet_OrderBy,
	OrderDirection,
} from "azuro-service/src/gql/graphql";
import { azuro } from "business-logic-gamblino";

import { cx } from "src/utils";
import { EmptyTab } from "../../EmptyTab";
import { MyBetsList } from "../MyBetsList";
import { EmptyState } from "@components/organisms";

const emptyState = {
	title: "No bets",
	imgSrc: "/sportsbook/bet.png",
	subtitle: "All your active bets will be displayed here",
};

const ActiveBets: React.FC<{ className?: string }> = ({ className }) => {
	const account = useAccount();
	const {
		isLoading,
		isFetching,
		error,
		data: bets,
	} = azuro.useBetsQuery({
		first: 10,
		where: {
			actor: account.address?.toLowerCase() || "0x",
			status: BetStatus.Accepted,
		},
		orderBy: Bet_OrderBy.CreatedBlockNumber,
		orderDirection: OrderDirection.Desc,
	});

	const loading = isLoading || isFetching;

	if (error) {
		return (
			<div className="@lg/dashboard:h-[460px] @lg:dashboard:pt-20  flex h-[350px] justify-center pt-10">
				<EmptyState
					imageType="ERROR"
					heading="Opss error!"
					description="Something went wrong"
				/>
			</div>
		);
	}

	if (bets?.length === 0 && !loading) {
		return (
			<div className="@lg/dashboard:h-[460px] flex  h-[350px] w-full items-center justify-center">
				<EmptyTab
					imgSrc={emptyState.imgSrc}
					title={emptyState.title}
					subtitle={emptyState.subtitle}
				/>
			</div>
		);
	}
	return (
		<MyBetsList
			className={cx(className)}
			bets={bets || []}
			isLoading={loading}
		/>
	);
};

export { ActiveBets };
