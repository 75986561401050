import {
	leaderboard,
	type leaderboardBussinessLogicApi,
} from "business-logic-gamblino";
import {
	ColumnFiltersState,
	FilterFn,
	getCoreRowModel,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable,
} from "@tanstack/react-table";

import { PAGE_SIZE, columns } from "../config";
import React from "react";
import { useAccount } from "wagmi";

const filterUser: FilterFn<any> = (row, columnId, value, addMeta) => {
	return row.original.address.toLowerCase().includes(value[0].toLowerCase());
};

const fallbackArray: (typeof leaderboardBussinessLogicApi)["endpoints"]["leaderboardPoints"]["Types"]["ResultType"]["leaderboard"] =
	[];

const usePaginatedLeaderboard = (filter: string) => {
	const [columnFilters, setColumnFilters] =
		React.useState<ColumnFiltersState>([]);
	const { address } = useAccount();

	const queryResult = leaderboard.useLeaderboardPointsQuery({
		userAddress: address,
	});
	const table = useReactTable({
		data: queryResult.data?.leaderboard ?? fallbackArray,
		columns,
		globalFilterFn: filterUser,
		state: {
			columnFilters,
		},
		getCoreRowModel: getCoreRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		onColumnFiltersChange: setColumnFilters,
		debugTable: false,
	});

	React.useEffect(() => {
		if (filter === "") return;
		table.setGlobalFilter([filter]);

		return () => table.resetGlobalFilter();
	}, [JSON.stringify(filter)]);

	React.useEffect(() => {
		table.setPageSize(PAGE_SIZE);
		table.setSorting([{ id: "points", desc: true }]);
	}, []);

	React.useEffect(() => {
		table.resetPageIndex();
	}, [
		JSON.stringify(table.getState().sorting),
		JSON.stringify(table.getState().columnFilters),
		JSON.stringify(table.getState().globalFilter),
	]);

	return {
		...queryResult,
		data: table.getRowModel().rows.map((row) => row.original),
		originalData: queryResult.data,
		itemsCount: table.getPrePaginationRowModel().rows.length,
		pageSize: PAGE_SIZE,
		pageIndex: table.getState().pagination.pageIndex,
		pageCount: table.getPageCount(),
		goToNextPage: table.nextPage,
		goToPreviousPage: table.previousPage,
		getCanNextPage: table.getCanNextPage,
		getCanPreviousPage: table.getCanPreviousPage,
		setPageIndex: table.setPageIndex,
		table,
	};
};

export { usePaginatedLeaderboard };
