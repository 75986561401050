/* eslint-disable max-lines-per-function */
import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react";
import { getEndpointCreators } from "get-endpoint-creators";
import {
	wager,
	getLastUserBets,
	getMaxBetAmount,
	calculateMaxBetAmount,
} from "../service/coinFlip";

import { getMinBetAmount } from "contract-service-gamblino-bank";

const coinFlipBusinessLogicApi = createApi({
	reducerPath: "coinFlipBusinessLogicApi",
	baseQuery: fakeBaseQuery(),
	tagTypes: ["max-bet-amount"],
	endpoints: (builder) => {
		const { createQuery, createMutation } = getEndpointCreators(builder);

		return {
			wager: createMutation(wager),
			getLastUserBets: createQuery(getLastUserBets),
			calculateMaxBetAmount: createQuery(calculateMaxBetAmount),
			maxBetAmount: createQuery(getMaxBetAmount, {
				providesTags: ["max-bet-amount"],
			}),
			minBetAmount: createQuery(getMinBetAmount),
		};
	},
});

const {
	useWagerMutation,
	useGetLastUserBetsQuery,
	useMaxBetAmountQuery,
	useMinBetAmountQuery,
	useCalculateMaxBetAmountQuery,
} = coinFlipBusinessLogicApi;

const coinFlip = {
	useWagerMutation,
	useCalculateMaxBetAmountQuery,
	useUserHistory: useGetLastUserBetsQuery,
	useMaxBetAmount: useMaxBetAmountQuery,
	useMinBetAmount: useMinBetAmountQuery,
};

export { coinFlip, coinFlipBusinessLogicApi };
