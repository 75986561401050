import React from "react";
import { WagmiProvider } from "wagmi";
import { Provider } from "react-redux";
import { Toaster } from "sonner";
import type { PropsWithChildren } from "react";
import { wagmiConfig } from "wallet-connection";
import { AzuroSDKProvider } from "@azuro-org/sdk";
import { RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { cx } from "src/utils";
import { store } from "../../redux/store";
import { ModalsWrapper } from "./ModalsWrapper";
import { AudioManagerWrapper } from "./AudioManagerWrapper";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "@rainbow-me/rainbowkit/styles.css";

const queryClient = new QueryClient();

const AppWrappers: React.FC<PropsWithChildren> = ({ children }) => {
	return (
		<Provider store={store}>
			<WagmiProvider config={wagmiConfig}>
				<QueryClientProvider client={queryClient}>
					<RainbowKitProvider>
						<ModalsWrapper>
							<AudioManagerWrapper>
								<div
									className={cx({
										"debug-screens": import.meta.env.DEV,
									})}
								>
									{import.meta.env
										.WALLET_CONNECTION_VARIANT ===
									"POLYGON" ? (
										<AzuroSDKProvider initialChainId={137}>
											{children}
										</AzuroSDKProvider>
									) : (
										<>{children}</>
									)}
								</div>
							</AudioManagerWrapper>
							{/*  */}
						</ModalsWrapper>
						<Toaster
							richColors
							toastOptions={{
								unstyled: true,
								classNames: {
									toast: "!bg-background-tertiary w-full border !border-border-primary flex gap-2 items-center max-w-[320px] px-4 py-2 rounded-lg",
									title: "text-content-primary",
									description: "text-content-secondary",
								},
							}}
						/>
					</RainbowKitProvider>
				</QueryClientProvider>
			</WagmiProvider>
		</Provider>
	);
};

export { AppWrappers };
