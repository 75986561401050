import { DashboardPageSection } from "@components/atoms";
import { Button } from "@components/molecules";
import React from "react";
import { cx } from "src/utils";

const FourthSection: React.FC<{ className?: string }> = ({ className }) => {
	return (
		<DashboardPageSection
			className={cx(
				"flex items-center justify-center pt-10 lg:pb-12 lg:pt-32",
				className,
			)}
		>
			<div className="bg-background-secondary flex max-w-3xl flex-col items-center rounded-3xl px-16 py-10 shadow-[0px_2px_8px_0px_rgba(62,_47,_122,_0.25)]">
				<h3 className="text-gradient-1 text-center text-4xl font-bold">
					Elevate your gaming experience and thrill to the next level
				</h3>
				<div className="relative mt-6 w-max lg:mt-8">
					<p className="bg-purple-light text-content-primary absolute left-1/2 top-0 z-[2] -translate-x-1/2 -translate-y-1/2 whitespace-nowrap rounded-3xl px-2 py-1 text-xs">
						COMING SOON
					</p>

					<Button
						type="gradient"
						onClick={() => {
							console.log("generate code");
						}}
						disabled
					>
						<Button.Text>Generate Code</Button.Text>
					</Button>
				</div>
			</div>
		</DashboardPageSection>
	);
};

export { FourthSection };
