import { LeagueQueryVariables } from "../gql/graphql";
import { client } from "../apollo";
import { graphql } from "../gql";

const query = graphql(`
	query League($id: ID!) {
		league(id: $id) {
			id
			name
			slug
			country {
				name
			}
			games {
				slug
			}
			hasActiveGames
			activeGamesEntityIds
			turnover
		}
	}
`);

const getLeague = async (variables: LeagueQueryVariables) => {
	if (!client) {
		throw new Error("Apollo client not initialized");
	}
	const {
		data: { league },
	} = await client.query({
		query,
		variables,
	});

	return league;
};

export { getLeague };
