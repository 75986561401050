import {
	getFee,
	getLastUserBets,
	wager as minesWager,
} from "contract-service-mines";
import { Address } from "viem";
import wait from "wait";
const timeout = 15000;

const wager = async ({
	tiles,
	amountWei,
	numMines,
	contractAddress,
	userAddress,
}: {
	tiles: boolean[];
	amountWei: string;
	numMines: number;
	contractAddress: Address;
	userAddress: Address;
}) => {
	const { fee } = await getFee({ contractAddress });

	const { betId, txHash } = await minesWager({
		tiles,
		amountWei,
		numMines,
		feeWei: fee.toString(),
		contractAddress,
	});

	async function waitForMatch() {
		const response = await getLastUserBets({
			userAddress,
			dataLength: 5,
			contractAddress,
		});

		const foundMatch = response.result.find((game) => {
			return game.bet.id.toString() === betId;
		});

		if (foundMatch && foundMatch.bet.resolved) {
			return {
				isWin:
					foundMatch.bet.payout.toString() !== "0" &&
					foundMatch.minesBet.minesTiles.filter((mine) => mine)
						.length === 0,
				payout: foundMatch.bet.payout.toString(),
				minesTiles: foundMatch.minesBet.minesTiles,
			};
		}

		if (Date.now() - startTime > timeout) {
			throw new Error("Timeout exceeded.");
		}

		await wait(1000);

		return waitForMatch();
	}
	const startTime = Date.now();
	return waitForMatch();
};

export { wager };
