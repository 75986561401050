import { PageSection } from "@components/atoms";
import { Button } from "@components/molecules";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { cx } from "src/utils";

const Menu: React.FC<{ className?: string }> = ({ className }) => {
	const navigate = useNavigate();
	return (
		<PageSection
			fullWidth
			className={cx(
				"bg-background-primary fixed top-0 w-full py-3",
				className,
			)}
		>
			<PageSection
				className={cx("flex h-16 items-center justify-between gap-x-6")}
			>
				<Link to="/" className="">
					<img
						src="/gamblino-logo-without-text.svg"
						alt="Gamblino logo"
						className="block h-10 w-10 sm:hidden"
					/>
					<img
						src="/gamblino-logo-with-text.svg"
						alt="Gamblino logo"
						className="hidden  h-10 sm:block"
					/>
				</Link>
				<div className="flex items-center gap-4">
					{/* <AppTabToggle /> */}

					<Button
						type="outline"
						size="sm"
						onClick={() => {
							window.open(`https://docs.gamblino.app/`);
						}}
					>
						<Button.Text>Docs</Button.Text>
					</Button>
					<Button
						type="gradient"
						size="sm"
						onClick={() => {
							navigate(
								import.meta.env.GAMBLINO_IS_PROD === "true"
									? "/sportsbook/hot"
									: "/games",
							);
						}}
					>
						<Button.Text>Launch App</Button.Text>
					</Button>
				</div>
			</PageSection>
		</PageSection>
	);
};

export { Menu };
