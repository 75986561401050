import axios from "axios";
import humps from "humps";
import wait from "wait";

import type { AxiosRequestConfig } from "axios";
import type { ZodType, z } from "zod";

type CreateRequestQueryFunction = <
	SchemaType extends ZodType<any>,
	ArgType,
	TransformReturnType = z.infer<SchemaType>,
>(props: {
	getAxiosRequestConfig: (props: ArgType) => AxiosRequestConfig;
	schema: SchemaType;
	isMockingEnabled?: boolean;
	getMockedData?: (props: ArgType) => any;
	transformData?: (
		data: z.infer<SchemaType>,
		props: ArgType,
	) => TransformReturnType;
}) => (props: ArgType) => Promise<TransformReturnType>;

const defaultValue: Record<any, any> = {};

export const createRequestQueryFunction: CreateRequestQueryFunction =
	({
		getAxiosRequestConfig,
		schema,
		isMockingEnabled = false,
		getMockedData = () => ({}),
		transformData,
	}) =>
	async (arg = defaultValue) => {
		const axiosRequestConfig = getAxiosRequestConfig(arg);
		if (window.localStorage) {
			const userToken = window.localStorage.getItem("userToken");

			if (userToken) {
				if (axiosRequestConfig.headers) {
					axiosRequestConfig.headers["Authorization"] =
						`Bearer ${userToken}`;
				} else {
					axiosRequestConfig.headers = {
						Authorization: `Bearer ${userToken}`,
					};
				}
			}
		}
		const data = await (async () => {
			if (isMockingEnabled) {
				await wait(1000);
				return getMockedData(arg);
			}

			const response = await axios.request(axiosRequestConfig);
			return humps.camelizeKeys(response.data);
		})();

		const validatedData = schema.safeParse(data);

		if (!validatedData.success) {
			console.error({
				type: "zod error",
				arg,
				axiosRequestConfig,
				data,
				issues: validatedData.error.issues,
			});

			throw new Error(validatedData.error.message);
		}

		if (transformData) {
			return transformData(validatedData.data, arg);
		}

		return validatedData.data;
	};
