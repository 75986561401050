import React from "react";
import { cx } from "src/utils";
import { WheelSvg } from "./WheelSvg";
import { motion, useAnimate, useMotionValue } from "framer-motion";
import { roulette } from "business-logic-gamblino";
import wait from "wait";
import { BallSvg } from "./BallSvg";
import { animationDurationsInSeconds } from "../config";
import { useRouletteHoveredNumbers } from "src/redux/useRouletteHoveredNumbers";
import { useRouletteSelectedNumbers } from "src/redux/useRouletteSelectedNumbers";

const numberToIndexOnTheWheel = [
	0, // 0
	23, // 1
	6, // 2
	35, // 3
	4, // 4
	19, // 5
	10, // 6
	31, // 7
	16, // 8
	27, // 9
	18, // 10
	14, // 11
	33, // 12
	12, // 13
	25, // 14
	2, // 15
	21, // 16
	8, // 17
	29, // 18
	3, // 19
	24, // 20
	5, // 21
	28, // 22
	17, // 23
	20, // 24
	7, // 25
	36, // 26
	11, // 27
	32, // 28
	30, // 29
	15, // 30
	26, // 31
	1, // 32
	22, // 33
	9, // 34
	34, // 35
	13, // 36
];

const BALL_INITIAL_ROTATION = (18 * 360) / 37;

const Wheel: React.FC<{ className?: string }> = ({ className }) => {
	const [, animate] = useAnimate();
	const rotateGroup = useMotionValue(0);
	const rotateBg = useMotionValue(0);
	const rotateBall = useMotionValue(BALL_INITIAL_ROTATION);
	const opacityBall = useMotionValue(0);
	const [lockForAnimation, setLockForAnimation] =
		React.useState<boolean>(false);

	const [, { isSuccess, data }] = roulette.useWagerMutation({
		fixedCacheKey: "rouletteBusinessLogicApi.useWagerMutation",
	});

	const { selectedNumbers, toggleSingleSelection } =
		useRouletteSelectedNumbers();
	const { hoveredNumbers } = useRouletteHoveredNumbers();

	React.useEffect(() => {
		void (async () => {
			if (isSuccess) {
				setLockForAnimation(true);

				const angle =
					(numberToIndexOnTheWheel[data?.rolled] * 360) / 37;

				await Promise.all([
					animate(rotateBg, rotateBg.get() + 360 * 1 - angle, {
						ease: "linear",
						duration: animationDurationsInSeconds.ROTATION_PART_1,
					}),
					animate(rotateBall, rotateBall.get() - 5 * 360, {
						ease: [0, 0.49, 0.51, 0.89],
						duration:
							(7 * animationDurationsInSeconds.ROTATION_PART_1) /
							12,
						delay:
							(5 * animationDurationsInSeconds.ROTATION_PART_1) /
							12,
					}),
					animate(opacityBall, 1, { delay: 3 }),
					animate(rotateGroup, rotateGroup.get() + 500, {
						ease: "easeInOut",
						duration:
							animationDurationsInSeconds.ROTATION_PART_1 +
							animationDurationsInSeconds.ROTATION_PART_2,
					}),
				]);

				await wait(
					animationDurationsInSeconds.WAIT_BEFORE_RESET * 1000,
				);

				await animate(opacityBall, 0);

				await animate(rotateBg, rotateBg.get() + angle, {
					duration: 1.5,
				});
				setLockForAnimation(false);
			}
		})();
	}, [isSuccess]);

	return (
		<div className={cx("relative", className)}>
			<motion.div
				style={{ rotate: rotateGroup }}
				className={cx("relative")}
			>
				<motion.div style={{ rotate: rotateBg }}>
					<WheelSvg
						hoveredNumbers={hoveredNumbers}
						selectedNumbers={selectedNumbers}
						toggleSingleSelection={toggleSingleSelection}
					/>
				</motion.div>
				<motion.div className="pointer-events-none absolute inset-0 h-full w-full">
					<motion.div
						style={{ rotate: rotateBall, opacity: opacityBall }}
					>
						<BallSvg />
					</motion.div>
				</motion.div>
			</motion.div>
		</div>
	);
};

export { Wheel };
