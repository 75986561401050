import {
	Address,
	bytesToHex,
	decodeEventLog,
	parseEventLogs,
	zeroAddress,
} from "viem";
import { abi } from "../../config";
import {
	simulateContract,
	waitForTransactionReceipt,
	writeContract,
} from "wagmi/actions";
import { wagmiConfig } from "wallet-connection";

const wager = async ({
	face,
	feeWei,
	amountWei,
	contractAddress,
}: {
	face: boolean;
	amountWei: string;
	feeWei: string;
	contractAddress: Address;
}) => {
	const tokenAmount = BigInt(amountWei);

	const payableAmountWei = BigInt(amountWei) + BigInt(feeWei);
	
	const randomBytes = crypto.getRandomValues(new Uint8Array(32))
	const randomNumber = bytesToHex(randomBytes)
	
	const { request } = await simulateContract(wagmiConfig, {
		address: contractAddress,
		abi: abi,
		functionName: "wager",
		args: [face, randomNumber, zeroAddress, tokenAmount],
		value: payableAmountWei,
	});

	const hash = await writeContract(wagmiConfig, request);

	const receipt = await waitForTransactionReceipt(wagmiConfig, {
		hash,
		confirmations: 3,
	});

	const logs = parseEventLogs({
		abi,
		eventName: "PlaceBet",
		logs: receipt.logs,
	});

	const placeBetEventLogs = logs.map(({ data, topics }) =>
		decodeEventLog({ abi, data, topics, eventName: "PlaceBet" }),
	);

	if (placeBetEventLogs.length === 0) {
		throw new Error("PlaceBet event is not found in tx receipt.");
	}

	if (placeBetEventLogs.length > 1) {
		console.error({
			placeBetEventLogs,
			logs,
		});
		throw new Error("More than 1 PlaceBet event is scanned.");
	}

	const betId = `${placeBetEventLogs[0]!.args.id}`;

	return {
		txHash: hash,
		betId,
	};
};

export { wager };
