import { z } from "zod";

const schema = z.object({
	data: z.object({
		sportHubs: z.array(
			z.object({
				id: z.string(),
				name: z.string(),
				sports: z.array(
					z.object({
						id: z.string(),
						name: z.string(),
						slug: z.string(),
						countries: z.array(
							z.object({
								id: z.string(),
								name: z.string(),
								slug: z.string(),
								turnover: z.string(),
								leagues: z.array(
									z.object({
										id: z.string(),
										name: z.string(),
										turnover: z.string(),
										slug: z.string(),
										country: z.object({
											name: z.string(),
										}),
										games: z.array(
											z.object({
												id: z.string(),
												turnover: z.string(),
												status: z.string(),
												startsAt: z.string(),
												title: z.string(),
												slug: z.string(),
												participants: z.array(
													z.object({
														name: z.string(),
														image: z.string(),
													}),
												),
												conditions: z.array(
													z.object({
														conditionId: z.string(),
														turnover: z.string(),
														isExpressForbidden:
															z.boolean(),
														outcomes: z.array(
															z.object({
																id: z.string(),
																outcomeId:
																	z.string(),
																currentOdds:
																	z.string(),
															}),
														),
														core: z.object({
															address: z.string(),
														}),
													}),
												),
												liquidityPool: z.object({
													address: z.string(),
												}),
												sport: z.object({
													id: z.string(),
													name: z.string(),
													slug: z.string(),
													sporthub: z.object({
														id: z.string(),
													}),
												}),
												league: z.object({
													name: z.string(),
													country: z.object({
														name: z.string(),
													}),
												}),
											}),
										),
									}),
								),
							}),
						),
					}),
				),
			}),
		),
	}),
});

export { schema };
