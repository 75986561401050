import React from "react";
import { cx } from "src/utils";
import { motion, useMotionValue } from "framer-motion";

import { BallSvg } from "./BallSvg";
import { WheelSvg } from "./WheelSvg";

const numberToIndexOnTheWheel = [
	0, // 0
	23, // 1
	6, // 2
	35, // 3
	4, // 4
	19, // 5
	10, // 6
	31, // 7
	16, // 8
	27, // 9
	18, // 10
	14, // 11
	33, // 12
	12, // 13
	25, // 14
	2, // 15
	21, // 16
	8, // 17
	29, // 18
	3, // 19
	24, // 20
	5, // 21
	28, // 22
	17, // 23
	20, // 24
	7, // 25
	36, // 26
	11, // 27
	32, // 28
	30, // 29
	15, // 30
	26, // 31
	1, // 32
	22, // 33
	9, // 34
	34, // 35
	13, // 36
];

const BALL_INITIAL_ROTATION = (18 * 360) / 37;

const StaticWheel: React.FC<{
	className?: string;
	rolled?: number;
	selectedNumbers: string[];
}> = ({ className, selectedNumbers, rolled = 0 }) => {
	const rotateBall = useMotionValue(BALL_INITIAL_ROTATION);

	const angle = (numberToIndexOnTheWheel[rolled] * 360) / 37;

	return (
		<div className={cx("relative", className)}>
			<div className={cx("relative")}>
				<div>
					<WheelSvg
						hoveredNumbers={[]}
						selectedNumbers={selectedNumbers}
					/>
				</div>
				<motion.div className="pointer-events-none absolute inset-0 h-full w-full">
					<motion.div initial={{ rotate: rotateBall.get() + angle }}>
						<BallSvg />
					</motion.div>
				</motion.div>
			</div>
		</div>
	);
};

export { StaticWheel };
