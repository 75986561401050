import React from "react";
import { cx } from "src/utils";

const PickCoinSide: React.FC<{
	inProgress?: boolean;
	face: boolean;
	onFaceChange: (face: boolean) => void;
	className?: string;
}> = ({ face, inProgress, onFaceChange, className }) => {
	return (
		<div
			className={cx(
				"flex flex-col items-center justify-between gap-6",
				className,
			)}
		>
			{inProgress ? (
				<p className="h-4"></p>
			) : (
				<p className="h-4">Pick a side</p>
			)}

			<div className="flex gap-6">
				<p
					className={cx(
						"text-badge-content-success flex h-[52px] w-[52px] cursor-pointer flex-col items-center justify-center rounded-full",
						{
							"border": face,
							"opacity-30": !face,
						},
					)}
					onClick={() => {
						onFaceChange(true);
					}}
				>
					<img src="/flip-master/coin-head.svg" alt="coin head" />
				</p>
				<p
					className={cx(
						"text-badge-content-success flex h-[52px] w-[52px] cursor-pointer flex-col items-center justify-center rounded-full",
						{
							"border": !face,
							"opacity-30": face,
						},
					)}
					onClick={() => {
						onFaceChange(false);
					}}
				>
					<img src="/flip-master/coin-tail.svg" alt="coin tail" />
				</p>
			</div>
		</div>
	);
};

export { PickCoinSide };
