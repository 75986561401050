import { Address, formatUnits } from "viem";
import { wagmiConfig } from "wallet-connection";
import { readContract } from "wagmi/actions";

import { abi } from "../../config";

const getMultipliers = async ({
	playedFields,
	numMines,
	contractAddress,
}: {
	playedFields: number;
	numMines: number;
	contractAddress: Address;
}) => {
	const result = await readContract(wagmiConfig, {
		abi,
		address: contractAddress,
		functionName: "getMultipliers",
		args: [BigInt(playedFields), BigInt(numMines)],
	});

	const multiplier = formatUnits(result, 4);
	const multiplierWei = result.toString();

	return {
		multiplierWei,
		multiplier,
	};
};

export { getMultipliers };
