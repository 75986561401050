import { parseUnits } from "viem";
import { motion } from "framer-motion";
import React, { SetStateAction } from "react";

import { coinFlip, useTokensPriceQuery } from "business-logic-gamblino";

import { cx } from "src/utils";
import { AssetIconTypeFlipMaster } from "../..";
import {
	CONTRACT_ADDRESS_COIN_FLIP,
	CONTRACT_ADDRESS_GAMBLINO_BANK,
} from "src/config";
import { Input, TooltipNumberWrapper } from "@components/molecules";
import { Tooltip } from "@components/atoms";
import { z } from "zod";

const CoinInputBet: React.FC<{
	inProgress?: boolean;
	face: boolean;
	amount: {
		valueWei: string;
		value: string;
	};
	onAmountChange: React.Dispatch<
		SetStateAction<{
			valueWei: string;
			value: string;
		}>
	>;
	className?: string;
}> = ({ inProgress, amount, face, onAmountChange, className }) => {
	const { data } = useTokensPriceQuery(undefined, {
		pollingInterval: 30000,
	});

	const [, { isLoading: isGamePlaying }] = coinFlip.useWagerMutation({
		fixedCacheKey: "coinFlipBet",
	});

	const targetTokenPayout = parseFloat(amount.value) * 1.95 || 0;
	const targetPayout = (data?.prices.sei || 1) * targetTokenPayout;

	const { data: maxBetData, isLoading } =
		coinFlip.useCalculateMaxBetAmountQuery(
			{
				face,
				contractAddress: CONTRACT_ADDRESS_COIN_FLIP,
				bankContractAddress: CONTRACT_ADDRESS_GAMBLINO_BANK,
			},
			{ pollingInterval: 5000 },
		);

	const schema = z
		.bigint()
		.max(BigInt(maxBetData?.maxBetAmountWei || 0n))
		.nonnegative()
		.refine((value) => {
			return value >= parseUnits("2", 18);
		});

	return (
		<motion.div
			className={cx("flex w-full flex-col items-center", className)}
			initial={{
				opacity: 1,
			}}
			animate={{
				opacity: inProgress ? 0.3 : 1,
			}}
			transition={{
				ease: "easeInOut",
			}}
		>
			<div className="text-content-secondary flex w-full items-center justify-between px-1  text-sm">
				<p>Enter Amount</p>

				<span className="text-content-primary">
					<TooltipNumberWrapper
						number={targetPayout}
						formatPriceConfig={{
							decimals: 2,
							labelPosition: "left",
							currencyLabel: "$",
						}}
					/>
				</span>
			</div>
			<div className="w-full">
				<Input
					type="text"
					name="betValue"
					value={amount.value}
					placeholder={"0"}
					onChange={(e) => {
						onAmountChange({
							value: e.currentTarget.value,
							valueWei: parseUnits(
								e.currentTarget.value,
								18,
							).toString(),
						});
					}}
					className={cx("h-[44px] w-full", {
						"border-background-error": !schema.safeParse(
							BigInt(amount.valueWei),
						).success,
					})}
				>
					<Input.AssetIcon type={AssetIconTypeFlipMaster} />
					<Input.Button
						type="tertiary"
						disabled={!maxBetData?.maxBetAmount || isGamePlaying}
						className=" h-9 self-center"
						onClick={() => {
							onAmountChange({
								value: maxBetData?.maxBetAmount || "0",
								valueWei: maxBetData?.maxBetAmountWei || "0",
							});
						}}
					>
						<Tooltip
							content={`Max bet amount is: ${maxBetData?.maxBetAmount || 0}`}
						>
							<Input.Button.Text>Max</Input.Button.Text>
						</Tooltip>
					</Input.Button>
				</Input>
			</div>
		</motion.div>
	);
};

export { CoinInputBet };
