import { getMultipliers as minesMultipliers } from "contract-service-mines";
import { Address } from "viem";

const getMultipliers = async ({
	playedFields,
	numMines,
	contractAddress,
}: {
	contractAddress: Address;
	playedFields: number;
	numMines: number;
	}) => {
	
	const response = await minesMultipliers({
		playedFields,
		numMines,
		contractAddress,
	});

	return {
		response,
	};
};

export { getMultipliers };
