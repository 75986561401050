import { z } from "zod";

const schema = z.object({
	status: z.string(),
	games: z.array(
		z.object({
			betId: z.string(),
			amount: z.number(),
			betTimestamp: z.number(),
			betTxHash: z.string(),
			betFace: z.boolean(),
			rolledFace: z.boolean(),
			isWin: z.boolean(),
			payout: z.number(),
		}),
	),
});

export { schema };
