import { zeroAddress } from "viem";
import { dice } from "business-logic-gamblino";
import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { cx } from "src/utils/cx";
import { useAccount } from "wagmi";

const DiceGameSectionHistory = () => {
	const { isConnected: isUserConnected, address } = useAccount();

	const { data: userHistory } = dice.useUserHistory(
		{ userAddress: address || zeroAddress },
		{
			skip: !isUserConnected,
		},
	);
	const games = userHistory?.games.slice(0, 10);

	if (!games) return null;

	return (
		<div className="flex w-full flex-wrap items-center justify-center gap-1">
			<AnimatePresence mode="popLayout">
				{games.map((item) => (
					<motion.div
						key={item.betId}
						initial={{ x: -50, scale: 0.66, opacity: 0 }}
						animate={{ x: 0, scale: 1, opacity: 1 }}
						exit={{ scale: 0.66, opacity: 0 }}
						transition={{ type: "spring", duration: 1 }}
						layout
						className={cx(
							"bg-badge-background-success text-badge-content-success rounded-lg px-2 py-[2px] text-xs",
							{
								"bg-background-badge-error text-content-badge-error":
									!item.isWin,
							},
						)}
					>
						{item.rolled}
					</motion.div>
				))}
			</AnimatePresence>
		</div>
	);
};

export { DiceGameSectionHistory };
