import mockedData from "./mockedData";
import { schema } from "./schema";
import { createRequestQueryFunction } from "query-function-creators";

const verifyTestEthRequest = createRequestQueryFunction({
	getAxiosRequestConfig: ({ token }: { token: string }) => ({
		url: `${
			import.meta.env.BACKEND_SERVICE_GAMBLINO_BASE_URL
		}/faucet/verify`,
		method: "POST",
		data: {
			token,
		},
	}),
	schema,
	getMockedData: () => mockedData.response1,
	isMockingEnabled: false,
});

export { verifyTestEthRequest };
