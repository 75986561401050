import { configureStore } from "@reduxjs/toolkit";
import { type TypedUseSelectorHook } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import {
	gamblinoBusinessLogicApi,
	coinFlipBusinessLogicApi,
	diceBusinessLogicApi,
	minesBusinessLogic,
	rouletteBusinessLogicApi,
	azuroBussinessLogicApi,
	leaderboardBussinessLogicApi,
} from "business-logic-gamblino";
import { toastMiddleware } from "./toastMiddleware";
import { audioManagerSlice } from "./audioSlice";
import { asideStatusSlice } from "./asideStatus";
import { rouletteSlice } from "./rouletteSlice";
import { azuroSlice } from "./azuroSlice";
import { minesSlice } from "./minesSlice";

export const store = configureStore({
	reducer: {
		// Add the generated reducer as a specific top-level slice
		[gamblinoBusinessLogicApi.reducerPath]:
			gamblinoBusinessLogicApi.reducer,
		[coinFlipBusinessLogicApi.reducerPath]:
			coinFlipBusinessLogicApi.reducer,
		[azuroBussinessLogicApi.reducerPath]: azuroBussinessLogicApi.reducer,
		[leaderboardBussinessLogicApi.reducerPath]:
			leaderboardBussinessLogicApi.reducer,
		[diceBusinessLogicApi.reducerPath]: diceBusinessLogicApi.reducer,
		[rouletteBusinessLogicApi.reducerPath]:
			rouletteBusinessLogicApi.reducer,
		[minesBusinessLogic.reducerPath]: minesBusinessLogic.reducer,
		[minesSlice.name]: minesSlice.reducer,
		[audioManagerSlice.name]: audioManagerSlice.reducer,
		[asideStatusSlice.name]: asideStatusSlice.reducer,
		[rouletteSlice.name]: rouletteSlice.reducer,
		[azuroSlice.name]: azuroSlice.reducer,
	},
	// Adding the api middleware enables caching, invalidation, polling,
	// and other useful features of `rtk-query`.
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware()
			.concat(gamblinoBusinessLogicApi.middleware)
			.concat(coinFlipBusinessLogicApi.middleware)
			.concat(diceBusinessLogicApi.middleware)
			.concat(minesBusinessLogic.middleware)
			.concat(rouletteBusinessLogicApi.middleware)
			.concat(azuroBussinessLogicApi.middleware)
			.concat(leaderboardBussinessLogicApi.middleware)
			.concat(toastMiddleware),
});

export type RootState = ReturnType<typeof store.getState>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;

export type GetEndpointReturnType<
	Key extends keyof (typeof gamblinoBusinessLogicApi)["endpoints"],
> = (typeof gamblinoBusinessLogicApi)["endpoints"][Key]["Types"]["ResultType"];

export type GetEndpointArgType<
	Key extends keyof (typeof gamblinoBusinessLogicApi)["endpoints"],
> = (typeof gamblinoBusinessLogicApi)["endpoints"][Key]["Types"]["QueryArg"];
