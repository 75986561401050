import { abi } from "../../config";
import { Address, formatEther, zeroAddress } from "viem";
import { readContract } from "wagmi/actions";
import { wagmiConfig } from "wallet-connection";

const getMaxBetAmount = async ({
	multiplier,
	bankContractAddress,
}: {
	multiplier: number;
	bankContractAddress: Address;
}) => {
	const result = await readContract(wagmiConfig, {
		abi,
		address: bankContractAddress,
		functionName: "getMaxBetAmount",
		args: [zeroAddress, BigInt(multiplier)],
	});

	const maxBetAmountWei = `${result}`;
	const maxBetAmount = +formatEther(result).toString();

	return { maxBetAmountWei, maxBetAmount };
};

export { getMaxBetAmount };
