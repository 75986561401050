import { useAppDispatch, useAppSelector } from "./store";
import { rouletteSlice } from "./rouletteSlice";

const useRouletteHoveredNumbers = () => {
	const hoveredNumbers = useAppSelector(
		(state) => state.roulette.hoveredNumbers,
	);

	const dispatch = useAppDispatch();

	const toggleHover = (payload: { value: string; isHover: boolean }) => {
		dispatch(rouletteSlice.actions.toggleHover(payload));
	};

	return {
		hoveredNumbers,
		toggleHover,
	};
};

export { useRouletteHoveredNumbers };
