import React from "react";
import { coerce, z } from "zod";
import { parseUnits, zeroAddress } from "viem";
import { useAccount } from "wagmi";
import { AnimatePresence, motion } from "framer-motion";
import { mines } from "business-logic-gamblino";

import { Tooltip } from "@components/atoms";
import { Button } from "@components/molecules";

import { cx } from "src/utils";
import {
	CONTRACT_ADDRESS_GAMBLINO_BANK,
	CONTRACT_ADDRESS_MINES,
} from "src/config";
import { useAppSelector } from "src/redux/store";
import ReactCanvasConfetti from "react-canvas-confetti";
import { useAudioManager } from "src/redux";
import { useMinesSelectionInfo } from "src/Pages/MinesPage/hooks/useMinesSelectionInfo";
import { toast } from "sonner";

const minesSchema = coerce.number().min(1).max(24).nonnegative(); // TODO: get this from contract

const animationConfig: React.ComponentProps<(typeof motion)["div"]> = {
	initial: { opacity: 0, scale: 0.66 },
	animate: { opacity: 1, scale: 1 },
	exit: { opacity: 0, scale: 0.66 },
	transition: { duration: 1, type: "spring" },
};

const statuses = ["INITIAL", "WON", "LOST", "LOADING"] as const;
type Status = (typeof statuses)[number];

const LOADING_SOUND_PATH =
	"/sounds/mines/27454126_videogame-controller-fast-plastic-button-clicks_by_prosoundfx_preview.mp3";
const LOSE_SOUND_PATH =
	"/sounds/mines/43388021_mine-trap-explosion_by_fxprosound_preview.mp3";

const WIN_SOUND_PATH =
	"/sounds/positive/9474090_well-done_by_gamechestaudio_preview.mp3";

const RevealButton: React.FC<{
	className?: string;
	amount: {
		valueWei: string;
		value: string;
	};
	numOfMines: string;
}> = ({ className, amount, numOfMines }) => {
	const [status, setStatus] = React.useState<Status>("INITIAL");

	const fields = useAppSelector((state) => state.minesSlice.fields);
	const maxSelection = useAppSelector(
		(state) => state.minesSlice.maxSelection,
	);

	const { multiplier } = useMinesSelectionInfo();

	const { data: maxBetData } = mines.useCalculateMaxBetAmountQuery(
		{
			contractAddress: CONTRACT_ADDRESS_MINES,
			numMines: parseInt(numOfMines) || 0,
			tiles: fields,
			multiplier: parseFloat(multiplier.value) || 0,
			bankContractAddress: CONTRACT_ADDRESS_GAMBLINO_BANK,
		},
		{
			pollingInterval: 5000,
		},
	);

	const amountSchema = z
		.bigint()
		.max(BigInt(maxBetData?.maxBetAmountWei || 0))
		.nonnegative()
		.refine((value) => {
			return value >= parseUnits("2", 18);
		});

	const { playSoundOnce } = useAudioManager();

	const refAnimationInstance = React.useRef(null);

	const getInstance = React.useCallback((instance) => {
		refAnimationInstance.current = instance;
	}, []);

	const minesNum = useAppSelector((state) => state.minesSlice.mines);
	const numOfMaxSelection = maxSelection[minesNum - 1];
	const numOfSelectedFields = fields.filter((field) => field).length;

	const isInvalidSelection = numOfSelectedFields > numOfMaxSelection;

	const account = useAccount();

	const [start, { isLoading, data: mutationData, isSuccess }] =
		mines.useMinesMutation({
			fixedCacheKey: `useMinesMutation`,
		});

	React.useEffect(() => {
		if (isSuccess && mutationData?.isWin) {
			setStatus("WON");
		}
		if (isSuccess && !mutationData?.isWin) {
			setStatus("LOST");
		}
		if (isLoading) {
			setStatus("LOADING");
		}
		if (!isLoading && !isSuccess) {
			setStatus("INITIAL");
		}
	}, [isSuccess, isLoading]);

	React.useEffect(() => {
		if (status === "LOADING") {
			playSoundOnce(LOADING_SOUND_PATH);
		}

		if (status === "WON") {
			fire();
			playSoundOnce(WIN_SOUND_PATH);
		}
		if (status === "LOST" || status === "WON") {
			playSoundOnce(LOSE_SOUND_PATH);
			setTimeout(() => {
				setStatus("INITIAL");
			}, 5000);
		}
	}, [status]);

	const makeShot = React.useCallback((particleRatio, opts) => {
		refAnimationInstance.current &&
			refAnimationInstance.current({
				...opts,
				origin: { y: 0.7 },
				particleCount: Math.floor(200 * particleRatio),
			});
	}, []);

	const fire = React.useCallback(() => {
		makeShot(0.25, {
			spread: 26,
			startVelocity: 55,
		});

		makeShot(0.2, {
			spread: 60,
		});

		makeShot(0.35, {
			spread: 100,
			decay: 0.91,
			scalar: 0.8,
		});

		makeShot(0.1, {
			spread: 120,
			startVelocity: 25,
			decay: 0.92,
			scalar: 1.2,
		});

		makeShot(0.1, {
			spread: 120,
			startVelocity: 45,
		});
	}, [makeShot]);

	const renderButton = () => {
		if (isInvalidSelection) {
			return (
				<Tooltip
					className="w-full"
					content={
						<p>
							You can only select {numOfMaxSelection} fields for{" "}
							{minesNum} mines
						</p>
					}
				>
					<Button
						disabled={true}
						type="gradient"
						className="h-[44px] w-full"
						onClick={() => {}}
					>
						<Button.Text>Reveal</Button.Text>
					</Button>
				</Tooltip>
			);
		}

		const isLarger =
			BigInt(amount.valueWei) > BigInt(maxBetData?.maxBetAmountWei || 0);

		const largerMsg = `Max bet amount is currently ${
			maxBetData?.maxBetAmount || 0
		}`;

		const smallerMsg = `Min bet amount is 2`;

		const message = isLarger ? largerMsg : smallerMsg;

		if (!amountSchema.safeParse(BigInt(amount.valueWei)).success) {
			return (
				<Tooltip className="w-full" content={<p>{message}</p>}>
					<Button
						disabled={true}
						type="gradient"
						className="h-[44px] w-full"
						onClick={() => {}}
					>
						<Button.Text>Reveal</Button.Text>
					</Button>
				</Tooltip>
			);
		}

		if (!minesSchema.safeParse(numOfMines).success) {
			return (
				<Tooltip
					className="w-full"
					content={
						<p>Mines must be larger that 0, and smaller than 25</p>
					}
				>
					<Button
						disabled={true}
						type="gradient"
						className="h-[44px] w-full"
						onClick={() => {}}
					>
						<Button.Text>Reveal</Button.Text>
					</Button>
				</Tooltip>
			);
		}

		if (fields.filter((field) => field).length === 0) {
			return (
				<Tooltip
					className="w-full"
					content={<p>Select at least one field</p>}
				>
					<Button
						disabled={true}
						type="gradient"
						className="h-[44px] w-full"
						onClick={() => {}}
					>
						<Button.Text>Reveal</Button.Text>
					</Button>
				</Tooltip>
			);
		}

		return (
			<Button
				type="gradient"
				className="h-[44px] w-full"
				onClick={() => {
					const promise = start({
						tiles: fields,
						amountWei: amount.valueWei,
						numMines: parseInt(numOfMines),
						contractAddress: CONTRACT_ADDRESS_MINES,
						userAddress: account.address || zeroAddress,
					}).unwrap();

					toast.promise(promise, {
						error: "Failed to reveal",
					});
				}}
			>
				<Button.Text>Reveal</Button.Text>
			</Button>
		);
	};

	return (
		<div className={cx("", className)}>
			<AnimatePresence mode="popLayout">
				{status === "INITIAL" && (
					<motion.div key="INITIAL" {...animationConfig}>
						{renderButton()}
					</motion.div>
				)}

				{status === "WON" && (
					<motion.div
						key="WON"
						{...animationConfig}
						className="relative w-full"
					>
						<ReactCanvasConfetti
							refConfetti={getInstance}
							className="pointer-events-none absolute left-1/2 top-0 z-0 h-[1000px] w-[700px] -translate-x-1/2 -translate-y-2/3"
						/>
						<Button
							onClick={() => {}}
							className={cx("h-[44px] w-full animate-bounce")}
						>
							<Button.Text>You Won</Button.Text>
						</Button>
					</motion.div>
				)}

				{status === "LOST" && (
					<motion.div
						key="LOST"
						className="w-full"
						{...animationConfig}
					>
						<Button
							onClick={() => {}}
							type="secondary"
							className={cx(
								"animate-shake animate-twice bg-background-badge-error animate-ease-in-out h-[44px] w-full",
							)}
						>
							<Button.Text>Opss mine!</Button.Text>
						</Button>
					</motion.div>
				)}

				{status === "LOADING" && (
					<motion.div
						key="LOST"
						className="w-full"
						{...animationConfig}
					>
						<Button
							disabled={true}
							onClick={() => {}}
							type="gradient"
							className="h-[44px] w-full"
						>
							<Button.Icon
								type="LOADER"
								className={cx("animate-spin")}
							/>
							<Button.Text>Revealing...</Button.Text>
						</Button>
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	);
};

export { RevealButton };
