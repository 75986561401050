import { TooltipNumberWrapper } from "@components/molecules";
import React from "react";

const CapCell: React.FC<{
	cap: number;
}> = ({ cap }) => {
	return <TooltipNumberWrapper number={cap} copy />;
};

export { CapCell };
