import React from "react";

import { useSelection } from "@azuro-org/sdk";

import { cx, isMatchInPast } from "src/utils";
import { azuroSlice } from "src/redux/azuroSlice";
import { RootState, useAppDispatch } from "src/redux/store";

import { Icon } from "@components/atoms";
import { InvalidBetMessage } from "../../InvalidBetMessage";
import { TooltipNumberWrapper } from "@components/molecules";
import { getMarketName, getSelectionName } from "@azuro-org/dictionaries";

const BetSlipItem: React.FC<{
	className?: string;
	bet: RootState["azuro"][number];
}> = ({ className, bet }) => {
	const { odds, isLocked } = useSelection({
		selection: {
			outcomeId: bet.outcomeId,
			conditionId: bet.conditionId,
			coreAddress: ``, //TODO: AZURO
		},
		initialOdds: bet.betOdds,
	});

	const dispatch = useAppDispatch();

	const handleDelete = () => {
		dispatch(
			azuroSlice.actions.deleteBet({
				conditionId: bet?.conditionId,
				outcomeId: bet?.outcomeId,
			}),
		);
	};

	const participants = `${bet?.participants[0] || ""} - ${
		bet?.participants[1] || ""
	}`;

	const isMatchUnavailable = isLocked || isMatchInPast(bet.startsAt);

	return (
		<div
			className={cx(
				"bg-background-tertiary flex min-h-[56px] w-full shrink-0 flex-col gap-2 rounded p-2",
				className,
			)}
		>
			<div className="flex justify-between">
				<div>
					{/* icon */}
					<p
						className={cx("text-content-primary text-xs", {
							"text-content-secondary": isMatchUnavailable,
						})}
					>
						{participants}
					</p>
				</div>
				<div>
					<Icon
						type="X"
						onClick={handleDelete}
						className="stroke-content-primary h-4 w-4 cursor-pointer"
					/>
				</div>
			</div>
			<div className="flex justify-between">
				<div className="flex gap-2 ">
					<p
						className={cx("text-content-primary text-xs", {
							"text-content-secondary": isMatchUnavailable,
						})}
					>
						{getMarketName({ outcomeId: bet.outcomeId })}:
					</p>

					<p className="text-content-primary text-xs">
						{getSelectionName({ outcomeId: bet.outcomeId })}
					</p>
				</div>
				{isMatchUnavailable ? (
					<Icon
						type="CIRCLEOFF"
						className="stroke-content-secondary h-3 w-3"
					/>
				) : (
					<p className="text-content-primary text-xs">
						<TooltipNumberWrapper number={odds} />
					</p>
				)}
			</div>

			{isMatchUnavailable && (
				<InvalidBetMessage text="Outcome not available" />
			)}
		</div>
	);
};

export { BetSlipItem };
