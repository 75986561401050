import dayjs from "dayjs";

const isMatchInPast = (startsAt: string): boolean => {
	const startAtTimestamp = dayjs(parseInt(startsAt) * 1000);

	const isStartAtInPast = dayjs().isAfter(startAtTimestamp);

	return isStartAtInPast;
};

export { isMatchInPast };
