import * as React from "react";
import Children from "react-children-utilities";

import {
	InputButton,
	InputIcon,
	InputLabel,
	InputMax,
	InputCurrency,
	InputAssetIcon,
} from "./Compound";
import { cx } from "src/utils";

const renderInput = (
	type: "email" | "number" | "password" | "text",
	name: string,
	className: string,
	value: string,
	setValue?: React.Dispatch<React.SetStateAction<string>>,
	readonly?: boolean,
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
	disabled?: boolean,
) => {
	const defaultClassnames = "";

	if (type === "text") {
		return (
			<input
				type="text"
				id={name}
				name={name}
				autoComplete="off"
				className={cx(defaultClassnames, className)}
				value={value}
				onChange={(e) => {
					if (setValue) {
						setValue(e.currentTarget.value);
					} else {
						onChange?.(e);
					}
				}}
				readOnly={readonly}
				disabled={disabled}
			/>
		);
	}

	if (type === "password") {
		return (
			<input
				autoComplete="new-password"
				type="password"
				id={name}
				name={name}
				className={cx(defaultClassnames, className)}
				value={value}
				onChange={(e) => {
					if (setValue) {
						setValue(e.currentTarget.value);
					} else {
						onChange?.(e);
					}
				}}
				readOnly={readonly}
				disabled={disabled}
			/>
		);
	}

	if (type === "email") {
		return (
			<input
				autoComplete="email"
				type="email"
				id={name}
				name={name}
				className={cx(defaultClassnames, className)}
				value={value}
				onChange={(e) => {
					if (setValue) {
						setValue(e.currentTarget.value);
					} else {
						onChange?.(e);
					}
				}}
				readOnly={readonly}
				disabled={disabled}
			/>
		);
	}

	if (type === "number") {
		const inputNumberClassName = cx(
			defaultClassnames,
			"Input--Number",
			className,
		);

		return (
			<input
				autoComplete="off"
				// @ts-ignore Not sure why
				incremental
				type="number"
				id={name}
				name={name}
				className={inputNumberClassName}
				value={value}
				onChange={(e) => {
					if (setValue) {
						setValue(e.currentTarget.value);
					} else {
						onChange?.(e);
					}
				}}
				readOnly={readonly}
				disabled={disabled}
			/>
		);
	}

	throw new Error("Input type is not correct");
};

const Input: React.FC<
	React.PropsWithChildren<{
		value: string;
		setValue?: React.Dispatch<React.SetStateAction<string>> | undefined;
		onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
		className?: string;
		placeholder: string;
		name: string;
		wrapperClassName?: string;
		type: "email" | "number" | "password" | "text";
		readonly?: boolean;
		disabled?: boolean;
	}>
> & {
	Button: typeof InputButton;
	Currency: typeof InputCurrency;
	AssetIcon: typeof InputAssetIcon;
	Icon: typeof InputIcon;
	Label: typeof InputLabel;
	Max: typeof InputMax;
} = ({
	type,
	value = "",
	setValue,
	placeholder,
	onChange,
	name,
	children,
	className = "",
	wrapperClassName = "",
	disabled = false,
}) => {
	const buttonComponent = Children.filter(children, (item) =>
		Boolean(
			item &&
				React.isValidElement(item) &&
				item.type &&
				item.type === InputButton,
		),
	);
	const labelComponent = Children.filter(children, (item) =>
		Boolean(
			item &&
				React.isValidElement(item) &&
				item.type &&
				item.type === InputLabel,
		),
	)[0];

	const iconComponent = Children.filter(children, (item) =>
		Boolean(
			item &&
				React.isValidElement(item) &&
				item.type &&
				item.type === InputIcon,
		),
	)[0];
	const assetIconComponent = Children.filter(children, (item) =>
		Boolean(
			item &&
				React.isValidElement(item) &&
				item.type &&
				item.type === InputAssetIcon,
		),
	)[0];
	const maxComponent = Children.filter(children, (item) =>
		Boolean(
			item &&
				React.isValidElement(item) &&
				item.type &&
				item.type === InputMax,
		),
	)[0];
	const currencyComponent = Children.filter(children, (item) =>
		Boolean(
			item &&
				React.isValidElement(item) &&
				item.type &&
				item.type === InputCurrency,
		),
	)[0];

	return (
		<div className={cx("max-w-full", wrapperClassName)}>
			{(labelComponent || maxComponent) && (
				<div className="mb-1 flex items-end justify-between">
					{labelComponent}
					{!labelComponent && <div></div>}
					{maxComponent}
				</div>
			)}

			<label
				className={cx(
					"border-border-primary bg-background-tertiary flex h-[60px] w-full cursor-text flex-row items-center gap-3 overflow-hidden rounded-full border px-1",
					className,
				)}
				htmlFor={name}
			>
				{(iconComponent || assetIconComponent) && (
					<div className={"flex items-center justify-center pl-1"}>
						{iconComponent}
						{assetIconComponent}
					</div>
				)}
				{!(iconComponent || assetIconComponent) && <div></div>}

				<div className="relative -ml-1 w-full cursor-text overflow-x-hidden">
					{renderInput(
						type,
						name,
						cx(
							"z-10 w-full text-base text-content-primary bg-transparent border-none outline-none",
							className,
						),
						value,
						setValue,
						disabled,
						onChange,
					)}
					{!value && (
						<p className="text-content-secondary pointer-events-none absolute left-0 top-[50%] z-10 -translate-y-[50%] whitespace-nowrap">
							{placeholder}
						</p>
					)}
				</div>
				{(currencyComponent || buttonComponent) && (
					<div className="ml-auto flex gap-1 self-stretch whitespace-nowrap">
						{currencyComponent}
						{buttonComponent}
					</div>
				)}
			</label>
		</div>
	);
};

Input.Button = InputButton;
Input.Currency = InputCurrency;
Input.Icon = InputIcon;
Input.Label = InputLabel;
Input.Max = InputMax;
Input.AssetIcon = InputAssetIcon;

export { Input };
