import React from "react";
import { type ColumnDef } from "@tanstack/react-table";

import { type coinFlipBusinessLogicApi } from "business-logic-gamblino";

import { TimeCell } from "./cells/TimeCell";
import { ScoreCell } from "./cells/ScoreCell";
import { RolledCell } from "./cells/RolledCell";
import { PayoutCell } from "./cells/PayoutCell";
const PAGE_SIZE = 5;

const columns: ColumnDef<
	(typeof coinFlipBusinessLogicApi)["endpoints"]["getLastUserBets"]["Types"]["ResultType"]["games"][number]
>[] = [
	{
		accessorFn: (row) => row.isWin,
		id: "score",
		header: () => <p className="text-content-secondary text-sm">Score</p>,
		cell: (info) => <ScoreCell isWin={info.row.original.isWin} />,
		footer: (props) => props.column.id,
	},
	{
		accessorFn: (row) => row.betFace,
		id: "rolled",
		header: () => <p className="text-content-secondary text-sm">Rolled</p>,
		cell: (info) => (
			<RolledCell rolledFace={info.row.original.rolledFace} />
		),
		footer: (props) => props.column.id,
	},
	{
		accessorFn: (row) => row.amount,
		id: "amount",
		header: () => <p className="text-content-secondary text-sm">Amount</p>,
		cell: (info) => <PayoutCell payout={info.row.original.amount} />,
		footer: (props) => props.column.id,
	},
	{
		accessorFn: (row) => row.payout,
		id: "payout",
		header: () => <p className="text-content-secondary text-sm">Payout</p>,
		cell: (info) => <PayoutCell payout={info.row.original.payout} />,
		footer: (props) => props.column.id,
	},

	{
		accessorFn: (row) => row.payout,
		id: "Time played",
		header: () => (
			<p className="text-content-secondary text-sm">Time played</p>
		),
		cell: (info) => <TimeCell time={info.row.original.betTimestamp} />,
		footer: (props) => props.column.id,
	},
];

export { PAGE_SIZE, columns };
