import type { schema } from "./schema";
import type { z } from "zod";

type ModelType = z.infer<typeof schema>;

const response1: ModelType = {
	status: "ok",
	leaderboard: [
		{
			user: "0x0902da7ef9018f55de87652a943ecb23fc643590",
			totalVolume: 200,
			netProfit: 63.999629,
			wins: 1,
			losses: 2,
			streak: 1,
			rank: 1,
		},
		{
			user: "0x6567d655953f38d29f57b1ebd55ca6cae4daa12b",
			totalVolume: 25,
			netProfit: -7.653174,
			wins: 1,
			losses: 1,
			streak: 1,
			rank: 2,
		},
		{
			user: "0xac1dc7873d5718d976eda7b8461138b21ca7f929",
			totalVolume: 8.77,
			netProfit: -0.448794,
			wins: 7,
			losses: 9,
			streak: 1,
			rank: 3,
		},
		{
			user: "0x4e60af2ed6b60e43595b41354b1b7e063d0c0008",
			totalVolume: 2.42,
			netProfit: -2.42,
			wins: 0,
			losses: 4,
			streak: 0,
			rank: 4,
		},
		{
			user: "0xc0ab4f2e35ed034d980a53a5a9b6b1e86a805f4e",
			totalVolume: 0.5,
			netProfit: -0.5,
			wins: 0,
			losses: 1,
			streak: 0,
			rank: 5,
		},
		{
			user: "0x0ed2cf4b2288a8c23f4fffbf59d6ddb2ba94b78c",
			totalVolume: 0.44,
			netProfit: 0.29875,
			wins: 1,
			losses: 0,
			streak: 1,
			rank: 6,
		},
	],
	stats: {
		uniquePlayersCount: 6,
		betsPlacedCount: 16,
		winningBetsCount: 10,
		totalVolume: 237.13,
		// netProfits: 53.795781,
	},
};

export default { response1 };
