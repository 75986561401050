import mockedData from "./mockedData";
import { schema } from "./schema";
import { createRequestQueryFunction } from "query-function-creators";

const getAzuroScore = createRequestQueryFunction({
	getAxiosRequestConfig: ({ address }: { address: `0x${string}` }) => ({
		url: `https://api.azuro.org/score/leaderboard`,
		params: {
			address: address.toLowerCase(),
		},
	}),
	schema,
	getMockedData: () => mockedData.response1,
	isMockingEnabled: false,
	transformData: (data, props) => {
		const totalScore =
			data.data.top.find(
				(item) =>
					item.address.toLowerCase() === props.address.toLowerCase(),
			)?.totalScore || -1;

		return totalScore;
	},
});

export { getAzuroScore };
