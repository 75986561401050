import { GetSportCountriesQueryVariables } from "../gql/graphql";
import { client } from "../apollo";
import { graphql } from "../gql";

const query = graphql(`
	query getSportCountries($sportSlug: String!, $sportType: ID!) {
		sportCountries: sportHubs(where: { id: $sportType }) {
			id
			name
			sports(where: { slug: $sportSlug }) {
				id
				name
				slug
				countries(
					where: { hasActiveLeagues: true }
					orderBy: turnover
					orderDirection: desc
				) {
					id
					name
					slug
					turnover
					leagues(
						where: {
							hasActiveGames: true
							games_: { status_in: [Created, Paused] }
						}
						orderBy: turnover
						orderDirection: desc
					) {
						id
						name
						turnover
						slug
						country {
							name
							__typename
						}
						games(
							first: 1000
							where: { status_in: [Created, Paused] }
						) {
							id
							__typename
						}
						__typename
					}
					__typename
				}
				__typename
			}
			__typename
		}
	}
`);

const getCountries = async (variables: GetSportCountriesQueryVariables) => {
	if (!client) {
		throw new Error("Apollo client not initialized");
	}
	const {
		data: { sportCountries },
	} = await client.query({
		query,
		variables,
	});

	return sportCountries;
};

export { getCountries };
