import {
	BrowserClient,
	Hub,
	defaultIntegrations,
	defaultStackParser,
	init,
	makeFetchTransport,
	BrowserTracing,
	Feedback,
	Replay,
	reactRouterV6Instrumentation,
} from "@sentry/react";
import React from "react";
import {
	createRoutesFromChildren,
	matchRoutes,
	useLocation,
	useNavigationType,
} from "react-router-dom";

const prodIntegrations = [
	new BrowserTracing({
		// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
		tracePropagationTargets: ["localhost", /^https:\/\/gamblino\.app/],
		routingInstrumentation: reactRouterV6Instrumentation(
			React.useEffect,
			useLocation,
			useNavigationType,
			createRoutesFromChildren,
			matchRoutes,
		),
	}),
	new Replay({
		maskAllText: false,
		maskAllInputs: false,
	}),
];

const stagingIntegrations = [
	...prodIntegrations,
	new Feedback({
		colorScheme: "system",
	}),
];

const sentryInit = () =>
	init({
		dsn: import.meta.env.GAMBLINO_SENTRY_DSN,
		integrations:
			import.meta.env.GAMBLINO_IS_PROD === "true"
				? prodIntegrations
				: stagingIntegrations,
		beforeSend(event, hint) {
			if (
				!(
					import.meta.env.GAMBLINO_IS_PROD === "true" &&
					import.meta.env.PROD
				)
			) {
				return null;
			}
			if (
				(hint.originalException as Error).message.includes(
					"User rejected the request.",
				)
			) {
				return null;
			}

			// Return the event as is
			return event;
		},
		tunnel: "/tunnel",
		// Performance Monitoring
		tracesSampleRate: 1.0, //  Capture 100% of the transactions
		// Session Replay
		replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
		environment:
			import.meta.env.GAMBLINO_IS_PROD === "true" ? "production" : "beta",
	});

const client = new BrowserClient({
	dsn: import.meta.env.GAMBLINO_SENTRY_DSN,
	transport: makeFetchTransport,
	stackParser: defaultStackParser,
	integrations: defaultIntegrations,
});

const hub = new Hub(client);

export { client, hub, sentryInit };
