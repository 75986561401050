import { wagmiConfig } from "wallet-connection";

const useBlockExplorer = () => {
	// const chainId = useChainId();

	// const baseUrl = `https://${chainId === 421613 ? "goerli." : ""}arbiscan.io`;
	const baseUrl = wagmiConfig.chains[0]?.blockExplorers?.default?.url || "";
	const getBlockExplorer = (addressOrTxHash: string) => {
		if (addressOrTxHash.length >= 64) {
			return `${baseUrl}/tx/${addressOrTxHash}`;
		}
		return `${baseUrl}/address/${addressOrTxHash}`;
	};

	return getBlockExplorer;
};

export { useBlockExplorer };
