import { getMaxBetAmount as getMaxBetAmountContract } from "contract-service-gamblino-bank";
import { Address } from "viem";

const getMaxBetAmount = async ({
	bankContractAddress,
	selectedNumbers,
}: {
	bankContractAddress: Address;
	selectedNumbers: number;
}) => {
	const { maxBetAmount, maxBetAmountWei } = await getMaxBetAmountContract({
		bankContractAddress,
		multiplier: Math.ceil((37 / selectedNumbers) * 10000),
	});

	return { maxBetAmount, maxBetAmountWei };
};

export { getMaxBetAmount };
