import { AssetIcon } from "@components/atoms";
import React from "react";
import { getNativeChainIconType } from "src/utils/getNativeChainIconType";

const AssetIconTypeDice: React.ComponentProps<typeof AssetIcon>["type"] =
	getNativeChainIconType();
const AssetIconDice: React.FC<{ className?: string }> = ({ className }) => {
	return <AssetIcon className={className} type={AssetIconTypeDice} />;
};

export { AssetIconDice, AssetIconTypeDice };
