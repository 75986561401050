const addTokenToWallet = async ({
	tokenAddress,
	symbol,
	decimals = 18,
	imageUrl,
}: {
	tokenAddress: string;
	symbol: string;
	decimals?: number;
	imageUrl?: string;
}) => {
	if (!window.ethereum) {
		return;
	}

	await window.ethereum.request({
		method: "wallet_watchAsset",
		params: {
			type: "ERC20",
			options: {
				address: tokenAddress,
				symbol: symbol,
				decimals,
				image: imageUrl,
			},
		},
	});

	return "ok";
};

export { addTokenToWallet };
