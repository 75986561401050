import { PageSection } from "@components/atoms";
import { Button } from "@components/molecules";
import React from "react";
import { cx } from "src/utils";

const StakeSection: React.FC<{ className?: string }> = ({ className }) => {
	return (
		<PageSection
			className={cx(
				"bg-background-tertiary flex flex-col items-start gap-8 rounded-3xl p-5 md:flex-row md:items-center md:p-10",
				className,
			)}
		>
			<img
				className="w-full max-w-[240px] self-center lg:max-w-[300px] xl:max-w-[500px] xl:grow xl:basis-1/2"
				src="/home-stake-image.png"
				alt=""
			/>
			<div className="flex grow basis-1/2 flex-col gap-10 ">
				<h3 className="text-content-primary text-center text-4xl font-semibold md:text-left md:text-5xl">
					Stake & Earn
				</h3>
				<p className="text-content-secondary text-xl md:text-2xl">
					Embrace the Gamblino revolution, contributing to the
					evolution of gaming through decentralization, and reaping
					tangible benefits. With Gamblino tokens in your possession,
					you play a crucial role in broadening and decentralizing the
					Gamblino ecosystem. As a result, you’ll receive a share of
					the revenue generated by Gamblino as a token of appreciation
				</p>
				<div className="flex flex-col gap-6 sm:flex-row">
					<div className="relative w-full grow sm:w-max  lg:grow-0">
						<p className="bg-purple-light text-content-primary absolute left-1/2 top-0 z-[2] -translate-x-1/2 -translate-y-1/2 whitespace-nowrap rounded-3xl px-2 py-1 text-xs">
							COMING SOON
						</p>

						<Button
							onClick={() => {
								console.log("Buy Token");
							}}
							className="w-full"
							disabled
							type="gradient"
						>
							<Button.Text>Buy Token</Button.Text>
						</Button>
					</div>
					<div className="relative w-full grow sm:w-max lg:grow-0">
						<p className="bg-purple-light text-content-primary absolute left-1/2 top-0 z-[2] -translate-x-1/2 -translate-y-1/2 whitespace-nowrap rounded-3xl px-2 py-1 text-xs">
							COMING SOON
						</p>

						<Button
							onClick={() => {
								console.log("Stake Now");
							}}
							className="w-full"
							disabled
							type="outline"
						>
							<Button.Text>Stake Now</Button.Text>
						</Button>
					</div>
				</div>
			</div>
		</PageSection>
	);
};

export { StakeSection };
