import { z } from "zod";

const schema = z.object({
	status: z.string(),
	leaderboard: z.array(
		z.object({
			rank: z.number(),
			address: z.string(),
			totalPoints: z.number(),
		}),
	),
	userLeaderboard: z
		.object({
			rank: z.number(),
			address: z.string(),
			totalPoints: z.number(),
		})
		.nullable(),
	epochs: z.array(
		z.object({
			name: z.string(),
			startAt: z.number(),
			endsAt: z.number(),
			totalPointsEarned: z.number(),
			challenges: z.array(
				z.object({
					type: z.string(),
					description: z.string(),
				}),
			),
		}),
	),
});

export { schema };
