/* eslint-disable max-lines-per-function */
import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react";
import { getEndpointCreators } from "get-endpoint-creators";
import { getLastUserBets } from "../service/dice/latestRounds/latestRounds";
import { wager, getMaxBetAmount, calculateMaxBetAmount } from "../service/dice";
import { getMinBetAmount } from "contract-service-gamblino-bank";

const diceBusinessLogicApi = createApi({
	reducerPath: "diceBusinessLogicApi",
	baseQuery: fakeBaseQuery(),
	tagTypes: ["VRF", "user-history", "calculateMaxBetAmount"],
	endpoints: (builder) => {
		const { createQuery, createMutation } = getEndpointCreators(builder);

		return {
			wager: createMutation(wager, {
				invalidatesTags: ["user-history"],
			}),
			diceUserHistory: createQuery(getLastUserBets, {
				providesTags: ["user-history"],
			}),
			calculateMaxBetAmount: createQuery(calculateMaxBetAmount, {
				providesTags: ["calculateMaxBetAmount"],
			}),
			maxBetAmount: createQuery(getMaxBetAmount),
			minBetAmount: createQuery(getMinBetAmount),
		};
	},
});

const {
	useWagerMutation,
	useDiceUserHistoryQuery,
	useCalculateMaxBetAmountQuery,
	useMaxBetAmountQuery,
	useMinBetAmountQuery,
} = diceBusinessLogicApi;

const dice = {
	useWagerMutation,
	useCalculateMaxBetAmountQuery,
	useUserHistory: useDiceUserHistoryQuery,
	useMaxBetAmount: useMaxBetAmountQuery,
	useMinBetAmount: useMinBetAmountQuery,
};

export { dice, diceBusinessLogicApi };
