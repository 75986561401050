import React from "react";
import { cx } from "src/utils";
import { motion, useTime, useTransform } from "framer-motion";
import { useLoadMultipleImagesQuery } from "business-logic-gamblino";

const ANIMATION_SPEED = 0.001;

const RabbitIllustration = ({ className }: { className?: string }) => {
	const currentTime = useTime();

	const wiggleAnimations = {
		redArrow: {
			x: useTransform(
				currentTime,
				(value) => Math.sin(value * ANIMATION_SPEED) * 10,
			),
			y: useTransform(
				currentTime,
				(value) => Math.cos(value * ANIMATION_SPEED) * 10,
			),
			rotate: useTransform(
				currentTime,

				(value) => Math.sin(value * ANIMATION_SPEED) * 3,
			),
		},
		greenArrow: {
			x: useTransform(
				currentTime,
				(value) => Math.sin(12 + value * ANIMATION_SPEED) * 5,
			),
			y: useTransform(
				currentTime,
				(value) => Math.cos(200 + value * ANIMATION_SPEED) * 8,
			),
			rotate: useTransform(
				currentTime,

				(value) => Math.sin(160 + value * ANIMATION_SPEED) * 2 - 20,
			),
		},
		rabbit: {
			x: useTransform(
				currentTime,
				(value) => Math.sin(300 + value * ANIMATION_SPEED) * 3 + 20,
			),
			y: useTransform(
				currentTime,
				(value) => Math.cos(20 + value * ANIMATION_SPEED) * 3,
			),
			rotate: useTransform(
				currentTime,
				(value) => Math.sin(160 + value * ANIMATION_SPEED) * 2 - 20,
			),
		},
	};

	const imageLoader = useLoadMultipleImagesQuery({
		srcs: [
			"/hero-illustration-green-arrow.svg",
			"/hero-illustration-red-arrow.svg",
			"/hero-illustration-rabbit.png",
		],
	});

	const shouldRenderImageSkeleton =
		imageLoader.isLoading ||
		imageLoader.isFetching ||
		imageLoader.isUninitialized;
	return (
		<div className={cx("relative ", className)}>
			<motion.div
				initial={{ opacity: 0, scale: 0.9 }}
				animate={{
					opacity: shouldRenderImageSkeleton ? 0 : 1,
					scale: shouldRenderImageSkeleton ? 0.9 : 1,
				}}
				transition={{ ease: "easeInOut", duration: 2 }}
			>
				<motion.img
					src="/hero-illustration-green-arrow.svg"
					className="absolute z-[0] w-1/3"
					style={{
						x: wiggleAnimations.greenArrow.x,
						y: wiggleAnimations.greenArrow.y,
						rotate: wiggleAnimations.greenArrow.rotate,
					}}
					transition={{
						repeat: Infinity,
						repeatType: "loop",
					}}
				></motion.img>
				<motion.img
					src="/hero-illustration-rabbit.png"
					className="z-[1]"
					style={{
						x: wiggleAnimations.rabbit.x,
						y: wiggleAnimations.rabbit.y,
						rotate: wiggleAnimations.rabbit.rotate,
						scale: 0.9,
					}}
					transition={{
						repeat: Infinity,
						repeatType: "loop",
					}}
				></motion.img>
				<motion.img
					src="/hero-illustration-red-arrow.svg"
					className="absolute bottom-0 right-0 z-[2] w-1/3"
					style={{
						x: wiggleAnimations.redArrow.x,
						y: wiggleAnimations.redArrow.y,
						rotate: wiggleAnimations.redArrow.rotate,
					}}
					transition={{
						repeat: Infinity,
						repeatType: "loop",
					}}
				></motion.img>
			</motion.div>
		</div>
	);
};

export { RabbitIllustration };
