import { DashboardPageSection } from "@components/atoms";
import React from "react";
import { cx } from "src/utils";

const ThirdSection: React.FC<{ className?: string }> = ({ className }) => {
	return (
		<DashboardPageSection
			className={cx(
				"bg-gradient-1 border-content-primary w-full rounded-3xl p-6",
				className,
			)}
		>
			<div className="relative">
				<img
					className=" mx-auto block lg:hidden"
					src="/pie-chart.png"
					alt=""
				/>
				<div className="mt-5 w-full lg:mt-0 lg:w-7/12 2xl:w-8/12">
					<h3 className="text-content-primary text-3xl font-bold lg:text-4xl">
						Boost your XP
					</h3>
					<p className="text-content-secondary mt-4 text-xl lg:mt-6 lg:text-2xl">
						When you refer friends to Gamblino as long as they play,
						you will keep receiving the rewards. As your Gamblino
						network grows you’ll be able to participate in
						promotions, jackpots and house edge boosts, adding an
						extra layer of excitement to your gaming.
					</p>
				</div>
				<img
					className="absolute bottom-0 right-0 hidden w-4/12 lg:block lg:-translate-x-8 lg:-translate-y-7 lg:scale-110 xl:-translate-x-16 xl:-translate-y-0 xl:scale-100 2xl:translate-x-0 2xl:translate-y-9 2xl:scale-90"
					src="/pie-chart.png"
					alt=""
				/>
			</div>
			<p className="text-content-secondary mt-4 text-xl  lg:mt-12 lg:text-2xl">
				The referral program is simple to use - just generate your
				unique code, and it becomes bound to your wallet ensuring
				seamless tracking.
			</p>
		</DashboardPageSection>
	);
};

export { ThirdSection };
