import {
	getFee,
	getLastUserBets,
	wager as wagerContract,
} from "contract-service-coin-flip";

import wait from "wait";

const wager = async ({
	amountWei,
	contractAddress,
	face,
}: Omit<Parameters<typeof wagerContract>[0], "feeWei">) => {
	const { fee } = await getFee({ contractAddress });

	const { txHash, betId } = await wagerContract({
		amountWei,
		contractAddress,
		face,
		feeWei: BigInt(fee).toString(),
	});

	const { payout, rolled } = await new Promise<{
		payout: bigint;
		rolled: boolean;
	}>((resolve, reject) => {
		void (async () => {
			// eslint-disable-next-line no-constant-condition
			for (let i = 0; i < 10; i++) {
				const { result } = await getLastUserBets({
					contractAddress,
				});
				if (result[0]) {
					if (
						result[0].bet.id.toString() === betId &&
						result[0].bet.resolved
					) {
						resolve({
							payout: result[0].bet.payout,
							rolled: result[0].coinFlipBet.rolled,
						});
						break;
					}
				}
				await wait(1000);
			}
			reject({ message: "Request timed out" });
		})();
	});

	return { txHash, betId, rolled, isWin: rolled === face };
};

export { wager };
