import { useModalManager } from "@components/ethereals/ModalsWrapper";
import { Button } from "@components/molecules";
import React from "react";

const convertNumberToSelectedArray = (number: number): string[] => {
	const binaryArray = number
		.toString(2)
		.padStart(37, "0")
		.split("")
		.reverse();

	const selectedNumbers: string[] = binaryArray.reduce(
		(indexes: string[], element, index) => {
			if (element === "1") {
				indexes.push(index.toString());
			}
			return indexes;
		},
		[],
	);

	return selectedNumbers;
};

const PlayedSelectionCell: React.FC<{
	playedSelection: string;
	rolled: number;
}> = ({ playedSelection, rolled }) => {
	const { modalManager } = useModalManager();

	const selectedNumbers = convertNumberToSelectedArray(
		parseInt(playedSelection),
	);
	return (
		<Button
			type="tertiary"
			size="sm"
			onClick={() => {
				modalManager.open("RouletteWheelModal", {
					selectedNumbers,
					rolled,
				});
			}}
		>
			<Button.Text>More</Button.Text>
		</Button>
	);
};

export { PlayedSelectionCell };
