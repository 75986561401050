import type { schema } from "./schema";
import type { z } from "zod";

type ModelType = z.infer<typeof schema>;

const response1: ModelType = {
	status: "ok",
	quests: [
		{
			backgroundImg:
				"https://img.freepik.com/free-photo/painting-mountain-lake-with-mountain-background_188544-9126.jpg",
			xpReward: "25",
			title: "Follow Gamblino",
			description: "Follow Gamblino on Twitter !",
			questId: "3af3c1a1-5b5c-49b2-a416-4f2d304f7cf4",
			tag: "onboarding",
			completed: true,
		},
		{
			backgroundImg:
				"https://img.freepik.com/free-photo/painting-mountain-lake-with-mountain-background_188544-9126.jpg",
			xpReward: "55",
			title: "Join Gamblino on Telegram",
			description:
				"Come hang out and chat shit with the team. Will be dropping token and product alpha mostly in TG! ",
			questId: "2cef8d89-e495-4762-9e06-70ce7334855f",
			tag: "onboarding",
			completed: false,
		},
		{
			backgroundImg:
				"https://img.freepik.com/free-photo/painting-mountain-lake-with-mountain-background_188544-9126.jpg",
			xpReward: "50",
			title: "Gamblino Introduction",
			description: "Retweet + Like + Reply",
			questId: "24792a49-059b-43bd-99a6-76df3b67181a",
			tag: "twitter",
			completed: false,
		},
		{
			backgroundImg:
				"https://img.freepik.com/free-photo/painting-mountain-lake-with-mountain-background_188544-9126.jpg",
			xpReward: "100",
			title: "Retweet our Zealy Campaign",
			description: "",
			questId: "88499af8-7a3c-4261-b208-dd739908c11d",
			tag: "twitter",
			completed: false,
		},
		{
			backgroundImg:
				"https://img.freepik.com/free-photo/painting-mountain-lake-with-mountain-background_188544-9126.jpg",
			xpReward: "50",
			title: "Invite Your Friends",
			description:
				"Invite your friends to join Gamblino\u2019s Zealy Quest! The more the merrier",
			questId: "10f7e63f-27fd-43ef-af01-c3d4d54d545a",
			tag: "Friend Invites",
			completed: false,
		},
		{
			backgroundImg:
				"https://img.freepik.com/free-photo/painting-mountain-lake-with-mountain-background_188544-9126.jpg",
			xpReward: "100",
			title: "Invite 5 of Your Friends",
			description:
				"Invite your friends to join Gamblino\u2019s Zealy Quest! The more the merrier",
			questId: "99419772-761b-4b10-9669-57498b6d0cae",
			tag: "Friend Invites",
			completed: false,
		},
		{
			backgroundImg:
				"https://img.freepik.com/free-photo/painting-mountain-lake-with-mountain-background_188544-9126.jpg",
			xpReward: "400",
			title: "Invite 10 of Your Friends",
			description:
				"Invite your friends to join Gamblino\u2019s Zealy Quest! The more the merrier",
			questId: "073a45bd-61cd-4005-b926-db62bef52ec1",
			tag: "Friend Invites",
			completed: false,
		},
	],
};

export default { response1 };
