import React from "react";

import { cx } from "src/utils";
import { DashboardPageSection } from "@components/atoms";
import { UserHistoryTable } from "./UserHistoryTable/UserHistoryTable";

const PastPredictionsSection: React.FC<{
	contractAddress: string;
	className?: string;
}> = ({ contractAddress, className }) => {
	return (
		<DashboardPageSection className="w-full">
			<UserHistoryTable />
		</DashboardPageSection>
	);
};

export { PastPredictionsSection };
