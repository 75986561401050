import React from "react";
import { cx } from "src/utils";
import { Button } from "@components/molecules";
import { Tooltip, Icon } from "@components/atoms";

const SlippageSelection: React.FC<{
	className?: string;
	setSlippage: React.Dispatch<React.SetStateAction<number>>;
	slippage: number;
}> = ({ className, setSlippage, slippage }) => {
	return (
		<div className={cx("flex flex-col gap-1 px-4", className)}>
			<div className="flex gap-1">
				<p className="text-xs">Odss Slippage Tolerance</p>
				<Tooltip
					content={
						<p>
							The percentage of maximum slippage or change in odds
							when placing a bet.
						</p>
					}
				>
					<Icon
						type="INFO"
						className="stroke-content-primary h-4 w-4"
					/>
				</Tooltip>
			</div>
			<div className="mt-2 flex gap-2">
				<Button
					onClick={() => {
						setSlippage(0);
					}}
					size="sm"
					type="outline"
					className={cx("flex-1", {
						"bg-content-primary bg-opacity-60": slippage === 0,
					})}
				>
					<Button.Text>0%</Button.Text>
				</Button>
				<Button
					onClick={() => {
						setSlippage(2);
					}}
					type="outline"
					size="sm"
					className={cx("flex-1", {
						"bg-content-primary bg-opacity-60": slippage === 2,
					})}
				>
					<Button.Text>2%</Button.Text>
				</Button>
				<Button
					onClick={() => {
						setSlippage(5);
					}}
					type="outline"
					size="sm"
					className={cx("flex-1", {
						"bg-content-primary bg-opacity-60": slippage === 5,
					})}
				>
					<Button.Text>5%</Button.Text>
				</Button>
				<Button
					onClick={() => {
						setSlippage(10);
					}}
					type="outline"
					size="sm"
					className={cx("flex-1", {
						"bg-content-primary bg-opacity-60": slippage === 10,
					})}
				>
					<Button.Text>10%</Button.Text>
				</Button>
			</div>
		</div>
	);
};

export { SlippageSelection };
