import { TooltipNumberWrapper } from "@components/molecules";
import React from "react";
import { AssetIconDice } from "../..";

const PayoutCell: React.FC<{
	payout: number;
}> = ({ payout }) => {
	return (
		<div className="flex items-center gap-1">
			<AssetIconDice className="h-5 w-5" />
			<TooltipNumberWrapper number={payout} copy />
		</div>
	);
};

export { PayoutCell };
