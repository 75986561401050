import React from "react";

import { cx } from "src/utils";
import { useRouletteSelectionStats } from "../../hooks/useRouletteSelectionStats";
import { TooltipNumberWrapper } from "@components/molecules";

const Stats: React.FC<{
	className?: string;
	amount: {
		valueWei: string;
		value: string;
	};
}> = ({ className, amount }) => {
	const { multiplier, payout, chance } = useRouletteSelectionStats(
		amount.value,
	);

	return (
		<div
			className={cx(
				" flex w-full items-center justify-around gap-2",
				className,
			)}
		>
			<div className="bg-background-tertiary flex flex-1 flex-col rounded-md p-2 text-center">
				<div className="flex items-center justify-center text-2xl">
					<TooltipNumberWrapper number={multiplier} />
					<p>x</p>
				</div>
				<p className="text-content-secondary text-sm">Multiplier</p>
			</div>
			<div className="bg-background-tertiary flex flex-1 flex-col rounded-md p-2 text-center">
				<div className="flex items-center justify-center text-2xl">
					<p>$</p>
					<TooltipNumberWrapper number={payout} />
				</div>
				<p className="text-content-secondary text-sm">Payout</p>
			</div>
			<div className="bg-background-tertiary flex flex-1 flex-col rounded-md p-2 text-center">
				<div className="flex items-center justify-center text-2xl">
					<TooltipNumberWrapper number={chance} />
					<p>%</p>
				</div>
				<p className="text-content-secondary text-sm">Chance</p>
			</div>
		</div>
	);
};

export { Stats };
