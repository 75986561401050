import React from "react";
import { useAccount, useDisconnect } from "wagmi";

import { Avatar, Button } from "@components/molecules";
import { Modal } from "@components/organisms/Modal";
import type { IModal } from "@components/organisms/Modal";
import { useModalManager } from "@components/ethereals/ModalsWrapper";

import { cx } from "src/utils";
import { truncateEthAddress } from "formatting-service";

const AddressNotParallelizedModal: IModal<"AddressNotParallelizedModal"> &
	React.FC = () => {
	const { modalManager } = useModalManager();

	const { address } = useAccount();
	React.useEffect(() => {
		modalManager.setNotClosable(true);

		return () => modalManager.setNotClosable(false);
	}, []);

	const { disconnect } = useDisconnect();

	return (
		<Modal className="bg-background-secondary border-border-primary text-content-primary relative max-h-[564px]  overflow-hidden rounded-2xl border sm:min-w-[416px] lg:w-[400px]">
			<Modal.Step>
				<div className="flex flex-col items-center p-5">
					<img src="/chain.png" className="my-5 size-20" />
					<div className="relative flex w-full items-center justify-center">
						<h3 className="text-danger self-center text-lg font-semibold text-black">
							Link Your Address to Use Apps on SEI EVM
						</h3>
					</div>

					{address && (
						<div className="bg-background-secondary flex flex-row items-center gap-1 rounded-full px-3 py-2">
							<Avatar
								userAddress={address}
								className=" h-6 w-6 "
							/>
							<span>{truncateEthAddress(address)}</span>
						</div>
					)}

					<p className="text-content-secondary mt-5 text-center text-sm">
						To use apps on SEI EVM, your address needs to be linked.
						Please visit{" "}
						<a
							href="https://app.sei.io/"
							target="_blank"
							rel="noopener noreferrer"
							className="text-content-primary-accent"
						>
							app.sei.io/
						</a>{" "}
						to link your address. This will enable your EVM address
						to receive native SEI tokens.
					</p>

					<Button
						type="gradient"
						onClick={() => {
							window.open("https://app.sei.io/", "_blank");
						}}
						className="mt-3 w-full"
					>
						<Button.Text>Visit app.sei.io</Button.Text>
					</Button>

					<p
						onClick={() => {
							disconnect();
							modalManager.setNotClosable(false);
							modalManager.close("AddressNotParallelizedModal");
						}}
						className="text-content-secondary mt-4 cursor-pointer text-sm hover:opacity-90"
					>
						Disconnect
					</p>
				</div>
			</Modal.Step>
		</Modal>
	);
};

AddressNotParallelizedModal.modalName = "AddressNotParallelizedModal";

export { AddressNotParallelizedModal };
