import { client } from "../apollo";
import { graphql } from "../gql";
import { SportHub } from "../gql/graphql";

const query = graphql(`
	query getSportsList {
		sportHubs {
			id
			name
			sports {
				id
				name
				slug
				games(first: 1000, where: { status_in: [Created, Paused] }) {
					id
					startsAt
					__typename
				}
				countries(
					where: { hasActiveLeagues: true }
					orderBy: turnover
					orderDirection: desc
				) {
					id
					name
					slug
					turnover
					__typename
				}
				__typename
			}
			__typename
		}
	}
`);

const getSportsList = async () => {
	if (!client) {
		throw new Error("Apollo client not initialized");
	}
	const {
		data: { sportHubs },
	} = await client.query({
		query,
	});

	return sportHubs as SportHub[];
};

export { getSportsList };
