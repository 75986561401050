import React from "react";
import * as Slider from "@radix-ui/react-slider";
import { cx } from "src/utils";
import { motion } from "framer-motion";
import { Tooltip } from "@components/atoms";

const DiceGameSlider: React.FC<{
	rollOverValue: number[];
	setRollOverValue: React.Dispatch<React.SetStateAction<number[]>>;
	lastRolledLabelValue: number;
	scope: React.RefObject<HTMLDivElement>;
}> = ({ rollOverValue, setRollOverValue, scope, lastRolledLabelValue }) => {
	return (
		<Slider.Root
			step={1}
			min={1}
			max={99}
			value={rollOverValue}
			onValueChange={(val) => setRollOverValue(val)}
			className={cx(
				"relative col-span-full mt-14 flex w-full touch-none select-none items-center",
			)}
		>
			<Slider.Track className="bg-gradient-dice relative h-2 w-full grow rounded-full">
				<motion.div
					ref={scope}
					className={`text-content-primary absolute -top-5 h-[14] -translate-x-1/2 -translate-y-full overflow-hidden opacity-0`}
				>
					<Tooltip content="This is the outcome of the last roll">
						<div className="relative">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="71"
								height="65"
								viewBox="0 0 71 65"
								fill="none"
							>
								<path
									d="M45.8924 58.5C41.2736 66.5 29.7265 66.5 25.1077 58.5L1.72508 18C-2.89373 10 2.87977 9.31222e-06 12.1174 1.01198e-05L58.8827 1.42081e-05C68.1203 1.50157e-05 73.8938 10 69.275 18L45.8924 58.5Z"
									fill="#0F172A"
								/>
							</svg>
							<span className=" text-x absolute left-7 top-2">
								{lastRolledLabelValue.toFixed(0)}
							</span>
						</div>
						{/* <div className="bg-violet-dark absolute inset-x-0 mx-auto h-full w-1 -translate-y-1" /> */}
					</Tooltip>
				</motion.div>
				<Slider.Range className="bg-primary absolute h-full" />
			</Slider.Track>
			<Slider.Thumb className="bg-gradient-dice block h-12 w-12 rounded-xl transition-colors">
				<img
					className="h-full w-full px-[6px] py-2"
					src="/dice/DiceImage.svg"
					alt=""
				/>
			</Slider.Thumb>
			<div className="text-content-secondary absolute inset-x-0 -bottom-10 flex w-full flex-row items-center justify-between text-xs">
				<span>1</span>
				<span>25</span>
				<span>50</span>
				<span>75</span>
				<span>99</span>
			</div>
			<div className="absolute inset-x-0 -bottom-[50px] overflow-hidden">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="561"
					height="9"
					viewBox="0 0 561 9"
					fill="none"
				>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M0.502688 9H0V0H0.502688V9ZM9.55107 9H8.5457V0H9.55107V9ZM18.5995 9H17.5941V0H18.5995V9ZM27.6479 9H26.6425V0H27.6479V9ZM36.6962 9H35.6909V0H36.6962V9ZM45.7446 9H44.7392V0H45.7446V9ZM54.793 9H53.7876V0H54.793V9ZM63.8414 9H62.836V0H63.8414V9ZM72.8898 9H71.8844V0H72.8898V9ZM81.9382 9H80.9328V0H81.9382V9ZM90.9865 9H89.9812V0H90.9865V9ZM100.035 9H99.0296V0H100.035V9ZM109.083 9H108.078V0H109.083V9ZM118.132 9H117.126V0H118.132V9ZM127.18 9H126.175V0H127.18V9ZM136.228 9H135.223V0H136.228V9ZM145.277 9H144.271V0H145.277V9ZM154.325 9H153.32V0H154.325V9ZM163.374 9H162.368V0H163.374V9ZM172.422 9H171.417V0H172.422V9ZM181.47 9H180.465V0H181.47V9ZM190.519 9H189.513V0H190.519V9ZM199.567 9H198.562V0H199.567V9ZM208.616 9H207.61V0H208.616V9ZM217.664 9H216.659V0H217.664V9ZM226.712 9H225.707V0H226.712V9ZM235.761 9H234.755V0H235.761V9ZM244.809 9H243.804V0H244.809V9ZM253.857 9H252.852V0H253.857V9ZM262.906 9H261.9V0H262.906V9ZM271.954 9H270.949V0H271.954V9ZM281.003 9H279.997V0H281.003V9ZM290.051 9H289.046V0H290.051V9ZM299.099 9H298.094V0H299.099V9ZM308.148 9H307.142V0H308.148V9ZM317.196 9H316.191V0H317.196V9ZM326.244 9H325.239V0H326.244V9ZM335.293 9H334.287V0H335.293V9ZM344.341 9H343.336V0H344.341V9ZM353.39 9H352.384V0H353.39V9ZM362.438 9H361.433V0H362.438V9ZM371.486 9H370.481V0H371.486V9ZM380.535 9H379.529V0H380.535V9ZM389.583 9H388.578V0H389.583V9ZM398.631 9H397.626V0H398.631V9ZM407.68 9H406.674V0H407.68V9ZM416.728 9H415.723V0H416.728V9ZM425.777 9H424.771V0H425.777V9ZM434.825 9H433.82V0H434.825V9ZM443.873 9H442.868V0H443.873V9ZM452.922 9H451.916V0H452.922V9ZM461.97 9H460.965V0H461.97V9ZM471.018 9H470.013V0H471.018V9ZM480.067 9H479.061V0H480.067V9ZM489.115 9H488.11V0H489.115V9ZM498.163 9H497.158V0H498.163V9ZM507.212 9H506.206V0H507.212V9ZM516.26 9H515.255V0H516.26V9ZM525.309 9H524.303V0H525.309V9ZM534.357 9H533.352V0H534.357V9ZM543.405 9H542.4V0H543.405V9ZM552.454 9H551.448V0H552.454V9ZM561 9H560.497V0H561V9Z"
						fill="#0F172A"
					/>
				</svg>
			</div>
		</Slider.Root>
	);
};

export default DiceGameSlider;
