import { TooltipNumberWrapper } from "@components/molecules";
import React from "react";

const MultiplierCell: React.FC<{ multiplier?: number }> = ({ multiplier }) => {
	return (
		<TooltipNumberWrapper
			number={multiplier === undefined ? "-" : multiplier}
			formatPriceConfig={{
				currencyLabel: "x",
			}}
		/>
	);
};

export { MultiplierCell };
