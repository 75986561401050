import { schema } from "./schema";
import mockedData from "./mockedData";
import { createRequestQueryFunction } from "query-function-creators";

const getGamesStats = createRequestQueryFunction({
	getAxiosRequestConfig: () => ({
		url: `${import.meta.env.BACKEND_SERVICE_GAMBLINO_BASE_URL}/stats`,
		method: "GET",
	}),
	schema,
	getMockedData: () => mockedData.response1,
	isMockingEnabled: false,
});

export { getGamesStats };
