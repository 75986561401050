import { mines } from "business-logic-gamblino";
import { CONTRACT_ADDRESS_MINES } from "src/config";
import { useAppSelector } from "src/redux/store";

const contractAddress = CONTRACT_ADDRESS_MINES;

const useMinesSelectionInfo = () => {
	const fields = useAppSelector((state) => state.minesSlice.fields);
	const numMines = useAppSelector((state) => state.minesSlice.mines);
	const playedFields = fields.filter((field) => field).length;
	const { data } = mines.useGetMultipliers(
		{
			numMines,
			playedFields,
			contractAddress,
		},
		{
			skip: !numMines || !playedFields,
		},
	);

	const coins = fields.filter((field) => field).length;

	return {
		coins,
		multiplier: {
			value: data?.response.multiplier || "0",
			valueWei: data?.response.multiplierWei || "0",
		},
		numMines,
	};
};

export { useMinesSelectionInfo };
