import React from "react";
import { useDispatch } from "react-redux";

import {
	ZERO,
	THIRD_GROUP,
	FIRST_GROUP,
	SECOND_GROUP,
	ROW_SELECTION,
	SELECTION_FIRST_GROUP,
	SELECTION_SECOND_GROUP,
} from "../../config";
import { cx } from "src/utils";
import { useAppSelector } from "src/redux/store";
import { RouletteButton } from "./RouletteButton";
import { rouletteSlice } from "src/redux/rouletteSlice";

const ROW_SELECTION_REVERSE = [...ROW_SELECTION].reverse();

const BoardButtonsMobile: React.FC<{ className?: string }> = ({
	className,
}) => {
	const { selectedNumbers } = useAppSelector((state) => state.roulette);

	const dispatch = useDispatch();

	return (
		<div className={cx("flex shrink-0 flex-row gap-2", className)}>
			<div className="flex flex-col gap-2">
				<div className="h-10"></div>
				{SELECTION_SECOND_GROUP.map((btn, i) => (
					<RouletteButton
						key={`${btn.value}${i}`}
						value={btn.value}
						type={btn.type}
						className={cx({
							"text-vertical-orientation":
								btn.value === "EVEN" || btn.value === "ODD",
						})}
						onClick={() => {
							dispatch(
								rouletteSlice.actions.toggleGroupSelection(
									btn.action,
								),
							);
						}}
					/>
				))}
				<div className="h-10"></div>
			</div>
			<div className="flex flex-col gap-2">
				<div className="h-10"></div>
				{SELECTION_FIRST_GROUP.map((btn, i) => (
					<RouletteButton
						key={`${btn.value}${i}`}
						value={btn.value}
						className=""
						onClick={() => {
							dispatch(
								rouletteSlice.actions.toggleGroupSelection(
									btn.action,
								),
							);
						}}
					/>
				))}
				<div className="h-10"></div>
			</div>
			<div className="w-full flex-col gap-2">
				<div className="flex flex-col gap-2">
					<RouletteButton
						value={ZERO.value}
						type={ZERO.type}
						className="w-full"
						placed={selectedNumbers.includes(ZERO.value)}
						onClick={() =>
							dispatch(
								rouletteSlice.actions.toggleSingleSelection(
									ZERO.value,
								),
							)
						}
					/>
				</div>
				<div className="mt-2 flex gap-2">
					<div className="flex grow flex-col gap-2">
						{THIRD_GROUP.map((btn) => (
							<RouletteButton
								key={btn.value}
								value={btn.value}
								type={btn.type}
								placed={selectedNumbers.includes(btn.value)}
								onClick={() => {
									dispatch(
										rouletteSlice.actions.toggleSingleSelection(
											btn.value,
										),
									);
								}}
							/>
						))}
					</div>

					<div className="flex grow flex-col gap-2">
						{SECOND_GROUP.map((btn) => (
							<RouletteButton
								key={btn.value}
								value={btn.value}
								type={btn.type}
								placed={selectedNumbers.includes(btn.value)}
								onClick={() => {
									dispatch(
										rouletteSlice.actions.toggleSingleSelection(
											btn.value,
										),
									);
								}}
							/>
						))}
					</div>

					<div className="flex grow flex-col gap-2">
						{FIRST_GROUP.map((btn) => (
							<RouletteButton
								key={btn.value}
								value={btn.value}
								type={btn.type}
								placed={selectedNumbers.includes(btn.value)}
								onClick={() => {
									dispatch(
										rouletteSlice.actions.toggleSingleSelection(
											btn.value,
										),
									);
								}}
							/>
						))}
					</div>
				</div>
				<div className="mt-2 flex gap-2">
					{ROW_SELECTION_REVERSE.map((btn, i) => (
						<RouletteButton
							key={`${btn.value}${i}`}
							value={btn.value}
							className="md:w-10"
							onClick={() =>
								dispatch(
									rouletteSlice.actions.toggleRowSelection(
										btn.action,
									),
								)
							}
						/>
					))}
				</div>
			</div>
		</div>
	);
};

export { BoardButtonsMobile };
