import React, { useMemo } from "react";

import { cx } from "src/utils";
import { Card } from "./Card";
import { gamesConfig } from "./data";
import { GameTypeTabs } from "./GameTypeTabs";
import { DashboardPageSection } from "@components/atoms";
import { AnimateChangeInHeight } from "@components/organisms";
import { AnimatePresence, motion } from "framer-motion";

const GamesSection: React.FC<{ className?: string }> = ({ className }) => {
	const [selected, setSelected] = React.useState<"allGames" | "pvp" | "pvh">(
		"allGames",
	);

	const filteredGames = useMemo(() => {
		if (selected === "allGames") return gamesConfig;

		return gamesConfig.filter((game) => game.type === selected);
	}, [selected]);
	return (
		<React.Fragment>
			<DashboardPageSection className={cx(" ", className)}>
				<h2 className="text-content-primary text-center text-3xl">
					Games
				</h2>

				<GameTypeTabs
					onSelectChange={setSelected}
					selected={selected}
				/>
				<AnimateChangeInHeight className="mt-10 h-full w-full">
					<motion.div className=" grid grid-cols-[repeat(auto-fill,_minmax(160px,_1fr))] gap-4">
						<AnimatePresence mode="popLayout">
							{filteredGames.map((game) => (
								<motion.div
									key={game.name}
									initial={{ opacity: 0 }}
									animate={{ opacity: 1 }}
									exit={{ opacity: 0 }}
								>
									<Card game={game} />
								</motion.div>
							))}
						</AnimatePresence>
					</motion.div>
				</AnimateChangeInHeight>
			</DashboardPageSection>
		</React.Fragment>
	);
};

export { GamesSection };
