import type { schema } from "./schema";
import type { z } from "zod";

type ModelType = z.infer<typeof schema>;

const response1: ModelType = {
	status: "ok",
	games: [
		{
			name: "Market Minds",
			secondaryName: "Predict to win",
			imageUrl: "/game-cards/market-minds-card.png",
		},
		{
			name: "Flip Master",
			secondaryName: "Heads or tails",
			imageUrl: "/game-cards/flip-master-card.png",
		},
		{
			name: "Adrenalin Wheel",
			secondaryName: "Prize pool",
			imageUrl: "/game-cards/adrenaline-wheel-card.png",
		},
		{
			name: "Gamblino Lottery",
			secondaryName: "Buy the glory",
			imageUrl: "/game-cards/lottery-card.png",
		},
		{
			name: "More coming soon 1",
			secondaryName: "More coming soon 1",
			imageUrl: "/game-more-coming-soon.png",
		},
		{
			name: "More coming soon 2",
			secondaryName: "More coming soon 2",
			imageUrl: "/game-more-coming-soon.png",
		},

		{
			name: "More coming soon 3",
			secondaryName: "More coming soon 3",
			imageUrl: "/game-more-coming-soon.png",
		},
		{
			name: "More coming soon 4",
			secondaryName: "More coming soon 4",
			imageUrl: "/game-more-coming-soon.png",
		},
	],
};

export default { response1 };
