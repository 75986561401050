import type { schema } from "./schema";
import type { z } from "zod";

type ModelType = z.infer<typeof schema>;

const response1: ModelType = {
	status: "ok",
	leaderboard: [
		{
			rank: 1,
			address: "0x62bc727318b6ac0ccb4403a19f92d16e65e67778",
			totalPoints: 44,
		},
		{
			rank: 2,
			address: "0x4e60af2ed6b60e43595b41354b1b7e063d0c0008",
			totalPoints: 28.219999999999998863131622783839702606201171875,
		},
		{
			rank: 3,
			address: "0x44ad24ce1d17cb9803dc0ccf5a7386dc7ff0c79e",
			totalPoints: 10,
		},
	],
	userLeaderboard: {
		rank: 1,
		address: "0x62bc727318b6ac0ccb4403a19f92d16e65e67778",
		totalPoints: 44,
	},
	epochs: [
		{
			name: "Epoch 1",
			startAt: 1,
			endsAt: 1,
			totalPointsEarned: 82.219999999999998863131622783839702606201171875,
			challenges: [
				{
					type: "Play",
					description:
						"Points can be earned by playing the following games: Roulette, Flip Master, Dice, and Mines",
				},
			],
		},
	],
};

export default { response1 };
