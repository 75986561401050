import React from "react";
import { useDispatch } from "react-redux";

import { cx } from "src/utils";
import { Icon } from "@components/atoms";
import { rouletteSlice } from "src/redux/rouletteSlice";
import { Button } from "@components/molecules";
import { roulette } from "business-logic-gamblino";

const ControlButtons: React.FC<{ className?: string }> = ({ className }) => {
	const dispatch = useDispatch();

	const [wager, { isLoading }] = roulette.useWagerMutation({
		fixedCacheKey: "rouletteBusinessLogicApi.useWagerMutation",
	});

	return (
		<div
			className={cx(
				" my-auto flex grow items-center justify-center gap-6",
				className,
			)}
		>
			<Button
				type="tertiary"
				size="sm"
				disabled={isLoading}
				onClick={() => dispatch(rouletteSlice.actions.undo())}
				className="flex cursor-pointer items-center gap-1"
			>
				<div>
					<Icon type="UNDO" className="stroke-content-primary w-4" />
				</div>
				<p>Undo</p>
			</Button>

			<Button
				size="sm"
				type="tertiary"
				disabled={isLoading}
				onClick={() => dispatch(rouletteSlice.actions.reset())}
				className="flex cursor-pointer items-center gap-1"
			>
				<Icon type="RESET" className="stroke-content-primary w-4" />
				<p>Reset</p>
			</Button>
		</div>
	);
};

export { ControlButtons };
