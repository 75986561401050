/* eslint-disable @typescript-eslint/no-unused-vars */

import { isRejectedWithValue } from "@reduxjs/toolkit";
import type { MiddlewareAPI, Middleware } from "@reduxjs/toolkit";

export const toastMiddleware: Middleware =
	(api: MiddlewareAPI) => (next) => (action) => {
		if (isRejectedWithValue(action) && action?.payload) {
			// toast.error(action.payload);
		}

		return next(action);
	};
