import wait from "wait";
import React from "react";
import { rouletteBusinessLogicApi } from "business-logic-gamblino";

import { GameSection } from "./GameSection";
import { CONTRACT_ADDRESS_ROULETTE } from "src/config";
import { rouletteSlice } from "src/redux/rouletteSlice";
import { PastPredictionsSection } from "./PastPredictionsSection";
import { SimpleDashboardGameWithLoadingLayout } from "@components/layouts";
import { useAppDispatch } from "src/redux/store";
import { useAccount } from "wagmi";

const RoulettePage: React.FC<{ className?: string }> = ({ className }) => {
	const contractAddress = CONTRACT_ADDRESS_ROULETTE;
	const [isSuccess, setIsSuccess] = React.useState<boolean>(false);

	const { isConnected } = useAccount();

	const dispatch = useAppDispatch();

	React.useEffect(() => {
		void (async () => {
			await wait(1500);
			setIsSuccess(true);
		})();
	}, []);

	React.useEffect(() => {
		return () => {
			dispatch(rouletteBusinessLogicApi.util.resetApiState());
			dispatch(rouletteSlice.actions.reset());
		};
	}, []);

	React.useEffect(() => {
		if (isConnected) {
			return;
		}
		console.log("reset");

		dispatch(rouletteBusinessLogicApi.util.resetApiState());
		dispatch(rouletteSlice.actions.reset());
	}, [isConnected]);

	return (
		<SimpleDashboardGameWithLoadingLayout
			bgImageUrl="/games-bg.png"
			gameComponent={<GameSection contractAddress={contractAddress} />}
			isLoading={!isSuccess}
			loadingImageUrl="/game-cards/roulette-card.png"
		>
			<PastPredictionsSection
				className=""
				contractAddress={contractAddress}
			/>
		</SimpleDashboardGameWithLoadingLayout>
	);
};

export { RoulettePage };
