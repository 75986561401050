import { z } from "zod";

const schema = z
	.object({
		status: z.string(),
		meta: z.object({
			leaderboardRank: z.number(),
		}),
		leaderboard: z.array(
			z
				.object({
					game: z.enum(["sportsbook"]),
					totalVolume: z.number(),
					netProfit: z.number(),
					roundsPlayed: z.number(),
					wins: z.number(),
					losses: z.number(),
					streak: z.number(),
					topVictory: z
						.object({
							payout: z.number(),
							multiplier: z.number(),
							entry: z.number(),
						})
						.nullable(),
					fortunateVictory: z
						.object({
							payout: z.number(),
							multiplier: z.number(),
							entry: z.number(),
						})
						.nullable(),
				})
				.strict(),
		),
	})
	.strict();

export { schema };
