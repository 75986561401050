import { z } from "zod";

const schema = z.object({
	roullete: z.object({
		gamesPlayed: z.number(),
		wonGames: z.number(),
		lostGames: z.number(),
		uniquePlayers: z.number(),
		pnl: z.number(),
		totalWagered: z.number(),
		totalPayed: z.number(),
		fortunateVictory: z.object({
			payout: z.number(),
			multiplier: z.number(),
			entry: z.number(),
		}),
		topVictory: z.object({
			payout: z.number(),
			multiplier: z.number(),
			entry: z.number(),
		}),
	}),
	coinFlip: z.object({
		gamesPlayed: z.number(),
		wonGames: z.number(),
		lostGames: z.number(),
		uniquePlayers: z.number(),
		pnl: z.number(),
		totalWagered: z.number(),
		totalPayed: z.number(),
		fortunateVictory: z.object({
			payout: z.number(),
			multiplier: z.number(),
			entry: z.number(),
		}),
		topVictory: z.object({
			payout: z.number(),
			multiplier: z.number(),
			entry: z.number(),
		}),
	}),
	dice: z.object({
		gamesPlayed: z.number(),
		wonGames: z.number(),
		lostGames: z.number(),
		uniquePlayers: z.number(),
		pnl: z.number(),
		totalWagered: z.number(),
		totalPayed: z.number(),
		fortunateVictory: z.object({
			payout: z.number(),
			multiplier: z.number(),
			entry: z.number(),
		}),
		topVictory: z.object({
			payout: z.number(),
			multiplier: z.number(),
			entry: z.number(),
		}),
	}),
	mines: z.object({
		gamesPlayed: z.number(),
		wonGames: z.number(),
		lostGames: z.number(),
		uniquePlayers: z.number(),
		pnl: z.number(),
		totalWagered: z.number(),
		totalPayed: z.number(),
		fortunateVictory: z.object({
			payout: z.number(),
			multiplier: z.number(),
			entry: z.number(),
		}),
		topVictory: z.object({
			payout: z.number(),
			multiplier: z.number(),
			entry: z.number(),
		}),
	}),
	global: z.object({
		pnl: z.number(),
		totalWonGames: z.number(),
		totalLostGames: z.number(),
		allBetsCount: z.number(),
	}),
});

export { schema };
