import React from "react";
import { Icon, PageSection } from "@components/atoms";
import { cx } from "src/utils";
import { Button, Input } from "@components/molecules";
import { useNavigate } from "react-router-dom";
import { RabbitIllustration } from "./RabbitIllustration";
import { Background } from "./Background";
import { useModalManager } from "@components/ethereals/ModalsWrapper";
import { z } from "zod";

const HeroBox = () => {
	const { modalManager } = useModalManager();
	const [email, setEmail] = React.useState<string>("");

	const zodSchema = z.string().email();

	const val = zodSchema.safeParse(email);
	const navigate = useNavigate();
	return (
		<div className="bg-background-secondary flex flex-col items-center gap-10 rounded-[40px] p-8 sm:p-16 lg:flex-row lg:justify-between lg:gap-14 xl:items-end">
			<div className="flex shrink-0 flex-col items-center lg:max-w-[50%] lg:items-start">
				<h2 className="text-content-primary text-center text-5xl font-bold lg:text-left lg:text-7xl">
					Limitless Predictions
				</h2>
				<h3 className="text-gradient-1 whitespace-nowrap text-center text-3xl lg:text-left lg:text-5xl">
					Play, Win, Repeat
				</h3>
				<p className="text-content-secondary mt-4 text-center text-base lg:mt-6 lg:text-left lg:text-lg">
					Get ready to immerse yourself in a world of
					adrenaline-pumping bets and exhilarating gameplay.
				</p>
				<Input
					type="text"
					value={email}
					setValue={setEmail}
					placeholder="Enter an email"
					name={"Join Our Mailing List"}
					wrapperClassName="max-w-[400px] w-full mt-4"
					className="text-purple-dark"
				>
					<Input.Button
						type="gradient"
						onClick={() => {
							if (val.success) {
								modalManager.open("SubscribeToEmailListModal", {
									email: val.data,
								});
								setEmail("");
							}
						}}
						// type="outline"
						className=" h-[52px] self-center rounded-full"
						disabled={email !== "" && !val.success}
					>
						<Input.Button.Text>Notify me</Input.Button.Text>
						{/* <Input.Button.Icon
							type="ARROW_RIGHT"
							className="h-6 w-6 stroke-white"
						/> */}
					</Input.Button>
				</Input>
			</div>
			<div className="h-auto xl:h-[300px]">
				<RabbitIllustration className="w-full xl:w-[500px] xl:-translate-x-8 xl:-translate-y-[160px] 2xl:w-[560px] 2xl:-translate-x-0 2xl:-translate-y-[200px]" />
			</div>
		</div>
	);
};

const config: {
	text: string;
	iconType: React.ComponentPropsWithRef<typeof Icon>["type"];
}[] = [
	{
		text: "Provably fair",
		iconType: "DICES",
	},
	{
		text: "Trustless",
		iconType: "SHIELD_CHECK",
	},
	{
		text: "Permissionless",
		iconType: "GLOBE2",
	},
	{
		text: "Decentralized",
		iconType: "CUBOID",
	},
];

const HeroIcons: React.FC<{ className?: string }> = ({ className }) => (
	<div
		className={cx(
			"grid grid-cols-2 justify-items-center gap-6 lg:grid-cols-4",
			className,
		)}
	>
		{config.map(({ text, iconType }) => (
			<div
				key={text}
				className="flex flex-col items-center gap-1  lg:flex-row lg:gap-3"
			>
				<div className="bg-gradient-1 rounded-full p-2">
					<Icon
						className="stroke-content-primary h-5 w-5"
						type={iconType}
					/>
				</div>
				<p className="text-content-primary whitespace-nowrap text-center text-base font-medium lg:text-left">
					{text}
				</p>
			</div>
		))}
	</div>
);

const HeroSection: React.FC<{ className?: string }> = ({ className }) => {
	const ref = React.useRef<HTMLVideoElement>(null);

	React.useEffect(() => {
		if (ref.current) {
			ref.current.playbackRate = 2;
		}
	}, []);

	return (
		<PageSection
			fullWidth
			className={cx(
				"relative pb-8 pt-28 sm:pt-32 lg:pb-10 xl:pt-48",
				className,
			)}
		>
			<video
				ref={ref}
				src="/home-background-video.mp4"
				muted
				autoPlay
				loop
				className={cx(
					"pointer-events-none absolute inset-0 z-0 h-full w-full object-cover",
				)}
			></video>
			<div
				// style={{
				// 	background:
				// 		"linear-gradient(100deg, rgba(80, 103, 208, 0.50) 3.17%, rgba(170, 90, 252, 0.50) 62.22%)",
				// }}
				className="bg-background-primary absolute inset-0 z-0 h-full w-full"
			></div>
			{/* <Background className="absolute inset-0 z-0 h-full w-full" /> */}
			<PageSection className="relative z-[1]">
				<HeroBox />
				<HeroIcons className="mt-6 lg:mt-8" />
			</PageSection>
		</PageSection>
	);
};

export { HeroSection };
