import React from "react";
import { Address } from "viem";

import { FlipButton } from "./FlipButton";
import { PickCoinSide } from "./PickCoinSide";
import { CoinInputBet } from "./CoinInputBet";
import CoinSection from "./CoinSection/CoinSection";
import { TooltipNumberWrapper } from "@components/molecules";
import { AssetIcon, DashboardPageSection } from "@components/atoms";
import { MuteUnmuteButton, OpenContractButton } from "@components/organisms";
import { OpenGameStats } from "@components/organisms/OpenGameStats/OpenGameStats";

const GameSection: React.FC<{
	contractAddress: Address;
	className?: string;
}> = ({ contractAddress, className }) => {
	const [amount, setAmount] = React.useState<{
		valueWei: string;
		value: string;
	}>({
		valueWei: "0",
		value: "",
	});
	const [face, setFace] = React.useState<boolean>(true);

	const targetTokenPayout = parseFloat(amount.value) * 1.95 || 0;

	return (
		<>
			<DashboardPageSection className="z-1 relative flex items-center justify-between">
				<h1 className="text-3xl">Flip Master</h1>

				<div className="flex items-center gap-2">
					<MuteUnmuteButton />
					<OpenContractButton contractAddress={contractAddress} />
					<OpenGameStats />
					{/* <OpenHowToModalButton modalName="HowToPlayCoinFlipModal" /> */}
				</div>
			</DashboardPageSection>
			<DashboardPageSection className="mt-4 flex h-full flex-col items-center gap-4 md:flex-row">
				<div className="bg-background-secondary border-border-primary flex h-[366px] w-full flex-col items-center justify-between rounded-2xl border bg-opacity-90 py-10 backdrop-blur-md md:flex-auto md:basis-4/6">
					<CoinSection />
					<PickCoinSide
						face={face}
						onFaceChange={(selectedFace) => {
							setFace(selectedFace);
						}}
						className="mt-6"
					/>
				</div>
				<div className="bg-background-secondary border-border-primary flex h-[366px] w-full flex-col gap-4 rounded-2xl border p-4 md:w-auto md:basis-3/6">
					<div className="bg-background-tertiary flex flex-col items-center justify-center gap-1 rounded-lg p-4">
						<div className="flex items-center gap-2 text-3xl">
							<TooltipNumberWrapper
								number={targetTokenPayout}
								formatPriceConfig={{
									decimals: 2,
								}}
							/>
							<AssetIcon type={"SEI"} />
						</div>
						<p className="text-content-secondary text-sm">
							Target Payout
						</p>
					</div>
					<div className="flex w-full items-center gap-4">
						<div className="bg-background-tertiary flex flex-1 flex-col items-center justify-center gap-1 rounded-lg p-4">
							<div className="flex items-center gap-2 text-3xl">
								<TooltipNumberWrapper
									number={1.95}
									formatPriceConfig={{
										labelPosition: "right",
										currencyLabel: "x",
									}}
								/>
							</div>
							<p className="text-content-secondary text-sm">
								Multiplier
							</p>
						</div>
						<div className="bg-background-tertiary flex flex-1 flex-col items-center justify-center gap-1 rounded-lg p-4">
							<div className="flex items-center gap-2 text-3xl">
								<TooltipNumberWrapper
									number={50}
									formatPriceConfig={{
										labelPosition: "right",
										currencyLabel: "%",
									}}
								/>
							</div>
							<p className="text-content-secondary text-sm">
								Win Chance
							</p>
						</div>
					</div>
					<CoinInputBet
						face={face}
						amount={amount}
						onAmountChange={setAmount}
						className="w-full"
					/>
					<FlipButton
						contractAddress={contractAddress}
						amount={amount}
						face={face}
						className="w-full"
					/>
				</div>
			</DashboardPageSection>
		</>
	);
};

export { GameSection };
