import mockedData from "./mockedData";
import { schema } from "./schema";
import { createRequestQueryFunction } from "query-function-creators";

const subscribeToEmailList = createRequestQueryFunction({
	getAxiosRequestConfig: ({ email }: { email: string }) => ({
		url: `${import.meta.env.BACKEND_SERVICE_GAMBLINO_BASE_URL}/maillist`,
		method: "POST",
		data: { email },
	}),
	schema,
	getMockedData: () => mockedData.response1,
	isMockingEnabled: false,
});

export { subscribeToEmailList };
