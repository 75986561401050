import { z } from "zod";

const schema = z
	.object({
		status: z.string(),
		stats: z
			.object({
				"allTime": z
					.object({
						uniquePlayersCount: z.number(),
						betsPlacedCount: z.number(),
						winningBetsCount: z.number(),
						totalVolume: z.number(),
					})
					.strict(),
				"24Hours": z
					.object({
						uniquePlayersCount: z.number(),
						betsPlacedCount: z.number(),
						winningBetsCount: z.number(),
						totalVolume: z.number(),
					})
					.strict(),
			})
			.strict(),
		leaderboard: z.array(
			z.object({
				user: z.string(),
				totalVolume: z.number(),
				netProfit: z.number(),
				wins: z.number(),
				losses: z.number(),
				streak: z.number(),
				rank: z.number(),
				score: z.number(),
				roundsPlayed: z.number(),
			}),
		),
	})
	.strict();

export { schema };
