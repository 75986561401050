export type RouletteItem = {
	value: string;

	type: RouletteItemType;
};

type RouletteActionItem = RouletteItem & {
	action: string;
};
type RouletteItemType = "RED" | "BLACK" | "DEFAULT" | "GREEN";

const ZERO: RouletteItem = {
	value: "0",
	type: "GREEN",
};

const FIRST_GROUP: RouletteItem[] = [
	{
		value: "3",
		type: "RED",
	},
	{
		value: "6",
		type: "BLACK",
	},
	{
		value: "9",
		type: "RED",
	},
	{
		value: "12",
		type: "RED",
	},
	{
		value: "15",
		type: "BLACK",
	},
	{
		value: "18",
		type: "RED",
	},
	{
		value: "21",
		type: "RED",
	},
	{
		value: "24",
		type: "BLACK",
	},
	{
		value: "27",
		type: "RED",
	},
	{
		value: "30",
		type: "RED",
	},
	{
		value: "33",
		type: "BLACK",
	},
	{
		value: "36",
		type: "RED",
	},
];

const SECOND_GROUP: RouletteItem[] = [
	{
		value: "2",
		type: "BLACK",
	},
	{
		value: "5",
		type: "RED",
	},
	{
		value: "8",
		type: "BLACK",
	},
	{
		value: "11",
		type: "BLACK",
	},
	{
		value: "14",
		type: "RED",
	},
	{
		value: "17",
		type: "BLACK",
	},
	{
		value: "20",
		type: "BLACK",
	},
	{
		value: "23",
		type: "RED",
	},
	{
		value: "26",
		type: "BLACK",
	},
	{
		value: "29",
		type: "BLACK",
	},
	{
		value: "32",
		type: "RED",
	},
	{
		value: "35",
		type: "BLACK",
	},
];

const THIRD_GROUP: RouletteItem[] = [
	{
		value: "1",
		type: "RED",
	},
	{
		value: "4",
		type: "BLACK",
	},
	{
		value: "7",
		type: "RED",
	},
	{
		value: "10",
		type: "BLACK",
	},
	{
		value: "13",
		type: "BLACK",
	},
	{
		value: "16",
		type: "RED",
	},
	{
		value: "19",
		type: "RED",
	},
	{
		value: "22",
		type: "BLACK",
	},
	{
		value: "25",
		type: "RED",
	},
	{
		value: "28",
		type: "BLACK",
	},
	{
		value: "31",
		type: "BLACK",
	},
	{
		value: "34",
		type: "RED",
	},
];

const ROW_SELECTION: RouletteActionItem[] = [
	{
		value: "2:1",
		type: "DEFAULT",
		action: "FIRST_ROW",
	},
	{
		value: "2:1",
		type: "DEFAULT",
		action: "SECOND_ROW",
	},
	{
		value: "2:1",
		type: "DEFAULT",
		action: "THIRD_ROW",
	},
];

const SELECTION_FIRST_GROUP: RouletteActionItem[] = [
	{
		value: "1 TO 12",
		type: "DEFAULT",
		action: "1_TO_12",
	},
	{
		value: "13 TO 24",
		type: "DEFAULT",
		action: "13_TO_24",
	},
	{
		value: "25 TO 36",
		type: "DEFAULT",
		action: "25_TO_36",
	},
];

const SELECTION_SECOND_GROUP: RouletteActionItem[] = [
	{
		value: "1 TO 18",
		type: "DEFAULT",
		action: "FIRST_HALF",
	},
	{
		value: "EVEN",
		type: "DEFAULT",
		action: "EVEN",
	},
	{
		value: "",
		type: "RED",
		action: "RED",
	},
	{
		value: "",
		type: "BLACK",
		action: "BLACK",
	},
	{
		value: "ODD",
		type: "DEFAULT",
		action: "ODD",
	},
	{
		value: "19 to 36",
		type: "DEFAULT",
		action: "SECOND_HALF",
	},
];

const RED_NUMBERS = [...FIRST_GROUP, ...SECOND_GROUP, ...THIRD_GROUP].filter(
	(item) => item.type === "RED",
);
const BLACK_NUMBERS = [...FIRST_GROUP, ...SECOND_GROUP, ...THIRD_GROUP].filter(
	(item) => item.type === "BLACK",
);

const FIRST_12 = [
	"1",
	"2",
	"3",
	"4",
	"5",
	"6",
	"7",
	"8",
	"9",
	"10",
	"11",
	"12",
];

const FROM_13_TO_24 = [
	"13",
	"14",
	"15",
	"16",
	"17",
	"18",
	"19",
	"20",
	"21",
	"22",
	"23",
	"24",
];

const FROM_25_36 = [
	"25",
	"26",
	"27",
	"28",
	"29",
	"30",
	"31",
	"32",
	"33",
	"34",
	"35",
	"36",
];

const FIRST_HALF = [
	"1",
	"2",
	"3",
	"4",
	"5",
	"6",
	"7",
	"8",
	"9",
	"10",
	"11",
	"12",
	"13",
	"14",
	"15",
	"16",
	"17",
	"18",
];

const SECOND_HALF = [
	"19",
	"20",
	"21",
	"22",
	"23",
	"24",
	"25",
	"26",
	"27",
	"28",
	"29",
	"30",
	"31",
	"32",
	"33",
	"34",
	"35",
	"36",
];

const ODD_NUMBERS = [
	"1",
	"3",
	"5",
	"7",
	"9",
	"11",
	"13",
	"15",
	"17",
	"19",
	"21",
	"23",
	"25",
	"27",
	"29",
	"31",
	"33",
	"35",
];

const EVEN_NUMBERS = [
	"2",
	"4",
	"6",
	"8",
	"10",
	"12",
	"14",
	"16",
	"18",
	"20",
	"22",
	"24",
	"26",
	"28",
	"30",
	"32",
	"34",
	"36",
];

export {
	ZERO,
	EVEN_NUMBERS,
	FIRST_12,
	ODD_NUMBERS,
	FIRST_HALF,
	SECOND_HALF,
	FROM_25_36,
	RED_NUMBERS,
	FROM_13_TO_24,
	BLACK_NUMBERS,
	FIRST_GROUP,
	SECOND_GROUP,
	THIRD_GROUP,
	ROW_SELECTION,
	SELECTION_FIRST_GROUP,
	SELECTION_SECOND_GROUP,
};
