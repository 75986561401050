import mockedData from "./mockedData";
import { schema } from "./schema";
import { createRequestQueryFunction } from "query-function-creators";

const getPromotions = createRequestQueryFunction({
	getAxiosRequestConfig: () => ({
		url: `${import.meta.env.BACKEND_SERVICE_GAMBLINO_BASE_URL}/quests`,
	}),
	schema,
	getMockedData: () => mockedData.response1,
	isMockingEnabled: false,
});

export { getPromotions };
