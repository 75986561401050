import React from "react";
import { Button } from "@components/molecules";
import { useModalManager } from "@components/ethereals/ModalsWrapper";
import { type IModal } from "@components/organisms";
import { Modal } from "@components/organisms";

const SuccessSimpleModal: IModal<"SuccessSimpleModal"> &
	React.FC<{
		heading: string;
		description: string;
	}> = ({ heading, description }) => {
	const { modalManager } = useModalManager();

	return (
		<Modal className="bg-background-secondary relative w-full max-w-[350px] overflow-hidden rounded-xl">
			<Modal.Step>
				<div className="flex flex-col items-center gap-3 p-6">
					<img
						className="h-64 w-64"
						src="/success-illustration.png"
						alt=""
					/>
					<h3 className="text-content-primary text-center text-lg font-semibold">
						{heading}
					</h3>
					<p className="text-content-primary text-center text-sm">
						{description}
					</p>
					<Button
						size="sm"
						type="primary"
						onClick={() => {
							modalManager.close("SuccessSimpleModal");
						}}
						className="w-full"
					>
						<Button.Text>Close</Button.Text>
					</Button>
				</div>
			</Modal.Step>
		</Modal>
	);
};

SuccessSimpleModal.modalName = "SuccessSimpleModal";

export { SuccessSimpleModal };
