import { useTokensPriceQuery } from "business-logic-gamblino";
import { useRouletteSelectedNumbers } from "src/redux/useRouletteSelectedNumbers";

const calculateChance = (selectedNumbers: number) => {
	if (!selectedNumbers) return 0;

	const calcChance = (selectedNumbers / 37) * 100;

	if (calcChance === Infinity) return 0;

	return calcChance;
};

const calculateMultiplier = (selectedNumbers: number) => {
	const calcMultiplier = 36 / selectedNumbers;
	if (calcMultiplier === Infinity) return 0;

	return calcMultiplier;
};

const calculatePayout = (
	multiplier: number,
	amount: string,
	ethPrice: number,
) => {
	const calcPayout = multiplier * parseFloat(amount) * ethPrice;

	if (calcPayout === Infinity) return 0;

	return calcPayout || 0;
};

const useRouletteSelectionStats = (amount: string) => {
	const { selectedNumbers } = useRouletteSelectedNumbers();

	const { data } = useTokensPriceQuery(undefined, {
		pollingInterval: 30000,
	});

	const chance = calculateChance(selectedNumbers.length);
	const multiplier = calculateMultiplier(selectedNumbers.length);
	const payout = calculatePayout(multiplier, amount, data?.prices.sei || 0);

	return { multiplier, payout, chance };
};
export { useRouletteSelectionStats };
