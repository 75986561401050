import { Address, formatUnits, parseGwei } from "viem";

import { estimateGas, getAccount } from "wagmi/actions";
import { wagmiConfig } from "wallet-connection";
const calculateGasEstimate = async ({
	functionData,
	to,
	value,
}: {
	functionData: Address;
	to: Address;
	value?: bigint;
}) => {
	const { address } = getAccount(wagmiConfig);

	if (value?.toString() === "100000000000000001") {
		return {
			value: "0",
			valueWei: "0",
		};
	}

	if (!address) {
		throw new Error("Wallet not connected or chainId wasn't found");
	}

	try {
		const gasLimit = await estimateGas(wagmiConfig, {
			account: address,
			data: functionData,
			to,
			value,
		});

		const gasPrice = parseGwei("1.5");

		const transactionFeeWei = gasPrice * gasLimit;

		const transactionFee = formatUnits(transactionFeeWei, 18);

		return {
			value: transactionFee || "0",
			valueWei: transactionFeeWei.toString() || "0n",
		};
	} catch (error) {
		console.error(error);

		return {
			value: "0",
			valueWei: "0",
		};
	}
};

export { calculateGasEstimate };
