import { TooltipNumberWrapper } from "@components/molecules";
import React from "react";
import { AssetIconRoulette } from "src/Pages/RoulettePage/AssetIconRoulette";

const ProfitCell: React.FC<{
	profit: number;
}> = ({ profit }) => {
	return (
		<div className="flex items-center gap-1">
			<AssetIconRoulette className="h-5 w-5" />
			<span
				className={`${
					profit >= 0
						? "text-badge-content-success"
						: "text-content-badge-error"
				}`}
			>
				{profit >= 0 ? "+" : "-"}
				<TooltipNumberWrapper number={Math.abs(profit)} copy />
			</span>
		</div>
	);
};

export { ProfitCell };
