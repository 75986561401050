import React from "react";
import { audioManagerSlice } from "./audioSlice";
import { useAppDispatch, useAppSelector } from "./store";

const useAudioManager = () => {
	const { isMuted, activeAudioResource } = useAppSelector(
		(state) => state.audioManager,
	);
	const dispatch = useAppDispatch();

	const mute = React.useCallback(() => {
		dispatch(audioManagerSlice.actions.mute());
	}, []);

	const unmute = React.useCallback(() => {
		dispatch(audioManagerSlice.actions.unmute());
	}, []);

	const setActiveAudioResource = React.useCallback(
		(activeAudioResource: string) => {
			dispatch(
				audioManagerSlice.actions.setActiveAudioResource(
					activeAudioResource,
				),
			);
		},
		[],
	);

	const playSoundOnce = React.useCallback(
		(audioResource: string) => {
			if (isMuted) {
				return;
			}
			const sound = new Audio(audioResource);
			sound.volume = 0.2;
			sound.play();
		},
		[isMuted],
	);

	return {
		isMuted,
		activeAudioResource,
		mute,
		unmute,
		setActiveAudioResource,
		playSoundOnce,
	};
};

export { useAudioManager };
