import { z } from "zod";

const schema = z
	.object({
		status: z.string(),
		quests: z.array(
			z
				.object({
					backgroundImg: z.string(),
					xpReward: z.string(),
					title: z.string(),
					description: z.string(),
					questId: z.string(),
					tag: z.string(),
					completed: z.boolean(),
				})
				.strict(),
		),
	})
	.strict();

export { schema };
