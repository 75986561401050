import React from "react";
import { motion } from "framer-motion";

import { EmptyTab } from "../../EmptyTab";
import { BetSlipItem } from "../BetSlipItem";
import { useAppSelector } from "src/redux/store";
import { cx } from "src/utils";

const emptyState = {
	title: "Empty Betslip",
	imgSrc: "/sportsbook/betslip.png",
	subtitle:
		"To create betslip, select a market and your bet will be added here",
};

const NoData = () => {
	return (
		<div className="mx-auto flex h-[210px] max-w-[258px] items-center lg:h-[250px]">
			<EmptyTab
				imgSrc={emptyState.imgSrc}
				title={emptyState.title}
				subtitle={emptyState.subtitle}
			/>
		</div>
	);
};

const BetSlipList: React.FC<{ className?: string }> = ({ className }) => {
	const bets = useAppSelector((state) => state.azuro);

	if (!bets || bets?.length === 0) {
		return <NoData />;
	}

	return (
		<div
			className={cx(
				"flex h-[210px] flex-col items-center gap-2 overflow-auto px-4 lg:h-[240px] lg:px-2",
				className,
			)}
		>
			{bets.map((bet) => (
				<motion.div
					className="min-h-[56px] w-full shrink-0"
					key={bet?.conditionId}
					initial={{ opacity: 0 }}
					animate={{ opacity: 1, y: 10 }}
				>
					<BetSlipItem bet={bet} />
				</motion.div>
			))}
		</div>
	);
};

export { BetSlipList };
