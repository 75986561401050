import React from "react";
import dayjs from "dayjs";
import { cx } from "src/utils";

const ScoreCell: React.FC<{
	isWin: boolean;
}> = ({ isWin }) => {
	return (
		<p
			className={cx("", {
				"text-badge-content-success": isWin,
				"text-content-badge-error": !isWin,
			})}
		>
			{isWin ? "Win" : "Lost"}
		</p>
	);
};

export { ScoreCell };
