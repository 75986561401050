import React from "react";
import { type IModal } from "@components/organisms";
import { Modal, ModalButtons } from "@components/organisms";
import { cx } from "src/utils";

const HowToPlayDiceModal: IModal<"HowToPlayDiceModal"> & React.FC = () => (
	<Modal className="bg-background-secondary border-violet-light/10 relative w-full max-w-[524px] overflow-hidden rounded-xl border p-6">
		<Modal.Step>
			<div className="flex flex-col gap-6">
				<p className="text-lg font-semibold">How to play Dice?</p>
				<ModalButtons />
				<img
					className="w-full rounded-lg"
					src="/how-to-play-modals/dice/dice-1.png"
					alt=""
				/>

				<p>
					Enter the amount you wish to wager. Wishing you the very
					best of fortune's grace. 🍀
				</p>
			</div>
		</Modal.Step>
		<Modal.Step>
			<div className="flex flex-col gap-6">
				<p className="text-lg font-semibold">How to play Dice?</p>
				<ModalButtons />
				<img
					className="w-full rounded-lg"
					src="/how-to-play-modals/dice/dice-2.png"
					alt=""
				/>

				<p>
					Decide your prediction by sliding the dice 🎲 to the ⬅️ left
					or right ➡️, or by entering a number into the "roll over"
					field to indicate the threshold you believe will be
					surpassed.
				</p>
			</div>
		</Modal.Step>
		<Modal.Step>
			<div className="flex flex-col gap-6">
				<p className="text-lg font-semibold">How to play Dice?</p>
				<ModalButtons />
				<img
					className="w-full rounded-lg"
					src="/how-to-play-modals/dice/dice-3.png"
					alt=""
				/>

				<p>
					Once the transaction has been submitted to the blockchain,
					please wait for it to process and generate a random result
					using Chainlink VRF.{" "}
				</p>
			</div>
		</Modal.Step>
		<Modal.Step>
			<div className="flex flex-col gap-6">
				<p className="text-lg font-semibold">How to play Dice?</p>
				<ModalButtons />
				<img
					className="w-full rounded-lg"
					src="/how-to-play-modals/dice/dice-4.png"
					alt=""
				/>

				<p>
					When the round concludes, a popup will appear to inform you
					of the outcome, indicating whether you have won or lost.{" "}
				</p>
			</div>
		</Modal.Step>
		<Modal.Step>
			<div className="flex flex-col gap-6">
				<p className="text-lg font-semibold">How to play Dice?</p>
				<ModalButtons />
				<img
					className="w-full rounded-lg"
					src="/how-to-play-modals/dice/dice-5.png"
					alt=""
				/>

				<p>
					💰Rewards are promptly allocated within the same transaction
					as your "Roll," eliminating the need for separate claims.
					However, you still have the option to review the historical
					record of your Rolls. 📈
				</p>
			</div>
		</Modal.Step>
	</Modal>
);

HowToPlayDiceModal.modalName = "HowToPlayDiceModal";

export { HowToPlayDiceModal };
