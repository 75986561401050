import { AnimatedIllustration } from "@components/organisms";
import React from "react";
import { cx } from "src/utils";

const FullScreenLoader: React.FC<{ imageUrl: string; className?: string }> = ({
	imageUrl,
	className,
}) => {
	return (
		<div
			className={cx(
				" bg-background-primary relative flex h-full min-h-screen flex-col items-center justify-center gap-4 overflow-hidden",
				className,
			)}
		>
			{/* <AnimatedIllustration
				animationDataPath="/liquid-spinner.json"
				className="absolute left-1/2 top-1/2 z-0 -translate-x-1/2 -translate-y-[0%]"
			/> */}
			<AnimatedIllustration
				animationDataPath="/liquid-spinner.json"
				className="absolute inset-0 w-full scale-125"
			/>
			<div className="flex flex-col items-center">
				<img
					className="z-1 h-[200px] w-[200px] animate-[bounce_1s_infinite] rounded-3xl md:h-[400px] md:w-[400px]"
					src={imageUrl}
					alt=""
				/>
				<h2 className="text-2xl font-bold">Loading...</h2>
			</div>
			{/* <AnimatedIllustration
				animationDataPath="/liquid-spinner.json"
				className="h-full"
			/> */}
		</div>
	);
};

export { FullScreenLoader };
