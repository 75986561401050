import { useAppDispatch, useAppSelector } from "./store";
import { rouletteSlice } from "./rouletteSlice";

const useRouletteSelectedNumbers = () => {
	const selectedNumbers = useAppSelector(
		(state) => state.roulette.selectedNumbers,
	);

	const dispatch = useAppDispatch();
	const toggleSingleSelection = (value: string) => {
		dispatch(rouletteSlice.actions.toggleSingleSelection(value));
	};

	const toggleRowSelection = (value: string) => {
		dispatch(rouletteSlice.actions.toggleRowSelection(value));
	};

	const toggleGroupSelection = (value: string) =>
		dispatch(rouletteSlice.actions.toggleGroupSelection(value));

	return {
		selectedNumbers,
		toggleRowSelection,
		toggleGroupSelection,
		toggleSingleSelection,
	};
};

export { useRouletteSelectedNumbers };
