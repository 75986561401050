import { Icon, PageSection } from "@components/atoms";
import { AnimateChangeInHeight } from "@components/organisms";
import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";
import { cx } from "src/utils";

const FAQData: { question: string; answer: string }[] = [
	{
		question: "How does the prediction market work?",
		answer: "Prediction market operates by allowing users to forecast the future prices of various cryptocurrencies. Users choose whether the price will be up or down at the end of the round. Before the round starts the payout multiple will be calculated based on counter predictions in that round.",
	},
	{
		question:
			"What measures are in place to ensure the reliability and accuracy of randomness on Gamblino?",
		answer: "Gamblino is committed to providing an exceptional gaming experience that is characterized by fairness and trustworthiness, thanks to the integration of Blockchain Technology. Through our partnership with Chainlink, a prominent provider of secure and dependable data feeds for smart contracts, we harness their VRF. This guarantees that our games are demonstrably fair, featuring randomness that is both tamper-proof and verifiable.",
	},
	{
		question:
			"How are rewards distributed to users who make accurate predictions?",
		answer: "Users who make accurate predictions are rewarded based on the amount of volume added to the counter prediction during the round. When a prediction market closes, users who have chosen the correct outcome receive a payout via the claim button. The final payout multiplier will be shown before the round ends.",
	},
	{
		question:
			"Can users create their own prediction markets or propose new cryptocurrency prediction events on this platform?",
		answer: "Yes, this will be possible following the development of synthetic betting pools and the selection of domain experts by the community. Users will then have the opportunity to propose new markets or events. This feature will grow the amount of prediction markets through a community-driven approach.",
	},
	{
		question: "How does Sportsbook work? Is it decentralized?",
		answer: "Our Sportsbook operates through the Azuro protocol, which is a decentralized platform for betting. Users can place bets on a variety of sports events, and all transactions are securely recorded on the blockchain for transparency. The betting process is managed by smart contracts, ensuring fair and verifiable outcomes. Funds are handled in cryptocurrencies, allowing for quick and efficient transactions. For detailed instructions and rules, users can refer to our betting guide and terms of service.",
	},
	{
		question: "Is there going to be a Gamblino token?",
		answer: "Yes, stay tuned with the latest updates. Announcements soon.",
	},
	{
		question: "Is there going to be any revenue sharing for $GLO holders?",
		answer: "Yes. $GLO token is revenue sharing token, where each token holder/staker will be exposed to earning part of house edge. More details available under https://docs.gamblino.app",
	},
];

const Question: React.FC<{
	question: string;
	answer: string;
	isActive: boolean;
	onClick: () => void;
	onClickActive: () => void;
}> = ({ answer, question, isActive, onClick, onClickActive }) => {
	return (
		<AnimatePresence>
			<motion.div
				onClick={() => {
					if (isActive) {
						onClickActive();
					} else {
						onClick();
					}
				}}
				className="bg-background-secondary border-border-primary relative line-clamp-1 flex cursor-pointer flex-col rounded-lg border px-6 py-4"
			>
				<AnimateChangeInHeight duration={0.6} className="z-[1]">
					<div className="z-[1] flex items-center justify-between">
						<h5 className="select-none text-lg font-semibold">
							{question}
						</h5>
						<Icon
							type={isActive ? "X" : "PLUS_CIRCLE"}
							className=" stroke-content-primary h-5 w-5 shrink-0 transition-all duration-[600ms]"
							style={{ rotate: !isActive ? "0deg" : "90deg" }}
						/>
					</div>
					{isActive && (
						<p className="z-1 text-content-secondary mt-3  select-none">
							{answer}
						</p>
					)}
				</AnimateChangeInHeight>
			</motion.div>
		</AnimatePresence>
	);
};

const FaqSection: React.FC<{ className?: string }> = ({ className }) => {
	const [activeId, setActiveId] = useState(-1);
	return (
		<PageSection
			className={cx(
				"flex flex-col items-start gap-6 md:flex-row",
				className,
			)}
		>
			<div className="md:grow md:basis-1/2 md:gap-10">
				<img className="max-w-[300px]" src="/faq-image.png" alt="" />
				<p className="text-content-primary mt-4 text-5xl font-semibold">
					FAQ
				</p>
				<p className="text-content-secondary mt-4 max-w-[450px]">
					Valuable resource for users seeking quick and concise
					answers to common queries.
				</p>
			</div>
			<div className="bg-rotate flex w-full flex-col gap-3 rounded-3xl bg-gradient-to-t md:shrink-0 md:grow md:basis-1/2">
				{FAQData.map((q, index) => (
					<Question
						{...q}
						onClick={() => setActiveId(index)}
						onClickActive={() => setActiveId(-1)}
						isActive={activeId === index}
						key={q.answer}
					/>
				))}
			</div>
		</PageSection>
	);
};

export { FaqSection };
