import React, { useMemo } from "react";
import { cx } from "src/utils";
import { getAvatarUrl } from "wallet-connection";

const Avatar = ({
	userAddress,
	className,
}: {
	userAddress: string;
	className?: string;
}) => {
	const avatarUrl = useMemo(() => {
		return getAvatarUrl(userAddress);
	}, [userAddress]);

	return (
		<img
			alt="User's Avatar"
			src={avatarUrl}
			className={cx("aspect-square w-10 rounded-full", className)}
		/>
	);
};

export { Avatar };
