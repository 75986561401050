import React from "react";
import { Popover } from "@headlessui/react";

import { cx } from "src/utils";
import { AssetIcon, Icon, Tooltip } from "@components/atoms";
import { useLocation } from "react-router-dom";
import { TooltipNumberWrapper } from "@components/molecules";
import {
	gamblinoBusinessLogicApi,
	useGetGamesStatsQuery,
} from "business-logic-gamblino";

const getCurrentGameName = (pathname: string) => {
	if (pathname.includes("roulette")) {
		return "Roulette";
	}
	if (pathname.includes("mines")) {
		return "Mines";
	}
	if (pathname.includes("dice")) {
		return "Dice";
	}
	if (pathname.includes("flip-master")) {
		return "Flip Master";
	}
	return "";
};

const getCurrentGame = (
	pathname: string,
	data?: (typeof gamblinoBusinessLogicApi)["endpoints"]["getGamesStats"]["Types"]["ResultType"],
) => {
	if (pathname.includes("roulette")) {
		return data?.roullete;
	}
	if (pathname.includes("mines")) {
		return data?.mines;
	}
	if (pathname.includes("dice")) {
		return data?.dice;
	}
	if (pathname.includes("flip-master")) {
		return data?.coinFlip;
	}
	return undefined;
};

const OpenGameStats = ({ className }: { className?: string }) => {
	const location = useLocation();

	const { data, isLoading, isError } = useGetGamesStatsQuery(undefined);

	const currentGameName = getCurrentGameName(location.pathname);

	const currentGame = getCurrentGame(location.pathname, data);
	return (
		<Popover className="relative z-[99999]">
			{({ open, close }) => (
				<>
					<Popover.Button>
						<Tooltip content="Open Game Stats">
							<div
								className={cx(
									"bg-background-secondary border-border-primary flex h-10 w-10 cursor-pointer items-center justify-center rounded-full border",
									className,
								)}
							>
								<Icon
									type="STATS"
									className="stroke-content-primary h-6 w-6"
								/>
							</div>
						</Tooltip>
					</Popover.Button>
					<Popover.Panel className="bg-background-tertiary border-border-primary  absolute right-0  z-[99999] mt-3 min-w-[300px] rounded-xl border md:min-w-[400px]">
						<div className="text-content-primary border-border-primary flex justify-between border-b p-4">
							<p className="text-content-primary text-base">
								{currentGameName} Game Stats
							</p>
							<Icon
								type="X"
								onClick={close}
								className="stroke-content-secondary size-6 w-6 cursor-pointer"
							/>
						</div>
						<div className="grid grid-cols-2 gap-2 p-4">
							<div className="bg-background-secondary h-[56px] rounded-xl p-2">
								<div className="flex justify-between">
									<div className="flex flex-col gap-2">
										<p className="text-content-secondary text-xs">
											Players
										</p>
										<p className="text-sm">
											<TooltipNumberWrapper
												number={
													currentGame?.uniquePlayers
												}
												formatPriceConfig={{
													decimals: 0,
												}}
											/>
										</p>
									</div>
									<img
										src="/stats/players.png"
										className="size-10"
									/>
								</div>
							</div>
							<div className="bg-background-secondary h-[56px] rounded-xl p-2">
								<div className="flex justify-between">
									<div className="flex flex-col gap-2">
										<p className="text-content-secondary text-xs">
											Bets
										</p>
										<p className="text-sm">
											<TooltipNumberWrapper
												number={
													currentGame?.gamesPlayed
												}
												formatPriceConfig={{
													decimals: 0,
												}}
											/>
										</p>
									</div>
									<img
										src="/stats/bets.png"
										className="size-10"
									/>
								</div>
							</div>
							<div className="bg-background-secondary h-[56px] rounded-xl p-2">
								<div className="flex justify-between">
									<div className="flex flex-col gap-2">
										<p className="text-content-secondary text-xs">
											Won Bets
										</p>
										<p className="text-sm">
											<TooltipNumberWrapper
												number={currentGame?.wonGames}
												formatPriceConfig={{
													decimals: 0,
												}}
											/>
										</p>
									</div>
									<img
										src="/stats/wins.png"
										className="size-10"
									/>
								</div>
							</div>
							<div className="bg-background-secondary h-[56px] rounded-xl p-2">
								<div className="flex justify-between">
									<div className="flex flex-col gap-2">
										<p className="text-content-secondary text-xs">
											Lost Bets
										</p>
										<p className="text-sm">
											<TooltipNumberWrapper
												number={currentGame?.lostGames}
												formatPriceConfig={{
													decimals: 0,
												}}
											/>
										</p>
									</div>
									<img
										src="/stats/lost.png"
										className="size-10"
									/>
								</div>
							</div>
							<div className="bg-background-secondary h-[56px] rounded-xl p-2">
								<div className="flex justify-between">
									<div className="flex flex-col gap-2">
										<p className="text-content-secondary text-xs">
											Wagered
										</p>
										<div className="flex items-center gap-1 text-sm">
											<TooltipNumberWrapper
												number={
													currentGame?.totalWagered
												}
											/>
											<AssetIcon
												type={"SEI"}
												className="h-4 w-4"
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="bg-background-secondary h-[56px] rounded-xl p-2">
								<div className="flex justify-between">
									<div className="flex flex-col gap-2">
										<p className="text-content-secondary text-xs">
											Payout
										</p>
										<div className="flex items-center gap-1 text-sm">
											<TooltipNumberWrapper
												number={currentGame?.totalPayed}
											/>
											<AssetIcon
												type={"SEI"}
												className="h-4 w-4"
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="border-border-primary mx-4 border-t"></div>
						<div className="flex w-full flex-col gap-4 p-4">
							<div className="bg-background-secondary  rounded-xl p-2">
								<p className="text-content-primary">
									Top victory
								</p>
								<div className="grid grid-cols-3 gap-2">
									<div className="bg-background-tertiary mt-2 h-[56px] rounded-xl p-2">
										<div className="flex justify-between">
											<div className="flex flex-col gap-2">
												<p className="text-content-secondary text-xs">
													Payout
												</p>
												<div className="flex items-center gap-1 text-sm">
													<TooltipNumberWrapper
														number={
															currentGame
																?.topVictory
																.payout
														}
													/>
													<AssetIcon
														type={"SEI"}
														className="h-4 w-4"
													/>
												</div>
											</div>
										</div>
									</div>
									<div className="bg-background-tertiary mt-2 h-[56px] rounded-xl p-2">
										<div className="flex justify-between">
											<div className="flex flex-col gap-2">
												<p className="text-content-secondary text-xs">
													Multiplier
												</p>
												<div className="flex items-center gap-1 text-sm">
													<TooltipNumberWrapper
														number={
															currentGame
																?.topVictory
																.multiplier
														}
													/>
													<AssetIcon
														type={"SEI"}
														className="h-4 w-4"
													/>
												</div>
											</div>
										</div>
									</div>
									<div className="bg-background-tertiary mt-2 h-[56px] rounded-xl p-2">
										<div className="flex justify-between">
											<div className="flex flex-col gap-2">
												<p className="text-content-secondary text-xs">
													Entry
												</p>
												<div className="flex items-center gap-1 text-sm">
													<TooltipNumberWrapper
														number={
															currentGame
																?.topVictory
																.entry
														}
													/>
													<AssetIcon
														type={"SEI"}
														className="h-4 w-4"
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="bg-background-secondary  rounded-xl p-2">
								<p className="text-content-primary">
									Fortunate victory
								</p>
								<div className="grid grid-cols-3 gap-2">
									<div className="bg-background-tertiary mt-2 h-[56px] rounded-xl p-2">
										<div className="flex justify-between">
											<div className="flex flex-col gap-2">
												<p className="text-content-secondary text-xs">
													Payout
												</p>
												<div className="flex items-center gap-1 text-sm">
													<TooltipNumberWrapper
														number={
															currentGame
																?.fortunateVictory
																.multiplier
														}
													/>
													<AssetIcon
														type={"SEI"}
														className="h-4 w-4"
													/>
												</div>
											</div>
										</div>
									</div>
									<div className="bg-background-tertiary mt-2 h-[56px] rounded-xl p-2">
										<div className="flex justify-between">
											<div className="flex flex-col gap-2">
												<p className="text-content-secondary text-xs">
													Multiplier
												</p>
												<div className="flex items-center gap-1 text-sm">
													<TooltipNumberWrapper
														number={
															currentGame
																?.fortunateVictory
																.multiplier
														}
													/>
												</div>
											</div>
										</div>
									</div>
									<div className="bg-background-tertiary mt-2 h-[56px] rounded-xl p-2">
										<div className="flex justify-between">
											<div className="flex flex-col gap-2">
												<p className="text-content-secondary text-xs">
													Entry
												</p>
												<div className="flex items-center gap-1 text-sm">
													<TooltipNumberWrapper
														number={
															currentGame
																?.fortunateVictory
																.entry
														}
													/>
													<AssetIcon
														type={"SEI"}
														className="h-4 w-4"
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</Popover.Panel>
				</>
			)}
		</Popover>
	);
};

export { OpenGameStats };
