import mockedData from "./mockedData";
import { schema } from "./schema";
import { createRequestQueryFunction } from "query-function-creators";

const getTokensPrice = createRequestQueryFunction({
	getAxiosRequestConfig: () => ({
		url: `${import.meta.env.BACKEND_SERVICE_GAMBLINO_BASE_URL}/prices`,
	}),
	schema,
	getMockedData: () => mockedData.response1,
	isMockingEnabled: false,
});

export { getTokensPrice };
