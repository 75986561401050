import type { schema } from "./schema";
import type { z } from "zod";

type ModelType = z.infer<typeof schema>;

const response1: ModelType = {
	status: "ok",
};

export default { response1 };
