import { Address } from "viem";
import { getMaxBetAmount as getMaxBetAmountContract } from "contract-service-gamblino-bank";

const getMaxBetAmount = async ({
	bankContractAddress,
	multiplier,
}: {
	bankContractAddress: Address;
	multiplier: number;
}) => {
	const { maxBetAmount, maxBetAmountWei } = await getMaxBetAmountContract({
		bankContractAddress,
		multiplier: multiplier * 10 ** 4,
	});

	return { maxBetAmount, maxBetAmountWei };
};

export { getMaxBetAmount };
