import React from "react";
import {
	EcosystemSection,
	HeroSection,
	ReferralSection,
	RoadmapSection,
	WhyGamblinoSection,
	OurGamesSection,
	GetInTouchSection,
	Menu,
	FaqSection,
} from "./sections";
import { Footer } from "@components/organisms";
import { StakeSection } from "./sections/StakeSection";

const HomePage: React.FC<{ className?: string }> = ({ className }) => {
	return (
		<div className="home">
			<Menu className="z-home-menu" />
			<HeroSection className="" />
			{/* <div className="mt-6 flex flex-col items-center justify-center gap-6 lg:mt-9 lg:flex-row">
				<a
					href="https://chain.link/badge"
					target="_blank"
					rel="noreferrer"
				>
					<img
						src="https://chain.link/badge-randomness-white"
						alt="randomness secured with chainlink"
						className="h-14"
					/>
				</a>
				<a
					href="https://data.chain.link/ethereum/mainnet/crypto-usd/btc-usd"
					target="_blank"
					rel="noreferrer"
				>
					<img
						src="https://chain.link/badge-market-data-white"
						alt="market data secured with chainlink"
						className="h-14"
					/>
				</a>
			</div> */}
			{/* { } */}
			<EcosystemSection className="mt-6 lg:mt-10" />
			<RoadmapSection className="mt-16 lg:mt-24" />
			<StakeSection className="mt-16 lg:mt-24" />
			<WhyGamblinoSection className="mt-16 lg:mt-32" />
			<ReferralSection className="mt-16 lg:mt-40" />
			<OurGamesSection className="mt-20 lg:mt-40" />
			<FaqSection className="mt-20 lg:mt-40" />
			<GetInTouchSection className="" />
			<Footer />
		</div>
	);
};

export { HomePage };
