import React from "react";
import { type ColumnDef } from "@tanstack/react-table";
import { type leaderboardBussinessLogicApi } from "business-logic-gamblino";
import {
	PlayerCell,
	RankCell,
	MultiplierCell,
	PointsCell,
} from "./LeaderboardTable/Cells";

const PAGE_SIZE = 10;

const columns: ColumnDef<
	(typeof leaderboardBussinessLogicApi)["endpoints"]["leaderboardPoints"]["Types"]["ResultType"]["leaderboard"][number]
>[] = [
	{
		accessorFn: (row) => row.rank,
		id: "rank",
		header: () => <p className="text-content-secondary text-sm">Rank</p>,
		cell: (info) => <RankCell rank={info.row.original.rank} />,
		footer: (props) => props.column.id,
		enableSorting: false,
	},
	{
		accessorFn: (row) => row.address,
		id: "user",
		header: () => <p className="text-content-secondary text-sm">User</p>,
		cell: (info) => <PlayerCell user={info.row.original.address} />,
		footer: (props) => props.column.id,
		enableSorting: false,
	},
	{
		accessorFn: (row) => 1,
		id: "multiplier",
		header: () => (
			<p className="text-content-secondary text-sm">Multiplier</p>
		),
		cell: (info) => <MultiplierCell multiplier={undefined} />,
		footer: (props) => props.column.id,
	},
	{
		accessorFn: (row) => row.totalPoints,
		id: "points",
		header: () => <p className="text-content-secondary text-sm">Points</p>,
		cell: (info) => <PointsCell points={info.row.original.totalPoints} />,
		footer: (props) => props.column.id,
	},
];

export { PAGE_SIZE, columns };
