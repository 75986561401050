import React from "react";

import { cx } from "src/utils";

const DashboardPageSection = React.forwardRef<
	HTMLDivElement,
	React.PropsWithChildren<{
		fullWidth?: boolean;
		className?: string;
	}>
>(({ children, fullWidth, className }, ref) => {
	return (
		<div
			ref={ref}
			className={cx("px-5", {
				"px-0": !!fullWidth,
				"h-full": className?.includes("h-full"),
			})}
		>
			<div
				className={cx(
					"@container/dashboard mx-auto max-w-[1028px]",
					{
						"max-w-none": !!fullWidth,
					},
					className,
				)}
			>
				{children}
			</div>
		</div>
	);
});

DashboardPageSection.displayName = "DashboardPageSection";

export { DashboardPageSection };
