import mockedData from "./mockedData";
import { schema } from "./schema";
import { createRequestQueryFunction } from "query-function-creators";

const getLeaderboard = createRequestQueryFunction({
	getAxiosRequestConfig: () => ({
		url: `${import.meta.env.BACKEND_SERVICE_GAMBLINO_BASE_URL}/leaderboard`,
	}),
	schema,
	getMockedData: () => mockedData.response1,
	isMockingEnabled: false,
	transformData: (data) => {
		return {
			...data,
			stats: {
				...data.stats,
				netProfit: data.leaderboard.reduce(
					(acc, curr) => acc + curr.netProfit,
					0,
				),
			},
		};
	},
});

export { getLeaderboard };
