import type { Table as TanStackTableType } from "@tanstack/react-table";
import { flexRender } from "@tanstack/react-table";
import React from "react";
import { cx } from "src/utils";
import { useAccount } from "wagmi";
import { EmptyState } from "../EmptyState";
import { Icon } from "@components/atoms";
import { Button } from "@components/molecules";
import { useConnectModal } from "@rainbow-me/rainbowkit";

const SimpleTable: React.FC<{
	className?: string;
	table: TanStackTableType<any>;
	isLoading: boolean;
	isError: boolean;
	isSuccess: boolean;
	isAuthProtected?: boolean;
	pageSize: number;
	rowHeight?: string;
	itemsCount: number;
	disableWalletConnectionError?: boolean;
	style?: React.DetailedHTMLProps<
		React.HTMLAttributes<HTMLDivElement>,
		HTMLDivElement
	>["style"];
	isSortable?: boolean;
}> = ({
	table,
	pageSize,
	className,
	isAuthProtected = false,
	isLoading,
	style,
	isError,
	isSuccess,
	itemsCount,
	disableWalletConnectionError = false,
	isSortable = false,
	rowHeight = "80px",
}) => {
	const { isConnected } = useAccount();

	const { openConnectModal } = useConnectModal();

	const displayContent = isAuthProtected ? isConnected : true;

	const renderBackgroundBoxes = () => {
		const length = isLoading ? pageSize : table.getRowModel().rows.length;
		return (
			<React.Fragment>
				<div
					style={{ gridRow: `1 / -1` }}
					key="header"
					className={cx("absolute inset-0 z-0 col-span-full")}
				></div>
				{Array.from({
					length,
				}).map((el, index) => (
					<div
						style={{ gridRow: `${index + 2} / span 1` }}
						key={index}
						className={cx(
							"bg-background-secondary absolute inset-0 z-0 col-span-full ",
							{
								"bg-background-tertiary": index % 2 === 0,
							},
						)}
					></div>
				))}
			</React.Fragment>
		);
	};

	const renderRows = () => {
		return table.getRowModel().rows.map((row) =>
			row.getVisibleCells().map((cell, index) => (
				<p
					key={cell.id}
					className={cx("z-0 flex h-full items-center", {
						"pl-6": index === 0,
						"pr-6": index === row.getVisibleCells().length - 1,
					})}
				>
					{flexRender(cell.column.columnDef.cell, cell.getContext())}
				</p>
			)),
		);
	};

	return (
		<div className={cx("relative", className)} style={style}>
			<div className=" overflow-x-auto">
				<div
					className="relative grid items-center gap-x-2"
					style={{
						gridTemplateRows: `auto repeat(${pageSize}, ${rowHeight}`,
						gridTemplateColumns: `repeat(${
							table.getFlatHeaders().length
						}, minmax(max-content, 1fr))`,
					}}
				>
					{displayContent && renderBackgroundBoxes()}
					{table.getFlatHeaders().map((header, index) => (
						<div
							onClick={
								isSortable
									? header
											.getContext()
											.column.getToggleSortingHandler()
									: undefined
							}
							key={header.id}
							className={cx({
								"cursor-pointer": header
									.getContext()
									.column.getCanSort(),
								"cursor-auto": !header
									.getContext()
									.column.getCanSort(),
								"py-3 pl-6": index === 0,
								"pr-6": index === table.getFlatHeaders().length,
							})}
						>
							<div className="flex items-center gap-2">
								<p
									className={cx(
										"text-content-primary z-0 w-max select-none text-start",
										{
											"text-[1.1rem] font-semibold":
												header
													.getContext()
													.column.getIsSorted(),
											"text-[1.1rem] font-normal": !header
												.getContext()
												.column.getIsSorted(),
										},
									)}
								>
									{flexRender(
										header.column.columnDef.header,
										header.getContext(),
									)}
								</p>
								{{
									asc: (
										<Icon
											type="CHEVRONS_UP"
											className="stroke-content-primary w-5"
										/>
									),
									desc: (
										<Icon
											type="CHEVRONS_DOWN"
											className="stroke-content-primary w-5"
										/>
									),
								}[header.column.getIsSorted() as string] ??
									null}
							</div>
						</div>
					))}
					{displayContent && renderRows()}
					{(!isConnected ||
						isError ||
						(isSuccess && itemsCount === 0)) && (
						<div
							className="absolute inset-0 z-0 col-span-full flex items-center justify-center"
							style={{ gridRow: `2 / -1` }}
						>
							{(() => {
								if (
									!disableWalletConnectionError &&
									!isConnected
								) {
									return (
										<EmptyState
											imageType="WALLET_NOT_CONNECTED"
											heading="Wallet Not Connected"
											description="Connect your wallet to access your data. Once connected, all your information will be visible."
											bottomElement={
												<Button
													onClick={openConnectModal}
												>
													<Button.Text>
														Connect Wallet
													</Button.Text>
												</Button>
											}
										/>
									);
								}

								if (isError) {
									return (
										<EmptyState
											imageType="TABLE_EMPTY_STATE"
											heading="No Data Found"
											description="It seems there are no items to display in this table."
										/>
									);
								}
								if (isSuccess && itemsCount === 0) {
									return (
										<EmptyState
											imageType="TABLE_EMPTY_STATE"
											heading="No Data Found"
											description="It seems there are no items to display in this table."
										/>
									);
								}
							})()}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export { SimpleTable };
