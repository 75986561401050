import { wagmiConfig } from "wallet-connection";
import { getAccount, getBalance } from "wagmi/actions";
import { abi as rouletteAbi } from "contract-service-roulette/src/config";
import {
	formatRouletteNumbers,
	getFee,
	wager as wagerContract,
} from "contract-service-roulette";
import {
	bytesToHex,
	zeroAddress,
	encodeFunctionData,
	formatUnits,
	Address,
} from "viem";
import { getMaxBetAmount as getMaxBetAmountContract } from "contract-service-gamblino-bank";

import { calculateGasEstimate } from "../../calculateGasEstimate";

const calculateMaxBetAmount = async ({
	bankContractAddress,
	contractAddress,
	numbers,
}: Omit<Parameters<typeof wagerContract>[0], "feeWei" | "amountWei"> & {
	bankContractAddress: Address;
}) => {
	const { address } = getAccount(wagmiConfig);

	if (!address) {
		throw new Error("Not connected");
	}

	const [{ fee }, { maxBetAmountWei: bankMaxBetWei }, balance] =
		await Promise.all([
			getFee({ contractAddress }),
			getMaxBetAmountContract({
				bankContractAddress,
				multiplier: Math.ceil((37 / (numbers.length || 1)) * 10000),
			}),
			getBalance(wagmiConfig, {
				address,
			}),
		]);

	const randomBytes = crypto.getRandomValues(new Uint8Array(32));
	const randomNumber = bytesToHex(randomBytes);

	const selectedNumbers = formatRouletteNumbers(numbers);

	const maxWithoutGas = balance.value - BigInt(fee.toString());

	const max =
		BigInt(maxWithoutGas) > BigInt(bankMaxBetWei)
			? BigInt(bankMaxBetWei)
			: maxWithoutGas;

	const payableAmountWei = max + BigInt(fee.toString());
	const gasEstimate = await calculateGasEstimate({
		functionData: encodeFunctionData({
			abi: rouletteAbi,
			functionName: "wager",
			args: [selectedNumbers, randomNumber, zeroAddress, max],
		}),
		to: contractAddress,
		value: payableAmountWei,
	});

	const maxBetAmountWei = (max - BigInt(gasEstimate.valueWei)).toString();
	const maxBetAmount = formatUnits(BigInt(maxBetAmountWei), 18);

	return {
		maxBetAmount,
		maxBetAmountWei,
	};
};

export { calculateMaxBetAmount };
