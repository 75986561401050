import { Icon } from "@components/atoms";
import React from "react";

const InvalidBetMessage: React.FC<{ text: string }> = ({ text }) => {
	return (
		<div className="content-badge-alert flex gap-2 rounded bg-opacity-20 p-1">
			<Icon
				type="ALERT_TRIANGLE"
				className="stroke-content-badge-alert h-4 w-4"
			/>
			<p className=" text-content-badge-alert text-xs">{text}</p>
		</div>
	);
};

export { InvalidBetMessage };
