import React from "react";
import { motion } from "framer-motion";

import { cx } from "src/utils";
import { MyBetItem } from "../MyBetItem";
import { MyBetItemSkeleton } from "../MyBetItem/skeleton/MyBetItemSkeleton";
import { Bet } from "azuro-service/src/queries/getBets";

const MyBetsList: React.FC<{
	bets: Bet[];
	className?: string;
	isLoading?: boolean;
}> = ({ bets, className, isLoading }) => {
	if (isLoading)
		return (
			<div
				className={cx(
					"@lg/dashboard:h-[460px]  flex  h-[350px] flex-col items-center gap-2 overflow-scroll px-4 pb-2 lg:px-2",
					className,
				)}
			>
				{Array.from(Array(4).keys()).map((key) => {
					return (
						<motion.div
							key={key}
							exit={{ opacity: 0 }}
							initial={{ opacity: 0 }}
							animate={{ opacity: 1, y: 10 }}
							className="min-h-[56px] w-full shrink-0"
						>
							<MyBetItemSkeleton />
						</motion.div>
					);
				})}
			</div>
		);
	return (
		<div
			className={cx(
				" @lg/dashboard:h-[460px]  flex  h-[350px] flex-col items-center gap-2 overflow-scroll px-4 pb-2 lg:px-2",
				className,
			)}
		>
			{bets?.map((bet, i) => (
				<motion.div
					key={bet.tokenId}
					exit={{ opacity: 0 }}
					initial={{ opacity: 0 }}
					animate={{ opacity: 1, y: 10 }}
					className="min-h-[56px] w-full shrink-0"
				>
					<MyBetItem bet={bet} id={i + 1} />
				</motion.div>
			))}
		</div>
	);
};

export { MyBetsList };
