import React from "react";
import { parseUnits } from "viem";
import { mines, useTokensPriceQuery } from "business-logic-gamblino";

import { cx } from "src/utils";
import { RevealButton } from "./RevealButton";
import { AssetIcon, Tooltip } from "@components/atoms";
import { minesSlice } from "src/redux/minesSlice";
import {
	CONTRACT_ADDRESS_GAMBLINO_BANK,
	CONTRACT_ADDRESS_MINES,
} from "src/config";
import { useAppDispatch, useAppSelector } from "src/redux/store";
import { Input, TooltipNumberWrapper } from "@components/molecules";
import { useMinesSelectionInfo } from "../../hooks/useMinesSelectionInfo";
import { z } from "zod";

const MinesBetSection: React.FC<{ className?: string }> = ({ className }) => {
	const [amount, setAmount] = React.useState<{
		value: string;
		valueWei: string;
	}>({
		value: "",
		valueWei: "",
	});

	const fields = useAppSelector((state) => state.minesSlice.fields);

	const [selectedMines, setSelectedMines] = React.useState("");

	const { multiplier, coins } = useMinesSelectionInfo();

	const payout =
		parseFloat(multiplier.value || "0") * parseFloat(amount.value);

	const dispatch = useAppDispatch();
	const { data } = useTokensPriceQuery(undefined, {
		pollingInterval: 30000,
	});

	const amountConverted =
		parseFloat(amount.value) * (data?.prices.sei || 1) || 0;

	const { data: maxBetData, isLoading } = mines.useCalculateMaxBetAmountQuery(
		{
			contractAddress: CONTRACT_ADDRESS_MINES,
			numMines: parseInt(selectedMines) || 0,
			tiles: fields,
			multiplier: parseFloat(multiplier.value) || 0,
			bankContractAddress: CONTRACT_ADDRESS_GAMBLINO_BANK,
		},
		{
			pollingInterval: 5000,
		},
	);

	const [, { isLoading: isGamePlaying }] = mines.useMinesMutation({
		fixedCacheKey: `useMinesMutation`,
	});

	React.useEffect(() => {
		if (
			!selectedMines ||
			parseInt(selectedMines) > 25 ||
			parseInt(selectedMines) < 0
		) {
			return;
		}

		dispatch(minesSlice.actions.setMines(parseInt(selectedMines)));
	}, [selectedMines]);

	const amountSchema = z
		.bigint()
		.max(BigInt(maxBetData?.maxBetAmountWei || 0))
		.nonnegative()
		.refine((value) => {
			return value >= parseUnits("2", 18);
		});

	return (
		<div className={cx("flex flex-col gap-2", className)}>
			<div className="bg-background-tertiary flex flex-col items-center justify-center gap-1 rounded-lg p-4">
				<div className="flex items-center gap-2 text-3xl">
					<TooltipNumberWrapper
						number={payout || 0}
						formatPriceConfig={{
							decimals: 2,
						}}
					/>
					<AssetIcon type={"SEI"} />
				</div>
				<p className="text-content-secondary text-sm">Target Payout</p>
			</div>

			<div className="bg-background-tertiary flex flex-1 flex-col items-center justify-center gap-1 rounded-lg p-4">
				<div className="flex items-center gap-2 text-3xl">
					<TooltipNumberWrapper
						number={multiplier.value || 0}
						formatPriceConfig={{
							labelPosition: "right",
							currencyLabel: "x",
						}}
					/>
				</div>
				<p className="text-content-secondary text-sm">
					{" "}
					Max Multiplier
				</p>
			</div>

			<div className="flex items-center gap-2">
				<div className="bg-background-tertiary flex flex-1 items-center justify-between gap-2 rounded p-2">
					<div className="flex flex-col gap-1 text-xs">
						<p className="text-content-secondary">Mines</p>
						<p>{selectedMines || 0}</p>
					</div>
					<img src="/mines/mine.png" className="h-6 w-6" />
				</div>

				<div className="bg-background-tertiary flex flex-1 items-center justify-between gap-2 rounded p-2">
					<div className="flex flex-col gap-1 text-xs">
						<p className="text-content-secondary">Coins</p>
						<p>{coins}</p>
					</div>
					<img src="/mines/coin.png" className="h-6 w-6" />
				</div>
			</div>
			<div>
				<div className="px-1">
					<div className="flex items-center justify-between">
						<p className="text-content-secondary text-xs">Mines</p>
					</div>
				</div>
				<Input
					type="text"
					name="betValue"
					placeholder={"0"}
					className={"h-[48px] w-full"}
					setValue={setSelectedMines}
					value={selectedMines}
				/>
			</div>

			<div>
				<div className="flex items-center justify-between px-1">
					<p className="text-content-secondary text-xs">
						Enter Amount
					</p>
					<p className="text-content-primary text-xs">
						$
						<TooltipNumberWrapper number={amountConverted} />
					</p>
				</div>

				<Input
					type="text"
					name="betValue"
					placeholder={"0"}
					className={cx("h-[48px] w-full", {
						"border-background-error": !amountSchema.safeParse(
							BigInt(amount.valueWei),
						).success,
					})}
					onChange={(e) => {
						setAmount({
							value: e.currentTarget.value,
							valueWei: parseUnits(
								e.currentTarget.value,
								18,
							).toString(),
						});
					}}
					value={amount.value}
				>
					<Input.AssetIcon type="SEI" />

					<Input.Button
						type="tertiary"
						size="sm"
						disabled={!maxBetData?.maxBetAmount || isGamePlaying}
						onClick={() => {
							setAmount({
								value: maxBetData?.maxBetAmount || "0",
								valueWei: maxBetData?.maxBetAmountWei || "0",
							});
						}}
						className="h-10 self-center"
					>
						<Tooltip
							content={`Max bet amount is: ${maxBetData?.maxBetAmount || 0}`}
						>
							<Input.Button.Text>MAX</Input.Button.Text>
						</Tooltip>
					</Input.Button>
				</Input>
			</div>

			<RevealButton
				amount={amount}
				numOfMines={selectedMines}
				className="w-full"
			/>
		</div>
	);
};

export { MinesBetSection };
