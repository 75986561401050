import React from "react";
import { useSearchParams } from "react-router-dom";

import { cx } from "src/utils";
import UserStats from "./UserStats/UserStats";
import { Input } from "@components/molecules";
import { DashboardPageSection } from "@components/atoms";
import { SimpleDashboardGameLayout } from "@components/layouts";
import { LeaderboardTable } from "./LeaderboardTable/LeaderboardTable";

const PointsPage = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [searchValue, setSearchValue] = React.useState("");

	React.useEffect(() => {
		if (searchValue !== "") {
			setSearchParams({ query: searchValue });
		} else {
			setSearchParams({}, { replace: true });
		}
	}, [searchValue, setSearchParams]);

	return (
		<SimpleDashboardGameLayout
			bgImageUrl="/dice/dice-bg.png"
			gameComponent={
				<DashboardPageSection>
					<div className="@2xl/dashboard:flex-row @2xl/dashboard:justify-between flex flex-col-reverse items-center gap-10">
						<div className="basis-1/2">
							<h2 className="text-content-primary-accent text-5xl font-semibold">
								Introducing Gamblino’s Points System
							</h2>
							<h3 className="mt-5 text-3xl">
								The ultimate challenge that brings points and
								incentives to the Gamblino community!
							</h3>
							<p className="text-content-secondary mt-9 text-2xl">
								Earn one point for every dollar wagered on our
								live PvH games like Roulette, Flip Master, Dice,
								and Mines. The more you play, the more you earn!
								Play daily to maximize your points.
							</p>
							<p className="text-content-secondary mt-4 text-2xl">
								Multipliers will show at the end of the program.
							</p>
						</div>
						<img
							className="@2xl/dashboard:max-w-80 max-w-60 basis-1/2"
							src="/points-illustration.png"
							alt=""
						/>
					</div>
				</DashboardPageSection>
			}
		>
			<DashboardPageSection className={cx("@2xl/dashboard:mt-4")}>
				<div className="@2xl/dashboard:flex-row @2xl/dashboard:items-center @2xl/dashboard:justify-between flex w-full flex-col gap-4">
					<div className="">
						<p className="text-xl">Leaderboard and stats</p>
					</div>
					<Input
						value={searchValue}
						setValue={setSearchValue}
						placeholder="Search address"
						name="search"
						type="text"
						className="h-10"
						wrapperClassName="w-full @sm:w-96 "
					>
						<Input.Icon
							type="SEARCH"
							className="stroke-content-primary h-6 w-6"
						/>
					</Input>
				</div>
				<UserStats />
				<LeaderboardTable className="mt-4" />
			</DashboardPageSection>
		</SimpleDashboardGameLayout>
	);
};

export default PointsPage;
