import { PageSection } from "@components/atoms";
import { Button } from "@components/molecules";
import React from "react";
import { useNavigate } from "react-router-dom";
import { cx } from "src/utils";

const ReferralSection: React.FC<{ className?: string }> = ({ className }) => {
	const navigate = useNavigate();
	return (
		<PageSection
			className={cx(
				"flex flex-col gap-6 lg:flex-row-reverse lg:items-end lg:gap-0",
				className,
			)}
		>
			<div className="relative lg:basis-1/2 lg:pb-6">
				<img
					className="absolute bottom-1/2 right-[18%] hidden lg:block lg:w-[240px] xl:w-[260px] 2xl:w-[300px]"
					src="/megaphone.png"
					alt=""
				/>
				<img src="/ribbon.svg" alt="" />
			</div>
			<div className="bg-background-secondary rounded-3xl p-4 shadow-[0px_2px_8px_0px_rgba(62,_47,_122,_0.16)] lg:basis-1/2 lg:px-16 lg:py-10">
				<h3 className="text-content-primary text-4xl font-semibold lg:text-5xl">
					Referral program
				</h3>
				<p className="text-content-secondary mt-4 text-base font-medium lg:mt-6 lg:text-2xl">
					Earn rewards with Gamblino for each friend you refer using
					your unique referral code. See docs for details
				</p>
				<p className="text-gradient-1 mt-5 text-base lg:mt-10 lg:text-2xl">
					Generate your unique referral code now to share in the
					protocol rewards!
				</p>

				<Button
					type="gradient"
					onClick={() => {
						navigate("/referral");
					}}
					className="mt-6 lg:mt-8"
				>
					<Button.Text>Read details</Button.Text>
				</Button>
			</div>
		</PageSection>
	);
};

export { ReferralSection };
