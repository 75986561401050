import React from "react";
import { Button } from "@components/molecules";

import { type IModal } from "@components/organisms";
import { Modal } from "@components/organisms";
import { useModalManager } from "@components/ethereals/ModalsWrapper";
import { useBlockExplorer } from "src/hooks";

const SuccessSeeTxModal: IModal<"SuccessSeeTxModal"> &
	React.FC<{
		heading: string;
		description: string;
		txHash: string;
	}> = ({ heading, description, txHash }) => {
	const getBlockExplorer = useBlockExplorer();
	const { modalManager } = useModalManager();
	return (
		<Modal className="bg-background-secondary relative w-full max-w-[350px] overflow-hidden rounded-xl">
			<Modal.Step>
				<div className="flex flex-col items-center gap-3 p-6">
					<img
						className="h-64 w-64"
						src="/success-illustration.png"
						alt=""
					/>
					<h3 className="text-content-primary text-center text-lg font-semibold">
						{heading}
					</h3>
					<p className="text-content-primary text-center text-sm">
						{description}
					</p>
					<a
						target="_blank"
						rel="noreferrer"
						href={getBlockExplorer(txHash)}
					>
						<Button
							size="sm"
							type="primary"
							onClick={() => {
								modalManager.close("SuccessSeeTxModal");
							}}
							className="w-full"
						>
							<Button.Text>See TX hash</Button.Text>
						</Button>
					</a>
				</div>
			</Modal.Step>
		</Modal>
	);
};

SuccessSeeTxModal.modalName = "SuccessSeeTxModal";

export { SuccessSeeTxModal };
