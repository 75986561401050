/* eslint-disable max-lines-per-function */
import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react";
import { getEndpointCreators } from "get-endpoint-creators";

import { getMaxBetAmount, getMultipliers, wager } from "../service/mines";
import { getMinesUserHistory } from "../../../backend-service-gamblino/src";
import { getMinBetAmount } from "contract-service-gamblino-bank";
import { calculateMaxBetAmount } from "../service/mines/calculateMaxBetAmount";

const minesBusinessLogic = createApi({
	reducerPath: "minesBusinessLogic",
	tagTypes: ["mines-history"],
	baseQuery: fakeBaseQuery(),
	endpoints: (builder) => {
		const { createQuery, createMutation } = getEndpointCreators(builder);

		return {
			wager: createMutation(wager, {}),
			getMultipliers: createQuery(getMultipliers),
			minesUserHistory: createQuery(getMinesUserHistory, {
				providesTags: ["mines-history"],
			}),
			getMaxBetAmount: createQuery(getMaxBetAmount),
			getMinBetAmount: createQuery(getMinBetAmount),
			calculateMaxBetAmount: createQuery(calculateMaxBetAmount),
		};
	},
});

const {
	useWagerMutation,
	useCalculateMaxBetAmountQuery,
	useGetMinBetAmountQuery,
	useGetMaxBetAmountQuery,
	useMinesUserHistoryQuery,
	useGetMultipliersQuery,
} = minesBusinessLogic;

const mines = {
	useCalculateMaxBetAmountQuery,
	useGetMultipliers: useGetMultipliersQuery,
	useMinesMutation: useWagerMutation,
	useUserHistory: useMinesUserHistoryQuery,
	useMaxBetAmount: useGetMaxBetAmountQuery,
	useMinBetAmount: useGetMinBetAmountQuery,
};

export { mines, minesBusinessLogic };
