import { Pagination, SimpleTable } from "@components/organisms";
import React from "react";
import { usePaginatedLeaderboard } from "./usePaginatedLeaderboard";
import { useSearchParams } from "react-router-dom";
import { cx } from "class-variance-authority";

const LeaderboardTable: React.FC<{ className?: string }> = ({ className }) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const query = searchParams.get("query") || "";

	const {
		table,
		isLoading,
		isUninitialized,
		isSuccess,
		isError,
		getCanNextPage,
		getCanPreviousPage,
		goToNextPage,
		goToPreviousPage,
		itemsCount,
		pageCount,
		pageIndex,
		pageSize,
		setPageIndex,
	} = usePaginatedLeaderboard(query);

	return (
		<div className={cx(className)}>
			<SimpleTable
				isLoading={isLoading || isUninitialized}
				isError={isError}
				isSuccess={isSuccess}
				itemsCount={itemsCount}
				pageSize={pageSize}
				table={table}
				rowHeight="65px"
				className=""
				isSortable
				disableWalletConnectionError
			/>
			<Pagination
				getCanNextPage={getCanNextPage}
				getCanPreviousPage={getCanPreviousPage}
				goToNextPage={goToNextPage}
				goToPreviousPage={goToPreviousPage}
				itemsCount={itemsCount}
				pageCount={pageCount}
				pageIndex={pageIndex + 1}
				pageSize={pageSize}
				setPageIndex={setPageIndex}
				className="mt-4"
			/>
		</div>
	);
};

export { LeaderboardTable };
