import { z } from "zod";

const schema = z.object({
	status: z.literal("ok"),
	user: z.object({
		email: z.string().nullable(),
		wallet: z.string(),
	}),
});

export { schema };
