const getSubgraphUrlFromEnv = (): string => {
	if (import.meta.env.WALLET_CONNECTION_VARIANT === "ARBITRUM") {
		if (import.meta.env.WALLET_CONNECTION_IS_PROD === "true") {
			return "https://thegraph.azuro.org/subgraphs/name/azuro-protocol/azuro-api-arbitrum-one-v3";
		}

		return "https://thegraph.azuro.org/subgraphs/name/azuro-protocol/azuro-api-arbitrum-goerli-dev-v3";
	}

	if (import.meta.env.WALLET_CONNECTION_VARIANT === "POLYGON") {
		if (import.meta.env.WALLET_CONNECTION_IS_PROD === "true") {
			return "https://thegraph.azuro.org/subgraphs/name/azuro-protocol/azuro-api-polygon-v3";
		}

		return "https://thegraph.azuro.org/subgraphs/name/azuro-protocol/azuro-api-mumbai-dev-v3";
	}

	return "";
};

export { getSubgraphUrlFromEnv };
