import { type FC, type HTMLAttributes, type ReactNode } from "react";
import React, { useEffect, useId, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { cx } from "src/utils";
import { Icon } from "@components/atoms";
import { useAsideStatus } from "src/redux/useAsideStatus";

const navItemClasses = {
	default: cx(
		"mb-2 p-3",
		"flex items-center",
		"cursor-pointer",
		"overflow-hidden",
		"rounded-xl",
		"border",
		"text-content-primary",
		"hover:text-content-secondary",
		"grow",
		"transition-all duration-300 ease-in-out",
	),
	inactive: "border-transparent",
	active: " border-border-primary text-content-primary",
	here: "text-content-primary stroke-content-primary border-transparent",
};

const navItemChildCheck = (
	children:
		| INavButtonProps
		| Iterable<React.ReactNode>
		| React.ReactElement<
				unknown,
				React.JSXElementConstructor<unknown> | string
		  >
		| React.ReactPortal
		| boolean
		| number
		| string
		| null
		| undefined,
): boolean => {
	// @ts-ignore

	return children?.length > 1
		? // @ts-ignore

		  children?.map((child) => child.type.displayName).includes("NavButton")
		: // @ts-ignore

		  children?.type?.displayName === "NavButton";
};

interface INavItemTextProps extends HTMLAttributes<HTMLDivElement> {
	children: ReactNode;
	className?: string;
}

const NavItemText: FC<INavItemTextProps> = ({
	children,
	className,
	...rest
}) => {
	return (
		<div
			className={cx(
				"select-none overflow-hidden truncate whitespace-nowrap",

				className,
			)}
			{...rest}
		>
			{children}
		</div>
	);
};

interface INavItemContentProps extends HTMLAttributes<HTMLDivElement> {
	children: ReactNode;
	className?: string;
}

const NavItemContent: FC<INavItemContentProps> = ({
	children,
	className,
	...rest
}) => {
	const { asideStatus } = useAsideStatus();

	return (
		<div
			className={cx(
				"flex w-full items-center justify-between truncate",
				{
					hidden: !asideStatus,
				},
				className,
			)}
			{...rest}
		>
			{children}
		</div>
	);
};
NavItemContent.defaultProps = {
	className: undefined,
};
NavItemContent.displayName = "NavItemContent";

const NavIcon: React.FC<{
	iconType: React.ComponentProps<typeof Icon>["type"];
	className?: string;
}> = ({ className, iconType }) => {
	const { asideStatus } = useAsideStatus();

	return (
		<Icon
			type={iconType}
			className={cx(
				"stroke-content-primary hover:stroke-content-secondary h-6 w-6 flex-none",
				{
					"me-3": asideStatus,
				},
				className,
			)}
		/>
	);
};

interface INavItemProps extends HTMLAttributes<HTMLLIElement> {
	children?: ReactNode;
	iconType: React.ComponentProps<typeof Icon>["type"];
	text: string;
	to?: string;
	className?: string;
	isActive?: boolean;
	iconClassName?: string;
}

export const NavItem: FC<INavItemProps> = ({
	children,
	iconType,
	text,
	to,
	className,
	isActive = true,
	iconClassName,
	...rest
}) => {
	const { asideStatus, setAsideStatus } = useAsideStatus();

	const isChildrenNavButton = navItemChildCheck(children);

	const CONTENT = (
		<React.Fragment>
			<NavIcon iconType={iconType} className={iconClassName} />
			<NavItemContent>
				<NavItemText>{text}</NavItemText>
				{children && !isChildrenNavButton && (
					<div>{children as ReactNode}</div>
				)}
			</NavItemContent>
		</React.Fragment>
	);
	return (
		<li
			className={cx(
				"flex list-none items-center overflow-hidden whitespace-nowrap",
				className,
				{
					"pointer-events-none opacity-60": !isActive,
				},
			)}
			{...rest}
		>
			{to ? (
				<React.Fragment>
					{/* For Desktop */}
					<NavLink
						end
						to={to}
						className={({ isActive }) =>
							isActive
								? cx(
										navItemClasses.default,
										navItemClasses.active,
										"max-md:hidden",
								  )
								: cx(
										navItemClasses.default,
										navItemClasses.inactive,
										"max-md:hidden",
								  )
						}
					>
						{CONTENT}
					</NavLink>
					{/* For Mobile */}
					<NavLink
						end
						to={to}
						onClick={() => setAsideStatus(false)}
						className={({ isActive }) =>
							isActive
								? cx(
										navItemClasses.default,
										navItemClasses.active,
										"md:hidden",
								  )
								: cx(
										navItemClasses.default,
										navItemClasses.inactive,
										"md:hidden",
								  )
						}
					>
						{CONTENT}
					</NavLink>
				</React.Fragment>
			) : (
				<React.Fragment>
					{/* For Desktop */}
					<div
						className={cx(
							navItemClasses.default,
							navItemClasses.inactive,
							"max-md:hidden",
						)}
					>
						{CONTENT}
					</div>
					{/* For Mobile */}
					<div
						className={cx(
							navItemClasses.default,
							navItemClasses.inactive,
							"md:hidden",
						)}
					>
						{CONTENT}
					</div>
				</React.Fragment>
			)}
			{asideStatus && children && isChildrenNavButton && (
				<div className="mb-2 flex items-center gap-3 px-3">
					{children as ReactNode}
				</div>
			)}
		</li>
	);
};

interface INavCollapseProps extends HTMLAttributes<HTMLLIElement> {
	children: ReactNode;
	iconType: React.ComponentProps<typeof Icon>["type"];
	text: string;
	to: string;
	className?: string;
}

export const NavCollapse: FC<INavCollapseProps> = (props) => {
	const { children, iconType, text, className, to, ...rest } = props;

	const id = useId();
	const [isActive, setIsActive] = useState<boolean>(false);

	const { asideStatus } = useAsideStatus();

	// React.useEffect(() => {
	// 	return () => {
	// 		setIsActive(false);
	// 	};
	// }, [asideStatus]);

	const location = useLocation();
	const here = to !== "/" && location.pathname.includes(to);

	useEffect(() => {
		setIsActive(here);
	}, [here, location.pathname]);

	return (
		<li className={cx("list-none overflow-hidden", className)} {...rest}>
			<div
				role="presentation"
				className={
					isActive || here
						? cx(navItemClasses.default, navItemClasses.here)
						: cx(navItemClasses.default, navItemClasses.inactive)
				}
				onClick={() => setIsActive(!isActive)}
			>
				<NavIcon iconType={iconType} />

				<NavItemContent>
					<NavItemText>{text}</NavItemText>
					<div>
						<Icon
							type="CHEVRON_DOWN"
							className={cx(
								"stroke-content-primary hover:stroke-content-secondary h-6 w-6",
								{
									"rotate-180": isActive,
								},
								"transition-all duration-300 ease-in-out",
							)}
						/>
					</div>
				</NavItemContent>
			</div>
			<AnimatePresence>
				{isActive && (
					<motion.ul
						key={id}
						initial="collapsed"
						animate="open"
						exit="collapsed"
						variants={{
							open: { height: "auto" },
							collapsed: { height: 0 },
						}}
						transition={{ duration: 0.3 }}
						className={cx(
							"!transition-margin !duration-300 !ease-in-out",
							{
								"ms-4": asideStatus,
							},
						)}
					>
						<div className="transition-margin"></div>
						{children}
					</motion.ul>
				)}
			</AnimatePresence>
		</li>
	);
};

interface INavTitleProps extends HTMLAttributes<HTMLLIElement> {
	children: string;
	className?: string;
}

export const NavTitle: FC<INavTitleProps> = (props) => {
	const { children, className, ...rest } = props;

	const { asideStatus } = useAsideStatus();

	return (
		<li
			className={cx(
				"text-content stroke-content-primary list-none overflow-hidden truncate whitespace-nowrap px-3 py-1.5 text-sm font-semibold",
				className,
			)}
			{...rest}
		>
			{asideStatus ? (
				children
			) : (
				<div className="bg-content stroke-content-primary my-1.5 h-2 w-full max-w-[6rem] rounded-full" />
			)}
		</li>
	);
};
NavTitle.defaultProps = {
	className: undefined,
};
NavTitle.displayName = "NavTitle";

interface INavSeparatorProps extends HTMLAttributes<HTMLLIElement> {
	className?: string;
}

export const NavSeparator: FC<INavSeparatorProps> = (props) => {
	const { className, ...rest } = props;
	return (
		<li
			className={cx(
				"border-content stroke-content-primary/25 mb-2 list-none rounded-full border-b",
				className,
			)}
			{...rest}
		/>
	);
};
NavSeparator.defaultProps = {
	className: undefined,
};
NavSeparator.displayName = "NavSeparator";

interface INavProps extends HTMLAttributes<HTMLDivElement> {
	children: ReactNode;
	className?: string;
}

const Nav: FC<INavProps> = (props) => {
	const { children, className, ...rest } = props;

	return (
		<nav className={cx(className)} {...rest}>
			<ul>{children}</ul>
		</nav>
	);
};
Nav.defaultProps = {
	className: undefined,
};
Nav.displayName = "Nav";

export { Nav };
