import {
	Route,
	RouterProvider,
	createBrowserRouter,
	createRoutesFromChildren,
} from "react-router-dom";
import React from "react";
import { wrapCreateBrowserRouter } from "@sentry/react";
import { AddressNotParallelizedWrapper } from "./AddressNotParallelizedWrapper";

import {
	Games,
	Page404,
	HomePage,
	DicePage,
	MinesPage,
	FaucetPage,
	RoulettePage,
	ReferralPage,
} from "../../Pages";
import { WrongChain } from "./WrongChain";
import { ScrollToTop } from "src/utils/ScrollToTop";
import GamePage from "src/Pages/GamePage/GamePage";
import { SportsbookMyBets } from "src/Pages/SportsbookMyBets";
import { PageTemplateWithSidebar } from "@components/organisms";
import GamesListPage from "src/Pages/GamesListPage/GamesListPage";
import SportsbookPage from "src/Pages/SportsbookPage/SportsbookPage";
import { FlipMasterPage } from "src/Pages/FlipMasterPage/FlipMasterPage";
import PointsPage from "src/Pages/PointsPage/PointsPage";

const routes = createRoutesFromChildren(
	<Route
		path="/"
		element={
			<>
				<ScrollToTop />
				<WrongChain />
				<AddressNotParallelizedWrapper />
			</>
		}
	>
		<Route path="/" element={<HomePage />} />
		<Route path="/" element={<PageTemplateWithSidebar />}>
			<Route path="/*" element={<Page404 />} />
			<Route path="/referral" element={<ReferralPage />} />
			{import.meta.env.GAMBLINO_IS_PROD === "true" ? (
				<>
					<Route path="sportsbook" element={<SportsbookPage />}>
						<Route path=":sportSlug" element={<GamesListPage />} />
						<Route
							path=":sportSlug/:gameId"
							element={<GamePage />}
						/>
					</Route>
					<Route
						path="/sportsbook/my-bets"
						element={<SportsbookMyBets />}
					></Route>
				</>
			) : (
				<Route path="/games">
					<Route index element={<Games />} />

					<>
						<Route path="dice" element={<DicePage />} />
						<Route
							path="flip-master"
							element={<FlipMasterPage />}
						/>
						<Route path="roulette" element={<RoulettePage />} />
						<Route path="mines" element={<MinesPage />} />
					</>
				</Route>
			)}
			{import.meta.env.GAMBLINO_IS_PROD !== "true" && (
				<Route path="/faucet" element={<FaucetPage />} />
			)}

			<Route path="points" element={<PointsPage />} />
		</Route>
	</Route>,
);

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter(routes);

const AppRoutes: React.FC = () => {
	return <RouterProvider router={router} />;
};

export { AppRoutes };
