import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
	BLACK_NUMBERS,
	EVEN_NUMBERS,
	FIRST_12,
	FIRST_GROUP,
	FIRST_HALF,
	FROM_13_TO_24,
	FROM_25_36,
	ODD_NUMBERS,
	RED_NUMBERS,
	RouletteItem,
	SECOND_GROUP,
	SECOND_HALF,
	THIRD_GROUP,
} from "src/Pages/RoulettePage/config";

const initialState: {
	history: string[][];
	selectedNumbers: string[];
	hoveredNumbers: string[];
} = {
	selectedNumbers: [],
	history: [],
	hoveredNumbers: [],
};

const rouletteSlice = createSlice({
	name: "roulette",
	initialState,
	reducers: {
		toggleSingleSelection: (state, { payload }: PayloadAction<string>) => {
			if (state.selectedNumbers.includes(payload)) {
				const index = state.selectedNumbers.findIndex(
					(selected) => selected === payload,
				);

				state.selectedNumbers.splice(index, 1);
				return;
			}

			state.selectedNumbers.push(payload);
			state.history.push(state.selectedNumbers);
		},
		toggleRowSelection: (state, { payload }: PayloadAction<string>) => {
			if (payload === "FIRST_ROW") {
				if (isRowSelected(state.selectedNumbers, FIRST_GROUP)) {
					state.selectedNumbers = removeSelectedRow(
						state.selectedNumbers,
						FIRST_GROUP,
					);

					return;
				}

				FIRST_GROUP.map((btn) => {
					if (state.selectedNumbers.includes(btn.value)) return;

					state.selectedNumbers.push(btn.value);
				});
				return;
			}

			if (payload === "SECOND_ROW") {
				if (isRowSelected(state.selectedNumbers, SECOND_GROUP)) {
					state.selectedNumbers = removeSelectedRow(
						state.selectedNumbers,
						SECOND_GROUP,
					);

					return;
				}

				SECOND_GROUP.map((btn) => {
					if (state.selectedNumbers.includes(btn.value)) return;

					state.selectedNumbers.push(btn.value);
				});
				return;
			}

			if (payload === "THIRD_ROW") {
				if (isRowSelected(state.selectedNumbers, THIRD_GROUP)) {
					state.selectedNumbers = removeSelectedRow(
						state.selectedNumbers,
						THIRD_GROUP,
					);

					return;
				}

				THIRD_GROUP.map((btn) => {
					if (state.selectedNumbers.includes(btn.value)) return;

					state.selectedNumbers.push(btn.value);
				});
				return;
			}

			state.history.push(state.selectedNumbers);
		},
		toggleGroupSelection: (state, { payload }: PayloadAction<string>) => {
			if (payload === "RED") {
				if (isRowSelected(state.selectedNumbers, RED_NUMBERS)) {
					state.selectedNumbers = removeSelectedRow(
						state.selectedNumbers,
						RED_NUMBERS,
					);

					return;
				}

				RED_NUMBERS.map((btn) => {
					if (state.selectedNumbers.includes(btn.value)) return;

					state.selectedNumbers.push(btn.value);
				});
			}

			if (payload === "BLACK") {
				if (isRowSelected(state.selectedNumbers, BLACK_NUMBERS)) {
					state.selectedNumbers = removeSelectedRow(
						state.selectedNumbers,
						BLACK_NUMBERS,
					);

					return;
				}

				BLACK_NUMBERS.map((btn) => {
					if (state.selectedNumbers.includes(btn.value)) return;

					state.selectedNumbers.push(btn.value);
				});
			}

			if (payload === "1_TO_12") {
				if (isGroupSelected(state.selectedNumbers, FIRST_12)) {
					state.selectedNumbers = removeSelectedGroup(
						state.selectedNumbers,
						FIRST_12,
					);

					return;
				}

				FIRST_12.map((value) => {
					if (state.selectedNumbers.includes(value)) return;

					state.selectedNumbers.push(value);
				});
			}

			if (payload === "13_TO_24") {
				if (isGroupSelected(state.selectedNumbers, FROM_13_TO_24)) {
					state.selectedNumbers = removeSelectedGroup(
						state.selectedNumbers,
						FROM_13_TO_24,
					);

					return;
				}

				FROM_13_TO_24.map((value) => {
					if (state.selectedNumbers.includes(value)) return;

					state.selectedNumbers.push(value);
				});
			}

			if (payload === "25_TO_36") {
				if (isGroupSelected(state.selectedNumbers, FROM_25_36)) {
					state.selectedNumbers = removeSelectedGroup(
						state.selectedNumbers,
						FROM_25_36,
					);

					return;
				}

				FROM_25_36.map((value) => {
					if (state.selectedNumbers.includes(value)) return;

					state.selectedNumbers.push(value);
				});
			}

			if (payload === "FIRST_HALF") {
				if (isGroupSelected(state.selectedNumbers, FIRST_HALF)) {
					state.selectedNumbers = removeSelectedGroup(
						state.selectedNumbers,
						FIRST_HALF,
					);

					return;
				}

				FIRST_HALF.map((value) => {
					if (state.selectedNumbers.includes(value)) return;

					state.selectedNumbers.push(value);
				});
			}

			if (payload === "SECOND_HALF") {
				if (isGroupSelected(state.selectedNumbers, SECOND_HALF)) {
					state.selectedNumbers = removeSelectedGroup(
						state.selectedNumbers,
						SECOND_HALF,
					);

					return;
				}

				SECOND_HALF.map((value) => {
					if (state.selectedNumbers.includes(value)) return;

					state.selectedNumbers.push(value);
				});
			}

			if (payload === "EVEN") {
				if (isGroupSelected(state.selectedNumbers, EVEN_NUMBERS)) {
					state.selectedNumbers = removeSelectedGroup(
						state.selectedNumbers,
						EVEN_NUMBERS,
					);

					return;
				}

				EVEN_NUMBERS.map((value) => {
					if (state.selectedNumbers.includes(value)) return;

					state.selectedNumbers.push(value);
				});
			}

			if (payload === "ODD") {
				if (isGroupSelected(state.selectedNumbers, ODD_NUMBERS)) {
					state.selectedNumbers = removeSelectedGroup(
						state.selectedNumbers,
						ODD_NUMBERS,
					);

					return;
				}

				ODD_NUMBERS.map((value) => {
					if (state.selectedNumbers.includes(value)) return;

					state.selectedNumbers.push(value);
				});
			}
			state.history.push(state.selectedNumbers);
		},
		undo: (state) => {
			if (!history.length) return;

			state.history.pop();

			const previous = JSON.parse(
				JSON.stringify(state.history.slice(-1)),
			);

			state.selectedNumbers = previous[0] || [];
		},
		reset: (state) => {
			state.selectedNumbers = [];
		},
		toggleHover: (
			state,
			{ payload }: PayloadAction<{ value: string; isHover: boolean }>,
		) => {
			if (!payload.isHover) {
				state.hoveredNumbers = [];
				return;
			}

			if (payload.value === "FIRST_ROW") {
				FIRST_GROUP.map((btn) => {
					state.hoveredNumbers.push(btn.value);
				});
				return;
			}

			if (payload.value === "SECOND_ROW") {
				SECOND_GROUP.map((btn) => {
					state.hoveredNumbers.push(btn.value);
				});
				return;
			}

			if (payload.value === "THIRD_ROW") {
				THIRD_GROUP.map((btn) => {
					state.hoveredNumbers.push(btn.value);
				});
				return;
			}

			if (payload.value === "RED") {
				RED_NUMBERS.map((btn) => {
					state.hoveredNumbers.push(btn.value);
				});
				return;
			}

			if (payload.value === "BLACK") {
				BLACK_NUMBERS.map((btn) => {
					state.hoveredNumbers.push(btn.value);
				});
				return;
			}

			if (payload.value === "1_TO_12") {
				FIRST_12.map((value) => {
					state.hoveredNumbers.push(value);
				});
				return;
			}

			if (payload.value === "13_TO_24") {
				FROM_13_TO_24.map((value) => {
					state.hoveredNumbers.push(value);
				});
				return;
			}

			if (payload.value === "25_TO_36") {
				FROM_25_36.map((value) => {
					state.hoveredNumbers.push(value);
				});
				return;
			}

			if (payload.value === "FIRST_HALF") {
				FIRST_HALF.map((value) => {
					state.hoveredNumbers.push(value);
				});
				return;
			}

			if (payload.value === "SECOND_HALF") {
				SECOND_HALF.map((value) => {
					state.hoveredNumbers.push(value);
				});
				return;
			}

			if (payload.value === "EVEN") {
				EVEN_NUMBERS.map((value) => {
					state.hoveredNumbers.push(value);
				});
				return;
			}

			if (payload.value === "ODD") {
				ODD_NUMBERS.map((value) => {
					state.hoveredNumbers.push(value);
				});
				return;
			}

			state.hoveredNumbers.push(payload.value);
		},
	},
});

const isRowSelected = (selection: string[], row: RouletteItem[]) => {
	return row.every((item) => selection.includes(item.value));
};

const removeSelectedRow = (selection: string[], row: RouletteItem[]) => {
	return [...selection].filter((item) =>
		row.every((btn) => btn.value !== item),
	);
};

const isGroupSelected = (selection: string[], row: string[]) => {
	return row.every((item) => selection.includes(item));
};

const removeSelectedGroup = (selection: string[], row: string[]) => {
	return [...selection].filter((item) => row.every((btn) => btn !== item));
};

export { rouletteSlice };
