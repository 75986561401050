import React from "react";
import { cx } from "src/utils";
import { Address, formatEther, parseUnits } from "viem";
import { useAccount } from "wagmi";
import { Tooltip } from "@components/atoms";
import { Button } from "@components/molecules";
import {
	CONTRACT_ADDRESS_GAMBLINO_BANK,
	CONTRACT_ADDRESS_COIN_FLIP,
} from "src/config";
import { coinFlip } from "business-logic-gamblino";
import { AnimatePresence, motion } from "framer-motion";
import { useAudioManager } from "src/redux";
import { useWindowSize } from "usehooks-ts";
import wait from "wait";
import * as confetti from "canvas-confetti";
import ReactCanvasConfetti from "react-canvas-confetti";
import { animationDurationsInSeconds } from "../config";
import { toast } from "sonner";
import { z } from "zod";

const animationConfig: React.ComponentProps<(typeof motion)["div"]> = {
	initial: { opacity: 0, scale: 0.66 },
	animate: { opacity: 1, scale: 1 },
	exit: { opacity: 0, scale: 0.66 },
	transition: { duration: 1, type: "spring" },
};

const statuses = ["INITIAL", "WON", "LOST", "LOADING"] as const;
type Status = (typeof statuses)[number];

const FlipButton: React.FC<{
	amount: {
		valueWei: string;
		value: string;
	};
	face: boolean;
	contractAddress: Address;
	className?: string;
}> = ({ amount, face, contractAddress, className }) => {
	const { playSoundOnce } = useAudioManager();
	const refAnimationInstance = React.useRef(null);

	const getInstance = React.useCallback((instance) => {
		refAnimationInstance.current = instance;
	}, []);

	const makeShot = React.useCallback((particleRatio, opts) => {
		refAnimationInstance.current &&
			refAnimationInstance.current({
				...opts,
				origin: { y: 0.7 },
				particleCount: Math.floor(200 * particleRatio),
			});
	}, []);

	const fire = React.useCallback(() => {
		makeShot(0.25, {
			spread: 26,
			startVelocity: 55,
		});

		makeShot(0.2, {
			spread: 60,
		});

		makeShot(0.35, {
			spread: 100,
			decay: 0.91,
			scalar: 0.8,
		});

		makeShot(0.1, {
			spread: 120,
			startVelocity: 25,
			decay: 0.92,
			scalar: 1.2,
		});

		makeShot(0.1, {
			spread: 120,
			startVelocity: 45,
		});
	}, [makeShot]);

	const { isConnected: isUserConnected } = useAccount();
	const { width } = useWindowSize();

	const { data: maxBetData } = coinFlip.useCalculateMaxBetAmountQuery(
		{
			face,
			contractAddress: CONTRACT_ADDRESS_COIN_FLIP,
			bankContractAddress: CONTRACT_ADDRESS_GAMBLINO_BANK,
		},
		{
			pollingInterval: 5000,
		},
	);

	const schema = z
		.bigint()
		.max(BigInt(maxBetData?.maxBetAmountWei || 0n))
		.nonnegative()
		.refine((value) => {
			return value >= parseUnits("2", 18);
		});

	const [flip, { isLoading, isSuccess, data }] = coinFlip.useWagerMutation({
		fixedCacheKey: "coinFlipBet",
	});

	const [status, setStatus] = React.useState<Status>("INITIAL");

	React.useEffect(() => {
		if (status !== "INITIAL") {
			return;
		}

		if (isLoading) {
			setStatus("LOADING");
		}
	}, [isLoading]);

	React.useEffect(() => {
		if (status !== "LOADING") {
			return;
		}

		void (async () => {
			await wait(1000 * animationDurationsInSeconds.MAIN_COIN_ROTATION);
			if (isSuccess && data) {
				setStatus(data.isWin ? "WON" : "LOST");
				playSoundOnce(
					data.isWin
						? "/10046325_magic-coin_by_gamechestaudio_preview.mp3"
						: "/15344564_wrong-answer_by_gamechestaudio_preview.mp3",
				);
				if (data.isWin) {
					await wait(600);
					fire();
				}

				await wait(
					1000 * animationDurationsInSeconds.WAIT_BEFORE_RESET,
				);
			}

			setStatus("INITIAL");
		})();
	}, [isSuccess, data?.isWin, isLoading]);

	const isDisabled =
		isLoading ||
		!schema.safeParse(BigInt(amount.valueWei)).success ||
		!isUserConnected;

	const renderButton = () => {
		if (schema.safeParse(BigInt(amount.valueWei)).success) {
			return (
				<Button
					disabled={isDisabled}
					type="gradient"
					className={cx("h-[44px]", className)}
					onClick={() => {
						const promise = flip({
							face,
							amountWei: amount.valueWei,
							contractAddress,
						}).unwrap;

						toast.promise(promise, {
							error: "Failed to flip the coin",
						});
					}}
				>
					<Button.Text>Flip Coin</Button.Text>
				</Button>
			);
		}

		const isLarger =
			BigInt(amount.valueWei) > BigInt(maxBetData?.maxBetAmountWei || 0);

		const largerMsg = `Max bet amount is currently ${
			maxBetData?.maxBetAmount || 0
		}`;

		const smallerMsg = `Min bet amount is 2`;

		const message = isLarger ? largerMsg : smallerMsg;

		return (
			<Tooltip className="w-full" content={<p>{message}</p>}>
				<Button
					disabled
					type="gradient"
					className={cx("] h-[44px] w-full", className)}
					onClick={() => {}}
				>
					<Button.Text>Flip Coin</Button.Text>
				</Button>
			</Tooltip>
		);
	};
	return (
		<>
			<AnimatePresence mode="popLayout">
				{status === "INITIAL" && (
					<motion.div
						className="w-full"
						key="INITIAL"
						{...animationConfig}
					>
						{renderButton()}
					</motion.div>
				)}
				{status === "LOADING" && (
					<motion.div
						className="w-full"
						key="LOADING"
						{...animationConfig}
					>
						<Button
							onClick={() => {}}
							type="gradient"
							disabled
							className={cx("h-[44px]", className)}
						>
							<Button.Icon
								type="LOADER"
								className={cx("animate-spin")}
							/>
							<Button.Text>
								{width >= 768 ? "Generating randomness..." : ""}
							</Button.Text>
						</Button>
					</motion.div>
				)}
				{status === "WON" && (
					<motion.div
						key="WON"
						{...animationConfig}
						className="relative w-full"
					>
						<ReactCanvasConfetti
							refConfetti={getInstance}
							className="pointer-events-none absolute left-1/2 top-0 z-0 h-[1000px] w-[700px] -translate-x-1/2 -translate-y-2/3"
						/>
						<Button
							onClick={() => {}}
							className={cx("h-[44px] animate-bounce", className)}
						>
							<Button.Text>You Won</Button.Text>
						</Button>
					</motion.div>
				)}
				{status === "LOST" && (
					<motion.div
						className="w-full"
						key="LOST"
						{...animationConfig}
					>
						<Button
							onClick={() => {}}
							type="secondary"
							className={cx(
								"animate-shake animate-twice animate-ease-in-out h-[44px]",
								className,
							)}
						>
							<Button.Text>You Lost</Button.Text>
						</Button>
					</motion.div>
				)}
			</AnimatePresence>
		</>
	);
};

export { FlipButton };
