import React from "react";
import { cx } from "src/utils";
import { MinesCard } from "./MinesCard";
import { minesSlice } from "src/redux/minesSlice";
import { useAppDispatch, useAppSelector } from "src/redux/store";
import { mines } from "business-logic-gamblino";
import { Icon, Tooltip } from "@components/atoms";
import { Button } from "@components/molecules";

function addRandomMines(
	minesArray: boolean[],
	selectedFields: boolean[],
	totalMines: number,
): boolean[] {
	const resultArray = [...minesArray];

	const minesExploded = resultArray.filter((field) => field).length;

	const availableIndices = resultArray
		.map((value, index) => (selectedFields[index] ? null : index))
		.filter((index) => index !== null) as number[];

	const randomMineIndices = Array.from(
		{ length: totalMines - minesExploded },
		() => {
			const randomIndex = Math.floor(
				Math.random() * availableIndices.length,
			);

			const randomMinePosition = availableIndices[randomIndex];

			availableIndices.splice(randomIndex, 1);

			return randomMinePosition;
		},
	);

	randomMineIndices.map((index) => {
		resultArray[index] = true;
	});

	return resultArray;
}

const MinesSection: React.FC<{
	className?: string;
}> = ({ className }) => {
	const [isMinesReveal, setIsMinesReveal] = React.useState<boolean>(false);
	const [randomMines, setRandomMines] = React.useState<boolean[]>([]);
	const fields = useAppSelector((state) => state.minesSlice.fields);
	const minesNum = useAppSelector((state) => state.minesSlice.mines);
	const maxSelection = useAppSelector(
		(state) => state.minesSlice.maxSelection,
	);

	const dispatch = useAppDispatch();

	const [, { isLoading, isSuccess, data: mutationData }] =
		mines.useMinesMutation({
			fixedCacheKey: `useMinesMutation`,
		});

	React.useEffect(() => {
		if (isSuccess) {
			setIsMinesReveal(true);
			setRandomMines(
				addRandomMines(
					mutationData?.minesTiles || [],
					fields,
					minesNum,
				),
			);

			setTimeout(() => {
				setIsMinesReveal(false);
				setRandomMines([]);
			}, 5000);
		}
	}, [isSuccess]);

	const numOfMaxSelection = maxSelection[minesNum - 1];
	const numOfSelectedField = fields.filter((field) => field).length;

	const isMaxMinesSelected = numOfSelectedField >= numOfMaxSelection;

	const handleReset = () => {
		dispatch(minesSlice.actions.resetMines());
	};

	return (
		<div
			className={cx(
				"mx-auto flex w-full max-w-[412px] flex-col gap-4",
				className,
			)}
		>
			<div className="grid grid-cols-5 gap-2">
				{fields.map((field, i) => {
					if (
						isMaxMinesSelected &&
						!isLoading &&
						!isMinesReveal &&
						!randomMines[i] &&
						!fields[i]
					)
						return (
							<Tooltip
								content={`You can only select ${numOfMaxSelection} fields for ${minesNum} mines`}
							>
								<MinesCard
									key={i}
									isSelected={field}
									onSelect={() => {
										dispatch(
											minesSlice.actions.toggleSelection({
												index: i,
											}),
										);
									}}
									isDisabled={true}
									isMine={randomMines[i]}
									isMineSelected={randomMines[i] && fields[i]}
									isLoading={isLoading}
								/>
							</Tooltip>
						);

					return (
						<MinesCard
							key={i}
							isSelected={field}
							onSelect={() => {
								dispatch(
									minesSlice.actions.toggleSelection({
										index: i,
									}),
								);
							}}
							isDisabled={
								numOfMaxSelection === numOfSelectedField ||
								isLoading ||
								isMinesReveal
							}
							isMine={randomMines[i]}
							isMineSelected={randomMines[i] && fields[i]}
							isLoading={isLoading}
						/>
					);
				})}
			</div>
			<div className="flex items-center justify-center">
				<Button
					type="tertiary"
					size="sm"
					onClick={handleReset}
					className="flex cursor-pointer items-center justify-center gap-1"
				>
					<Icon type="RESET" className="stroke-content-primary w-5" />
					<p>Reset</p>
				</Button>
			</div>
		</div>
	);
};

export { MinesSection };
