import React from "react";
import dayjs from "dayjs";
import relativetime from "dayjs/plugin/relativeTime";

dayjs.extend(relativetime);

const TimeCell: React.FC<{
	time: number;
}> = ({ time }) => {
	return <p>{dayjs.unix(time).from(dayjs())}</p>;
};

export { TimeCell };
