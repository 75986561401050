import React from "react";
import { SimpleTable, Pagination } from "@components/organisms";
import { usePaginatedUserHistory } from "./usePaginatedUserQuery";
import { cx } from "src/utils";
import { DashboardPageSection } from "@components/atoms";

const UserHistoryTable = ({ className }: { className?: string }) => {
	const {
		table,
		isLoading,
		isUninitialized,
		isSuccess,
		isError,
		getCanNextPage,
		getCanPreviousPage,
		goToNextPage,
		goToPreviousPage,
		itemsCount,
		pageCount,
		pageIndex,
		pageSize,
		setPageIndex,
	} = usePaginatedUserHistory();

	return (
		<DashboardPageSection className={cx("", className)}>
			<div className="">
				<SimpleTable
					isLoading={isLoading || isUninitialized}
					isError={isError}
					isSuccess={isSuccess}
					itemsCount={itemsCount}
					pageSize={pageSize}
					table={table}
					className="bg-background-primary relative z-0"
				/>
				<Pagination
					getCanNextPage={getCanNextPage}
					getCanPreviousPage={getCanPreviousPage}
					goToNextPage={goToNextPage}
					goToPreviousPage={goToPreviousPage}
					itemsCount={itemsCount}
					pageCount={pageCount}
					pageIndex={pageIndex + 1}
					pageSize={pageSize}
					setPageIndex={setPageIndex}
					className="mt-4"
				/>
			</div>
		</DashboardPageSection>
	);
};

export { UserHistoryTable };
