/* eslint-disable max-lines-per-function */
import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react";
import { getRouletteUserHistory } from "../../../backend-service-gamblino/src";
import { getEndpointCreators } from "get-endpoint-creators";
import {
	calculateMaxBetAmount,
	getMaxBetAmount,
	wager,
} from "../service/roulette";
import { getMinBetAmount } from "contract-service-gamblino-bank";

const rouletteBusinessLogicApi = createApi({
	reducerPath: "rouletteBusinessLogicApi",
	baseQuery: fakeBaseQuery(),
	tagTypes: ["calculateMaxBetAmount"],
	endpoints: (builder) => {
		const { createQuery, createMutation } = getEndpointCreators(builder);

		return {
			wager: createMutation(wager),
			getRouletteUserHistory: createQuery(getRouletteUserHistory),
			maxBetAmount: createQuery(getMaxBetAmount),
			minBetAmount: createQuery(getMinBetAmount),
			calculateMaxBetAmount: createQuery(calculateMaxBetAmount, {
				providesTags: ["calculateMaxBetAmount"],
			}),
		};
	},
});

const {
	useWagerMutation,
	useGetRouletteUserHistoryQuery,
	useCalculateMaxBetAmountQuery,
	useMaxBetAmountQuery,
	useMinBetAmountQuery,
} = rouletteBusinessLogicApi;

const roulette = {
	useWagerMutation,
	useCalculateMaxBetAmountQuery,
	useRouletteUserHistory: useGetRouletteUserHistoryQuery,
	useMaxBetAmount: useMaxBetAmountQuery,
	useMinBetAmount: useMinBetAmountQuery,
};

export { roulette, rouletteBusinessLogicApi };
