import React from "react";
import { cx } from "src/utils";
import { motion, useTime, useTransform } from "framer-motion";
import { useLoadImageQuery } from "business-logic-gamblino";

const ANIMATION_SPEED = 0.001;

const RabbitIllustration = ({ className }: { className?: string }) => {
	const currentTime = useTime();

	const wiggleAnimation = {
		x: useTransform(
			currentTime,
			(value) => Math.sin(300 + value * ANIMATION_SPEED) * 3 + 20,
		),
		y: useTransform(
			currentTime,
			(value) => Math.cos(20 + value * ANIMATION_SPEED) * 3,
		),
		rotate: useTransform(
			currentTime,
			(value) => Math.sin(160 + value * ANIMATION_SPEED) * 2,
		),
	};

	const imageLoader = useLoadImageQuery({
		src: "/hero-illustration-rabbit.png",
	});

	const shouldRenderImageSkeleton =
		imageLoader.isLoading ||
		imageLoader.isFetching ||
		imageLoader.isUninitialized;
	return (
		<div className={cx("relative ", className)}>
			<motion.div
				initial={{ opacity: 0, scale: 0.9 }}
				animate={{
					opacity: shouldRenderImageSkeleton ? 0 : 1,
					scale: shouldRenderImageSkeleton ? 0.9 : 1,
				}}
				transition={{ ease: "easeInOut", duration: 2 }}
			>
				<motion.img
					src="/why-gamblino-rabbit.png"
					className="z-[1]"
					style={{
						...wiggleAnimation,
						scale: 0.7,
					}}
					transition={{
						repeat: Infinity,
						repeatType: "loop",
					}}
				></motion.img>
			</motion.div>
		</div>
	);
};

export { RabbitIllustration };
