import { TextSkeleton, Tooltip } from "@components/atoms";
import { formatPrice } from "formatting-service";
import React from "react";
import { cx } from "src/utils";

const TooltipNumberWrapper: React.FC<{
	number: number | string | undefined;
	formatPriceConfig?: Parameters<typeof formatPrice>[1];
	copy?: boolean;
	toolTipConfig?: Omit<
		React.ComponentProps<typeof Tooltip>,
		"content" | "children"
	>;
	skeletonClassNames?: string;
}> = ({
	number: numberProp,
	formatPriceConfig,
	copy,
	toolTipConfig,
	skeletonClassNames,
}) => {
	if (numberProp === undefined) {
		return <TextSkeleton className={cx("w-[4ch]", skeletonClassNames)} />;
	}

	const num =
		typeof numberProp === "number" ? numberProp : parseFloat(numberProp);

	return (
		<Tooltip
			content={numberProp}
			copy={copy ? numberProp.toString() : undefined}
			{...toolTipConfig}
		>
			{formatPrice(num, formatPriceConfig)}
		</Tooltip>
	);
};

export { TooltipNumberWrapper };
