import * as React from "react";

import { Icon } from "@components/atoms/Icon";
import { cx } from "src/utils";

const InputIcon: typeof Icon = (props) => {
	const { className, ...rest } = props;
	return (
		<Icon
			className={cx("ml-1 h-4 w-4 stroke-black", className)}
			{...rest}
		/>
	);
};

export { InputIcon };
