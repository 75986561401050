import { ApolloClient, DefaultOptions, InMemoryCache } from "@apollo/client";
import { getSubgraphUrlFromEnv } from "./getSubgraphUrlFromEnv";

const url = getSubgraphUrlFromEnv();

const defaultOptions: DefaultOptions = {
	watchQuery: {
		fetchPolicy: "no-cache",
		errorPolicy: "ignore",
	},
	query: {
		fetchPolicy: "no-cache",
		errorPolicy: "all",
	},
};

const client = new ApolloClient({
	uri: url,
	cache: new InMemoryCache(),
	defaultOptions: defaultOptions,
});

export { client };
