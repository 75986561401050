import { Address } from 'viem';
import { wagmiConfig } from 'wallet-connection';
import { readContract, getAccount } from "wagmi/actions";

import { abi } from "../../config";

const getLastUserBets = async ({	
	contractAddress,
}: {
	contractAddress: Address;
}) => {
	const { address } = await getAccount(wagmiConfig);

	const result = await readContract(wagmiConfig, {
		abi,
		address: contractAddress,
		functionName: "getLastUserBets",
		args: [address, BigInt(1)],
	});

	return { result };
};

export { getLastUserBets };
