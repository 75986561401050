import { z } from "zod";
import { motion } from "framer-motion";
import React, { useState } from "react";
import { useAccount, useBalance } from "wagmi";
import { useChain, useOdds, usePrepareBet } from "@azuro-org/sdk";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import { azuroBussinessLogicApi } from "business-logic-gamblino";

import { isMatchInPast, cx } from "src/utils";
import { AssetIcon } from "@components/atoms";
import { AZURO_AFFILIATE_ADDRESS } from "src/config";
import { InvalidBetMessage } from "../../InvalidBetMessage";
import { AzuroState, azuroSlice } from "src/redux/azuroSlice";
import { useAppDispatch, useAppSelector } from "src/redux/store";
import { useModalManager } from "@components/ethereals/ModalsWrapper";
import { Button, Input, TooltipNumberWrapper } from "@components/molecules";
import WalletConnectionWrapper from "@components/organisms/WalletConnectionWrapper/WalletConnectionWrapper";

function hasDuplicateParticipants(betArray: AzuroState[]): boolean {
	const uniqueParticipants: string[] = [];

	return betArray.some((bet) => {
		const participantsKey = bet.participants.slice().sort().join(",");

		if (uniqueParticipants.includes(participantsKey)) {
			// Duplicate participants found
			return true;
		}

		uniqueParticipants.push(participantsKey);
		return false;
	});
}

const PlaceBet: React.FC<{ className?: string; slippage: number }> = ({
	className,
	slippage,
}) => {
	const [amount, setAmount] = useState("");

	const { modalManager } = useModalManager();

	const account = useAccount();
	const { betToken } = useChain();

	const { data: balance } = useBalance({
		address: account.address,
		token: betToken.address,
	});

	const parsableNumberSchema = z.coerce
		.number({
			invalid_type_error: "Please provide a valid number",
		})
		.max(
			balance?.formatted
				? parseFloat(balance.formatted)
				: Number.MAX_VALUE,
			{
				message: "You don't have enough tokens to place this bet.",
			},
		)
		.nonnegative()
		.multipleOf(0.001, { message: "Please enter no more than 3 decimals" });

	const amountValidated = parsableNumberSchema.safeParse(amount);

	const dispatch = useAppDispatch();
	const bets = useAppSelector((state) => state.azuro);

	const { openConnectModal } = useConnectModal();

	const odds = useOdds({
		betAmount: "0.01",
		selections: bets,
	});

	const hasDuplicateBet = hasDuplicateParticipants(bets);

	const hasUnavailableMatches = bets.some((bet) =>
		isMatchInPast(bet.startsAt),
	);

	const res = usePrepareBet({
		betAmount: amountValidated.success ? amount : "0",
		slippage,
		odds: odds.odds,
		totalOdds: odds.totalOdds,
		affiliate: AZURO_AFFILIATE_ADDRESS,
		selections: bets.length
			? bets.map((bet) => ({
					conditionId: bet.conditionId,
					outcomeId: bet.outcomeId,
					coreAddress: bet.coreAddress,
				}))
			: [{ conditionId: "", outcomeId: "", coreAddress: "" }],
		onSuccess: (receipt) => {
			dispatch(azuroSlice.actions.deleteAllBets());
			setAmount("");

			modalManager.open("SuccessSeeTxModal", {
				heading: "Success",
				description: `You successfully placed bet.`,
				txHash: receipt?.transactionHash || "",
			});

			setTimeout(() => {
				dispatch(azuroBussinessLogicApi.util.invalidateTags(["Bets"]));
			}, 1000);
		},
	});

	const connectWallet = () => {
		if (!openConnectModal) return;

		openConnectModal();
	};

	const placeBet = () => {
		res.submit();
	};

	const totalPayment = parseFloat(amount) * odds.totalOdds || 0;

	const btnText = account.isConnected
		? res.isApproveRequired
			? "Approve USDT"
			: "Place a bet"
		: "Connect wallet";
	const btnOnClickFn = account.isConnected ? placeBet : connectWallet;

	const maxBalanceValue =
		(parseFloat(balance?.formatted || "") - 0.001) * 0.99;

	const renderInvalidBetMessage = () => {
		if (!amountValidated.success) {
			const errors = JSON.parse(amountValidated.error.message) || "";

			const error = errors.find((err) => err?.code);

			return <InvalidBetMessage text={error?.message || ""} />;
		}

		if (hasDuplicateBet) {
			return (
				<InvalidBetMessage text="Please remove all duplicate outcomes to place a bet." />
			);
		}

		if (hasUnavailableMatches) {
			return (
				<InvalidBetMessage text="Please remove all unavailable outcomes to place a bet." />
			);
		}

		return <></>;
	};

	const isLoading =
		res.approveTx.isPending ||
		res.approveTx.isProcessing ||
		res.betTx.isPending ||
		res.betTx.isProcessing;

	return (
		<motion.div
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			className={cx(
				"bg-background-tertiary flex flex-col gap-3 px-4 lg:px-2",
				className,
			)}
		>
			<div className=" w-full">
				<Input
					type="text"
					name="betValue"
					value={amount}
					placeholder={"0"}
					setValue={setAmount}
					className="border-border-primary h-10 w-full border"
				>
					<Input.Button
						type="ghost"
						disabled={!account.isConnected}
						className="bg-background-primary h-8 self-center"
						onClick={() => {
							setAmount(`${maxBalanceValue.toFixed(3)}`);
						}}
					>
						<Input.Button.Text>Max</Input.Button.Text>
					</Input.Button>

					<Input.AssetIcon type="TETHER" />
				</Input>
			</div>

			<div className=" flex justify-between ">
				<p className="text-content-secondary  text-xs">Total odds</p>

				<p className="text-content-primary  text-xs">
					<TooltipNumberWrapper number={odds.totalOdds || 0} />
				</p>
			</div>
			<div className="flex justify-between">
				<p className="text-content-secondary  text-xs">Total payout</p>

				<div className="flex gap-2">
					<AssetIcon type="TETHER" className="h-4 w-4" />
					<p className="text-content-primary  text-xs">
						<TooltipNumberWrapper number={totalPayment} />
					</p>
				</div>
			</div>

			<div className="h-10">{renderInvalidBetMessage()}</div>

			<WalletConnectionWrapper>
				<Button
					onClick={btnOnClickFn}
					className="bg-purple-gradient w-full"
					disabled={
						hasDuplicateBet ||
						hasUnavailableMatches ||
						// isLoading ||
						bets.length === 0 ||
						amount === "" ||
						!amountValidated.success
					}
				>
					{isLoading ? (
						<Button.Icon
							type="LOADER"
							className={cx("animate-spin")}
						/>
					) : (
						<></>
					)}

					<Button.Text>{btnText}</Button.Text>
				</Button>
			</WalletConnectionWrapper>
		</motion.div>
	);
};

export { PlaceBet };
