import { GamesQueryVariables } from "../gql/graphql";
import { client } from "../apollo";
import { graphql } from "../gql";

const query = graphql(`
	query Games(
		$first: Int
		$where: Game_filter
		$orderBy: Game_orderBy
		$orderDirection: OrderDirection
	) {
		games(
			first: $first
			where: $where
			orderBy: $orderBy
			orderDirection: $orderDirection
			subgraphError: allow
		) {
			gameId
			slug
			status
			startsAt
			league {
				name
				slug
				country {
					name
					slug
				}
			}
			sport {
				name
				slug
			}
			participants {
				image
				name
			}
			conditions {
				conditionId
				isExpressForbidden
				status
				outcomes {
					currentOdds
					outcomeId
				}
			}
		}
	}
`);

const getGames = async (variables: GamesQueryVariables) => {
	if (!client) {
		throw new Error("Apollo client not initialized");
	}
	const {
		data: { games },
	} = await client.query({
		query,
		variables,
	});

	return games;
};

export { getGames };
