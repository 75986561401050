export const abi = [
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "bankAddress",
		  "type": "address"
		},
		{
		  "internalType": "address",
		  "name": "entropy",
		  "type": "address"
		},
		{
		  "internalType": "address",
		  "name": "entropyProvider",
		  "type": "address"
		},
		{
		  "internalType": "uint8[24]",
		  "name": "maxReveal",
		  "type": "uint8[24]"
		}
	  ],
	  "stateMutability": "nonpayable",
	  "type": "constructor"
	},
	{
	  "inputs": [],
	  "name": "AccessDenied",
	  "type": "error"
	},
	{
	  "inputs": [],
	  "name": "ExcessiveHouseEdge",
	  "type": "error"
	},
	{
	  "inputs": [],
	  "name": "ForbiddenToken",
	  "type": "error"
	},
	{
	  "inputs": [],
	  "name": "InvalidAddress",
	  "type": "error"
	},
	{
	  "inputs": [],
	  "name": "InvalidNumMines",
	  "type": "error"
	},
	{
	  "inputs": [
		{
		  "internalType": "uint32",
		  "name": "numberPicked",
		  "type": "uint32"
		},
		{
		  "internalType": "uint256",
		  "name": "maxAllowed",
		  "type": "uint256"
		}
	  ],
	  "name": "InvalidNumberToReveal",
	  "type": "error"
	},
	{
	  "inputs": [],
	  "name": "NotFulfilled",
	  "type": "error"
	},
	{
	  "inputs": [],
	  "name": "NotPendingBet",
	  "type": "error"
	},
	{
	  "inputs": [],
	  "name": "TokenHasPendingBets",
	  "type": "error"
	},
	{
	  "inputs": [
		{
		  "internalType": "uint256",
		  "name": "minBetAmount",
		  "type": "uint256"
		}
	  ],
	  "name": "UnderMinBetAmount",
	  "type": "error"
	},
	{
	  "inputs": [],
	  "name": "WrongGasValueToCoverFee",
	  "type": "error"
	},
	{
	  "anonymous": false,
	  "inputs": [
		{
		  "indexed": false,
		  "internalType": "uint64",
		  "name": "id",
		  "type": "uint64"
		},
		{
		  "indexed": false,
		  "internalType": "address",
		  "name": "user",
		  "type": "address"
		},
		{
		  "indexed": false,
		  "internalType": "uint256",
		  "name": "amount",
		  "type": "uint256"
		}
	  ],
	  "name": "BetRefunded",
	  "type": "event"
	},
	{
	  "anonymous": false,
	  "inputs": [
		{
		  "indexed": false,
		  "internalType": "uint256",
		  "name": "id",
		  "type": "uint256"
		},
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "user",
		  "type": "address"
		},
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "token",
		  "type": "address"
		},
		{
		  "indexed": false,
		  "internalType": "uint256",
		  "name": "amount",
		  "type": "uint256"
		},
		{
		  "indexed": false,
		  "internalType": "bool[25]",
		  "name": "revealedTiles",
		  "type": "bool[25]"
		},
		{
		  "indexed": false,
		  "internalType": "uint8",
		  "name": "numMines",
		  "type": "uint8"
		},
		{
		  "indexed": false,
		  "internalType": "bool[25]",
		  "name": "minesTiles",
		  "type": "bool[25]"
		},
		{
		  "indexed": false,
		  "internalType": "uint256",
		  "name": "payout",
		  "type": "uint256"
		},
		{
		  "indexed": false,
		  "internalType": "uint256",
		  "name": "multiplier",
		  "type": "uint256"
		}
	  ],
	  "name": "MinesReveal",
	  "type": "event"
	},
	{
	  "anonymous": false,
	  "inputs": [
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "previousOwner",
		  "type": "address"
		},
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "newOwner",
		  "type": "address"
		}
	  ],
	  "name": "OwnershipTransferred",
	  "type": "event"
	},
	{
	  "anonymous": false,
	  "inputs": [
		{
		  "indexed": false,
		  "internalType": "address",
		  "name": "account",
		  "type": "address"
		}
	  ],
	  "name": "Paused",
	  "type": "event"
	},
	{
	  "anonymous": false,
	  "inputs": [
		{
		  "indexed": false,
		  "internalType": "uint256",
		  "name": "id",
		  "type": "uint256"
		},
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "user",
		  "type": "address"
		},
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "token",
		  "type": "address"
		},
		{
		  "indexed": false,
		  "internalType": "uint256",
		  "name": "amount",
		  "type": "uint256"
		},
		{
		  "indexed": false,
		  "internalType": "uint256",
		  "name": "entropyCost",
		  "type": "uint256"
		},
		{
		  "indexed": false,
		  "internalType": "bool[25]",
		  "name": "tilesPicked",
		  "type": "bool[25]"
		}
	  ],
	  "name": "PlaceBet",
	  "type": "event"
	},
	{
	  "anonymous": false,
	  "inputs": [
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "token",
		  "type": "address"
		},
		{
		  "indexed": false,
		  "internalType": "uint16",
		  "name": "houseEdge",
		  "type": "uint16"
		}
	  ],
	  "name": "SetHouseEdge",
	  "type": "event"
	},
	{
	  "anonymous": false,
	  "inputs": [
		{
		  "indexed": false,
		  "internalType": "address",
		  "name": "account",
		  "type": "address"
		}
	  ],
	  "name": "Unpaused",
	  "type": "event"
	},
	{
	  "inputs": [
		{
		  "internalType": "uint64",
		  "name": "sequence",
		  "type": "uint64"
		},
		{
		  "internalType": "address",
		  "name": "provider",
		  "type": "address"
		},
		{
		  "internalType": "bytes32",
		  "name": "randomNumber",
		  "type": "bytes32"
		}
	  ],
	  "name": "_entropyCallback",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "bank",
	  "outputs": [
		{
		  "internalType": "contract IBank",
		  "name": "",
		  "type": "address"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "uint64",
		  "name": "",
		  "type": "uint64"
		}
	  ],
	  "name": "bets",
	  "outputs": [
		{
		  "internalType": "bool",
		  "name": "resolved",
		  "type": "bool"
		},
		{
		  "internalType": "address",
		  "name": "user",
		  "type": "address"
		},
		{
		  "internalType": "address",
		  "name": "token",
		  "type": "address"
		},
		{
		  "internalType": "uint64",
		  "name": "id",
		  "type": "uint64"
		},
		{
		  "internalType": "uint256",
		  "name": "amount",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "blockNumber",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "payout",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "entropyCost",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "getFee",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "fee",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "user",
		  "type": "address"
		},
		{
		  "internalType": "uint256",
		  "name": "dataLength",
		  "type": "uint256"
		}
	  ],
	  "name": "getLastUserBets",
	  "outputs": [
		{
		  "components": [
			{
			  "components": [
				{
				  "internalType": "bool",
				  "name": "resolved",
				  "type": "bool"
				},
				{
				  "internalType": "address",
				  "name": "user",
				  "type": "address"
				},
				{
				  "internalType": "address",
				  "name": "token",
				  "type": "address"
				},
				{
				  "internalType": "uint64",
				  "name": "id",
				  "type": "uint64"
				},
				{
				  "internalType": "uint256",
				  "name": "amount",
				  "type": "uint256"
				},
				{
				  "internalType": "uint256",
				  "name": "blockNumber",
				  "type": "uint256"
				},
				{
				  "internalType": "uint256",
				  "name": "payout",
				  "type": "uint256"
				},
				{
				  "internalType": "uint256",
				  "name": "entropyCost",
				  "type": "uint256"
				}
			  ],
			  "internalType": "struct Game.Bet",
			  "name": "bet",
			  "type": "tuple"
			},
			{
			  "components": [
				{
				  "internalType": "uint8",
				  "name": "numMines",
				  "type": "uint8"
				},
				{
				  "internalType": "bool[25]",
				  "name": "tilesPicked",
				  "type": "bool[25]"
				},
				{
				  "internalType": "bool[25]",
				  "name": "revealedTiles",
				  "type": "bool[25]"
				},
				{
				  "internalType": "bool[25]",
				  "name": "minesTiles",
				  "type": "bool[25]"
				}
			  ],
			  "internalType": "struct Mines.MinesBet",
			  "name": "minesBet",
			  "type": "tuple"
			}
		  ],
		  "internalType": "struct Mines.FullMinesBet[]",
		  "name": "",
		  "type": "tuple[]"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "getMaxReveal",
	  "outputs": [
		{
		  "internalType": "uint256[24]",
		  "name": "maxReveal",
		  "type": "uint256[24]"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "uint256",
		  "name": "numMines",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "numRevealed",
		  "type": "uint256"
		}
	  ],
	  "name": "getMultipliers",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "multiplier",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "token",
		  "type": "address"
		}
	  ],
	  "name": "hasPendingBets",
	  "outputs": [
		{
		  "internalType": "bool",
		  "name": "",
		  "type": "bool"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "name": "minesBets",
	  "outputs": [
		{
		  "internalType": "uint8",
		  "name": "numMines",
		  "type": "uint8"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "bytes[]",
		  "name": "data",
		  "type": "bytes[]"
		}
	  ],
	  "name": "multicall",
	  "outputs": [
		{
		  "internalType": "bytes[]",
		  "name": "results",
		  "type": "bytes[]"
		}
	  ],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "owner",
	  "outputs": [
		{
		  "internalType": "address",
		  "name": "",
		  "type": "address"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "pause",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "paused",
	  "outputs": [
		{
		  "internalType": "bool",
		  "name": "",
		  "type": "bool"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "uint64",
		  "name": "id",
		  "type": "uint64"
		}
	  ],
	  "name": "refundBet",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "renounceOwnership",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "token",
		  "type": "address"
		},
		{
		  "internalType": "uint16",
		  "name": "houseEdge",
		  "type": "uint16"
		}
	  ],
	  "name": "setHouseEdge",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "uint256",
		  "name": "numMines",
		  "type": "uint256"
		}
	  ],
	  "name": "setMultipliers",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "",
		  "type": "address"
		}
	  ],
	  "name": "tokens",
	  "outputs": [
		{
		  "internalType": "uint16",
		  "name": "houseEdge",
		  "type": "uint16"
		},
		{
		  "internalType": "uint64",
		  "name": "pendingCount",
		  "type": "uint64"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "newOwner",
		  "type": "address"
		}
	  ],
	  "name": "transferOwnership",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "bool[25]",
		  "name": "tilesPicked",
		  "type": "bool[25]"
		},
		{
		  "internalType": "uint8",
		  "name": "numMines",
		  "type": "uint8"
		},
		{
		  "internalType": "bytes32",
		  "name": "userRandomNumber",
		  "type": "bytes32"
		},
		{
		  "internalType": "address",
		  "name": "token",
		  "type": "address"
		},
		{
		  "internalType": "uint256",
		  "name": "tokenAmount",
		  "type": "uint256"
		}
	  ],
	  "name": "wager",
	  "outputs": [],
	  "stateMutability": "payable",
	  "type": "function"
	}
  ] as const ;
