/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Address } from "viem";
import { getCoinUserHistory } from "../../../../../backend-service-gamblino/src";

const getLastUserBets = async ({
	txHash,
	userAddress,
}: {
	txHash?: string;
	userAddress: Address;
}) => {
	const result = await getCoinUserHistory({ userAddress });

	const foundGame = result?.games.find((game) => game.betTxHash === txHash);

	return {
		...result,
		foundGame,
	};
};

export { getLastUserBets };
