import React from "react";

import { type IModal } from "@components/organisms";
import { Modal } from "@components/organisms";
import { StaticWheel } from "src/Pages/RoulettePage/GameSection/Wheel";
import { Button } from "@components/molecules";
import { useModalManager } from "@components/ethereals/ModalsWrapper";

const RouletteWheelModal: IModal<"RouletteWheelModal"> &
	React.FC<{ selectedNumbers: string[]; rolled?: number }> = ({
	selectedNumbers,
	rolled,
}) => {
	const { modalManager } = useModalManager();
	return (
		<Modal className="bg-background-secondary border-violet-light/20 relative w-[250px] overflow-hidden rounded-xl border p-6 md:w-[350px]">
			<Modal.Step className="flex flex-col items-center gap-6">
				<p className="text-center text-xl ">Game Details</p>
				<StaticWheel
					key={rolled}
					className="aspect-square w-full"
					selectedNumbers={selectedNumbers}
					rolled={rolled}
				/>

				<Button
					className="w-full max-w-[180px]"
					type="outline"
					size="sm"
					onClick={() => modalManager.close("RouletteWheelModal")}
				>
					<Button.Text>Close</Button.Text>
				</Button>
			</Modal.Step>
		</Modal>
	);
};

RouletteWheelModal.modalName = "RouletteWheelModal";

export { RouletteWheelModal };
