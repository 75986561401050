import {
	getFee,
	getLastUserBets,
	wager as wagerContract,
} from "contract-service-dice";
import { wagmiConfig } from 'wallet-connection';
import { getAccount } from "wagmi/actions";

import wait from "wait";
import { zeroAddress } from "viem";


const wager = async ({
	amountWei,
	contractAddress,
	cap,
}: Omit<Parameters<typeof wagerContract>[0], "feeWei">) => {

	const {fee} = await getFee({ contractAddress });
	
	const { txHash, betId } = await wagerContract({
		amountWei,
		contractAddress,
		cap,
		feeWei: fee.toString(),
	});

	const { address } = getAccount(wagmiConfig);

	const { payout, rolled } = await new Promise<{
		payout: number;
		rolled: number;
	}>((resolve, reject) => {
		void (async () => {
			// eslint-disable-next-line no-constant-condition
			for (let i = 0; i < 20; i++) {
				const result = await getLastUserBets({
					userAddress: address || zeroAddress,
					contractAddress,
					dataLength: 1,
				});
				if (result.bets[0]) {
					if (
						result.bets[0].id.toString() === betId &&
						result.bets[0].resolved
					) {
						resolve({
							payout: result.bets[0].payout,
							rolled: result.bets[0].rolled,
						});
						break;
					}
				}
				await wait(1000);
			}
			reject({ message: "Request timed out" });
		})();
	});


	return { txHash, betId, rolled, isWin: rolled > cap };
};

export { wager };
