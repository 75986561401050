import { AssetIcon } from "@components/atoms";
import { TooltipNumberWrapper } from "@components/molecules";
import React from "react";

const PayoutCell: React.FC<{
	payout: number;
}> = ({ payout }) => {
	return (
		<div className="flex items-center gap-1">
			<AssetIcon type="SEI" className="h-5 w-5" />
			<TooltipNumberWrapper number={payout} copy />
		</div>
	);
};

export { PayoutCell };
