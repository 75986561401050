import React from "react";
import { Address, parseUnits } from "viem";
import { roulette, useTokensPriceQuery } from "business-logic-gamblino";

import { cx } from "src/utils";
import { SpinButton } from "../SpinButton";
import { Tooltip } from "@components/atoms";
import { useAppSelector } from "src/redux/store";
import { CONTRACT_ADDRESS_GAMBLINO_BANK } from "src/config";
import { AssetIconTypeRoulette } from "../../AssetIconRoulette";
import { Input, TooltipNumberWrapper } from "@components/molecules";
import { z } from "zod";

const AmountInput: React.FC<{
	className?: string;
	setAmount: React.Dispatch<
		React.SetStateAction<{
			valueWei: string;
			value: string;
		}>
	>;
	amount: { valueWei: string; value: string };
	contractAddress: Address;
}> = ({ className, contractAddress, setAmount, amount }) => {
	const { data } = useTokensPriceQuery(undefined, {
		pollingInterval: 30000,
	});
	const { selectedNumbers } = useAppSelector((state) => state.roulette);

	const amountConverted =
		parseFloat(amount.value) * (data?.prices.sei || 1) || 0;

	const { data: maxBetData, isFetching } =
		roulette.useCalculateMaxBetAmountQuery(
			{
				contractAddress,
				bankContractAddress: CONTRACT_ADDRESS_GAMBLINO_BANK,
				numbers: selectedNumbers,
			},
			{ pollingInterval: 5000, refetchOnMountOrArgChange: true },
		);

	const [wager, { isLoading: isGamePlaying }] = roulette.useWagerMutation({
		fixedCacheKey: "rouletteBusinessLogicApi.useWagerMutation",
	});

	const schema = z
		.bigint()
		.max(BigInt(maxBetData?.maxBetAmountWei || 0))
		.refine(
			(value) => {
				return value >= parseUnits("2", 18);
			},
			{
				message: "Min bet amount is 2 SEI",
			},
		);

	return (
		<div
			className={cx(
				"@xl/dashboard:flex-row @xl/dashboard:items-end flex w-full flex-col gap-4",
				className,
			)}
		>
			<div className="flex flex-1 flex-col gap-1">
				<div>
					<div className="flex items-center justify-between px-1">
						<p className=" text-content-secondary text-sm">
							Enter Amount
						</p>
						<p className="text-content-primary text-sm">
							$
							<TooltipNumberWrapper number={amountConverted} />
						</p>
					</div>
				</div>
				<Input
					type="text"
					name="betValue"
					disabled={isGamePlaying}
					placeholder={"0"}
					className={cx("h-[44px] w-full", {
						"border-background-error": !schema.safeParse(
							BigInt(amount.valueWei),
						).success,
					})}
					onChange={(e) => {
						setAmount({
							value: e.currentTarget.value,
							valueWei: parseUnits(
								e.currentTarget.value,
								18,
							).toString(),
						});
					}}
					value={amount.value}
				>
					<Input.AssetIcon type={AssetIconTypeRoulette} />

					<Input.Button
						disabled={isFetching || isGamePlaying}
						onClick={() => {
							if (isFetching || isGamePlaying) {
								return;
							}
							setAmount({
								value: maxBetData?.maxBetAmount || "",
								valueWei: maxBetData?.maxBetAmountWei || "",
							});
						}}
						type="tertiary"
						size="sm"
						className="self-center"
					>
						<Input.Button.Text className="text-content-primary">
							MAX
						</Input.Button.Text>
					</Input.Button>
				</Input>
			</div>
			<div className="@xl/dashboard:w-fit w-full">
				<SpinButton contractAddress={contractAddress} amount={amount} />
			</div>
		</div>
	);
};

export { AmountInput };
