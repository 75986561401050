import React from "react";

import {
	ZERO,
	FIRST_GROUP,
	THIRD_GROUP,
	SECOND_GROUP,
	ROW_SELECTION,
	SELECTION_FIRST_GROUP,
	SELECTION_SECOND_GROUP,
} from "../../config";
import { cx } from "src/utils";

import { RouletteButton } from "./RouletteButton";
import { useRouletteHoveredNumbers } from "src/redux/useRouletteHoveredNumbers";
import { useRouletteSelectedNumbers } from "src/redux/useRouletteSelectedNumbers";
import { useAppSelector } from "src/redux/store";
import { useAudioManager } from "src/redux";
import { roulette } from "business-logic-gamblino";

// const SOUND_PATH =
// "/sounds/neutral/8145614_selection_by_gamechestaudio_preview.mp3";

const SOUND_PATH =
	"/sounds/neutral/20595124_button_by_sound_effect_preview.mp3";

const BoardButtons: React.FC<{ className?: string }> = ({ className }) => {
	const { playSoundOnce } = useAudioManager();
	const {
		selectedNumbers,
		toggleGroupSelection,
		toggleRowSelection,
		toggleSingleSelection,
	} = useRouletteSelectedNumbers();

	const [wager, { isLoading }] = roulette.useWagerMutation({
		fixedCacheKey: "rouletteBusinessLogicApi.useWagerMutation",
	});

	const { toggleHover } = useRouletteHoveredNumbers();

	const history = useAppSelector((state) => state.roulette.history);

	return (
		<div
			className={cx(
				"flex flex-col items-center justify-center gap-1 rounded-xl ",
				className,
				{ "pointer-events-none cursor-default": isLoading },
			)}
		>
			<div className="flex w-full gap-1">
				<div className="md:w-10">
					<RouletteButton
						value={ZERO.value}
						type={ZERO.type}
						className="h-full md:w-10"
						placed={selectedNumbers.includes(ZERO.value)}
						onClick={() => {
							playSoundOnce(SOUND_PATH);
							toggleSingleSelection(ZERO.value);
						}}
						onHover={(isHover) =>
							toggleHover({ value: ZERO.value, isHover })
						}
					/>
				</div>

				<div className="flex w-full flex-col gap-1">
					<div className="flex w-full shrink-0 gap-1">
						{FIRST_GROUP.map((btn) => (
							<RouletteButton
								key={btn.value}
								value={btn.value}
								type={btn.type}
								placed={selectedNumbers.includes(btn.value)}
								onClick={() => {
									playSoundOnce(SOUND_PATH);
									toggleSingleSelection(btn.value);
								}}
								onHover={(isHover) =>
									toggleHover({ value: btn.value, isHover })
								}
							/>
						))}
					</div>

					<div className="flex gap-1">
						{SECOND_GROUP.map((btn) => (
							<RouletteButton
								key={btn.value}
								value={btn.value}
								type={btn.type}
								placed={selectedNumbers.includes(btn.value)}
								onClick={() => {
									playSoundOnce(SOUND_PATH);
									toggleSingleSelection(btn.value);
								}}
								onHover={(isHover) =>
									toggleHover({ value: btn.value, isHover })
								}
							/>
						))}
					</div>
					<div className="flex gap-1">
						{THIRD_GROUP.map((btn) => (
							<RouletteButton
								key={btn.value}
								value={btn.value}
								type={btn.type}
								placed={selectedNumbers.includes(btn.value)}
								onClick={() => {
									playSoundOnce(SOUND_PATH);
									toggleSingleSelection(btn.value);
								}}
								onHover={(isHover) =>
									toggleHover({ value: btn.value, isHover })
								}
							/>
						))}
					</div>
				</div>
				<div className="flex flex-col gap-1">
					{ROW_SELECTION.map((btn, i) => (
						<RouletteButton
							key={`${btn.value}${i}`}
							value={btn.value}
							className="md:w-10"
							onClick={() => {
								playSoundOnce(SOUND_PATH);
								toggleRowSelection(btn.action);
							}}
							onHover={(isHover) =>
								toggleHover({ value: btn.action, isHover })
							}
						/>
					))}
				</div>
			</div>

			<div className="mt-1 flex w-full flex-col gap-1">
				<div className="flex w-full gap-1">
					{SELECTION_FIRST_GROUP.map((btn, i) => (
						<RouletteButton
							key={`${btn.value}${i}`}
							value={btn.value}
							className="w-full"
							onClick={() => {
								playSoundOnce(SOUND_PATH);
								toggleGroupSelection(btn.action);
							}}
							onHover={(isHover) =>
								toggleHover({ value: btn.action, isHover })
							}
						/>
					))}
				</div>
				<div className="flex gap-1">
					{SELECTION_SECOND_GROUP.map((btn, i) => (
						<RouletteButton
							key={`${btn.value}${i}`}
							value={btn.value}
							type={btn.type}
							className="w-full"
							onClick={() => {
								playSoundOnce(SOUND_PATH);
								toggleGroupSelection(btn.action);
							}}
							onHover={(isHover) =>
								toggleHover({ value: btn.action, isHover })
							}
						/>
					))}
				</div>
			</div>
		</div>
	);
};

export { BoardButtons };
