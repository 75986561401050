/* eslint-disable max-lines-per-function */
import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react";
import { getEndpointCreators } from "get-endpoint-creators";
import {
	getCountries,
	getCountry,
	getLeague,
	getLeagues,
	getSport,
	getGames,
	getGame,
	getHotSports,
	getGamesList,
	getSportsList,
	getBets,
} from "azuro-service";
import { getAzuroScore, getLeaderboard } from "../../../backend-service-gamblino/src";

const azuroBussinessLogicApi = createApi({
	reducerPath: "azuroBussinessLogicApi",
	baseQuery: fakeBaseQuery(),
	tagTypes: ["Bets"],
	endpoints: (builder) => {
		const { createQuery, createMutation } = getEndpointCreators(builder);

		return {
			country: createQuery(getCountry),
			countries: createQuery(getCountries),
			games: createQuery(getGames),
			game: createQuery(getGame),
			league: createQuery(getLeague),
			leagues: createQuery(getLeagues),
			sport: createQuery(getSport),
			sportsList: createQuery(getSportsList),
			hotSports: createQuery(getHotSports),
			gamesList: createQuery(getGamesList),
			bets: createQuery(getBets, { providesTags: ["Bets"] }),
			score: createQuery(getAzuroScore),
		};
	},
});

const {
	useCountryQuery,
	useCountriesQuery,
	useGamesQuery,
	useGameQuery,
	useLeagueQuery,
	useLeaguesQuery,
	useSportQuery,
	useSportsListQuery,
	useHotSportsQuery,
	useGamesListQuery,
	useBetsQuery,
	useScoreQuery,
} = azuroBussinessLogicApi;

const azuro = {
	useCountryQuery,
	useCountriesQuery,
	useGamesQuery,
	useGameQuery,
	useLeagueQuery,
	useLeaguesQuery,
	useSportQuery,
	useSportsListQuery,
	useHotSportsQuery,
	useGamesListQuery,
	useBetsQuery,
	useScoreQuery,
};

export { azuro, azuroBussinessLogicApi };
