import React from "react";
import { type ColumnDef } from "@tanstack/react-table";
import { minesBusinessLogic } from "business-logic-gamblino";
import {
	EntryCell,
	PayoutCell,
	TimeCell,
	MultiplierCell,
	ProfitCell,
} from "./Cells";

const PAGE_SIZE = 5;

const columns: ColumnDef<
	(typeof minesBusinessLogic)["endpoints"]["minesUserHistory"]["Types"]["ResultType"]["games"][number]
>[] = [
	{
		accessorFn: (row) => row.amount,
		id: "Bet amount",
		header: () => <p className="text-content-secondary text-sm">Entry</p>,
		cell: (info) => <EntryCell amount={info.row.original.amount} />,
		footer: (props) => props.column.id,
	},
	{
		accessorFn: (row) => row.multiplier,
		id: "multiplier",
		header: () => (
			<p className="text-content-secondary text-sm">Multiplier</p>
		),
		cell: (info) => (
			<MultiplierCell multiplier={info.row.original.multiplier} />
		),
		footer: (props) => props.column.id,
	},
	{
		accessorFn: (row) => row.payout,
		id: "Payout",
		header: () => <p className="text-content-secondary text-sm">Payout</p>,
		cell: (info) => <PayoutCell payout={info.row.original.payout} />,
		footer: (props) => props.column.id,
	},
	{
		accessorFn: (row) => row.payout,
		id: "Profit",
		header: () => <p className="text-content-secondary text-sm">Profit</p>,
		cell: (info) => (
			<ProfitCell
				profit={info.row.original.payout - info.row.original.amount}
			/>
		),
		footer: (props) => props.column.id,
	},
	{
		accessorFn: (row) => row.blockTimestamp,
		id: "Time played",
		header: () => (
			<p className="text-content-secondary text-sm">Time played</p>
		),
		cell: (info) => <TimeCell time={info.row.original.blockTimestamp} />,
		footer: (props) => props.column.id,
	},
	// {
	// 	accessorFn: (row) => row.payout,
	// 	id: "Details",
	// 	header: () => "Details",
	// 	cell: (info) => (
	// 		<PlayedSelectionCell
	// 			playedSelection={info.row.original.number}
	// 			rolled={info.row.original.rolled}
	// 		/>
	// 	),
	// 	footer: (props) => props.column.id,
	// },
];

export { PAGE_SIZE, columns };
