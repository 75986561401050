import React from "react";
import { FloatingOverlay } from "@floating-ui/react";
import { useOnClickOutside } from "usehooks-ts";
import { AnimatePresence, motion } from "framer-motion";
import { cx } from "src/utils";

export interface IModal<N> {
	modalName: N;
}

const configureModalManager = <
	ModalName extends string = any,
	DifferentPropType extends Parameters<React.FC>["0"] = any,
	M extends IModal<ModalName> & React.FC<DifferentPropType> = any,
>(
	modals: M[],
) => {
	// Remaps modals to state type
	// Example:

	// 	IModal<"AcceptOfferModal"> & React.FC<{
	//     contractAddress: string;
	//     itemId: number;
	//     asker: string;
	// }> | IModal<"RevealModal"> & React.FC<{
	//     contractAddress: string;
	// 	}>
	//
	// 	will be remapped to
	// {
	// 	AcceptOfferModal: {
	// 		modalName: "AcceptOfferModal",
	// 		props: {
	// 			contractAddress: string;
	// 			itemId: number;
	// 			asker: string;
	// 		},
	//    isOpen: boolean,
	//    jsxElement: ...
	// 	},
	// 	RevealModal: {
	// 		modalName: "RevealModal",
	// 		props: {
	// 			contractAddress: string;
	// 		},
	//    isOpen: boolean,
	//    jsxElement: ...
	// 	}
	// }

	type StateType = {
		[K in (typeof modals)[number] as K["modalName"]]: {
			modalName: K["modalName"];
			props: React.ComponentPropsWithoutRef<K>;
			isOpen: boolean;
			jsxElement: Omit<K, "modalName">;
		};
	};

	const useModalManagerInternal = () => {
		const [notClosable, setNotClosable] = React.useState(false);

		const stateInitializer = () => {
			const defaultState = {} as StateType;
			return modals.reduce<StateType>(
				(acc, modal) => ({
					...acc,
					[modal.modalName]: {
						isOpen: false,
						jsxElement: modal,
						props: undefined,
						modalName: modal.modalName,
					},
				}),
				defaultState,
			);
		};

		const [modalManager, setModalManager] =
			React.useState(stateInitializer);

		const openModal = <MN extends keyof StateType>(
			modalName: MN,
			props: StateType[MN]["props"],
		) => {
			if (modalName in modalManager) {
				setModalManager((prev) => {
					const modalToOpen = prev[modalName];
					return {
						...prev,
						[modalName]: {
							...modalToOpen,
							isOpen: true,
							props,
						},
					};
				});
			}
		};

		const closeModal = <MN extends keyof StateType>(modalName: MN) => {
			setModalManager((prev) => {
				const modalToOpen = prev[modalName];
				return {
					...prev,
					[modalName]: {
						...modalToOpen,
						isOpen: false,
					},
				};
			});
		};

		return {
			modalManager,
			openModal,
			closeModal,
			setNotClosable,
			notClosable,
		};
	};

	const ModalManagerContext = React.createContext<{
		openModal: ReturnType<typeof useModalManagerInternal>["openModal"];
		closeModal: ReturnType<typeof useModalManagerInternal>["closeModal"];
		setNotClosable: ReturnType<
			typeof useModalManagerInternal
		>["setNotClosable"];
		notClosable: ReturnType<typeof useModalManagerInternal>["notClosable"];
	} | null>(null);

	const useModalManagerContext = () => {
		const ctx = React.useContext(ModalManagerContext);

		if (!ctx) {
			throw new Error(
				"useModalManagerContext has to be used within <ModalManagerContext.Provider>",
			);
		}

		return ctx;
	};

	const useModalManager = () => {
		const { closeModal, openModal, setNotClosable, notClosable } =
			useModalManagerContext();
		return {
			modalManager: {
				close: closeModal,
				open: openModal,
				setNotClosable,
				notClosable,
			},
		};
	};

	const ModalFloatingOverlay: React.FC<{
		currentElement: ReturnType<
			typeof useModalManagerInternal
		>["modalManager"][keyof StateType];
	}> = ({ currentElement }) => {
		const { modalManager } = useModalManager();
		const ModalElement = currentElement.jsxElement;
		const outsideClickRef = React.useRef<HTMLDivElement>(null);
		const handleClickOutside = () => {
			if (modalManager.notClosable) {
				return;
			}

			modalManager.close(currentElement.modalName);
		};
		useOnClickOutside(outsideClickRef, handleClickOutside);
		return (
			<FloatingOverlay
				className={cx("z-modal-overlay relative", {
					"pointer-events-none": !currentElement.isOpen,
					"pointer-events-auto": currentElement.isOpen,
				})}
			>
				<AnimatePresence>
					{currentElement.isOpen && (
						<motion.div className="relative h-screen w-full">
							<motion.div
								className="z-modal-overlay absolute inset-0 h-screen w-full bg-[rgba(0,0,0,0.6)]"
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								exit={{ opacity: 0 }}
							/>
							<motion.div
								initial={{ scale: 0.5, opacity: 1 }}
								animate={{ scale: 1, opacity: 1 }}
								exit={{ scale: 0.5, opacity: 0 }}
								transition={{ type: "spring", bounce: 0.2 }}
								className="z-modal relative flex h-screen w-full items-center justify-center px-8"
							>
								<div
									ref={outsideClickRef}
									// TODO: try to replace this display:contents with something else, since it is not
									// accessible https://developer.mozilla.org/en-US/docs/Web/CSS/display#display_contents
									className="contents"
								>
									{/* @ts-ignore Check isReactComponent and flex render from @tanstack/table */}
									{/* @ts-ignore https://github.com/TanStack/table/blob/b9a30a109fc8c676547bd988152d6fcf2579999e/packages/react-table/src/index.tsx#L26 */}
									<ModalElement {...currentElement.props} />
								</div>
							</motion.div>
						</motion.div>
					)}
				</AnimatePresence>
			</FloatingOverlay>
		);
	};

	const ModalManager = ({
		children,
	}: {
		children: React.PropsWithChildren["children"];
	}) => {
		const {
			closeModal,
			openModal,
			modalManager,
			setNotClosable,
			notClosable,
		} = useModalManagerInternal();

		return (
			<ModalManagerContext.Provider
				value={{ openModal, closeModal, setNotClosable, notClosable }}
			>
				{children}
				{Object.values<(typeof modalManager)[keyof StateType]>(
					modalManager,
				).map((el) => (
					<ModalFloatingOverlay
						key={el.modalName}
						currentElement={el}
					/>
				))}
			</ModalManagerContext.Provider>
		);
	};

	return {
		ModalManager,
		useModalManager,
	};
};

export { configureModalManager };
