import React from "react";
import { type ColumnDef } from "@tanstack/react-table";
import { type diceBusinessLogicApi } from "business-logic-gamblino";
import {
	BetAmountCell,
	PayoutCell,
	CapCell,
	ScoreCell,
	TimeCell,
	RolledCell,
	MultiplierCell,
} from "./UserHistoryTable/Cells";

const PAGE_SIZE = 5;

const columns: ColumnDef<
	(typeof diceBusinessLogicApi)["endpoints"]["diceUserHistory"]["Types"]["ResultType"]["games"][number]
>[] = [
	{
		accessorFn: (row) => row.isWin,
		id: "score",
		header: () => <p className="text-content-secondary text-sm">Score</p>,
		cell: (info) => <ScoreCell isWin={info.row.original.isWin} />,
		footer: (props) => props.column.id,
	},
	{
		accessorFn: (row) => row.cap,
		id: "cap",
		header: () => (
			<p className="text-content-secondary text-sm">Your Number</p>
		),
		cell: (info) => <CapCell cap={info.row.original.cap} />,
		footer: (props) => props.column.id,
	},
	{
		accessorFn: (row) => row.rolled,
		id: "rolled",
		header: () => <p className="text-content-secondary text-sm">Rolled</p>,
		cell: (info) => <RolledCell rolled={info.row.original.rolled} />,
		footer: (props) => props.column.id,
	},
	{
		accessorFn: (row) => row.amount,
		id: "Bet amount",
		header: () => (
			<p className="text-content-secondary text-sm">Bet Amount</p>
		),
		cell: (info) => <BetAmountCell amount={info.row.original.amount} />,
		footer: (props) => props.column.id,
	},
	{
		accessorFn: (row) => 1,
		id: "multiplier",
		header: () => (
			<p className="text-content-secondary text-sm">Multiplier</p>
		),
		cell: (info) => (
			<MultiplierCell
				payout={info.row.original.payout}
				amount={info.row.original.amount}
			/>
		),
		footer: (props) => props.column.id,
	},
	{
		accessorFn: (row) => row.payout,
		id: "Payout",
		header: () => <p className="text-content-secondary text-sm">Payout</p>,
		cell: (info) => <PayoutCell payout={info.row.original.payout} />,
		footer: (props) => props.column.id,
	},
	{
		accessorFn: (row) => row.betTimestamp,
		id: "Time played",
		header: () => (
			<p className="text-content-secondary text-sm">Time played</p>
		),
		cell: (info) => <TimeCell time={info.row.original.betTimestamp} />,
		footer: (props) => props.column.id,
	},
];

export { PAGE_SIZE, columns };
