import {
	getFee,
	getLastUserBets,
	wager as wagerRouletteContract,
} from "contract-service-roulette";

import wait from "wait";
import { wagmiConfig } from 'wallet-connection';
import { getAccount } from "wagmi/actions";
import { zeroAddress } from "viem";

const wager = async ({
	amountWei,
	contractAddress,
	numbers,
}: Omit<Parameters<typeof wagerRouletteContract>[0], "feeWei">) => {
	const { fee } = await getFee({ contractAddress });
	const { txHash, betId } = await wagerRouletteContract({
		amountWei,
		contractAddress,
		numbers,
		feeWei: fee.toString(),
	});

	const { address } =  getAccount(wagmiConfig);

	const { payout, rolled } = await new Promise<{
		payout: bigint;
		rolled: number;
	}>((resolve, reject) => {
		void (async () => {
			// eslint-disable-next-line no-constant-condition
			for (let i = 0; i < 10; i++) {
				const result = await getLastUserBets({
					user: address || zeroAddress,
					contractAddress,
					length: 1,
				});
				if (result[0]) {
					if (
						result[0].bet.id.toString() === betId &&
						result[0].bet.resolved
					) {
						resolve({
							payout: result[0].bet.payout,
							rolled: result[0].rouletteBet.rolled,
						});
						break;
					}
				}
				await wait(1000);
			}
			reject({ message: "Request timed out" });
		})();
	});
	return {
		txHash,
		betId,
		payout: payout.toString(),
		rolled,
		isWin: payout.toString() && payout.toString() !== "0",
	};
};

export { wager };
