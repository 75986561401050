import React from "react";
import { graphql } from "../gql";
import {
	BetResult,
	BetStatus,
	BetsQuery,
	BetsQueryVariables,
	ConditionStatus,
	GameQuery,
	SelectionResult,
} from "../gql/graphql";
import { type Address } from "wagmi";
import { client } from "../apollo";
import { getMarketName, getSelectionName } from "@azuro-org/dictionaries";
import { GameStatus, getGameStatus } from "../helpers/getGameStatus";

const query = graphql(`
	query Bets(
		$first: Int
		$skip: Int
		$where: Bet_filter!
		$orderBy: Bet_orderBy
		$orderDirection: OrderDirection
	) {
		bets(
			first: $first
			skip: $skip
			where: $where
			orderBy: $orderBy
			orderDirection: $orderDirection
			subgraphError: allow
		) {
			id
			tokenId: betId
			amount
			status
			potentialPayout
			payout # don't account canceled sub-bets after resolve of express
			result
			isRedeemed
			isRedeemable
			odds
			settledOdds # don't account canceled sub-bets after resolve of express
			createdAt: createdBlockTimestamp
			txHash: createdTxHash
			core {
				address
				liquidityPool {
					address
				}
			}
			selections {
				odds
				result
				outcome {
					outcomeId
					condition {
						conditionId
						status
						game {
							id
							gameId
							title
							startsAt
							status
							sport {
								slug
								name
							}
							league {
								slug
								name
								country {
									slug
									name
								}
							}
							participants {
								image
								name
							}
						}
					}
				}
			}
			freebet {
				freebetId
				contractAddress: freebetContractAddress
			}
		}
	}
`);

export type BetOutcome = {
	selectionName: string;
	odds: number;
	marketName: string;
	game: GameQuery["game"];
	isWin: boolean | null;
	isLose: boolean | null;
	isCanceled: boolean;
} & Selection;

export type Bet = {
	id: string;
	tokenId: string;
	freebetId: string | null;
	freebetContractAddress?: Address;
	totalOdds: number;
	coreAddress: Address;
	lpAddress: Address;
	outcomes: BetOutcome[];
	txHash: string;
	status: BetStatus;
	amount: string;
	isActive: boolean;
	possibleWin: number;
	payout: number;
	createdAt: number;
	isWin: boolean;
	isLose: boolean;
	isRedeemable: boolean;
	isRedeemed: boolean;
	isCanceled: boolean;
};

const getBets = async (variables: BetsQueryVariables) => {
	const { data } = await client.query<BetsQuery, BetsQueryVariables>({
		query,
		variables,
	});

	if (!data?.bets?.length) {
		return [];
	}
	const bets = data.bets.map((rawBet) => {
		const {
			tokenId,
			status,
			amount,
			odds,
			id,
			settledOdds,
			createdAt,
			result,
			core: {
				address: coreAddress,
				liquidityPool: { address: lpAddress },
			},
			payout: _payout,
			isRedeemed: _isRedeemed,
			isRedeemable,
			freebet,
			txHash,
			selections,
		} = rawBet;

		const isWin = result === BetResult.Won;

		const isLose =
			result === BetResult.Lost ||
			rawBet.selections.some(
				(selection) => selection?.result === SelectionResult.Lost,
			);

		const isCanceled = status === BetStatus.Canceled;
		const isRedeemed = (isWin || isCanceled) && _isRedeemed;
		const isFreebet = Boolean(freebet);
		const isActive = status === BetStatus.Accepted;
		const freebetId = freebet?.freebetId;
		const freebetContractAddress = freebet?.contractAddress;
		const payout = isRedeemable && isWin ? _payout : null;
		const betDiff = isFreebet ? amount : 0;
		const totalOdds = settledOdds ? +settledOdds : +odds;
		const possibleWin = +amount * totalOdds - +betDiff;

		const outcomes = selections
			.map((selection) => {
				const {
					odds,
					result,
					outcome: {
						outcomeId,
						condition: {
							conditionId,
							status: conditionStatus,
							game,
						},
					},
				} = selection;

				const gameStatus = getGameStatus({
					graphStatus: game.status,
					startsAt: game.startsAt,
				});

				const isWin = result ? result === SelectionResult.Won : null;
				const isLose = result ? result === SelectionResult.Lost : null;
				const isCanceled =
					conditionStatus === ConditionStatus.Canceled ||
					gameStatus === GameStatus.Canceled;

				const marketName = getMarketName({ outcomeId });
				const selectionName = getSelectionName({
					outcomeId,
					withPoint: true,
				});

				return {
					selectionName,
					outcomeId,
					conditionId,
					odds: +odds,
					marketName,
					game,
					isWin,
					isLose,
					isActive,
					isCanceled,
				};
			})
			.sort(
				(a, b) => +a.game.startsAt - +b.game.startsAt,
			) as unknown as BetOutcome[];

		const bet: Bet = {
			tokenId,
			freebetContractAddress: freebetContractAddress as Address,
			freebetId,
			txHash,
			totalOdds,
			id,
			status,
			amount,
			possibleWin,
			payout,
			createdAt,
			isWin,
			isLose,
			isRedeemable,
			isRedeemed,
			isActive,
			isCanceled,
			coreAddress: coreAddress as Address,
			lpAddress: lpAddress as Address,
			outcomes,
		};

		return bet;
	});

	return bets;
};

export { getBets };
