import { PageSection } from "@components/atoms";
import React from "react";
import { cx } from "src/utils";
import { Button } from "@components/molecules";
import { Autoplay, EffectCoverflow } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { useNavigate } from "react-router-dom";
import { gamesConfig } from "../../../Games/GamesSection/data";

const uniqueArray = gamesConfig.map(({ imageUrl }) => imageUrl);

const config = [...uniqueArray, ...uniqueArray, ...uniqueArray];

const OurGamesSection: React.FC<{ className?: string }> = ({ className }) => {
	const navigate = useNavigate();
	return (
		<PageSection
			fullWidth
			className={cx("flex flex-col items-center", className)}
		>
			<h3 className="text-content-secondary text-center text-4xl font-bold lg:text-5xl">
				Our Games
			</h3>
			<p className="text-content-primary mt-2 text-center text-base lg:text-xl">
				Play more and get more
			</p>
			<div className="relative mt-5 w-full lg:mt-10">
				<div className="from-purple-black absolute bottom-0 left-0 top-0 z-[2] w-1/6 bg-gradient-to-r  md:w-1/4"></div>
				<div className="from-purple-black absolute bottom-0 right-0 top-0 z-[2] w-1/6 bg-gradient-to-l md:w-1/4"></div>
				<Swiper
					slidesPerView={2}
					spaceBetween={20}
					breakpoints={{
						768: {
							slidesPerView: 4,
							spaceBetween: 40,
						},
						1538: {
							slidesPerView: 5,
							spaceBetween: 40,
						},
					}}
					loop={true}
					pagination={{
						clickable: true,
					}}
					autoplay={{
						delay: 1500,
						disableOnInteraction: false,
					}}
					centeredSlides={true}
					effect={"coverflow"}
					coverflowEffect={{
						rotate: 0,
						stretch: 0,
						depth: 100,
						modifier: 1,
						slideShadows: false,
						scale: 0.9,
					}}
					modules={[EffectCoverflow, Autoplay]}
					className="mySwiper"
				>
					{config.map((url) => (
						<SwiperSlide key={url}>
							<img
								className="h-full w-full rounded-3xl"
								src={url}
							/>
						</SwiperSlide>
					))}
				</Swiper>
			</div>
			<Button
				onClick={() => {
					navigate("/games");
				}}
				type="outline"
				className="mt-5 lg:mt-10"
			>
				<Button.Text>Browse games</Button.Text>
			</Button>
		</PageSection>
	);
};

export { OurGamesSection };
