import { DashboardPageSection } from "@components/atoms";
import React from "react";
import { cx } from "src/utils";

const SecondSection: React.FC<{ className?: string }> = ({ className }) => {
	return (
		<DashboardPageSection className={cx("overflow-hidden", className)}>
			<h3 className="text-content-primary @[800px]/dashboard:text-5xl px-5 text-center text-3xl font-semibold">
				Share in Platform Rewards
			</h3>
			<p className="text-content-secondary @[800px]/dashboard:hidden @[800px]/dashboard:text-2xl mt-3 block w-full max-w-5xl px-5 text-center text-lg">
				For every friend you refer, you will receive 5% of the house
				edge in real-time. Added directly to your wallet
			</p>
			<div className="relative mt-5 flex justify-center">
				<p className="text-content-secondary @[800px]/dashboard:block @[800px]/dashboard:text-2xl absolute left-1/2 top-5 z-[1] hidden w-full max-w-5xl -translate-x-1/2 text-center text-lg">
					For every friend you refer, you will receive 5% of the house
					edge in real-time. Added directly to your wallet
				</p>
				<img
					className="@[500px]/dashboard:scale-100 pointer-events-none relative z-0 w-full max-w-6xl scale-150 select-none"
					src="/referral-orbit-image.png"
					alt=""
				/>
			</div>
		</DashboardPageSection>
	);
};

export { SecondSection };
