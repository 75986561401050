import { type IModal, Modal } from "@components/organisms";
import React from "react";
import { Button } from "@components/molecules";
import { useModalManager } from "@components/ethereals/ModalsWrapper";

const SimpleErrorModal: IModal<"SimpleErrorModal"> &
	React.FC<{
		heading: string;
		description: string;
	}> = ({ heading, description }) => {
	const { modalManager } = useModalManager();

	return (
		<Modal className="bg-background-secondary relative w-full max-w-[340px] overflow-hidden rounded-xl">
			<Modal.Step>
				<div className="flex flex-col items-center gap-3 p-6">
					<img
						className="h-64 w-64"
						src="/error-illustration.png"
						alt=""
					/>
					<h3 className="text-content-primary text-center text-lg font-semibold">
						{heading}
					</h3>
					<p className="text-content-primary w-full text-center text-sm">
						{description}
					</p>
					<div className="flex w-full gap-4">
						<Button
							type="outline"
							size="sm"
							onClick={() => {
								modalManager.close("SimpleErrorModal");
							}}
							className={"w-full"}
						>
							<Button.Text>Close</Button.Text>
						</Button>
					</div>
				</div>
			</Modal.Step>
		</Modal>
	);
};

SimpleErrorModal.modalName = "SimpleErrorModal";

export { SimpleErrorModal };
