import React from "react";
import { cx } from "src/utils";

const imageTypes = [
	"WALLET_NOT_CONNECTED",
	"TABLE_EMPTY_STATE",
	"UNDER_CONSTRUCTION",
	"ERROR",
] as const;

const getImageType = (imageType: (typeof imageTypes)[number]) => {
	if (imageType === "TABLE_EMPTY_STATE") {
		return "/empty-box.png";
	}

	if (imageType === "WALLET_NOT_CONNECTED") {
		return "/wallet-empty-state.png";
	}

	if (imageType === "UNDER_CONSTRUCTION") {
		return "/under-maintenance.png";
	}

	if (imageType === "ERROR") {
		return "/error-illustration.png";
	}

	return "";
};

const EmptyState: React.FC<{
	className?: string;
	imageType: (typeof imageTypes)[number];
	heading: string;
	description: string;
	bottomElement?: React.JSX.Element | null;
}> = ({ className, imageType, heading, description, bottomElement = null }) => {
	return (
		<div className={cx("flex flex-col items-center gap-6", className)}>
			<img
				className="w-full max-w-[200px]"
				src={getImageType(imageType)}
				alt="empty state"
			/>
			<div>
				<h3 className="text-content-primary text-center text-xl font-semibold">
					{heading}
				</h3>
				<p className="text-content-secondary mt-2 max-w-sm text-center text-sm font-normal">
					{description}
				</p>
			</div>
			{bottomElement}
		</div>
	);
};

export { EmptyState };
