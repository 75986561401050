import { DashboardPageSection, Icon } from "@components/atoms";
import { Button } from "@components/molecules";
import { useGetPromotionsQuery } from "business-logic-gamblino";
import React from "react";
import type { GetEndpointReturnType } from "src/redux/store";
import { cx } from "src/utils";
import { Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import { useWindowSize } from "usehooks-ts";
import { useAccount } from "wagmi";
import { motion } from "framer-motion";

const Promotion: React.FC<
	GetEndpointReturnType<"getPromotions">["quests"][number] & {
		className?: string;
	}
> = ({
	title,
	description,
	backgroundImg,
	completed,
	questId,
	xpReward,
	className,
}) => {
	const { isConnected } = useAccount();

	return (
		<motion.div
			whileHover={{ scale: 0.98 }}
			transition={{ type: "spring" }}
			className={cx(
				"bg-background-secondary @container relative h-full cursor-pointer overflow-hidden rounded-2xl p-4",
				className,
			)}
			onClick={() => {
				window.open(
					`https://zealy.io/c/gamblino/questboard/${questId}`,
					"_blank",
				);
			}}
		>
			<img
				className="absolute right-0 top-1/2 max-w-[30%] -translate-y-1/2"
				src={backgroundImg}
				alt=""
			/>
			<div className="bg-content-primary w-min rounded-full px-2 py-1 ">
				<p className="text-gradient-1 uppercase">Promotion</p>
			</div>
			<h3 className="text-content-primary mt-4 text-xl font-medium">
				{title}
			</h3>
			<p className="text-content-primary mt-2 line-clamp-3  h-[72px] max-w-[60%]  text-base">
				{description}
			</p>
			<div className="mt-2">
				{isConnected && completed ? (
					<div
						onClick={() => {
							window.open(
								`https://zealy.io/c/gamblino/questboard/${questId}`,
								"_blank",
							);
						}}
						className="text-badge-content-success flex w-max items-center gap-1 rounded-full border px-2 py-1"
					>
						<Icon
							type="CHECK_CIRCLE"
							className="stroke-text-badge-content-success h-6 w-6"
						/>
						<p className="text-badge-content-success font-bold">
							Completed ({xpReward} XP)
						</p>
					</div>
				) : (
					<Button
						onClick={() => {}}
						type="outline"
						className="bg-button-secondary-background text-content-secondary"
						size="sm"
					>
						<Button.Text>Complete Task</Button.Text>
						<Button.Icon type="EXTERNAL_LINK"></Button.Icon>
					</Button>
				)}
			</div>
		</motion.div>
	);
};

const PromotionsSection: React.FC<{ className?: string }> = ({ className }) => {
	const { width } = useWindowSize();
	const { data, isSuccess } = useGetPromotionsQuery(undefined);

	if (!isSuccess) {
		return null;
	}

	if (data.quests.length === 0) {
		return null;
	}

	return (
		<DashboardPageSection className={cx("", className)}>
			<h4 className="text-content-primary text-3xl">Zealy Campaign</h4>

			<Swiper
				slidesPerView={1}
				spaceBetween={16}
				slidesPerGroup={1}
				slidesPerGroupAuto
				breakpoints={{
					700: {
						slidesPerView: 2,
						slidesPerGroup: 2,
					},
				}}
				// loop={true}
				rewind
				pagination={{
					clickable: true,
					clickableClass: "static",

					bulletActiveClass: "bg-gamblino-light opacity-100",
				}}
				autoplay={{
					delay: 4500,
					disableOnInteraction: false,
				}}
				modules={[Pagination, Autoplay]}
				className="mt-4"
				// wrapperClass=" h-full"
			>
				{data.quests.map((promotion) => (
					<SwiperSlide className="h-full" key={promotion.name}>
						<Promotion {...promotion} />
					</SwiperSlide>
				))}
			</Swiper>
		</DashboardPageSection>
	);
};

export { PromotionsSection };
