import { AssetIcon } from "@components/atoms";
import React from "react";
import { getNativeChainIconType } from "src/utils/getNativeChainIconType";
const AssetIconTypeRoulette: React.ComponentProps<typeof AssetIcon>["type"] =
	getNativeChainIconType();

const AssetIconRoulette: React.FC<{ className?: string }> = ({ className }) => {
	return <AssetIcon className={className} type={AssetIconTypeRoulette} />;
};

export { AssetIconRoulette, AssetIconTypeRoulette };
