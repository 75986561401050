import { Modal } from "@components/organisms/Modal";
import type { IModal } from "@components/organisms/Modal";
import { useSubscribeToEmailListMutation } from "business-logic-gamblino";
import {
	useTime,
	useTransform,
	motion,
	useMotionTemplate,
} from "framer-motion";
import React from "react";
import type { GetEndpointArgType } from "src/redux/store";
import { cx } from "src/utils";
import { AnimatedSuccessIllustration } from "./AnimatedSuccessIllustration";
// import { AnimatedMutationStatusStep } from "@components/organisms/AnimatedMutationStatusStep";
// import { useSubscribeToEmailListMutation } from "business-logic-gamblino";

const SubscribeToEmailListModal: IModal<"SubscribeToEmailListModal"> &
	React.FC<GetEndpointArgType<"subscribeToEmailList">> = ({ email }) => {
	const time = useTime();
	const rotate = useTransform(time, [0, 10000], [0, 360], { clamp: false });

	const backgroundImageSuccess = useMotionTemplate`linear-gradient(${rotate}deg, #3CB878, #3E2F7A)`;

	const [subscribeToEmailList] = useSubscribeToEmailListMutation();

	React.useEffect(() => {
		if (email) {
			subscribeToEmailList({ email });
		}
	}, [email]);

	return (
		<Modal className="bg-content-primary relative w-full max-w-[500px] overflow-hidden rounded-xl">
			<Modal.Step>
				<div className="relative h-[240px] w-full overflow-hidden rounded-xl">
					<motion.div
						className="absolute left-0 right-0 top-0 h-full w-full "
						style={{
							backgroundSize: "100% 100%",
							backgroundImage: backgroundImageSuccess,
						}}
						transition={{ ease: "easeInOut", duration: 3 }}
					/>
					<AnimatedSuccessIllustration className="absolute left-[50%] top-[50%] h-48 w-48 -translate-x-[50%] -translate-y-[50%]" />
				</div>
				<div className="min-h-24 w-full p-5">
					<h3 className="text-purple-black text-center text-xl font-semibold">
						You’re subscribed!
					</h3>
					<p className="text-purple-black mt-1 text-center">
						Stay tuned to your email inbox for the latest updates
						and news from Gamblino!
					</p>
				</div>
			</Modal.Step>
		</Modal>
	);
};

SubscribeToEmailListModal.modalName = "SubscribeToEmailListModal";

export { SubscribeToEmailListModal };
