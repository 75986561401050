import type { schema } from "./schema";
import type { z } from "zod";

type ModelType = z.infer<typeof schema>;

const response1: ModelType = {
	status: "ok",
	games: [
		{
			betId: "12345678901234567890123456789012345678901234567890123456789012345678901",
			amount: 0.125,
			betTimestamp: 1682923400,
			betTxHash:
				"0xabcdef1234567890abcdef1234567890abcdef1234567890abcdef1234567890",
			betFace: true,
			rolledFace: true,
			isWin: true,
			payout: 0.5,
		},
		{
			betId: "98765432109876543210987654321098765432109876543210987654321098765432109",
			amount: 0.075,
			betTimestamp: 1682923401,
			betTxHash:
				"0x1234567890abcdef1234567890abcdef1234567890abcdef1234567890abcdef12",
			betFace: false,
			rolledFace: true,
			isWin: false,
			payout: 0.0,
		},
		{
			betId: "24681357902468135790246813579024681357902468135790246813579024681357902",
			amount: 0.1,
			betTimestamp: 1682923402,
			betTxHash:
				"0x7890abcdef1234567890abcdef1234567890abcdef1234567890abcdef1234567890",
			betFace: true,
			rolledFace: false,
			isWin: false,
			payout: 0.0,
		},
		{
			betId: "13579246801357924680135792468013579246801357924680135792468013579246801",
			amount: 0.05,
			betTimestamp: 1682923403,
			betTxHash:
				"0xabcdef1234567890abcdef1234567890abcdef1234567890abcdef1234567890abcdef",
			betFace: false,
			rolledFace: false,
			isWin: true,
			payout: 0.1,
		},
		{
			betId: "54321098765432109876543210987654321098765432109876543210987654321098765",
			amount: 0.075,
			betTimestamp: 1682923404,
			betTxHash:
				"0x4567890abcdef1234567890abcdef1234567890abcdef1234567890abcdef12345678",
			betFace: true,
			rolledFace: true,
			isWin: true,
			payout: 0.3,
		},
		{
			betId: "11111111111111111111111111111111111111111111111111111111111111111111",
			amount: 0.01,
			betTimestamp: 1682923405,
			betTxHash:
				"0x1111111111111111111111111111111111111111111111111111111111111111111",
			betFace: false,
			rolledFace: true,
			isWin: false,
			payout: 0.0,
		},
		{
			betId: "22222222222222222222222222222222222222222222222222222222222222222222",
			amount: 0.02,
			betTimestamp: 1682923406,
			betTxHash:
				"0x2222222222222222222222222222222222222222222222222222222222222222222",
			betFace: true,
			rolledFace: true,
			isWin: true,
			payout: 0.04,
		},
		{
			betId: "33333333333333333333333333333333333333333333333333333333333333333333",
			amount: 0.03,
			betTimestamp: 1682923407,
			betTxHash:
				"0x3333333333333333333333333333333333333333333333333333333333333333333",
			betFace: true,
			rolledFace: false,
			isWin: false,
			payout: 0.0,
		},
		{
			betId: "44444444444444444444444444444444444444444444444444444444444444444444",
			amount: 0.04,
			betTimestamp: 1682923408,
			betTxHash:
				"0x4444444444444444444444444444444444444444444444444444444444444444444",
			betFace: false,
			rolledFace: false,
			isWin: true,
			payout: 0.08,
		},
		{
			betId: "55555555555555555555555555555555555555555555555555555555555555555555",
			amount: 0.05,
			betTimestamp: 1682923409,
			betTxHash:
				"0x5555555555555555555555555555555555555555555555555555555555555555555",
			betFace: true,
			rolledFace: true,
			isWin: true,
			payout: 0.25,
		},
		{
			betId: "66666666666666666666666666666666666666666666666666666666666666666666",
			amount: 0.06,
			betTimestamp: 1682923410,
			betTxHash:
				"0x6666666666666666666666666666666666666666666666666666666666666666666",
			betFace: false,
			rolledFace: true,
			isWin: false,
			payout: 0.0,
		},
		{
			betId: "77777777777777777777777777777777777777777777777777777777777777777777",
			amount: 0.07,
			betTimestamp: 1682923411,
			betTxHash:
				"0x7777777777777777777777777777777777777777777777777777777777777777777",
			betFace: true,
			rolledFace: false,
			isWin: false,
			payout: 0.0,
		},
	],
};

export default { response1 };
