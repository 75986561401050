import { cx } from "class-variance-authority";
import React from "react";
import { azuroSlice } from "src/redux/azuroSlice";
import { useAppDispatch, useAppSelector } from "src/redux/store";

const BetSlipHeading: React.FC<{ className?: string }> = ({ className }) => {
	const bets = useAppSelector((state) => state.azuro);

	const dispatch = useAppDispatch();

	if (!bets || bets.length === 0) {
		return <div className="h-4"></div>;
	}

	const handleDeleteAllBets = () => {
		dispatch(azuroSlice.actions.deleteAllBets());
	};

	return (
		<div className={cx("flex justify-end  px-4 lg:px-2", className)}>
			<p
				className="text-content-primary cursor-pointer text-xs"
				onClick={handleDeleteAllBets}
			>
				Remove all
			</p>
		</div>
	);
};

export { BetSlipHeading };
