import React from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

import { cx } from "src/utils";
import { GameType } from "../data";
import { Icon } from "@components/atoms";

const Card: React.FC<{
	game: GameType;
}> = ({ game }) => {
	const navigate = useNavigate();

	return (
		<motion.div
			whileHover={{ scale: 0.98 }}
			transition={{ type: "spring" }}
			className="flex w-full flex-col items-center"
		>
			<div
				className={cx(
					"relative cursor-pointer overflow-hidden rounded-2xl",
					{
						"cursor-not-allowed": !game.url,
					},
				)}
				onClick={() => {
					game.url && navigate(game.url);
				}}
			>
				<img className="h-full w-full" src={game.imageUrl} alt="" />

				<div
					className={cx(
						"bg-gamblino-dark absolute right-2 top-2 z-10 flex h-[20px] w-[50px] items-center gap-1 rounded-3xl bg-opacity-50 px-[6px] text-white",
						{
							"bg-badge-background-tag bg-opacity-100":
								game.comingSoon,
						},
					)}
				>
					{game.labelIcon && (
						<Icon
							className="stroke-content-primary h-3 w-3"
							type={game.labelIcon}
						/>
					)}
					<p
						className={cx("text-xs", {
							"text-content-primary": game.comingSoon,
						})}
					>
						{game.labelText}
					</p>
				</div>
			</div>
		</motion.div>
	);
};

export { Card };
