import React from "react";
import { Button } from "@components/molecules";
import { useModalControls } from "@components/organisms";
import { cx } from "src/utils";

const NextButton: React.FC<{ className?: string }> = ({ className }) => {
	const { goToNextStep, canNextStep } = useModalControls();

	return (
		<Button
			className={cx("h-7 w-7 p-0", className)}
			onClick={goToNextStep}
			type="outline"
			disabled={!canNextStep}
		>
			<Button.Icon type="CHEVRON_RIGHT" className="h-5 w-5" />
		</Button>
	);
};

const PreviousButton: React.FC<{ className?: string }> = ({ className }) => {
	const { goToPreviousStep, canPreviousStep } = useModalControls();

	return (
		<Button
			className={cx("h-7 w-7 p-0", className)}
			onClick={goToPreviousStep}
			type="outline"
			disabled={!canPreviousStep}
		>
			<Button.Icon type="CHEVRON_LEFT" className="h-5 w-5" />
		</Button>
	);
};

const ModalButtons: React.FC<{ className?: string }> = ({ className }) => {
	const { currentStep, numberOfSteps } = useModalControls();
	return (
		<div
			className={cx(
				"flex w-full items-center justify-between",
				className,
			)}
		>
			<p className="">
				Step <span className="">{currentStep}</span>
				<span className="text-content-secondary text-xs">
					{" "}
					/ {numberOfSteps}
				</span>
			</p>
			<div className="flex items-center gap-2">
				<PreviousButton className="" />
				<NextButton className="" />
			</div>
		</div>
	);
};

export { ModalButtons };
