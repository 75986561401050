import { PageSection } from "@components/atoms";
import React from "react";
import { cx } from "src/utils";
import { RabbitIllustration } from "./RabbitIllustration";
import { ImageWithLoading } from "./ImageWithLoading";
import { useScroll, useTransform, motion } from "framer-motion";

const config: { heading: string; description: string }[] = [
	{
		heading: "Secure and Transparent",
		description:
			"Our platform contract are open source and auditable, ensuring the highest level of security and transparency across all games.",
	},
	{
		heading: "Publicly Auditable",
		description:
			"Anyone can independently verify the randomness generation process provided by Chainlink VRF.",
	},
	{
		heading: "Instant Payouts",
		description:
			"Winnings can be claimed and transferred directly to your wallet instantly, providing you with quick access to your funds.",
	},
	{
		heading: "Community and Social Features",
		description:
			"Engage with fellow players, form alliances, and share your gaming experiences on our interactive platform.",
	},
	{
		heading: "Real-Time Action",
		description:
			"Experience the excitement of real-time betting and lottery draws, right at your fingertips.",
	},
];

const Box: React.FC<{
	heading: string;
	description: string;
	className?: string;
}> = ({ heading, description, className }) => (
	<div
		className={cx(
			"border-violet-light bg-gradient-light-glass rounded-3xl border border-opacity-30 p-6 shadow-[0px_4px_4px_0px_rgba(0,_0,_0,_0.25)] backdrop-blur-md",
			className,
		)}
	>
		<h4 className="text-gradient-1 text-xl font-semibold lg:text-4xl">
			{heading}
		</h4>
		<p className="mt-4 text-lg lg:mt-6">{description}</p>
	</div>
);

const WhyGamblinoSection: React.FC<{ className?: string }> = ({
	className,
}) => {
	const ref = React.useRef<HTMLDivElement>(null);
	const { scrollYProgress } = useScroll({
		target: ref,
		offset: ["start end", "end start"],
	});

	const animations = {
		ribbon: {
			y: useTransform(scrollYProgress, [0, 1], [-30, 30]),
			rotate: useTransform(scrollYProgress, [0, 0.5], [-20, 0]),
		},
		sphere: {
			y: useTransform(scrollYProgress, [0.3, 1], [20, 75]),
			rotate: useTransform(scrollYProgress, [0.3, 1], [30, 210]),
		},
		ring1: {
			y: useTransform(scrollYProgress, [0, 1], [-20, 100]),
			rotate: useTransform(scrollYProgress, [0, 1], [-30, 30]),
			x: -80,
		},
		ring2: {
			y: useTransform(scrollYProgress, [0.33, 1], [80, 100]),
			x: useTransform(scrollYProgress, [0.33, 1], [0, -140]),
			rotate: useTransform(scrollYProgress, [0.33, 1], [-100, -150]),
			scale: useTransform(scrollYProgress, [0.33, 1], [0.6, 0.9]),
		},
		ring3: {
			y: useTransform(scrollYProgress, [0, 1], [0, 150]),
			rotate: useTransform(scrollYProgress, [0, 1], [0, 70]),
		},
	};

	return (
		<PageSection className={cx("relative", className)} ref={ref}>
			<h3 className="text-content-primary text-center text-4xl font-semibold lg:text-5xl">
				Why Gamblino?
			</h3>

			<RabbitIllustration className="absolute left-1/2 z-0 hidden -translate-x-1/2 lg:top-20 lg:block xl:top-16 2xl:top-0" />
			<motion.div
				className="absolute left-2/3 top-0 hidden lg:block"
				style={{
					...animations.ribbon,
				}}
			>
				<ImageWithLoading
					url="/why-gamblino-ribbon.png"
					className="h-32 w-32"
				/>
			</motion.div>
			<motion.div
				className="absolute bottom-0 left-2/3 hidden lg:block"
				style={{
					...animations.sphere,
				}}
			>
				<ImageWithLoading
					url="/why-gamblino-sphere.png"
					className="h-32 w-32"
				/>
			</motion.div>
			<motion.div
				className="absolute left-[20%] hidden lg:top-[44%] lg:block 2xl:top-1/3"
				style={{
					...animations.ring1,
				}}
			>
				<ImageWithLoading
					url="/why-gamblino-ring-1.png"
					className="h-44 w-44 2xl:h-52 2xl:w-52"
				/>
			</motion.div>
			<motion.div
				className="absolute left-[20%] z-[2] hidden lg:bottom-0 lg:block"
				style={{
					...animations.ring2,
				}}
			>
				<ImageWithLoading
					url="/why-gamblino-ring-2.png"
					className="h-44 w-44 2xl:h-52 2xl:w-52"
				/>
			</motion.div>
			<motion.div
				className="absolute right-9 top-1/3 hidden h-44 w-44 lg:bottom-0 lg:block 2xl:h-52 2xl:w-52"
				style={{
					...animations.ring3,
				}}
			>
				<ImageWithLoading
					url="/why-gamblino-ring-3.png"
					className="h-44 w-44 2xl:h-52 2xl:w-52"
				/>
			</motion.div>
			<div
				className={cx(
					"relative z-[1] mt-10 grid grid-cols-1 gap-5 lg:grid-cols-3 lg:gap-y-[80px] xl:gap-y-[170px] 2xl:gap-y-[216px]",
				)}
			>
				<Box {...config[0]} className="" />
				<Box {...config[1]} className="lg:col-start-3" />
				<RabbitIllustration className="block justify-self-center lg:hidden" />
				<Box {...config[2]} className="" />
				<Box {...config[3]} className="" />
				<Box {...config[4]} className="" />
			</div>
		</PageSection>
	);
};

export { WhyGamblinoSection };
