import type { schema } from "./schema";
import type { z } from "zod";

type ModelType = z.infer<typeof schema>;

const response1: ModelType = {
	status: "ok",
	meta: {
		leaderboardRank: 1,
	},
	leaderboard: [
		{
			game: "sportsbook",
			totalVolume: 0.44,
			netProfit: 0.29875,
			roundsPlayed: 1,
			wins: 1,
			losses: 0,
			streak: 1,
			topVictory: {
				payout: 0.44,
				multiplier: 1.22,
				entry: 0.44,
			},
			fortunateVictory: null,
		},
	],
};

export default { response1 };
