/* eslint-disable max-lines-per-function */
import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react";
import {
	getLeaderboard,
	getUserStats,
	getLeaderboardPoints,
} from "../../../backend-service-gamblino/src";
import { getEndpointCreators } from "get-endpoint-creators";

const leaderboardBussinessLogicApi = createApi({
	reducerPath: "leaderboardBussinessLogicApi",
	baseQuery: fakeBaseQuery(),
	tagTypes: ["Bets"],
	endpoints: (builder) => {
		const { createQuery, createMutation } = getEndpointCreators(builder);

		return {
			leaderboard: createQuery(getLeaderboard),
			userStats: createQuery(getUserStats),
			leaderboardPoints: createQuery(getLeaderboardPoints),
		};
	},
});

const { useLeaderboardQuery, useUserStatsQuery, useLeaderboardPointsQuery } =
	leaderboardBussinessLogicApi;

const leaderboard = {
	useLeaderboardQuery,
	useUserStatsQuery,
	useLeaderboardPointsQuery,
};

export { leaderboard, leaderboardBussinessLogicApi };
