import { type IModal, Modal } from "@components/organisms";
import React from "react";
import { Button } from "@components/molecules";
import { useModalManager } from "@components/ethereals/ModalsWrapper";

const WelcomeModalBeta: IModal<"WelcomeModalBeta"> & React.FC = () => {
	const { modalManager } = useModalManager();

	return (
		<Modal className="bg-background-secondary relative w-full max-w-[600px] overflow-hidden rounded-xl">
			<Modal.Step>
				<div className="p-6">
					<h2 className="text-content-primary  text-xl font-semibold">
						Welcome to Our Beta Application!
					</h2>
					<h3 className="text-content-primary mt-5  text-base font-semibold">
						Hello there! 👋
					</h3>
					<p className="text-content-primary mt-1 w-full text-sm">
						We&apos;re thrilled to have you onboard. As you&apos;ve
						noticed, you&apos;re exploring our{" "}
						<strong>beta version</strong>. This means you&apos;re
						among the first to experience the new features
						we&apos;re cooking up! 🚀
					</p>
					<h3 className="text-content-primary mt-5  text-base font-semibold">
						What to Expect?
					</h3>
					<ul className="text-content-primary mt-1 w-full text-sm">
						<li>
							You might encounter some rough edges and the
							occasional hiccup. It&apos;s all part of the beta
							charm!
						</li>
						<li>
							New features will be rolling out regularly. Stay
							tuned!
						</li>
					</ul>
					{/* <h3 className="text-content-primary mt-5 text-base font-semibold">
						We Need Your Insights!
					</h3>
					<p className="text-content-primary w-full mt-1 text-sm">
						Your feedback is invaluable to us. Whether it&apos;s a
						bug, a suggestion, or a eureka moment you&apos;ve had,
						we&apos;re all ears. Your input will directly influence
						the final version of our app.
					</p>
					<div className="flex w-full gap-4">
						<Button
							type="outline"
							size="sm"
							onClick={() => {
								modalManager.close("WelcomeModalBeta");
							}}
							className={"w-full"}
						>
							<Button.Text>Feedback</Button.Text>
						</Button>
					</div> */}
					<p className="text-content-primary mt-5 w-full text-sm">
						Thank you for being a part of our journey. Let&apos;s
						make something amazing together!
					</p>

					<Button
						type="outline"
						size="sm"
						onClick={() => {
							modalManager.close("WelcomeModalBeta");
						}}
						className={"bg-button-secondary-background mt-5 w-full"}
					>
						<Button.Text>Close</Button.Text>
					</Button>
				</div>
			</Modal.Step>
		</Modal>
	);
};

WelcomeModalBeta.modalName = "WelcomeModalBeta";

export { WelcomeModalBeta };
