import React from "react";
import { Address } from "viem";
import { DashboardPageSection } from "@components/atoms";
import { DiceGameSectionHistory } from "./History/DiceGameSectionHistory";
import DiceGameSectionForm from "./Form/DiceGameSectionForm";

import { MuteUnmuteButton, OpenContractButton } from "@components/organisms";
import DiceGameSlider from "./Slider/DiceGameSlider";
import { motion, useAnimate } from "framer-motion";
import { dice } from "business-logic-gamblino";
import { OpenGameStats } from "@components/organisms/OpenGameStats/OpenGameStats";

const DiceGameSection: React.FC<{ contractAddress: Address }> = ({
	contractAddress,
}) => {
	const [rollOverValue, setRollOverValue] = React.useState<number[]>([50]);

	const [lastRolledLabelValue, setLastRolledLabelValue] =
		React.useState<number>(0);

	const handleOnChangeRollOver = (e: React.SetStateAction<string>) => {
		const result = e.toString().replace(/\D/g, "");

		if (+result > 99) return setRollOverValue([99]);
		if (+result < 0) return setRollOverValue([1]);

		setRollOverValue([+result]);
	};

	const [scope, animate] = useAnimate();

	const animateSlider = (amount: number) => {
		animate(
			scope.current,
			{
				left: `${amount}%`,
				opacity: 1,
			},
			{
				onUpdate(latest) {
					setLastRolledLabelValue(+latest.slice(0, -1));
				},
				duration: 1,
			},
		);
	};

	const [wager, { data: wagerData, isSuccess }] = dice.useWagerMutation({
		fixedCacheKey: `useWagerMutation`,
	});

	// animate slider upon game completion
	React.useEffect(() => {
		if (!isSuccess || !wagerData) {
			return;
		}
		animateSlider(wagerData.rolled);
	}, [isSuccess, wagerData?.rolled]);

	React.useEffect(() => {
		animateSlider(50);
	}, []);

	// React.useEffect(() => {
	// 	if (!isHowToModalShown) {
	// 		setIsHowToModalShown(true);
	// 		setTimeout(() => {
	// 			modalManager.open("HowToPlayDiceModal", {});
	// 		}, 5000);
	// 	}
	// }, [isHowToModalShown]);

	return (
		<>
			<DashboardPageSection className="z-1 relative flex items-center justify-between">
				<h1 className="text-3xl">Dice</h1>

				<div className="flex items-center gap-2">
					<MuteUnmuteButton />
					<OpenContractButton contractAddress={contractAddress} />
					{/* <OpenHowToModalButton modalName="HowToPlayDiceModal" /> */}
					<OpenGameStats />
				</div>
			</DashboardPageSection>

			<DashboardPageSection className="relative z-0 mt-4 flex h-full flex-col items-center gap-4 md:flex-row">
				<div className="bg-background-secondary border-border-primary flex h-[366px] w-full flex-col items-center justify-between rounded-2xl border bg-opacity-90 p-4 pb-14 backdrop-blur-md md:flex-auto md:basis-4/6">
					<DiceGameSectionHistory />

					<motion.div
						initial={{
							scale: 0.5,
							y: -100,
							opacity: 0,
						}}
						transition={{
							delay: 1,
						}}
						animate={{
							scale: 1,
							y: 0,
							opacity: 1,
						}}
						className="bg-background-tertiary flex w-[100px] flex-col items-center justify-center gap-1 rounded-lg p-4"
					>
						<p className="text-content-primary text-3xl ">
							{rollOverValue}
						</p>
						<p className="text-content-secondary text-sm">
							Roll Over
						</p>
					</motion.div>
					<DiceGameSlider
						rollOverValue={rollOverValue}
						setRollOverValue={setRollOverValue}
						scope={scope}
						lastRolledLabelValue={lastRolledLabelValue}
					/>
				</div>

				<div className="bg-background-secondary border-border-primary flex h-[366px] w-full flex-col gap-4 rounded-2xl border p-4 md:w-auto md:basis-3/6">
					<DiceGameSectionForm
						onChangeRollOver={handleOnChangeRollOver}
						rollOverValue={rollOverValue}
						contractAddress={contractAddress}
						className=""
					/>
				</div>
			</DashboardPageSection>
		</>
	);
};

export default DiceGameSection;
