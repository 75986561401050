import React from "react";
import { useAccount } from "wagmi";
import { WalletConnectedPopover } from "./WalletConnectedPopover/WalletConnectedPopover";
import { Button } from "@components/molecules";
import { useConnectModal, useChainModal } from "@rainbow-me/rainbowkit";
import { wagmiConfig } from "wallet-connection";

const WalletConnection = () => {
	const { openConnectModal } = useConnectModal();
	const { openChainModal } = useChainModal();

	const { chainId, isConnected } = useAccount();
	const isCorrectChain = chainId && chainId === wagmiConfig.chains[0].id;

	if (isConnected && !isCorrectChain) {
		return (
			<Button
				type="ghost"
				className="bg-red text-content-primary bg-background-error h-fit px-3 py-2 font-normal"
				onClick={openChainModal}
			>
				<Button.Text className="text-content-primary ">
					Wrong network
				</Button.Text>
			</Button>
		);
	}

	if (!isConnected) {
		return (
			<Button
				onClick={openConnectModal}
				type="ghost"
				className="bg-gradient-button text-content-primary h-fit px-3 py-2 font-normal"
			>
				<Button.Text className="text-content-primary">
					Connect wallet
				</Button.Text>
			</Button>
		);
	}

	return <WalletConnectedPopover />;
};

export default WalletConnection;
