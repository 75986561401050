import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "@components/atoms";
import { useAsideStatus } from "src/redux/useAsideStatus";

const LogoAndAsideTogglePart = () => {
	const { asideStatus, setAsideStatus } = useAsideStatus();

	return (
		<React.Fragment>
			{asideStatus && (
				<Link to="/" aria-label="Logo">
					<img
						className="h-12"
						src="/gamblino-logo-without-text.svg"
						alt=""
					/>
				</Link>
			)}
			<button
				type="button"
				aria-label="Toggle Aside Menu"
				onClick={() => setAsideStatus(!asideStatus)}
				className="flex h-12 w-12 items-center justify-center"
			>
				<Icon
					type={asideStatus ? "X" : "HAMBURGER"}
					className="stroke-content-primary h-6 w-6"
				/>
			</button>
		</React.Fragment>
	);
};

export default LogoAndAsideTogglePart;
