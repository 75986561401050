import React from "react";
import { useAppDispatch, useAppSelector } from "./store";
import { asideStatusSlice } from "./asideStatus";

const useAsideStatus = () => {
	const { asideStatus } = useAppSelector((state) => state.asideStatus);
	const dispatch = useAppDispatch();

	const setAsideStatus = React.useCallback((status: boolean) => {
		dispatch(asideStatusSlice.actions.setAsideStatus(status));
	}, []);

	return {
		asideStatus,
		setAsideStatus,
	};
};

export { useAsideStatus };
