import { z } from "zod";

const schema = z
	.object({
		status: z.string(),
		games: z.array(
			z.object({
				entry: z.number(),
				payout: z.number(),
				txHash: z.string(),
				rolled: z.number(),
				number: z.string(),
				timestamp: z.number(),
				status: z.union([z.literal("win"), z.literal("lost")]),
			}),
		),
	})
	.strict();

export { schema };
