import { GameQueryVariables } from "../gql/graphql";
import { client } from "../apollo";
import { graphql } from "../gql";

const query = graphql(`
	query Game($id: ID!) {
		game(id: $id) {
			id
			turnover
			startsAt
			status
			sport {
				id
				name
				slug
				__typename
			}
			league {
				name
				country {
					name
					__typename
				}
				__typename
			}
			participants {
				name
				image
				__typename
			}
			liquidityPool {
				address
				__typename
			}
			conditions(orderBy: turnover, orderDirection: desc) {
				conditionId
				turnover
				wonOutcomeIds
				isExpressForbidden
				outcomes {
					id
					outcomeId
					currentOdds
					__typename
				}
				core {
					address
					type
					__typename
				}
				__typename
			}
			__typename
		}
	}
`);

const getGame = async (variables: GameQueryVariables) => {
	if (!client) {
		throw new Error("Apollo client not initialized");
	}

	const {
		data: { game },
	} = await client.query({
		query,
		variables,
	});

	return game;
};

export { getGame };
