import { readContract } from "wagmi/actions";
import { abi } from "../../config";
import { wagmiConfig } from "wallet-connection";
import { Address, formatEther, zeroAddress } from "viem";

const getMinBetAmount = async ({
	bankContractAddress,
}: {
	bankContractAddress: Address;
}) => {
	const result = await readContract(wagmiConfig, {
		abi,
		address: bankContractAddress,
		functionName: "getMinBetAmount",
		args: [zeroAddress],
	});

	const minBetAmountWei = `${result}`;
	const minBetAmount = +formatEther(result);

	return { minBetAmountWei, minBetAmount };
};

export { getMinBetAmount };
